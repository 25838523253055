import '../../styles/_faculty-panel.scss';

import React from 'react';

import { useAppDispatch } from '../../hooks';
import { FetchType, useAnalytics } from '../../hooks/useAnalytics';
import { setSelectedFaculty } from '../Store/facultySlice';

export type IFacultyProps = {
    individualSk?: number;
    name: string;
    institutionName: string;
    imageUrl: string;
    biography: string;
    onSeeDetails?(): void;
};

export const Faculty = (props: IFacultyProps) => {
    const { individualSk, name, institutionName, imageUrl, biography, onSeeDetails: handleSeeDetails } = props;
    const dispatch = useAppDispatch();
    const { pushToDataLayer } = useAnalytics();

    const seeDetails = () => {
        dispatch(setSelectedFaculty({ imageUrl, institutionName, name, biography }));
        if (handleSeeDetails) {
            pushToDataLayer({ event: 'view_faculty', fetchType: FetchType.Faculty, value: individualSk });
            handleSeeDetails();
        }
    };
    
    return (
        <button className='faculty' onClick={seeDetails} aria-label={`See faculty details for ${name}`}>
            <img src={imageUrl} alt={name} />
            <span className='faculty__info'>
                <span className='paragraph-1--medium'>{name}</span>
                <span className='paragraph-2 text--medium-grey'>{institutionName}</span>
            </span>
            <i className='faculty__arrow-icon watch watch-forward-arrow' />
        </button>
    );
};

export default Faculty;
