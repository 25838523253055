import { apiSlice } from '..';
import { MultimediaNotification } from '../generated';

const notificationsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotifications: builder.query<MultimediaNotification[], { itemSk: number, isWebcast: boolean }>({
            query: (arg: { itemSk: number, isWebcast: boolean }) => 
                `/media/${arg.itemSk}/notifications?isWebcast=${arg.isWebcast}`
        })
    })
});

export const { useGetNotificationsQuery } = notificationsSlice;
export default notificationsSlice;