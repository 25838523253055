import '../../styles/_materials-panel.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useRef } from 'react';

import { ProgramMaterial, ProgramMaterialType } from '../../datasource/generated';
import { useGetCmsSettingsQuery } from '../../datasource/queries/cmsSettings';
import { useGetMaterialsByItemPkQuery } from '../../datasource/queries/materials';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { SegmentsTabSelection } from '../../enums/TabSelection';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useProgramDetails } from '../../hooks/useProgramDetails';
import { useTimeInterval } from '../../hooks/useTimeInterval';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import { setSelectedTab } from '../Store/materialsSlice';
import { Tabs } from '../Tabs/Tabs';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as LinkOutIcon } from './assets/link-out.svg';

export type IMaterialsPanelProps = {
    title?: string;
};

const ONE_SECOND_MS = 1000;

export const MaterialsPanel = ({ title }: IMaterialsPanelProps) => {
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { programDetails } = useProgramDetails(launchDetails?.itemPk ?? '0');
    const currentODPrgSegmentSk = useAppSelector((state) => state.player.currentPrgSegmentSk);
    const isWebcast = useAppSelector((state) => state.player.launchDetails.isWebcast);
    const liveSegments = useMemo(() => {
        return programDetails?.dailySegments?.flatMap(it => it.segments ?? []) ?? [];
    }, [programDetails?.dailySegments]);
    const currentTime = useTimeInterval(ONE_SECOND_MS, !isWebcast);
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const tabsRef = useRef<HTMLButtonElement>(null);
    const selectedTab = useAppSelector((state) => state.materials.selectedTab);
    const dispatch = useAppDispatch();

    const { data: materials, isLoading, isSuccess, isError } = useGetMaterialsByItemPkQuery(launchDetails?.itemPk ?? skipToken);
    const uniqueMaterials = useMemo(() => Object.values(materials?.reduce(function (acc: { [key: string]: ProgramMaterial[] }, item) {
        if (item.chapter) {
            if(!acc[item.chapter])
                acc[item.chapter] = [];
            acc[item.chapter].push(item);
        }

        if (!item.chapter && item.title) {
            if(!acc[item.title])
                acc[item.title] = [];
            acc[item.title].push(item);
        }
        return acc;
    }, {}) ?? []).flat(), [materials]);
    const isSingleSegment = useMemo(
        () => Math.max(launchDetails?.segments?.length ?? 0, programDetails?.dailySegments?.[0].segments?.length ?? 0) <= 1 &&
            (launchDetails.isWebcast ? !!programDetails : true),
        [launchDetails.isWebcast, launchDetails?.segments?.length, programDetails]);

    useEffect(() => {
        if (isSingleSegment) {
            dispatch(setSelectedTab(SegmentsTabSelection.FullProgram));
        }
        else {
            tabsRef.current?.focus();
        }
    }, [dispatch, isSingleSegment]);


    const getMaterialCaption = (item: ProgramMaterial) => {
        if (item.type === ProgramMaterialType.FILE) {
            return 'PDF';
        }

        return item.chapter === '999' ? 'Complete Course Handbook' : `Chapter ${item.chapter}: Course Handbook`;
    };

    const getMaterialAriaLabel = (item: ProgramMaterial) => {
        const chapterType = item.chapter === '999' ? 'Complete Course Handbook' : 'Chapter';
        const type = item.type === ProgramMaterialType.FILE ? 'PDF' : chapterType;
        const viewOn = item.type === ProgramMaterialType.FILE ? 'Download' : 'View on PLI Plus';

        return item.chapter === '999'
            ? `${type}. ${viewOn}`
            : `${type}: ${item.title}. ${viewOn}`;
    };

    const currentPrgSegmentSk = useMemo(() => {
        if (isWebcast) {
            const currentLiveSegment = liveSegments.find(segment => {
                if (!segment.startTimeUtc || !segment.endTimeUtc) return false;

                const currentTimeInSeconds = Math.floor(currentTime / 1000);
                const start = Math.floor(new Date(segment.startTimeUtc).getTime() / 1000);
                const end = Math.floor(new Date(segment.endTimeUtc).getTime() / 1000);

                return start <= currentTimeInSeconds && currentTimeInSeconds <= end;
            });
            return currentLiveSegment?.programSegmentSk ?? currentODPrgSegmentSk;
        } else {
            return currentODPrgSegmentSk;
        }
    }, [currentODPrgSegmentSk, currentTime, isWebcast, liveSegments]);

    const filteredMaterials = useMemo(() =>
        uniqueMaterials.filter(item => selectedTab === SegmentsTabSelection.FullProgram ? true : item.prgSegmentSks?.includes(currentPrgSegmentSk)),
    [currentPrgSegmentSk, selectedTab, uniqueMaterials]);
    
    const {
        data: watchSettings
    } = useGetCmsSettingsQuery();

    const isMaterialsNotAvailable = !uniqueMaterials.length;
    const materialsNotAvailableMessage = watchSettings?.noMaterialsMessage 
        ?? 'Course materials are not yet available for this program.';
    return (
        <>
            {isLoading && <Loader />}
            {isSuccess && (
                <>
                    <div className='small-panel__heading'>
                        <h3 className='heading-5'>{title}</h3>
                    </div>
                    {isMaterialsNotAvailable && <p className='paragraph-1'>{materialsNotAvailableMessage}</p>}
                    {!isSingleSegment &&
                        <Tabs.Root>
                            <Tabs.Option
                                onClick={() => dispatch(setSelectedTab(SegmentsTabSelection.ThisSegment))}
                                isActive={selectedTab === SegmentsTabSelection.ThisSegment}
                                ref={tabsRef}
                            >
                                This segment
                            </Tabs.Option>
                            {!isLargeScreen && <span className='spacer'></span>}
                            <Tabs.Option
                                onClick={() => dispatch(setSelectedTab(SegmentsTabSelection.FullProgram))}
                                isActive={selectedTab === SegmentsTabSelection.FullProgram}
                            >
                                Full program
                            </Tabs.Option>
                            {!isLargeScreen && <span className='spacer--grow'></span>}
                        </Tabs.Root>}
                    <ul className='materials-list'>
                        {filteredMaterials
                            .map((item, index) => (
                                <li key={`${item.title}-${index}`}>
                                    <a className='materials-list__item' href={item.url!} target='_blank' rel="noreferrer" aria-label={getMaterialAriaLabel(item)}>
                                        <p aria-hidden={true} className='paragraph-2--medium'>{item.title}</p>
                                        <p aria-hidden={true} className='paragraph-2 materials-list__item__author' >{item.author}</p>
                                        <div id='a'>
                                            {item.type === ProgramMaterialType.PLI_PLUS
                                                ? <LinkOutIcon aria-hidden={true} />
                                                : <DownloadIcon aria-hidden={true} />
                                            }
                                            <p aria-hidden={true} className='paragraph-2' >{getMaterialCaption(item)}</p>
                                        </div>
                                    </a>
                                    {(index !== filteredMaterials.length - 1 &&
                                        <span className='separator'></span>)}
                                </li>
                            ))
                        }
                        {
                            !filteredMaterials.length &&
                            <div className='paragraph-2 mt-3 text--medium-grey'>There are no materials for this segment.</div>
                        }
                    </ul>
                </>
            )}
            {isError && <MainError errorContainer={ErrorContainer.SmallPanel} />}
        </>
    );
};
