import { skipToken } from '@reduxjs/toolkit/dist/query';
import Cookies from 'js-cookie';
import { useEffect, useMemo } from 'react';

import { useGetCmsSettingsQuery } from '../datasource/queries/cmsSettings';
import { setShowTutorial,ShowTutorialState } from '../stories/Store/prePlayerSlice';
import { TutorialType, tutorialTypeCookiesMap } from '../stories/Tutorial/Tutorial';
import { useAppDispatch, useAppSelector } from '.';

function useCmsSettings(delayQueries?: boolean) {
    const { data, isError, isLoading, isSuccess } = useGetCmsSettingsQuery(delayQueries ? skipToken : undefined);
    const dispatch = useAppDispatch();

    const isExternal = useAppSelector(state => !!state.player.launchDetails.externalUrl);

    const shouldDisplayTutorial = useMemo<ShowTutorialState[] | undefined>(() => (
        data?.tutorialSettings?.map(x => {
            const tutorialResetDate: Date | undefined = x.tutorialResetDate
                ? new Date(x.tutorialResetDate)
                : undefined;

            const tutorialIntervalDays = x.tutorialIntervalDays;

            const tutorialType = x.tutorialType as TutorialType;

            const cookieName = tutorialTypeCookiesMap[tutorialType];
            const cookieValue = Cookies.get(cookieName);

            if (!cookieValue) return { tutorialType: tutorialType, showTutorial: true };

            const cookieDate = new Date(cookieValue);

            const intervalDate = tutorialIntervalDays
                ? new Date(cookieDate).setDate(
                    cookieDate.getDate() + tutorialIntervalDays)
                : cookieDate;
    
            const dateNow = new Date();

            return {
                tutorialType: tutorialType,
                showTutorial: tutorialResetDate
                    ? (tutorialResetDate <= dateNow) || (intervalDate <= dateNow)
                    : true
            };
        })
    ), [data]);

    useEffect(() => {
        if (!isExternal && shouldDisplayTutorial)
            dispatch(setShowTutorial(shouldDisplayTutorial));
    }, [dispatch, isExternal, shouldDisplayTutorial]);

    return {
        isLoadingSettings: isLoading,
        isGetSettingsSuccess: isSuccess,
        isGetSettingsError: isError,
    };
}

export default useCmsSettings;