import '../../styles/_loading-dots.scss';

import React from 'react';

type DotSize = 'small' | 'big';

interface LoadingDotsProps {
    color?: boolean;
    dotSize?: DotSize;
}

export const LoadingDots = ({ color, dotSize = 'big' }: LoadingDotsProps) => {
    const dotClass = `loading-dots__dot ${
        dotSize === 'small' ? 'loading-dots__dot--small' : ''
    }`;

    return (
        <div className="d-flex flex-row justify-content-center">
            <div
                className={`${dotClass} loading-dots__dot1 ${
                    color && 'loading-dots__dot1--color'
                }`}
            />
            <div
                className={`${dotClass} loading-dots__dot2 ${
                    color && 'loading-dots__dot2--color'
                }`}
            />
            <div
                className={`${dotClass} loading-dots__dot3 ${
                    color && 'loading-dots__dot3--color'
                }`}
            />
        </div>
    );
};
