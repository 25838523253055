import { apiSlice } from '..';
import { ProgramMaterial } from '../generated';

const materialsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMaterialsByItemPk: builder.query<ProgramMaterial[], string>({
            query: (itemPk: string) => `/materials/by-pk/${itemPk}`,
            keepUnusedDataFor: Number.MAX_SAFE_INTEGER
        })
    })
});

export const { useGetMaterialsByItemPkQuery } = materialsSlice;
export default materialsSlice;
