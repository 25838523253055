import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface FacultyQuestionState {
    email?: string;
    programTitle: string;
    topic: string;
    segmentTitle: string;
    messageText: string;
}

const initialState: FacultyQuestionState = {
    email: '',
    programTitle: '',
    topic: '',
    segmentTitle: '',
    messageText: '',
};

const facultyQuestionSlice = createSlice({
    name: 'facultyQuestion',
    initialState,
    reducers: {
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setProgramTitle(state, action: PayloadAction<string>) {
            state.programTitle = action.payload;
        },
        setTopic(state, action: PayloadAction<string>) {
            state.topic = action.payload;
        },
        setSegmentTitle(state, action: PayloadAction<string>) {
            state.segmentTitle = action.payload;
        },
        setMessageText(state, action: PayloadAction<string>) {
            state.messageText = action.payload;
        },
    },
});

export const { setEmail, setProgramTitle, setTopic, setSegmentTitle, setMessageText } =
  facultyQuestionSlice.actions;

export const { reducer: facultyQuestionReducer } = facultyQuestionSlice;
