import '../../styles/_back-button.scss';

import React, { ButtonHTMLAttributes, forwardRef } from 'react';

export interface IBackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    buttonClass: string;
    ariaLabel?: string;
}

export const BackButton = forwardRef<HTMLButtonElement, IBackButtonProps>((props: IBackButtonProps, ref) => {
    const { buttonClass, ariaLabel, ...otherProps } = props;

    return (
        <button
            className={buttonClass}
            aria-label={ariaLabel ?? 'Return to previous screen'}
            ref={ref}
            {...otherProps}
        >
            <i className="watch watch-back-arrow"></i>
        </button>
    );
});
BackButton.displayName = 'BackButton';

export default BackButton;
