import '../../styles/_idle-overlay.scss';
import '../../styles/_typography.scss';

import React, { useEffect } from 'react';

import { useAppSelector } from '../../hooks';

export interface IIdleOverlayProps {
    onMouseMove(): void;
    onKeyUp(e: KeyboardEvent): void;
}

export const IdleOverlay = (props: IIdleOverlayProps) => {

    const { onMouseMove, onKeyUp } = props;
    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);

    const segment = launchDetails?.segments?.find(
        (seg) => seg.itemSk === currentSegmentItemSk ?? launchDetails?.currentSegment
    );

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('keyup', onKeyUp);

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [onMouseMove, onKeyUp]);

    return (
        <div className="idle-overlay">
            <div className="idle-overlay__container">
                <div className="idle-overlay__logo" aria-label="logo">
                    <i className="watch watch-pli-logo"></i>
                </div>
                {launchDetails && segment && (
                    <>
                        <h5 className="paragraph-1--medium">{segment.title}</h5>
                        {
                            launchDetails.title !== segment.title &&
                            <h6 className="paragraph-2">Program: {launchDetails.title}</h6>
                        }
                    </>
                )}
                {launchDetails && !segment && (
                    <h5 className="paragraph-1--medium">{launchDetails.title}</h5>
                )}
            </div>
        </div>
    );
};

export default IdleOverlay;