import { apiSlice } from '..';
import { SFSurveyResponse } from '../generated';

export interface ISfSurveySaveProps {
    individual_Sk: number;
    registrationId: number;
    item_Sk: number;
    responses: SFSurveyResponse[];
}

const surveySaveSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        saveSfSurveyResponse: build.mutation<boolean, ISfSurveySaveProps>({
            query: (body) => ({
                url: '/surveys/program-evaluation',
                method: 'POST',
                body,
            })
        })
    })
});

export const { useSaveSfSurveyResponseMutation } = surveySaveSlice;
export default surveySaveSlice;
  