import '../../styles/_cle-dialog.scss';

import React, {
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';

import { CleTimeStamp } from '../../datasource/generated';
import { cleTimestampSaveLock, useSaveCleTimeStampsMutation } from '../../datasource/mutations/cleTimeStamps';
import { useGetCleDialogMessagesQuery } from '../../datasource/queries/cmsMessages';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useAppDispatch, useAppSelector, useAppStore } from '../../hooks';
import { MediaLogEventTypes, useLogMediaEvents } from '../../hooks/useLogMediaEvents';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { usePlayerElement } from '../../hooks/usePlayerElement';
import Button from '../Button/Button';
import { clearTimeStampEvent, setFailedTimeStamps } from '../Store/cleSlice';
import { Toggle } from '../Toggle/Toggle';

export interface ICleDialogProps {
    container?: RefObject<HTMLElement>;
    chime: RefObject<HTMLAudioElement>;
    animateCreditIcon: () => void;
}

export const CleDialog = ({
    container,
    chime,
    animateCreditIcon,
} : ICleDialogProps) => {

    const dispatch = useAppDispatch();
    const store = useAppStore();
    const sessionGuid = useAppSelector((state) => state.cle.sessionGuid);
    const timeStampEvent = useAppSelector((state) => state.cle.timeStampEvent);
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);

    const { logMediaEvent } = useLogMediaEvents();

    const {
        data: cleDialogMessages,
    } = useGetCleDialogMessagesQuery();


    const dialogHeading = cleDialogMessages?.attendanceDialogHeader ?? 'Still with us?';
    const dialogBody = cleDialogMessages?.attendanceDialogBody ?? 'Verifying your attendance is necessary for receiving earned credits. Just click below to let us know you’re still here.';
    const buttonText = cleDialogMessages?.attendanceDialogButton ?? 'Verify attendance';

    const [isSoundEnabled, setIsSoundEnabled] = useState(true);

    const cleDialogRef = useRef<HTMLDivElement>(null);
    const verifyAttendanceButtonRef = useRef<HTMLButtonElement>(null);
    const { playerElement, refreshPlayerElement } = usePlayerElement();
    const previousActiveElement = useRef<HTMLElement>();

    const [saveTimeStamps, { isLoading, isSuccess, isError, reset }] = useSaveCleTimeStampsMutation();
    const isMutationCompleted = isSuccess || isError;
    const [isAcknowledged, setIsAcknowledged] = useState(false);

    const idleTimeoutRef = useRef<number>();
    const acknowledgementTimeoutRef = useRef<number>();

    useEffect(() => {
        if (!timeStampEvent) return;
        
        if (!document.body.contains(playerElement)) {
            refreshPlayerElement();
            // this will trigger the effect again since playerElement will change
            return;
        }

        // Prevents keyboard controls on CleDialog inputs from firing JWPlayer play/pause keyboard shortcuts
        const preventJwKeyEvent = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter' || code === 'ArrowUp' || code === 'ArrowDown') {
                e.stopPropagation();
            }
        };

        const jwPlayerControls = document.querySelectorAll('.jwplayer');

        setIsAcknowledged(false);
        acknowledgementTimeoutRef.current && clearTimeout(acknowledgementTimeoutRef.current);
        reset();

        jwPlayerControls.forEach(elem => {
            elem.addEventListener('keydown', preventJwKeyEvent, true);
        });

        // Don't render dialog over sidebar when sidebar is expanded
        const sidebar = document.getElementById('sidebar');
        if (sidebar && sidebar.classList.contains('sidebar--expanded')) {
            cleDialogRef.current?.classList.add('cle-dialog--menu-expanded');
        }

        // Focus dialog
        previousActiveElement.current = document.activeElement as HTMLElement;
        cleDialogRef.current?.focus();

        if (isSoundEnabled && chime.current)
            chime.current.play();

        return () => {
            // Restore focus after dialog is closed
            if (!previousActiveElement) playerElement?.focus();
            previousActiveElement.current?.focus({ preventScroll: true });
            previousActiveElement.current = undefined;   

            jwPlayerControls.forEach(elem => {
                elem.removeEventListener('keydown', preventJwKeyEvent, true);
            });
        };
    // This effect should only happen when cleEvent is dispatched, or cleared
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeStampEvent, playerElement]);

    useLayoutEffect(() => {
        // Move CPE dialog if CLE dialog is present
        const cpeDialog = document.getElementById('cpe-dialog');
        if (!cpeDialog) return;
        if (timeStampEvent || isAcknowledged)
            cpeDialog.classList.add('cpe-dialog--cle-dialog-open');
        else
            cpeDialog.classList.remove('cpe-dialog--cle-dialog-open');
    }, [isAcknowledged, timeStampEvent]);

    const handleVerifyAttendancePress = useCallback(async () => {
        if (timeStampEvent) {
            clearTimeout(idleTimeoutRef.current);
            idleTimeoutRef.current = undefined;

            const now = new Date();
            const nowSeconds = Math.round(now.getTime() / 1000);
            const reactionSeconds = nowSeconds - timeStampEvent.displayDateSeconds;

            const cleTimeStamp : CleTimeStamp = {
                encryptedRegistrationID: timeStampEvent.registrationId,
                itemSK: timeStampEvent.itemSk,
                weight: timeStampEvent.weight,
                minWeight: timeStampEvent.minWeight,
                maxWeight: timeStampEvent.maxWeight,
                reactionSeconds: reactionSeconds,
                sessionGuid: sessionGuid,
                indexGuid: timeStampEvent.indexGuid,
                sequenceNo: timeStampEvent.sequence,
                isRecovered: false,
                reactionTimeStampUtc: nowSeconds,
            };

            // wait for concurrent timestamps sync
            await cleTimestampSaveLock.waitForUnlock();

            // prevent race condition by taking timestamps directly from store right before request
            const timestampsToSend = store.getState().cle.failedTimeStamps.map(ts => {
                const recoveredTs : CleTimeStamp = { ...ts, isRecovered: true };
                return recoveredTs;
            });
            timestampsToSend.push(cleTimeStamp);

            setIsAcknowledged(true);
            dispatch(clearTimeStampEvent());

            try {
                const response = await saveTimeStamps(timestampsToSend).unwrap();
                const failedTimeStamps = response.map(x => {
                    if (!x.isSuccess) {
                        return timestampsToSend.find(y => y.indexGuid === x.indexGuid);
                    }
                }).filter(x => x !== undefined) as CleTimeStamp[];
                dispatch(setFailedTimeStamps(failedTimeStamps));
            } catch (err) {
                const error = err as Error;
                logMediaEvent(MediaLogEventTypes.ERROR, `handleVerifyAttendancePress(): ${error.message}`);
                dispatch(setFailedTimeStamps(timestampsToSend));
            }
            animateCreditIcon();
            acknowledgementTimeoutRef.current = setTimeout(() => {
                setIsAcknowledged(false);
            }, 1600) as unknown as number;
        }
    }, [timeStampEvent, sessionGuid, store, dispatch, animateCreditIcon, saveTimeStamps, logMediaEvent]);

    const shouldShowBackdropRef = useRef<boolean>(true);
    const largePanelOpenCommand = useAppSelector((state) => state.menu.largePanelOpenCommand);
    const handleJwControlsOverlay = () => {
        const jwControls = document.querySelectorAll('.jw-controls');
        const isOverlayOpen = document.querySelectorAll('.overlay-menu, .ReactModal__Overlay--after-open, .small-panel--opened').length > 0;
        const isAnyPanelOpen = document.querySelectorAll('.small-panel--opened, .large-panel--opened').length > 0;
        
        if (largePanelOpenCommand) {
            shouldShowBackdropRef.current = false;
        }

        jwControls.forEach((elem) => {
            elem.classList.add('zindex-dialog-backdrop');
        });

        const shouldNotBringJwControlsUp = !shouldShowBackdropRef.current || isOverlayOpen || (!isLargeScreen && isAnyPanelOpen);
        
        if (shouldNotBringJwControlsUp) {
            jwControls.forEach((elem) => {
                elem.classList.remove('zindex-dialog-backdrop');
            });
        }
    };

    const resetJwControlsOverlay = () => {
        const jwControls = document.querySelectorAll('.jw-controls');
        jwControls.forEach((elem) => {
            elem.classList.remove('zindex-dialog-backdrop');
        });
        shouldShowBackdropRef.current = true;
    };

    if (timeStampEvent || isAcknowledged) {
        handleJwControlsOverlay();
        
        return createPortal(
            <div className={ shouldShowBackdropRef.current ? 'cle-dialog__container' : 'cle-dialog__container-above' }>
                <div className='cle-dialog' id='cle-dialog' role='document' ref={cleDialogRef} tabIndex={-1}
                    aria-label='Attendance verification dialog'>
                    <div className='cle-dialog__content'>

                        <div className='cle-dialog__content__sound-setting'>
                            <label htmlFor='soundToggle' 
                                className='cle-dialog__content__sound-setting__label'>
                                <i className={`watch watch-volume-${isSoundEnabled ? 'up' : 'mute'} cle-dialog__content__sound-setting__label__icon`}></i>
                                <span className='text--silver paragraph-2 cle-dialog__content__sound-setting__label__text'>
                                Notification Sounds
                                </span>
                            </label>
                            <Toggle id='soundToggle' 
                                checked={isSoundEnabled}
                                color={'amethyst'}
                                onChange={() => setIsSoundEnabled(state => !state)}
                                ariaLabel={`Turn ${isSoundEnabled ? 'off' : 'on'} notification sounds`}
                                cssClass='toggle-margin-ios'
                                isWithinPlayerPortal={true} />
                        </div>

                        <div className='cle-dialog__content__text'>
                            <h1 className='heading-5 mb-0'>{dialogHeading}</h1>
                            <p className='paragraph-2 text--silver'>
                                {dialogBody}
                            </p>
                        </div>
                    </div>

                    <Button 
                        buttonClass={`cle-dialog__button sticky-button ${isMutationCompleted ? 'sticky-button--success' : ''}`}
                        label={buttonText}
                        aria-label={isMutationCompleted ? 'Attendance confirmed' : 'Verify attendance and close'}
                        action={handleVerifyAttendancePress}
                        isLoading={isLoading}
                        icon={isMutationCompleted ? 'checkmark' : undefined}
                        iconPosition={isMutationCompleted ? 'center' : undefined}
                        ref={verifyAttendanceButtonRef}
                        isPlayerElementButton={true}
                    />

                </div>
            </div>,
            playerElement ?? container?.current ?? document.body
        );
    } else {
        resetJwControlsOverlay();
        return <></>;
    }
};

export default CleDialog;