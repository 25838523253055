import { apiSlice } from '..';
import { StsSession, StsSessionUpdateResponse } from '../generated';

const stsSessionsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        updateStsSessions: builder.mutation<Array<StsSessionUpdateResponse>, Array<StsSession>>({
            query: (stsSessions) => ({
                url: '/media/sts-sessions/batch',
                method: 'PUT',
                body: stsSessions,
            }),
        }),
    }),
});

export const { useUpdateStsSessionsMutation } = stsSessionsSlice;
export default stsSessionsSlice.reducer;