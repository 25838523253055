import { apiSlice } from '..';
import { ProgramCreditDetails, ProgramDetails } from '../generated';

const programDetailsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProgramDetails: builder.query<ProgramDetails, string>({
            query: (itemPk: string) => `/program-details?itemPk=${itemPk}`
        }),
        getProgramCreditDetails: builder.query<ProgramCreditDetails, string>({
            query: (itemPk: string) => `/program-details/credit?itemPk=${itemPk}`
        })
    })
});

export const { useGetProgramDetailsQuery, useGetProgramCreditDetailsQuery } = programDetailsSlice;
export default programDetailsSlice;