import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '.';

export const usePlayerElement = () => {

    const isPlayerElementReady = useAppSelector((state) => state.player.isPlayerElementReady);
    const [playerElement, setPlayerElement] = useState<HTMLElement|null>(null);

    const refreshPlayerElement = useCallback(() => {
        if (!isPlayerElementReady) return;

        const playerElement = document.getElementById('player');
        if (playerElement) {
            setPlayerElement(playerElement);
        }
    }, [isPlayerElementReady]);
    
    useEffect(() => {
        refreshPlayerElement();
    }, [refreshPlayerElement]);

    const focusPlayerElement = useCallback(() => {
        if (!isPlayerElementReady || !playerElement)
            return;
        playerElement.focus();
    }, [isPlayerElementReady, playerElement]);

    return { 
        playerElement,
        focusPlayerElement, 
        isPlayerElementReady,
        refreshPlayerElement,
    };
};