import '../../styles/_faculty-panel.scss';

import React, { useEffect } from 'react';

import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useFaculty } from '../../hooks/useFaculty';
import { setSelectedFaculty } from '../Store/facultySlice';

export const FacultyDetail = () => {
    const { faculty } = useFaculty();
    const selectedFaculty = useAppSelector((state) => state.faculty.selectedFaculty);
    const forceFacultyDetails = (faculty?.length ?? 0) <= 1;
    const dispatch = useAppDispatch();
    const { data: clientConfig } = useGetClientConfigQuery();

    useEffect(() => {
        if (forceFacultyDetails && faculty) {
            dispatch(setSelectedFaculty({
                name: faculty[0].displayName ?? '',
                institutionName: faculty[0].institutionName ?? '',
                biography: faculty[0].biography ?? '',
                imageUrl: `${clientConfig?.baseContentUrl}/api/client/faculty-image-map/${faculty[0].individualSk}`
            }));
        }
    }, [clientConfig, dispatch, faculty, forceFacultyDetails]);

    return (
        <div className='faculty-detail'>
            <img src={selectedFaculty?.imageUrl} alt={selectedFaculty?.name} />
            <div>
                <div className='faculty-detail__title heading-6--medium'>{selectedFaculty?.name}</div>
                <div className='paragraph-2 text--medium-grey'>{selectedFaculty?.institutionName}</div>
            </div>
            {
                selectedFaculty?.biography &&
                <div>
                    <div className='paragraph-1 faculty-detail__title'>Biography</div>
                    <div className='paragraph-2 text--medium-grey' dangerouslySetInnerHTML={{ __html: selectedFaculty?.biography ?? '' }}></div>
                </div>
            }
        </div>
    );
};

export default FacultyDetail;
