import { useEffect, useRef } from 'react';

export function useUpdateEffect(callback: (changeCount: number) => ReturnType<React.EffectCallback>, arrayDeps: unknown[]) {
    const changeCount = useRef(0);

    useEffect(() => {
        if (changeCount.current++ === 0) return;

        return callback(changeCount.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, arrayDeps);
}