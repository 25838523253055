import '../../styles/_modal.scss';
import '../../styles/_license-delete.scss';

import React, { useState } from 'react';

import { IndividualCreditAndLicenseInfo } from '../../datasource/generated';
import { useDeleteLicenseMutation } from '../../datasource/mutations/licenses';
import { useGetLicenseMessagesQuery } from '../../datasource/queries/cmsMessages';
import { useAppSelector } from '../../hooks';
import Button from '../Button/Button';
import { LICENSE_MODAL_HEADING_ID } from '../LicenseModal/LicenseModal';

export interface IDeleteLicenseProps {
    license: IndividualCreditAndLicenseInfo;
    navigateAfterDelete: () => void;
}

const deleteTimeout = 1600;

export const DeleteLicense = ({
    license,
    navigateAfterDelete,
}: IDeleteLicenseProps) => {

    const [
        deleteLicense,
        { isLoading, isSuccess, isError },
    ] = useDeleteLicenseMutation();

    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const [isSubmitError, setIsSubmitError] = useState(false);

    const onDeleteLicensePress = async () => {
        if (!license.indvStateLicense_SK) {
            setIsSubmitError(true);
            return;
        }
        setIsSubmitError(false);

        try {
            const deleteLicenseResponse = await deleteLicense({ licenseSk: license.indvStateLicense_SK, encryptedRegistrationId });

            if ('data' in deleteLicenseResponse && deleteLicenseResponse.data === true) {
                setTimeout(() => {
                    navigateAfterDelete();
                }, deleteTimeout); 
            } else {
                setIsSubmitError(true);
            }
        } catch {
            setIsSubmitError(true);
        }
    };

    const { 
        data: licenseMessages,
    } = useGetLicenseMessagesQuery();

    const deleteLicenseHeader = licenseMessages?.deleteLicenseHeader ?? 'Are you sure?';
    const deleteLicenseBody = licenseMessages?.deleteLicenseBody ?? 
        'Removing this license will delete it permanently from your profile. You can add this license back to your PLI account at any time through your profile.';

    return (
        <>
            <div className='watch-modal__content h-100'>
                <div className='license-delete__container'>
                    <h1 id={LICENSE_MODAL_HEADING_ID} className='heading-5'>
                        {deleteLicenseHeader}
                    </h1>
                    <div className='paragraph-1'>{deleteLicenseBody}</div>
                </div>
            </div>
            <div className='watch-modal__footer'>
                <div className='watch-modal__footer__button-container'>
                    {
                        (isError || isSubmitError) && 
                            <div className='pb-1'>
                                <i className='watch watch-alert pr-1 text--system-red' aria-hidden={true}/>
                                <span id='delete-license-error' className='sentence-label text--system-red'>
                                    Error: There was a problem deleting your license. Please try again later.
                                </span>
                            </div>
                    }
                    <Button 
                        label={isSuccess ? 'Your profile has been updated' : 'Remove from my profile'}
                        action={ onDeleteLicensePress } 
                        isLoading={isLoading}
                        buttonClass={`sticky-button ${
                            isSuccess
                                ? 'sticky-button--success'
                                : ''
                        }`}
                        icon={isSuccess ? 'checkmark' : undefined}
                        iconPosition={isSuccess ? 'left' : undefined}
                        aria-describedby={(isError || isSubmitError) ? 'delete-license-error' : ''}
                        isSuccessState={isSuccess}
                    />
                </div>
            </div>
        </>
    );
};