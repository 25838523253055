/* eslint-disable jsx-a11y/media-has-caption */
import React, { useLayoutEffect, useRef } from 'react';

export interface IVideoStreamProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    sourceRef: React.MutableRefObject<HTMLVideoElement | undefined> | React.RefObject<HTMLVideoElement | undefined>;
    hidden?: boolean;
}

export const VideoThumbnail = ({
    sourceRef,
    hidden = false,
    ...props
}: IVideoStreamProps) => {
    const root = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (!root.current || !sourceRef.current) return;

        const $root = root.current;
        const $source = sourceRef.current;

        const aspectRatio = $source.videoHeight / $source.videoWidth;

        $source.classList.add('thumbnail-mode');

        const last = { width: $source.offsetWidth, height: $source.offsetHeight };
        let loopId = requestAnimationFrame(function loop() {
            const { top, right, width } = $root.getBoundingClientRect();
            const height = width * aspectRatio;
            $source.style.top = `${top}px`;
            $source.style.right = `${window.innerWidth - right}px`;
            if (last.width !== width)
                $source.style.setProperty('--width', `${width}px`);
            if (last.height !== height)
                $source.style.setProperty('--height', `${height}px`);
            if (hidden)
                $source.style.opacity = '0';

            loopId = requestAnimationFrame(loop);
        });

        return () => {
            $source.classList.remove('thumbnail-mode');
            cancelAnimationFrame(loopId);
            $source.removeAttribute('style');
        };
    }, [hidden, sourceRef]);

    return (
        <div ref={root} {...props} className="thumbnail__video-container" />
    );
};