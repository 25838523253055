import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface DownloadState {
    showDownloadTutorial: boolean,
}

const initialState: DownloadState = {
    showDownloadTutorial: false
};

const downloadSlice = createSlice({
    name: 'download',
    initialState,
    reducers: {
        setShowDownloadTutorial(state, action: PayloadAction<boolean>) {
            state.showDownloadTutorial = action.payload;
        }
    },
});

export const { setShowDownloadTutorial } =
    downloadSlice.actions;

export const { reducer: downloadReducer } = downloadSlice;
