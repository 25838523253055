import { apiSlice } from '..';
import { CleSessionSubmissionResponse } from '../generated';

export interface ICreditSubmissionProps {
    encryptedRegistrationId: string;
    requestedIndvLicenseSks: number[];
    authorizationCode?: number;
}

const creditSubmissionSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        submitCreditRequest: builder.mutation<CleSessionSubmissionResponse, ICreditSubmissionProps>({
            query: (creditSubmissionProps) => ({
                url: '/cle/submit-for-credit',
                method: 'POST',
                body: creditSubmissionProps,
            })
        })
    })
});

export const { useSubmitCreditRequestMutation } = creditSubmissionSlice;
export default creditSubmissionSlice;