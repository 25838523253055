import '../../styles/_time-card.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo } from 'react';

import { CleTimeRecord } from '../../datasource/generated';
import { useGetCleTimeRecordsQuery } from '../../datasource/queries/cle';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useAppSelector } from '../../hooks';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import { TimeCard } from './TimeCard';

export type ITimeCardsProps = {
    onClose: (() => void) | null;
    onTimeCardsLoaded: (newValue: boolean) => void;
    requestingCredit?: boolean;
    shouldShowTitle?: boolean;
};

export const TimeCards = ({ onClose, onTimeCardsLoaded: onHasTimeCardsLoadedChange, requestingCredit, shouldShowTitle = true }: ITimeCardsProps) => {
    const encryptedRegistrationId= useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const {
        data: timeRecords,
        isLoading: isLoadingTimeRecords,
        isSuccess: isGetTimeCardsSuccess,
        isError: isGetTimeCardsError,
    } = useGetCleTimeRecordsQuery(encryptedRegistrationId ?? skipToken);

    useEffect(() => {
        onHasTimeCardsLoadedChange(isGetTimeCardsSuccess);
    }, [isGetTimeCardsSuccess, onHasTimeCardsLoadedChange]);

    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const programTitle = useAppSelector((state) => state.requestCredit.programTitle);
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);

    const records = useMemo(() => {
        if (timeRecords === undefined)
            return {
                liveCleRecords: undefined,
                onDemandCleRecords: undefined,
            };

        const liveCleRecords = timeRecords.filter(timeRecord => timeRecord.isLive);
        const onDemandCleRecords = timeRecords.filter(timeRecord => !timeRecord.isLive);
        
        if (!launchDetails.isWebcast && liveCleRecords.length) {
            const recordTemplates : CleTimeRecord[] = timeRecords
                .filter((value, index, self) => 
                    self.findIndex(v => v.prgSegment_SK === value.prgSegment_SK) === index)
                .map(value => {
                    return {
                        ...value,
                        percentComplete: 0,
                        isLive: false,
                    };
                });

            const onDemandRecords = recordTemplates.map(recordTemplate => {
                return {
                    ...recordTemplate,
                    percentComplete: onDemandCleRecords.find(x => x.prgSegment_SK === recordTemplate.prgSegment_SK)?.percentComplete ?? 0,
                    isLive: false,
                };
            });

            const liveRecords = recordTemplates.map(recordTemplate => {
                return {
                    ...recordTemplate,
                    percentComplete: liveCleRecords.find(x => x.prgSegment_SK === recordTemplate.prgSegment_SK)?.percentComplete ?? 0,
                    isLive: true,
                };
            });

            return {
                liveCleRecords: liveRecords,
                onDemandCleRecords: onDemandRecords,
            };
        }
        else {
            return {
                liveCleRecords: liveCleRecords,
                onDemandCleRecords: onDemandCleRecords,
            };
        }
    }, [launchDetails.isWebcast, timeRecords]);

    const hasLiveCleRecords: boolean = (records?.liveCleRecords && records?.liveCleRecords.length > 0) ?? false;
    const hasOnDemandCleRecords: boolean = (records?.onDemandCleRecords && records?.onDemandCleRecords.length > 0) ?? false;
    const hasBothLiveAndODRecords: boolean = hasLiveCleRecords && hasOnDemandCleRecords;
    const hasEitherLiveOrODRecords: boolean = (hasLiveCleRecords || hasOnDemandCleRecords) && !(hasLiveCleRecords && hasOnDemandCleRecords);

    const getSegmentsLanguage = (records? : CleTimeRecord[]) => {
        if ((records?.length ?? 0) === 1) return 'segment';
        return 'segments';
    };

    const onDemandHeading = requestingCredit && records?.onDemandCleRecords
        ? (hasBothLiveAndODRecords
            ? `On-demand • ${records.onDemandCleRecords.length} ${getSegmentsLanguage(records.onDemandCleRecords)}`
            : `${records.onDemandCleRecords.length} ${getSegmentsLanguage(records.onDemandCleRecords)}`)
        : 'On-demand';

    const nonEmptyLiveRecords = records.liveCleRecords?.filter(x => (x.percentComplete ?? 0) > 0);
    const liveHeading = requestingCredit && records?.liveCleRecords
        ? (hasBothLiveAndODRecords
            ? `Live • You watched ${nonEmptyLiveRecords?.length ?? 0} ${getSegmentsLanguage(nonEmptyLiveRecords)} of this program while it was live`
            : `${records.liveCleRecords.length} ${getSegmentsLanguage(records.liveCleRecords)}`)
        : 'Watched live';

    const requestCreditHeader = <div className='paragraph-1 text--medium-grey pb-3'>Program details</div>;

    const timeCardsStyleClass = requestingCredit ? 'time-cards__request-credit' : 'time-cards';
    const sectionHeadingClass = requestingCredit ? 'request-credit__section-heading' : '';
    const isSingleSegment = (launchDetails.segments?.length ?? 0) <= 1;

    if (isGetTimeCardsError) {
        return <MainError errorContainer={ErrorContainer.LargePanel}/>;
    }

    const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    return (
        <>
            {!isLargeScreen
                && !requestingCredit
                && shouldShowTitle
                && <h3 className='heading-4 mb-4 pb-3'>Program Credit</h3>}

            {isLoadingTimeRecords && (
                <div className='h-75'>
                    <Loader />
                </div>
            )}

            {isGetTimeCardsSuccess ? (
                hasBothLiveAndODRecords ? (
                    <div>
                        {
                            requestingCredit ?
                                (
                                    <>
                                        {requestCreditHeader}
                                        <div className={sectionHeadingClass}>
                                            <div className='heading-6--medium'>{programTitle}</div>
                                            <div className='time-card__type-heading paragraph-2 mt-2 mb-0'>{onDemandHeading}</div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='time-card__type-heading paragraph-2'>{onDemandHeading}</div>
                                )
                        }

                        <ul className={timeCardsStyleClass}>
                            {records?.onDemandCleRecords?.map((record) => (
                                <TimeCard timeRecord={record} key={record.prgSegment_SK ?? ''} onClose={onClose} isSingleSegment={isSingleSegment} />
                            ))}

                        </ul>

                        {
                            requestingCredit ?
                                (
                                    <div className={sectionHeadingClass}>
                                        <div className='heading-6--medium'>{programTitle}</div>
                                        <div className='time-card__type-heading paragraph-2 mt-2 mb-0'>{liveHeading}</div>
                                    </div>
                                ) : (
                                    <div className='time-card__type-heading paragraph-2 mt-3'>{liveHeading}</div>
                                )

                        }

                        <ul className={timeCardsStyleClass}>
                            {records?.liveCleRecords?.map((record) => (
                                <TimeCard timeRecord={record} key={record.prgSegment_SK ?? ''} onClose={onClose} isSingleSegment={isSingleSegment} />
                            ))}
                        </ul>
                    </div>
                ) : (hasEitherLiveOrODRecords ? (
                    <div className='time-cards' onFocus={handleFocus}>

                        {requestingCredit &&
                            <>
                                {requestCreditHeader}
                                <div className={sectionHeadingClass}>
                                    <div className='heading-6--medium'>{programTitle}</div>
                                    <div className='time-card__type-heading paragraph-2 mt-2 mb-0'>{hasLiveCleRecords ? liveHeading : onDemandHeading}</div>
                                </div>
                            </>
                        }
                        <ul className={timeCardsStyleClass}>
                            {hasLiveCleRecords && records.liveCleRecords?.map((record) => (
                                <TimeCard timeRecord={record} key={record.prgSegment_SK ?? ''} onClose={onClose} isSingleSegment={isSingleSegment} />
                            ))}
                            {hasOnDemandCleRecords && records.onDemandCleRecords?.map((record) => (
                                <TimeCard timeRecord={record} key={record.prgSegment_SK ?? ''} onClose={onClose} isSingleSegment={isSingleSegment} />
                            ))}
                        </ul>
                    </div>
                ) : null)
            ) : null}
        </>
    );
};