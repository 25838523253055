import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useRef } from 'react';

import { Exam } from '../datasource/generated/models/Exam';
import { useGetExamsQuery, useLazyGetExamLaunchUrlQuery } from '../datasource/queries/exams';
import { useGetLicensesQuery } from '../datasource/queries/licenses';
import { useAppSelector } from '.';

const CPE_CERT_TYPE = 3;

export interface UseCpeExamsResponse {
    openExam: () => void,
    exams: Exam[] | undefined,
    areExamsPassed: boolean,
    isSuccess: boolean,   
    isGetExamsError: boolean,   
}

const useCpeExams = (checkExamPassStatusOnFocus = true): UseCpeExamsResponse => {
    const itemSk = useAppSelector((state) => state.requestCredit.itemSk);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const isExamStatusVisible = useAppSelector((state) => 
        Object.values(state.menu.examStatusVisibility).includes(true));

    const { data: exams, isSuccess, isError: isGetExamsError, refetch } = useGetExamsQuery(
        (!launchDetails?.itemSk || launchDetails.hasCPE ) ?
            (itemSk || skipToken) : skipToken
    );
    const [getExamLaunchUrl] = useLazyGetExamLaunchUrlQuery();
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const { data: licenses } = useGetLicensesQuery(!encryptedRegistrationId ? skipToken : { encryptedRegistrationId });

    const openExam = async () => {
        if (!itemSk) return;
        const windowRef = window.open(undefined, '_blank', 'popup');
        const examUrl = await getExamLaunchUrl({ itemSk: itemSk, certTypeSk: CPE_CERT_TYPE });
        if ('data' in examUrl && !!examUrl.data?.url && windowRef)
            windowRef.location = examUrl.data.url;
    };

    const areExamsPassed = useMemo(
        () => (exams && !exams.some(exam => !exam.passed)) ?? false,
        [exams]);

    const prevLicenses = useRef<string>();
    useEffect(() => {
        if (prevLicenses.current) return;
        prevLicenses.current = licenses?.map(x => x.creditRegion_SK).sort().join(',');
    }, [licenses]);

    useEffect(() => {
        async function getExams() {
            if (!itemSk) return;

            // If launchDetails.itemSk (watch player) AND it doesn't have CPE, do not refetch
            // Unfortunately in Standalone RequestCredit page we may have unnecessary refetch from license operations
            if (launchDetails?.itemSk && !launchDetails.hasCPE) return;

            // Only refetch when license regions on profile have changed
            const licensesRegionSks = licenses?.map(x => x.creditRegion_SK).sort().join(',');
            if (licensesRegionSks !== prevLicenses.current) {
                await refetch();
                prevLicenses.current = licensesRegionSks;
            }
        }
        getExams();
    }, [isExamStatusVisible, itemSk, launchDetails, licenses, refetch]);

    useEffect(() => {
        async function getExamsOnFocus() {
            if (!itemSk) return;

            // No need to refetch if exam status is not visible
            if (!isExamStatusVisible) return;

            // If launchDetails.itemSk (watch player) AND it doesn't have CPE, do not refetch
            if (launchDetails?.itemSk && !launchDetails.hasCPE) return;

            // If previous request was success and there are no unpassed exams, do not refetch onFocus
            // Refetch will not happen again until there is a change in licenses
            if (isSuccess && !exams?.filter(e => !e.passed).length) return;

            await refetch();
        }
        if (checkExamPassStatusOnFocus) {
            window.addEventListener('focus', getExamsOnFocus);
        }
        return () => window.removeEventListener('focus', getExamsOnFocus);

    }, [checkExamPassStatusOnFocus, exams, isExamStatusVisible, isSuccess, itemSk, launchDetails, refetch]);

    return {
        openExam,
        exams,
        areExamsPassed,
        isSuccess,
        isGetExamsError,
    };

};

export default useCpeExams;