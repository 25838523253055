import '../../styles/_large-panel.scss';

import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';

import LargePanelContext from '../../contexts/LargePanelContext';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useAppSelector } from '../../hooks';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { trapFocus } from '../../utility/trapFocus';
import Loader from '../Loader/Loader';
import { NavBar } from '../NavBar/NavBar';

export type ILargePanelProps = React.PropsWithChildren<{
    title: string;
    onClose(): void;
    isOpen?: boolean;
    isLoading?: boolean;
}>;

export const LargePanel = ({ title, onClose: handleClose, isOpen, isLoading, children }: ILargePanelProps) => {
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const isSideBarDisabled = useAppSelector((state) => state.menu.isSideBarDisabled);
    const [shouldShowBlur, setShouldShowBlur] = useState(false);
    const blurredRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen && !shouldShowBlur) {
            setShouldShowBlur(true);
        }

        if (!isOpen && shouldShowBlur && blurredRef.current) {
            blurredRef.current.classList.add('blured--fade-out');
        }
    }, [isOpen, shouldShowBlur]);


    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && document.activeElement) {
            trapFocus(document.activeElement, e, 'button, [href], input[checked], select, textarea, [tabindex]:not([tabindex="-1"])');
        }
    };

    const handleAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
        if (e.animationName === 'fade-out') {
            setShouldShowBlur(false);
        }
    };

    const largePanelRef = useRef<HTMLDivElement>(null);

    return (
        <div id='large-panel-wrapper' key='large-panel'>
            {(shouldShowBlur) &&
                <div
                    ref={blurredRef}
                    className={`${shouldShowBlur ? 'blured' : ''}`}
                    onAnimationEnd={(e) => handleAnimationEnd(e)}
                />
            }

            <ReactModal
                isOpen={isOpen ?? true}
                className={'watch-right-modal'}
                closeTimeoutMS={500}
                overlayClassName={'watch-right-modal__overlay'}
            >
                <div 
                    className={`large-panel ${isOpen ? 'large-panel--opened' : ''}`} 
                    onKeyDown={handleKeyDown} 
                    role='presentation'
                    ref={largePanelRef}
                >
                    <NavBar.Root mode={isLargeScreen ? 'spacedTitle' : 'close'} containerMode='largePanel'>
                        {isLargeScreen && <NavBar.Title title={title} size='heading-4' />}
                        {!isSideBarDisabled && <NavBar.CloseButton onClick={handleClose} spaced={!isLargeScreen} isShowing={isOpen ? true : false} />}
                    </NavBar.Root>
                    {
                        isLoading &&
                        <Loader />
                    }
                    {
                        !isLoading &&
                        <div className="large-panel__body">
                            <LargePanelContext.Provider value={largePanelRef}>
                                {children}
                            </LargePanelContext.Provider>
                        </div>
                    }
                </div>
            </ReactModal>
        </div>
    );
};

// temporary component just to demo the title on smaller screens
export const CreditsDetailExample = () => {
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);

    return (<>
        {!isLargeScreen &&
            <div className='large-panel__heading'>
                <h3 className={'heading-4'}>Credits</h3>
            </div>}

        {new Array(100).fill(0).map((_, i) => <div key={i}>This is a large panel content placeholder</div>)}
    </>);
};
