import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';

import { useGetNotificationsQuery } from '../datasource/queries/notifications';
import { setVideoUrl } from '../stories/Store/playerSlice';
import { SignalREventTypes } from '../stories/Store/signalRSlice';
import { removePlaybackErrorAlert, removeToast } from '../stories/Store/toastSlice';
import logger from '../utility/logger';
import { signalRStart } from '../utility/signalR';
import { useAppDispatch, useAppSelector, useAppStore } from '.';
import { useToasts } from './useToasts';


export const useSignalREvents = ({ delayQueries, isExternalLaunch }: { delayQueries: boolean, isExternalLaunch: boolean }) => {
    const store = useAppStore();
    const dispatch = useAppDispatch();

    const { lastEvent } = useAppSelector((state) => state.signalR);
    
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const individualSk = useAppSelector((state) => state.user.individualSK);
    const { addToastsFromNotifications } = useToasts();

    const {
        data: notifications,
        isSuccess: isNotificationsSuccess,
    } = useGetNotificationsQuery(isExternalLaunch || delayQueries || !launchDetails?.itemSk
        ? skipToken
        : {
            itemSk: launchDetails?.itemSk ?? 0,
            isWebcast: launchDetails?.isWebcast ?? false
        });

    useEffect(() => {
        if (process.env.STORYBOOK !== 'true' && launchDetails.isWebcast) {
            signalRStart(store);
        }
    }, [launchDetails.isWebcast, store]);
    
    useEffect(() => {
        if (isNotificationsSuccess) {
            addToastsFromNotifications(notifications);
        }
    }, [addToastsFromNotifications, isNotificationsSuccess, notifications]);
        
    useEffect(() => {
        if (!individualSk || !launchDetails.itemSk || !lastEvent) return;

        logger.log('SignalR event received', {
            event: lastEvent,
            individualSk: individualSk,
            itemSk: launchDetails.itemSk,
        });

        const lastAction = lastEvent?.actions[0];

        switch (lastAction?.type) {
            case SignalREventTypes.Announcement:
                addToastsFromNotifications([lastAction]);
                break;
            case SignalREventTypes.DeleteAnnouncement:
                dispatch(removeToast(lastAction.notificationId ?? 0));
                break;
            case SignalREventTypes.ChangeStream:
                dispatch(removePlaybackErrorAlert());
                dispatch(setVideoUrl(lastAction.url));
                break;
        }
    }, [addToastsFromNotifications, lastEvent, dispatch, individualSk, launchDetails.itemSk]);
};