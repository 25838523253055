import '../../styles/_thumbnail.scss';

import React, { useCallback, useEffect, useRef } from 'react';

import { useAnalytics } from '../../hooks/useAnalytics';
import { sockets } from '../ThumbnailContainer/ThumbnailContainer';
import { ToolTip } from '../ToolTip/ToolTip';

export interface IThumbnailControlsProps {
    socket: string;
    handleMove(socket: string): void;
    handleSwap(): void;
    handleSetTarget(target: string): void;
    movePositionAriaLabel?: string;
    handleShowControls(showControls: boolean): void;
    showControls: boolean;
    isSwapped: boolean;
}

export const ThumbnailControls = (props: IThumbnailControlsProps) => {
    const {
        socket,
        handleMove,
        handleSwap,
        handleSetTarget,
        movePositionAriaLabel,
        handleShowControls,
        showControls,
        isSwapped,
    } = props;

    const activeMoveButtonRef = useRef<HTMLButtonElement>(null);
    const activeSwapButtonRef = useRef<HTMLButtonElement>(null);
    const activeMinimizeButtonRef = useRef<HTMLButtonElement>(null);

    const { pushToDataLayer } = useAnalytics();

    useEffect(() => {
        if (showControls) {
            activeMoveButtonRef.current?.focus();
        }
    }, [showControls]);

    const handleMinimize = useCallback((technique: string) => {
        if (socket === 'bottom-left' || socket === 'top-left') {
            pushToDataLayer({  event: 'thumb_minimize', additionalParams: { technique: technique, socket: socket, view_target: 'center-left' } });
            handleSetTarget('center-left');
        } else if (socket === 'bottom-right' || socket === 'top-right') {
            pushToDataLayer({  event: 'thumb_minimize', additionalParams: { technique: technique, socket: socket, view_target: 'center-right' } });
            handleSetTarget('center-right');
        } else {
            return;
        }
    }, [handleSetTarget, pushToDataLayer, socket]);

    const handleMinimizeClick = useCallback(() => {
        handleMinimize('click');
    }, [handleMinimize]);

    const handleMinimizeKeyDown = useCallback(
        (e: Event) => {
            const key = (e as KeyboardEvent).key;
            if (key === 'Enter' || key === ' ') {
                e.preventDefault();
                e.stopPropagation();
                handleMinimize('dragAndDrop');
            }
        },
        [handleMinimize]
    );

    const handleSwapKeyDown = useCallback(
        (e: Event) => {
            const key = (e as KeyboardEvent).key;
            if (key === 'Enter' || key === ' ') {
                e.preventDefault();
                e.stopPropagation();
                handleSwap();
                pushToDataLayer({  event: 'thumb_switch', additionalParams: { technique: 'dragAndDrop', swapped: !isSwapped } });
            }
        },
        [handleSwap, isSwapped, pushToDataLayer]
    );

    const handleSwapClick = () => {
        handleSwap();
        activeSwapButtonRef.current?.blur();
        pushToDataLayer({  event: 'thumb_switch', additionalParams: { technique: 'click', swapped: !isSwapped } });
    };

    const handleMoveSocket = useCallback(() => {
        const SOCKET_INDEX = sockets.indexOf(socket);
        SOCKET_INDEX < sockets.length - 1
            ? handleMove(sockets[SOCKET_INDEX + 1])
            : handleMove(sockets[0]);
    }, [handleMove, socket]);

    const handleMoveClick = useCallback(() => {
        handleMoveSocket();

        pushToDataLayer({  event: 'thumb_move', additionalParams: { technique: 'click', socket: socket } });
    }, [handleMoveSocket, pushToDataLayer, socket]);

    const handleMoveKeydown = useCallback(
        (e: Event) => {
            const key = (e as KeyboardEvent).key;
            e.stopPropagation();
            handleShowControls(true);

            switch (key) {
                case 'Enter':
                    e.preventDefault();
                    handleMoveSocket;
                    pushToDataLayer({ event: 'thumb_move', additionalParams: { technique: 'dragAndDrop', keyboard_key:'enter', socket: socket } });                  
                    break;
                case ' ':
                    e.preventDefault();
                    handleMoveSocket;
                    pushToDataLayer({ event: 'thumb_move', additionalParams: { technique: 'dragAndDrop', keyboard_key:'space', socket: socket } });                  
                    break;
                case 'ArrowLeft':
                    if (socket.includes('right'))
                    {
                        const newSocket = socket.replace('right', 'left');
                        handleMove(newSocket);                      
                        pushToDataLayer({ event: 'thumb_move', additionalParams: { technique: 'dragAndDrop', keyboard_key:'arrowLeft', socket: newSocket } });
                    }                    
                    break;
                case 'ArrowRight':
                    if (socket.includes('left'))
                    {
                        const newSocket = socket.replace('left', 'right');
                        handleMove(newSocket);                      
                        pushToDataLayer({ event: 'thumb_move', additionalParams: { technique: 'dragAndDrop', keyboard_key:'arrowRight', socket: newSocket } });
                    }                    
                    break;
                case 'ArrowUp':
                    if (socket.includes('bottom'))
                    {
                        const newSocket = socket.replace('bottom', 'top');
                        handleMove(newSocket);                      
                        pushToDataLayer({ event: 'thumb_move', additionalParams: { technique: 'dragAndDrop', keyboard_key:'arrowUp', socket: newSocket } });
                    }                    
                    break;
                case 'ArrowDown':
                    if (socket.includes('top'))
                    {
                        const newSocket = socket.replace('top', 'bottom');
                        handleMove(newSocket);                      
                        pushToDataLayer({ event: 'thumb_move', additionalParams: { technique: 'dragAndDrop', keyboard_key:'arrowDown', socket: newSocket } });
                    }                    
                    break;
            }
        },
        [handleShowControls, handleMoveSocket, pushToDataLayer, socket, handleMove]
    );

    useEffect(() => {
        const moveBtn = activeMoveButtonRef.current;
        moveBtn?.addEventListener('keydown', handleMoveKeydown, true);

        return () => {
            moveBtn?.removeEventListener('keydown', handleMoveKeydown, true);
        };
    }, [handleMoveKeydown]);

    useEffect(() => {
        const swapBtn = activeSwapButtonRef.current;
        swapBtn?.addEventListener('keydown', handleSwapKeyDown, true);

        return () => {
            swapBtn?.removeEventListener('keydown', handleSwapKeyDown, true);
        };
    }, [handleSwapKeyDown]);

    useEffect(() => {
        const minimizeBtn = activeMinimizeButtonRef.current;
        minimizeBtn?.addEventListener('keydown', handleMinimizeKeyDown, true);

        return () => {
            minimizeBtn?.removeEventListener(
                'keydown',
                handleMinimizeKeyDown,
                true
            );
        };
    }, [handleMinimizeKeyDown]);

    return (
        <div className={`thumbnail__controls ${showControls && 'd-flex'}`}>
            <ToolTip toolTipText="Move thumbnail position" alignment="left">
                <button
                    onClick={handleMoveClick}
                    ref={activeMoveButtonRef}
                    aria-label={`Move thumbnail position to ${movePositionAriaLabel} corner`}
                >
                    <i className="watch watch-move" />
                </button>
            </ToolTip>
            <ToolTip toolTipText="Switch views">
                <button
                    onClick={handleSwapClick}
                    ref={activeSwapButtonRef}
                    aria-label="Switch views"
                >
                    <i className="watch watch-flip" />
                </button>
            </ToolTip>
            <ToolTip toolTipText="Minimize thumbnail" alignment="right">
                <button
                    onClick={handleMinimizeClick}
                    ref={activeMinimizeButtonRef}
                    aria-label="Minimize thumbnail"
                >
                    <i className="watch watch-minimize" />
                </button>
            </ToolTip>
        </div>
    );
};

export default ThumbnailControls;
