import { useCallback, useEffect } from 'react';

import { CleTimeStamp } from '../datasource/generated';
import { cleTimestampSaveLock, useSaveCleTimeStampsMutation } from '../datasource/mutations/cleTimeStamps';
import { setFailedTimeStamps } from '../stories/Store/cleSlice';
import { useAppDispatch, useAppStore } from '.';

const RETRY_INTERVAL_SECONDS = 60;

export const useCleRetryHandler = () => {
    const dispatch = useAppDispatch();
    const store = useAppStore();

    const [saveTimeStamps] = useSaveCleTimeStampsMutation();

    const retryFailedTimestamps = useCallback(async () => {
        // wait for concurrent timestamps sync
        await cleTimestampSaveLock.waitForUnlock();

        const timestampsToSend = store.getState().cle.failedTimeStamps.map(ts => {
            const recoveredTs : CleTimeStamp = { ...ts, isRecovered: true };
            return recoveredTs;
        });

        if (timestampsToSend.length === 0) {
            return;
        }

        try {
            const response = await saveTimeStamps(timestampsToSend).unwrap();
            const failedTimeStamps = response.map(x => {
                if (!x.isSuccess) {
                    return timestampsToSend.find(y => y.indexGuid === x.indexGuid);
                }
            }).filter(x => x !== undefined) as CleTimeStamp[];
            dispatch(setFailedTimeStamps(failedTimeStamps));
        } catch {
            dispatch(setFailedTimeStamps(timestampsToSend));
        }
    }, [dispatch, saveTimeStamps, store]);

    useEffect(() => {
        const retryInterval = setInterval(
            retryFailedTimestamps,
            RETRY_INTERVAL_SECONDS * 1000
        );
        return () => {
            clearInterval(retryInterval);
        };
    }, [retryFailedTimestamps]);

    return { retryFailedTimestamps };
};