import '../../styles/_sidebar.scss';

import React, { ButtonHTMLAttributes, forwardRef, useContext, useState } from 'react';

import CreditIconAnimationContext from '../../contexts/CreditIconAnimationContext';
import { ReactComponent as CreditIcon } from './assets/creditIcon.svg';

export interface IMenuButtonButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick(): void;
    ariaLabel?: string;
    buttonClass: string;
    iconClass?: string;
    label?: string;
    showLabel: boolean;
    labelClass?: string;
    hasTooltip: boolean;
    isSelected?: boolean;
    isCreditButton?: boolean;
}

export const MenuButton = forwardRef<
    HTMLButtonElement,
    React.PropsWithChildren<IMenuButtonButtonProps>
>(
    (
        {
            onClick: handleClick,
            ariaLabel,
            buttonClass,
            iconClass,
            label,
            showLabel = false,
            labelClass,
            hasTooltip,
            isSelected = false,
            isCreditButton = false,
            children,
            ...otherProps
        },
        ref
    ) => {
        const [showTooltip, setShowTooltip] = useState(false);
        const creditIconContext = useContext(CreditIconAnimationContext);

        const handleMouseEnter = () => {
            setShowTooltip(true);
        };

        const handleMouseLeave = () => {
            setShowTooltip(false);
        };

        return (
            <button
                className={`${buttonClass} ${isCreditButton && creditIconContext ? 'menu-button--animate' : ''}`}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-label={ariaLabel}
                ref={ref}
                {...otherProps}
            >
                {hasTooltip && (
                    <span
                        className={`menu-button__tooltip paragraph-2 ${
                            !isSelected && showTooltip && !showLabel ? 'd-flex' : 'd-none'
                        }`}
                    >
                        {label}
                    </span>
                )}
                {
                    isCreditButton ? <CreditIcon /> : <i className={iconClass} />
                }
                <span className='menu-button__text__container'>
                    <span
                        className={`${labelClass ?? ''} ${
                            label && showLabel
                                ? 'menu-button__text--visible'
                                : 'menu-button__text'
                        }`}
                    >
                        {label}
                    </span>
                    {children}
                </span>
            </button>
        );
    }
);
MenuButton.displayName = 'MenuButton';
export default MenuButton;
