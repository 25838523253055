import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SegmentsTabSelection } from '../../enums/TabSelection';
import { ISegmentProps } from '../Segment/Segment';

export interface SegmentState {
    selectedSegment?: ISegmentProps;
    selectedTab: SegmentsTabSelection;
}

const initialState: SegmentState = {
    selectedSegment: undefined,
    selectedTab: SegmentsTabSelection.ThisSegment
};

const segmentSlice = createSlice({
    name: 'segment',
    initialState,
    reducers: {
        setSelectedSegment(state, action: PayloadAction<ISegmentProps>) {
            state.selectedSegment = action.payload;
        },
        setSelectedTab(state, action: PayloadAction<SegmentsTabSelection>) {
            state.selectedTab = action.payload;
        },
    },
});

export const { setSelectedSegment, setSelectedTab } = segmentSlice.actions;

export const { reducer: segmentReducer } = segmentSlice;