import '../../styles/_tabs.scss';

import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';

interface TabOptionProps extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
    isActive: boolean;
}

const TabOption = forwardRef<HTMLButtonElement, TabOptionProps>((props, ref) => {
    const { children, isActive, ...otherProps } = props;
    return (
        <button
            ref={ref}
            className={`tabs__option ${isActive ? 'tabs__option--active' : ''}`}
            {...otherProps}
        >
            {children}
        </button>);
});
TabOption.displayName = 'TabOption';

const TabsRoot = ({ children }: PropsWithChildren) => {
    return (
        <div className='tabs paragraph-2--medium'>
            {children}
        </div>
    );
};
TabsRoot.displayName = 'TabsRoot';

export const Tabs = {
    Root: TabsRoot,
    Option: TabOption,
};