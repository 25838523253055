import '../../styles/_bookmarks-panel.scss';

import React from 'react';

import { SegmentsTabSelection } from '../../enums/TabSelection';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAnalytics } from '../../hooks/useAnalytics';
import useChangeSegment from '../../hooks/useChangeSegment';
import { setExternalSeekCommand, setLastVideoStartedFrom } from '../Store/playerSlice';
import Submenu from '../Submenu/Submenu';

export type IBookmarkProps = {
    bookmarkId: number;
    webSegmentSk: number;
    dateCreated: string;
    userNotes: string;
    clipPosition: number;
    segmentTitle?: string;
    onBookmarkChange(option: 'update' | 'delete', bookmarkId?: number, userNotes?: string, timestamp?: string): void;
    onPlaySegment?(): void;
};

export const Bookmark = (props: IBookmarkProps) => {
    const {
        bookmarkId,
        webSegmentSk,
        dateCreated,
        userNotes,
        clipPosition,
        segmentTitle,
        onBookmarkChange: handleBookmarkChange,
        onPlaySegment: handlePlaySegment
    } = props;
    const dispatch = useAppDispatch();
    const { changeSegment } = useChangeSegment();
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const selectedTab = useAppSelector((state) => state.segment.selectedTab);
    const formattedDate = new Date(dateCreated).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');
    
    const hours = Math.floor(clipPosition / 3600);
    const minutes = Math.floor((clipPosition % 3600) / 60);
    const seconds = clipPosition % 60;
    const formattedClipPosition = hours > 0 
        ? `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`
        : `${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;

    const { pushToDataLayer } = useAnalytics();
    
    const goToBookmark = () => {
        const startedFrom = 'notes';
        if (selectedTab == SegmentsTabSelection.FullProgram && currentSegmentItemSk != webSegmentSk) {
            changeSegment({ itemSk: webSegmentSk, requestStartTime: clipPosition });
           
            if (handlePlaySegment) {    
                dispatch(setLastVideoStartedFrom(startedFrom));
                
                handlePlaySegment();

                pushToDataLayer({  event: 'video_start', additionalParams: { started_from: startedFrom } });
            }
        } else {
            dispatch(setExternalSeekCommand(clipPosition));
            pushToDataLayer({  event: 'video_start', additionalParams: { started_from: startedFrom } });
        }
    };

    const submenuOptions = [
        { label: 'Edit', action: () => { handleBookmarkChange('update', bookmarkId, userNotes, formattedClipPosition); } },
        { label: 'Delete', action: () => { handleBookmarkChange('delete', bookmarkId); } }
    ];

    return (
        <div className='bookmark'>
            <div className='sentence-label text--medium-grey'>{formattedDate}</div>
            <button onClick={goToBookmark} className='bookmark__user-notes paragraph-1' aria-label={`Note: ${userNotes}`}>{userNotes ? userNotes : 'Empty note'}</button>
            <div className='bookmark__options'>
                <button onClick={goToBookmark} className='bookmark__options__timestamp paragraph-2' aria-label='Play segment on timestamp'>
                    <span className='bookmark__options__timestamp__play'><i className='watch watch-play' /></span>
                    <span className='bookmark__options__timestamp__title'>{`${formattedClipPosition}${segmentTitle ? `, ${segmentTitle}` : ''}`}</span>
                </button>
                <Submenu options={submenuOptions} ariaLabel='More options' />
            </div>
        </div>
    );
};

export default Bookmark;
