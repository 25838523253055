import '../../styles/_nav-bar.scss';
import '../../styles/_typography.scss';
import '../../styles/_theme.scss';

import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';

import { BackButton } from '../BackButton/BackButton';
import { CloseButton } from '../CloseButton/CloseButton';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    spaced?: boolean;
    isShowing: boolean;
}

const NavBarBackButton = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
    const { spaced = false, isShowing, ...otherProps } = props;
    return (
        <BackButton
            ref={ref}
            buttonClass={`back-button${spaced ? '--spaced' : ''}`}
            tabIndex={isShowing ? 0 : -1}
            {...otherProps}
        />);
});
NavBarBackButton.displayName = 'NavBarBackButton';

const NavBarCloseButton = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
    const { spaced = false, isShowing, ...otherProps } = props;
    return (
        <CloseButton
            ref={ref}
            buttonClass={`close-button${spaced ? '--spaced' : ''}`}
            tabIndex={isShowing ? 0 : -1}
            {...otherProps}
        />);
});
NavBarCloseButton.displayName = 'NavBarCloseButton';

interface NavBarTitleProps {
    title: string;
    subtitle?: string;
    size?: 'heading-4' | 'heading-5' | 'paragraph-1--medium'
}

const NavBarTitle = ({ title, subtitle, size = 'heading-5' }: NavBarTitleProps) => {
    return (
        <div id='title-container' className='nav-bar__title-container'>
            <h3 tabIndex={-1} className={`nav-bar__title ${size}`}>{title}</h3>
            {subtitle && <h4 className='nav-bar__title sentence-label text--medium-grey mb-0 mt-1'>{subtitle}</h4>}
        </div>);
};

const NavBarSubtitle = ({ subtitle }: { subtitle: string }) => {
    return (<h4 className='sentence-label text--medium-grey mb-0'>{subtitle}</h4>);
};

interface NavBarRootProps {
    mode?: 'close' | 'back' | 'closeAndBack' | 'spacedTitle' | 'inlineTitle' | 'default';
    containerMode?: 'smallPanel' | 'largePanel' | 'modal' | 'settings';
}

const NavBarRoot = ({ mode = 'default', containerMode = 'smallPanel', children }: PropsWithChildren<NavBarRootProps>) => {
    const baseClassName = 'nav-bar';
    const additionalClassName = {
        close: `${baseClassName}--close`,
        back: `${baseClassName}--back`,
        closeAndBack: `${baseClassName}--close-and-back`,
        spacedTitle: `${baseClassName}--spaced-title`,
        inlineTitle: `${baseClassName}--inline-title`,
        default: baseClassName
    }[mode];

    const containerClassName = {
        smallPanel: 'small-panel',
        largePanel: 'large-panel',
        modal: 'modal',
        settings: 'settings'
    }[containerMode];

    return (
        <div className={`nav-bar__container--${containerClassName}`}>
            <div className={`${baseClassName} ${additionalClassName}`}>
                {children}
            </div>
        </div>);
};


export const NavBar = {
    Root: NavBarRoot,
    CloseButton: NavBarCloseButton,
    BackButton: NavBarBackButton,
    Title: NavBarTitle,
    Subtitle: NavBarSubtitle,
};
