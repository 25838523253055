import { appInsights } from '../hooks/useApplicationInsights';


export const logger = {
    isConsoleOutputEnabled: process.env.REACT_APP_CONSOLE_LOG_ENABLED?.toLowerCase() === 'true',
    
    log(message: string, customProperties?: { [ key: string] : any }, sendTelemetry = true) {
        if (sendTelemetry) {
            appInsights?.trackEvent({
                name: message,
                properties: customProperties,
            });
        }

        if (this.isConsoleOutputEnabled) {
            console.debug(message, customProperties);
        }
    },

    error(message: string, customProperties?: { [ key: string] : any }, sendTelemetry = true) {
        if (sendTelemetry) {
            appInsights?.trackEvent({
                name: message,
                properties: customProperties,
            });
        }

        if (this.isConsoleOutputEnabled) {
            console.error(message, customProperties);
        }
    },

    exception(error: Error, customProperties?: { [ key: string] : any }) {
        appInsights?.trackException({
            exception: error,
            properties: customProperties,
        });
        if (this.isConsoleOutputEnabled) {
            console.error(error, customProperties);
        }
    },
};

export default logger;
