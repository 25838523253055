import { apiSlice } from '..';
import { LaunchDetails } from '../generated';

export interface launchDetailsRequest {
    itemSk: number,
    shouldPlacePurchaseForExpiredPrograms: boolean,
    opc?: boolean
}

const launchSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        launch: builder.mutation<LaunchDetails, launchDetailsRequest>({
            query: ({ itemSk, shouldPlacePurchaseForExpiredPrograms, opc }) => {
                let queryString = `/launch-details?itemSk=${itemSk}&shouldPlacePurchaseForExpiredPrograms=${shouldPlacePurchaseForExpiredPrograms}`;

                if(opc === true){
                    queryString += `&opc=${opc}`;
                }

                return {
                    url: queryString,
                    method: 'POST',
                };
            }
        })
    })
});

export const { useLaunchMutation } = launchSlice;
export default launchSlice;