import { apiSlice } from '..';
import { SFSurvey } from '../generated';

const surveysSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getProgramEvaluationSurvey: build.query<SFSurvey, void>({
            query: () => '/surveys/program-evaluation',
        }),
    }),
});

export const { useGetProgramEvaluationSurveyQuery } = surveysSlice;
export default surveysSlice;