import '../../styles/_button.scss';

import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { LoadingDots } from './LoadingDots';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    action?: () => void;
    label?: string;
    icon?: 'chevron-down' | 'chevron-up' | 'checkmark' | 'forward-arrow' | 'outbound-link'
    iconPosition?: 'left' | 'right' | 'center'
    iconClass?: string;
    buttonClass: string;
    typeSubmit?: boolean;
    isLoading?: boolean;
    isSuccessState?: boolean;
    isPlayerElementButton?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(({
    action,
    label,
    icon,
    iconPosition,
    iconClass,
    buttonClass,
    typeSubmit,
    isLoading,
    isSuccessState = false,
    isPlayerElementButton = false,
    ...otherProps
}: IButtonProps, ref) => {
    
    const onClick = () => {
        if (!action) return;
        if (isLoading) return;
        if (isSuccessState) return;
        action();
    };

    const handleKeyUp = (e: React.KeyboardEvent) => {
        if ((e.code === 'Space' || e.code === 'Enter') && action && isPlayerElementButton) {
            onClick();
        }
    };

    const showLeftIcon = icon && iconPosition === 'left';
    const showRightIcon = icon && iconPosition === 'right';
    const showCenterIcon = icon && iconPosition === 'center';
    const completeIconClass = `watch watch-${icon} ${iconClass}`;
    const iconElement = <i className={completeIconClass} aria-hidden={true} />;
    return (
        <button
            className={buttonClass}
            onClick={onClick}
            type={typeSubmit ? 'submit' : 'button'}
            ref={ref}
            onKeyUp={handleKeyUp}
            {...otherProps}
        >
            {isLoading ? (
                <LoadingDots color={false} dotSize='big' />
            ) : (
                <>
                    {showCenterIcon ? 
                        iconElement :
                        <>
                            {showLeftIcon && iconElement}
                            <span>{label}</span>
                            {showRightIcon && iconElement}
                        </>
                    }

                </>
            )}
        </button>
    );
});
Button.displayName = 'Button';

export default Button;
