import Cookies from 'js-cookie';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useLazyGetTrackCleQuery } from '../datasource/queries/trackCle';
import { CleTimerEvent, setTimeStampEvent } from '../stories/Store/cleSlice';
import { ExternalCommand, setExternalCommand } from '../stories/Store/playerSlice';
import logger from '../utility/logger';
import { WorkerInterval } from '../utility/WorkerInterval';
import { useAppDispatch, useAppSelector, useAppStore } from '.';
import { MediaLogEventTypes, useLogMediaEvents } from './useLogMediaEvents';

interface CleTimer {
    weightSeconds: number,
    accruedSeconds: number
}

const cookieOptions = {
    sameSite: 'strict',
    expires: 30,
};

type TCurrentTimer = CleTimer | null;

export const useCleTimer = () => {

    const dispatch = useAppDispatch();
    const store = useAppStore();

    const { logMediaEvent } = useLogMediaEvents();

    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const cookieName = `cle-${launchDetails?.itemSk}`;
    const [isCleTimerRunning, setIsCleTimerRunning] = useState(false);
    const currentTimer = useRef<TCurrentTimer>(null);
    const setCurrentTimer = useCallback((value: TCurrentTimer | ((old: TCurrentTimer) => TCurrentTimer)) => {
        const newValue = typeof value === 'function'
            ? value(currentTimer.current)
            : value;
        currentTimer.current = newValue;
        Cookies.set(
            cookieName,
            JSON.stringify(newValue),
            cookieOptions
        );
    }, [cookieName]);

    // For Live this is the Live Webcast itemSk, for OD it is the current segment itemSk
    const itemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const individualSk = useAppSelector((state) => state.user.individualSK);
    const sequence = useRef(1);

    const getRandomWeightSeconds = useCallback(() => {
        const minMinutes = launchDetails?.minWeight ?? 8;
        const maxMinutes = launchDetails?.maxWeight ?? 12;

        const randomMinutes = Math.floor(Math.random() * (maxMinutes - minMinutes + 1)) + minMinutes;

        return randomMinutes * 60;

    }, [launchDetails]);

    const getNewCleTimer = useCallback(() => {
        const weight = getRandomWeightSeconds();
        return {
            weightSeconds: weight,
            accruedSeconds: 0,
        };
    }, [getRandomWeightSeconds]);

    const cleIntervalLastFiredTime = useRef<number>();

    const getCleIntervalElapsedSeconds = useCallback((timestamp: number) => {
        if (!cleIntervalLastFiredTime.current)
            return 0;
        const ellapsedMillis = timestamp - cleIntervalLastFiredTime.current;
        const elapsedSeconds = ellapsedMillis / 1000;
        return parseFloat(elapsedSeconds.toFixed(3));
    }, []);

    const startCleTimer = () => {
        if (isCleTimerRunning) return;
        
        setIsCleTimerRunning(true);
        if (!currentTimer.current) {
            const newTimer = {
                weightSeconds: getRandomWeightSeconds(),
                accruedSeconds: 0,
            };
            setCurrentTimer(newTimer);
            logger.log('Start CLE timer, creating new timer', {
                individualSk: individualSk,
                itemSk: launchDetails.itemSk,
                currentSegmentItemSk: itemSk,
                timer: newTimer,
                eventTime: Date(),
            });
        } else {
            logger.log('Start CLE timer, resuming existing timer', {
                individualSk: individualSk,
                itemSk: launchDetails.itemSk,
                currentSegmentItemSk: itemSk,
                timer: currentTimer.current,
                eventTime: Date(),
            });
        }
        cleIntervalLastFiredTime.current = performance.now();
    };

    const pauseCleTimer = () => {
        logger.log('Pause CLE timer', {
            individualSk: individualSk,
            itemSk: launchDetails.itemSk,
            currentSegmentItemSk: itemSk,
            timer: currentTimer.current,
            eventTime: Date(),
        });
        setIsCleTimerRunning(false);
        cleIntervalLastFiredTime.current = undefined;
    };

    useEffect(() => {
        // Try to retrieve the timer from the cookie when the component initially mounts
        const cookieValue = Cookies.get(cookieName);
        if (cookieValue) {
            const timer = JSON.parse(cookieValue);
            logger.log('Retrieved CLE timer from cookie', {
                individualSk: individualSk,
                itemSk: itemSk,
                timer: timer,
                cookieValue: cookieValue,
                eventTime: Date(),
            });
            setCurrentTimer(timer);
        }
    }, [cookieName, individualSk, itemSk, setCurrentTimer]);

    const [getTrackCleForLive] = useLazyGetTrackCleQuery();

    useEffect(() => {
        if (!isCleTimerRunning) return;
        
        const timerInterval = new WorkerInterval(async (timestamp) => {
            const currentSequence = sequence.current;
            const currentTimerValue = currentTimer.current;
            const elapsedSeconds = getCleIntervalElapsedSeconds(timestamp);

            const getNewTimerIfWeightReached = () => {
                if (!currentTimerValue)
                    return getNewCleTimer();

                if (currentTimerValue.accruedSeconds + elapsedSeconds < currentTimerValue.weightSeconds) {
                    return null;
                }

                const bleedingSeconds = parseFloat(((currentTimerValue.accruedSeconds + elapsedSeconds) - currentTimerValue.weightSeconds).toFixed(3));

                logger.log('CLE Timer weight reached', {
                    individualSk: individualSk,
                    itemSk: launchDetails.itemSk,
                    currentSegmentItemSk: itemSk,
                    eventTime: Date(),
                    weightSeconds: currentTimerValue.weightSeconds,
                });

                const trackClePromise = launchDetails.isWebcast
                    ? getTrackCleForLive(itemSk)
                        .then((value) => value.data)
                        .catch((error: Error) => {
                            logger.error('Error trying to check track cle', { error });
                            logMediaEvent(MediaLogEventTypes.ERROR, `getTrackCleForLive(): ${error.message}`);
                            return true;
                        }) // if trackCle fails, default to true to avoid missed attendance
                    : Promise.resolve(launchDetails.trackCle && !launchDetails.isPortable);

                trackClePromise.then(trackCle => {
                    if (!trackCle) {
                        logger.log('CLE Timer is not tracking', {
                            individualSk: individualSk,
                            itemSk: launchDetails.itemSk,
                            currentSegmentItemSk: itemSk,
                            eventTime: Date(),
                            sequence: sequence,
                            trackCle,
                        });
                        return;
                    }

                    const activeCleDialogEvent = store.getState().cle.timeStampEvent;

                    // If previous dialog hasn't been dismissed, don't send a new event
                    if (!activeCleDialogEvent) {
                        const event: CleTimerEvent = {
                            registrationId: launchDetails.encryptedRegistrationId ?? '',
                            itemSk: itemSk,
                            weight: Math.round(currentTimerValue.weightSeconds / 60),
                            indexGuid: uuidv4(),
                            displayDateSeconds: Math.round(new Date().getTime() / 1000),
                            sequence: currentSequence,
                            minWeight: launchDetails.minWeight ?? 8,
                            maxWeight: launchDetails.maxWeight ?? 12,
                        };
        
                        logger.log('CLE Timer weight reached, showing attendance dialog', {
                            individualSk: individualSk,
                            itemSk: launchDetails.itemSk,
                            currentSegmentItemSk: itemSk,
                            eventTime: Date(),
                            event: event,
                            sequence: sequence,
                        });
        
                        dispatch(setTimeStampEvent(event));
                        sequence.current += 1;
                    } else if (activeCleDialogEvent && !launchDetails?.isWebcast) {

                        logger.log('CLE Timer weight reached but user is inactive, pause timer and video', {
                            individualSk: individualSk,
                            itemSk: launchDetails.itemSk,
                            currentSegmentItemSk: itemSk,
                            eventTime: Date(),
                        });
        
                        dispatch(setExternalCommand(ExternalCommand.pause));
                    }
                });
                // Reset accrued time to 0 and get new weight
                const newTimer = getNewCleTimer();

                // bleed exceeding time into next cle timer
                newTimer.accruedSeconds += bleedingSeconds;

                return newTimer;
            };

            const newTimer = getNewTimerIfWeightReached();

            setCurrentTimer((prevTimer) => {
                cleIntervalLastFiredTime.current = performance.now();
                if (newTimer) {
                    logger.log('Creating new CLE Timer', {
                        individualSk: individualSk,
                        itemSk: launchDetails.itemSk,
                        currentSegmentItemSk: itemSk,
                        timer: newTimer,
                        eventTime: Date(),
                    });
                    return newTimer;
                }
                else if (prevTimer) {
                    const updatedTimer : CleTimer = {
                        ...prevTimer,
                        accruedSeconds: Math.max(
                            0,
                            prevTimer.accruedSeconds,
                            Number((prevTimer.accruedSeconds + elapsedSeconds).toFixed(3)),
                        ),
                    };
        
                    if (Math.round(updatedTimer.accruedSeconds) % 60 === 0) {
                        logger.log('Accrued minute on CLE Timer', {
                            individualSk: individualSk,
                            itemSk: launchDetails.itemSk,
                            currentSegmentItemSk: itemSk,
                            timer: updatedTimer,
                            eventTime: Date(),
                        });
                    }
    
                    return updatedTimer;
                } else {
                    return null;
                }
            });
        }, 1000);

        return () => {
            timerInterval.stop();
        };
    }, [dispatch, getCleIntervalElapsedSeconds, getNewCleTimer, getRandomWeightSeconds, 
        getTrackCleForLive, individualSk, isCleTimerRunning, itemSk, 
        launchDetails.encryptedRegistrationId, launchDetails.isPortable, 
        launchDetails.isWebcast, launchDetails.itemSk, launchDetails.maxWeight, 
        launchDetails.minWeight, launchDetails.trackCle, setCurrentTimer, store, logMediaEvent]);

    return {
        isCleTimerRunning,
        startCleTimer,
        pauseCleTimer,
    };
};

export default useCleTimer;
