import { Mutex } from 'async-mutex';

import { delay } from '../../utility/delay';
import { apiSlice } from '..';
import { CleTimeStamp, CleTimeStampResponse } from '../generated';

const cleTimeStampsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        saveCleTimeStamps: builder.mutation<Array<CleTimeStampResponse>, Array<CleTimeStamp>>({
            query: (cleTimeStamps) => ({
                url: '/cle/cle-timestamps/batch',
                method: 'POST',
                body: cleTimeStamps,
            }),
            invalidatesTags: ['CleTimeRecords'],
            async onQueryStarted(_, { queryFulfilled }) {
                const releaseLock = await cleTimestampSaveLock.acquire();
                try {
                    await queryFulfilled;
                } finally {
                    // wait for some time so some basic logic can run 
                    // right after request goes through before releasing lock
                    await delay(100);
                    releaseLock();
                }
            },
        }),
    }),
});

export const { useSaveCleTimeStampsMutation } = cleTimeStampsSlice;
export default cleTimeStampsSlice.reducer;

export const cleTimestampSaveLock = new Mutex();
