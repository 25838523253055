import '../../styles/_bookmarks-panel.scss';

import React, { useEffect, useState } from 'react';

import { useAddBookmarkMutation, useDeleteBookmarkMutation, useUpdateBookmarkMutation } from '../../datasource/mutations/bookmarks';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAnalytics } from '../../hooks/useAnalytics';
import Button from '../Button/Button';
import { setUserNotes } from '../Store/bookmarkSlice';
import TextArea from '../TextArea/TextArea';

export type IBookmarkEditorProps = {
    segmentTitle?: string;
    timestamp?: string;
    option: 'add' | 'update' | 'delete' | 'none';
    bookmarkId?: number;
    closeSubPanel?(): void;
};

export const BookmarkEditor = (props: IBookmarkEditorProps) => {
    const { segmentTitle, timestamp, option, bookmarkId, closeSubPanel } = props;
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const [addBookmark, { isSuccess: isAddSuccess, isError: isAddError, isLoading: isAddLoading }] = useAddBookmarkMutation();
    const [updateBookmark, { isSuccess: isUpdateSuccess, isError: isUpdateError, isLoading: isUpdateLoading }] = useUpdateBookmarkMutation();
    const [deleteBookmark, { isSuccess: isDeleteSuccess, isError: isDeleteError, isLoading: isDeleteLoading }] = useDeleteBookmarkMutation();
    const [showRequiredError, setShowRequiredError] = useState(false);
    const [pushedToDataLayer, setPushedToDataLayer] = useState(false);
    const bookmarkState = useAppSelector((state) => state.bookmark);
    const dispatch = useAppDispatch();
    const { pushToDataLayer } = useAnalytics();

    const handleDeleteBookmark = () => {
        if (!isDeleteLoading && bookmarkId) {
            deleteBookmark(bookmarkId);
        }
    };

    const handleUpdateBookmark = () => {
        if (!bookmarkState.userNotes) {
            setShowRequiredError(true);

            return;
        }

        if (!isUpdateLoading) {
            updateBookmark({
                bookmarkId: bookmarkId,
                topicName: segmentTitle,
                userNotes: bookmarkState.userNotes,
            });
        }
    };

    const handleAddBookmark = () => {
        const ts = timestamp ? timestamp.split(':') : ['0', '0'];
    
        if (!bookmarkState.userNotes) {
            setShowRequiredError(true);
            return;
        }
    
        let clipPosition = 0;
    
        if (ts.length === 2) {
            // Format is minutes:seconds (xx:xx)
            clipPosition = (Number.parseInt(ts[0]) * 60) + Number.parseInt(ts[1]);
        } else if (ts.length === 3) {
            // Format is hours:minutes:seconds (xx:xx:xx)
            clipPosition = (Number.parseInt(ts[0]) * 3600) // Hours to seconds
                + (Number.parseInt(ts[1]) * 60)           // Minutes to seconds
                + Number.parseInt(ts[2]);                  // Seconds
        }
    
        if (!isAddLoading) {
            addBookmark({
                webSegmentSk: currentSegmentItemSk,
                topicName: segmentTitle,
                userNotes: bookmarkState.userNotes,
                clipPosition: clipPosition
            });
        }
    };

    useEffect(() => {
        if (isAddSuccess || isUpdateSuccess || isDeleteSuccess) {
            if (isAddSuccess && !pushedToDataLayer) {
                pushToDataLayer({ event: 'player_create_bookmark' });
                setPushedToDataLayer(true);
            }

            setTimeout(() => {
                closeSubPanel && closeSubPanel();
                dispatch(setUserNotes(''));
            }, 1000);
        }
    }, [pushToDataLayer, dispatch, isAddSuccess, pushedToDataLayer, isUpdateSuccess, isDeleteSuccess, closeSubPanel]);

    return (
        <>
            
            <div className='bookmark-editor'>
                {
                    (option == 'add' || option == 'update') &&
                    <div className='add-bookmark__form'>
                        <div className='bookmark-editor__form__title paragraph-2 text--medium-grey'>{segmentTitle}</div>
                        <div className='paragraph-2 text--grey d-flex'>
                            <div className='bookmark__options__timestamp__play'><i className='watch watch-play' /></div>
                            <span className='bookmark__options__timestamp__title'>{`Timestamped at ${timestamp}`}</span>
                        </div>
                        <div className='bookmark-editor__form__required sentence-label text--grey'>* Indicates required field</div>
                        <TextArea
                            currentValue={bookmarkState.userNotes}
                            updateValue={(text) => dispatch(setUserNotes(text))}
                            showTextAreaError={!bookmarkState.userNotes && showRequiredError}
                            maxLength={500}
                            required={true}
                            textAreaLabel='Note'
                            ariaLabel='Enter Note, 500 characters maximum'
                        />
                    </div>
                }
                {
                    option == 'delete' &&
                    <>
                        <div>
                            <div className='bookmark-editor__title heading-4'>Are you sure?</div>
                            <div className='paragraph-2 text--grey'>Removing this note will permanently delete it from your profile.</div>
                        </div>
                    </>
                }
                <div className='bookmarks-panel__button-container'>
                    {
                        (isAddError || isUpdateError || isDeleteError) &&
                        <div className='bookmark-editor__error sentence-label'>
                            <i className='watch watch-alert' />
                            <span>{`Error: note was unable to ${isDeleteError ? 'to be deleted' : 'save'}. Please try again later`}</span>
                        </div>
                    }
                    <Button
                        label={isAddSuccess || isUpdateSuccess || isDeleteSuccess ? (option == 'delete' ? 'Deleted' : 'Saved') : (option == 'delete' ? 'Delete' : 'Save')}
                        icon={isAddSuccess || isUpdateSuccess || isDeleteSuccess ? 'checkmark' : undefined}
                        iconPosition='left'
                        iconClass='mr-2'
                        typeSubmit={true}
                        aria-label={option == 'delete' ? 'Delete note' : 'Save note' }
                        buttonClass={`sticky-button ${isAddSuccess || isUpdateSuccess || isDeleteSuccess ? 'sticky-button--success' : ''}`}
                        action={option == 'add' ? handleAddBookmark : option == 'update' ? handleUpdateBookmark : handleDeleteBookmark}
                        isLoading={isAddLoading || isUpdateLoading || isDeleteLoading}
                        isSuccessState={isAddSuccess || isUpdateSuccess || isDeleteSuccess}
                    />
                </div>
            </div>
        </>
    );
};

export default BookmarkEditor;
