import { useSearchParams } from 'react-router-dom';

import { useLaunchMutation } from '../datasource/mutations/launch';
import { ExternalCommand, setCurrentPrgSegmentSk, setCurrentSegmentItemSk, setExternalCommand, setRequestStartTime } from '../stories/Store/playerSlice';
import { useAppDispatch, useAppSelector } from '.';
import { useAnalytics } from './useAnalytics';

interface IChangeSegmentProps {
    itemSk: number,
    requestStartTime?: number,
}

export const useChangeSegment = () => {

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const [launchSegment] = useLaunchMutation();
    const user = useAppSelector((state) => state.user);
    const { performZeroDollarPurchaseGA } = useAnalytics();

    const changeSegment = (props: IChangeSegmentProps) => {
        // If requestStartTime is provided, this will be used instead of the segment's last saved playback progress
        dispatch(setRequestStartTime(props.requestStartTime));

        dispatch(setExternalCommand(ExternalCommand.pause));
        // Call useLaunchMutation when isPromoted because zero dollar purchase is required
        if (launchDetails.isPromoted) {         
            launchSegment({ itemSk: props.itemSk, shouldPlacePurchaseForExpiredPrograms: true });
            performZeroDollarPurchaseGA({ segmentItemSk: props.itemSk });
        } else {
            dispatch(setCurrentSegmentItemSk(props.itemSk));
            const prgSegmentSk = launchDetails.segments?.find(x => x.itemSk === props.itemSk)?.prgSegmentSk ?? 0;
            dispatch(setCurrentPrgSegmentSk(prgSegmentSk));
        }

        // For groupcasts, leave the URL as is and do not introduce/update itemSk query params.
        if (user.isGroupcast) return;

        const currentItemSkSearchParam = Number.parseInt(searchParams.get('itemSk') ?? '0');
        if (launchDetails.isPromoted) {
            // If in Promoted player, do NOT write the programItemSk to the url, write requested segment itemSk instead,
            // because a refresh will trigger purchase of the full program
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('itemSk', props.itemSk.toString());
            window.history.replaceState({}, '', currentUrl);
        } else if (launchDetails.itemSk !== currentItemSkSearchParam) {
            // When segment is changed, and itemSk search param is for a segment, change it to Program Item Sk.
            // This is needed in case user refreshes, so that they are not taken back to segment in the query string.
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const programItemSk = launchDetails.itemSk!;
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set('itemSk', programItemSk.toString());
            window.history.replaceState({}, '', currentUrl);
        }
    };

    return {
        changeSegment
    };
};

export default useChangeSegment;