import { ItemData } from '../datasource/generated';
import { IIdentity } from '../stories/App';
import logger from './logger';

export interface ItemDataLayer {
    item_id?: string; // Required GA4 attribute
    item_name?: string; // Required GA4 attribute
    affiliation?: string;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_variant?: string;
    location_id?: string;
    quantity?: number;
    coupon?: string;
    discount?: number;
    price?: number;
    item_list_id?: string;
    item_list_name?: string;
    currency?: string;
    // Extra item attributes:  GA4 collection item limitation: up to 27 item-level custom parameters
    item_list_type?: string;
    item_list_price?: number;
    item_location?: string;
    item_practice_area?: string;
    item_practice_area2?: string;
    item_practice_area3?: string;
    item_practice_area4?: string;
    item_practice_area5?: string;
    item_industry?: string;
    item_industry2?: string;
    item_industry3?: string;
    item_industry4?: string;
    item_industry5?: string;
    item_od_segment_source_id?: string;
    item_legacy_brand?: string;
    item_parent_id?: string;
    item_parent_name?: string;
    item_master_id?: string;
    item_master_name?: string;
    item_series_id?: string;
    item_series_name?: string;
}

export function MapItemDataToItemDataLayer(item: ItemData, item_index?: number) {
    try {
        const listId = 'watch';
        const listName = 'watch';
        let listType: string | undefined;
        const index: number | undefined = item_index;
        const itemDataLayer: ItemDataLayer = {
            item_id: item.id || undefined,
            item_name: item.name || undefined,
            affiliation: item.affiliation || undefined,
            index: index,
            item_brand: item.brand || undefined,
            item_category: item.categories ? item.categories[0] : undefined,
            item_category2: item.categories ? item.categories[1] : undefined,
            item_category3: item.categories ? item.categories[2] : undefined,
            item_category4: item.categories ? item.categories[3] : undefined,
            item_category5: item.categories ? item.categories[4] : undefined,
            //item_variant: FFU 
            location_id: item.locationPlaceId || undefined,
            quantity: 1,
            coupon: item.coupon || undefined,
            discount: (item.originalPrice ?? 0) - (item.price ?? 0),
            price: item.originalPrice || undefined,
            item_list_id: listId,
            item_list_name: listName,
            currency: item.currency ?? 'USD',
            // Extra item attributes
            item_list_type: listType,
            item_list_price: item.listPrice || undefined,
            item_location: item.location|| undefined,
            item_practice_area: (item.practiceAreas && item.practiceAreas?.length > 0) ? item.practiceAreas[0] : undefined,
            item_practice_area2: (item.practiceAreas && item.practiceAreas?.length > 1) ? item.practiceAreas[1] : undefined,
            item_practice_area3: (item.practiceAreas && item.practiceAreas?.length > 2) ? item.practiceAreas[2] : undefined,
            item_practice_area4: (item.practiceAreas && item.practiceAreas?.length > 3) ? item.practiceAreas[3] : undefined,
            item_practice_area5: (item.practiceAreas && item.practiceAreas?.length > 4) ? item.practiceAreas[4] : undefined,
            item_industry: (item.industries && item.industries?.length > 0) ? item.industries[0] : undefined,
            item_industry2: (item.industries && item.industries?.length > 1) ? item.industries[1] : undefined,
            item_industry3: (item.industries && item.industries?.length > 2) ? item.industries[2] : undefined,
            item_industry4: (item.industries && item.industries?.length > 3) ? item.industries[3] : undefined,
            item_industry5: (item.industries && item.industries?.length > 4) ? item.industries[4] : undefined,
            item_od_segment_source_id: item.onDemandSegmentSourceItemPk || undefined,
            item_legacy_brand: item.legacyBrand || undefined,
            item_parent_id: item.parentId || undefined,
            item_parent_name: item.parentName || undefined,
            item_master_id: item.masterId || undefined,
            item_master_name: item.masterName || undefined,
            item_series_id: item.seriesId || undefined,
            item_series_name: item.seriesName|| undefined,
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return Object.fromEntries(Object.entries(itemDataLayer).filter(([_, v]) => v != null));
    } catch (error) {
        console.warn(error);
        return undefined;
    }
}

const getMemberType = (identity: IIdentity) => {
    if ((identity == null) || (!identity.isAuthorized))
    {
        return 'Anon';
    }
    if (identity.individualSK == 0) {
        return 'Non-Member';
    }
    if (identity.isIM) {
        return 'Individual Member';
    }
    if (identity.isPM) {
        return 'Privileged Member';
    }
    return 'Member';
};

export const setUserInfo = (identity: IIdentity) => {
    try {
        window.dataLayer = window.dataLayer || [];
        window.eventHubDataLayer = window.eventHubDataLayer || [];

        window.dataLayer.push({
            event: 'set_user_information',
            userId: identity?.individualSK ? identity.individualSK : null,
            membership: identity?.isPM || identity?.isIM,
            memberType: getMemberType(identity),
            organizationId: identity?.organizationSK ? identity.organizationSK : null,
            source: 'watch.pli.edu'
        });
    } catch (error) {
        logger.error('setUserInfo error', { error: error }, false);
    } 
};

export const getUID = () =>
    String(Date.now().toString(32) + Math.random().toString(16)).replace(
        /\./g,
        '',
    );

// Send an event only to EventHub (synthetic event)
//  Usage. f.ex: syntheticEventHubEvent({ event: 'one' });
//  Only modify the window.eventHubDataLayer array.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const syntheticEventHubEvent = async (event: any) => {
    try {      
        window.eventHubDataLayer = window.eventHubDataLayer || [];
       
        const analyticsEventTimestamp = new Date().getTime(); 
        const uniqueEventId = getUID(); // synthetic gtm.uniqueEventId
        const newEvent = { ...event, analytics_event_timestamp: analyticsEventTimestamp, 'gtm.uniqueEventId': uniqueEventId };

        window.eventHubDataLayer.push(newEvent);
    } catch (error) {
        logger.error('synthetic hub event error', { error: error }, false);
    }
};
