import '../../styles/_overlay-menu.scss';

import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { MenuOptions } from '../../enums/MenuOptions';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useAppSelector } from '../../hooks';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useMenuItems } from '../../hooks/useMenuItems';
import { trapFocus } from '../../utility/trapFocus';
import { CloseButton } from '../CloseButton/CloseButton';
import { topMenuItem } from '../MainMenu/MainMenu.utils';
import { MenuButton } from '../MenuButton/MenuButton';

export type IOverlayMenuProps = React.PropsWithChildren<{
    isPanelOpen: boolean;
    isMenuOpen: boolean;
    onShowMenuClick: (showMenu: boolean) => void;
    onSmallPanelOpen(menuOption: MenuOptions): void;
    onLargePanelOpen(menuOption: MenuOptions): void;
}>;

export function OverlayMenu({
    onSmallPanelOpen,
    onLargePanelOpen,
    isPanelOpen,
    isMenuOpen,
    onShowMenuClick,
    children,
}: IOverlayMenuProps) {
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);

    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const firstButtonFocusRef = useRef<HTMLButtonElement>(null);

    const menuItems  = useMenuItems();

    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const creditButtonRef = useRef<HTMLButtonElement>(null);
    const lastUsedButton = useRef<'menu' | 'credit' | null>(null);

    useEffect(() => {
        onShowMenuClick(isPanelOpen || isMenuOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLargeScreen]);

    useEffect(() => {
        firstButtonFocusRef.current?.focus();
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && document.activeElement) {
            trapFocus(
                document.activeElement,
                e,
                'button, [href], input[checked], select, textarea, [tabindex]:not([tabindex="-1"])'
            );
        }
    };

    useEffect(() => {
        if(!isPanelOpen && !isMenuOpen)
        {
            switch(lastUsedButton.current) {
                case 'credit':
                    creditButtonRef.current?.focus();
                    break;
                case 'menu':
                    menuButtonRef.current?.focus();
                    break;
            }
        }
    }, [menuButtonRef, isMenuOpen, isPanelOpen]);

    const segmentName = launchDetails?.segments?.find(
        (seg) => seg.itemSk === launchDetails.currentSegment
    )?.title ?? launchDetails?.title;

    const programName = launchDetails?.title;

    if (isPanelOpen) return <>{children}</>;

    if (isPanelOpen || isMenuOpen)
        return createPortal(
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div onKeyDown={handleKeyDown}>
                <CloseButton
                    onClick={() => onShowMenuClick(false)}
                    buttonClass='close-button close-button--overlay-menu'
                />
                <div className='overlay-menu container-fluid d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12 col-sm-6 mb-4'>
                            <h1 className='overlay-menu__title heading-4'>{`Now playing: ${segmentName}`}</h1>
                            {
                                segmentName !== programName &&
                                <span className='overlay-menu__subtitle paragraph-1'>{`Program: ${programName}`}</span>
                            }
                        </div>
                        <ul className='col-12 col-sm-6 overlay-menu__menu-list list-unstyled mb-0'>
                            {menuItems
                                .map((item, i) => {
                                    return (
                                        <li key={item.id}>
                                            <MenuButton
                                                onClick={() => onSmallPanelOpen(item.id)}
                                                ariaLabel={item.label}
                                                buttonClass='menu-button w-100'
                                                iconClass={`${item.iconClassName} mr-3`}
                                                label={item.label}
                                                showLabel={true}
                                                ref={i === 0 ? firstButtonFocusRef : null}
                                                labelClass='paragraph-1--medium'
                                                hasTooltip={false}
                                            />
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </div>,
            document.body
        );
    else
        return (
            <div className='overlay-menu__button-container'>
                {topMenuItem && (
                    <MenuButton
                        onClick={() => {
                            lastUsedButton.current = 'credit';
                            onSmallPanelOpen(topMenuItem.id);
                        }}
                        ariaLabel={topMenuItem.label}
                        buttonClass='menu-button--credit'
                        iconClass={`${topMenuItem.iconClassName} mr-2`}
                        label={topMenuItem.label}
                        showLabel={true}
                        hasTooltip={false}
                        isCreditButton={true}
                        ref={creditButtonRef}
                    />
                )}
                <MenuButton
                    onClick={() => {
                        lastUsedButton.current = 'menu';
                        onShowMenuClick(true);
                    }}
                    ariaLabel='Action options, menu'
                    buttonClass='menu-button--menu'
                    showLabel={false}
                    labelClass='d-none'
                    hasTooltip={false}
                    ref={menuButtonRef}
                >
                    <span className='menu-button--dot'></span>
                    <span className='menu-button--dot'></span>
                    <span className='menu-button--dot'></span>
                </MenuButton>
            </div>
        );
}

export default OverlayMenu;
