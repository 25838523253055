import '../../styles/_toast.scss';

import React, { useCallback , useEffect, useState } from 'react';

import { useUpdateNotificationReceiptsMutation } from '../../datasource/mutations/notificationReceipts';
import { useGetAlertsAndAnnouncementsQuery } from '../../datasource/queries/cmsMessages';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { formatErrorMessage } from '../../utility/toastUtils';
import { addAlert,removeToast, Toast as ToastType } from '../Store/toastSlice';
import { Toast } from './Toast';

const TOAST_MESSAGE_MAX_LENGTH_INLINE = 85;

export const ToastContainer = () => {
    const dispatch = useAppDispatch();
    const toasts = useAppSelector(state => state.toast.toasts);
    const { isWebcast } = useAppSelector((state) => state.player.launchDetails);
    const [updateNotificationReceipt] = useUpdateNotificationReceiptsMutation();

    const handleCloseButton = useCallback((toastId: number, needsReceiptUpdate: boolean) => {
        needsReceiptUpdate && updateNotificationReceipt({ notificationIds: [toastId] });
        dispatch(removeToast(toastId));
    }, [dispatch, updateNotificationReceipt]);

    const shouldShowCloseButton = useCallback((toast: ToastType) => 
        (!isWebcast || toast.type === 'announcement'), 
    [isWebcast]);

    const { data: alertsAndAnnoncements } = useGetAlertsAndAnnouncementsQuery();

    const handleOffline = useCallback((e: Event) => {
        const message = formatErrorMessage(alertsAndAnnoncements?.internetConnectionErrorMessage ?? 'Something wrong happened');
        
        dispatch(addAlert({ contentType: 'html', htmlContent: message }));
    }, [alertsAndAnnoncements?.internetConnectionErrorMessage, dispatch]);

    const handleOnline = useCallback((e: Event) => {
        const offlineMessage = formatErrorMessage(alertsAndAnnoncements?.internetConnectionErrorMessage ?? 'Something wrong happened');
        const offlineToasts = toasts.filter(t => t.htmlContent === offlineMessage);
        
        if (offlineToasts.length > 0) {
            offlineToasts.map(t => dispatch(removeToast(t.id)));
        }
    }, [alertsAndAnnoncements?.internetConnectionErrorMessage, dispatch, toasts]);

    useEffect(() => {
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => { 
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, [handleOffline, handleOnline]);

    const [isActionElementFocus, setIsActionElementFocus] = useState(false);
    useEffect(() => {
        // Prevents keyboard controls on  firing JWPlayer play/pause keyboard shortcuts
        const jwPlayerControls = document.querySelectorAll('.jwplayer');
        const preventJwKeyEvent = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter') {
                e.stopPropagation();
            }
        };

        if (isActionElementFocus) {
            jwPlayerControls.forEach(elem => {
                elem.addEventListener('keydown', preventJwKeyEvent, true);
            });
        }

        return () => {
            jwPlayerControls.forEach(elem => {
                elem.removeEventListener('keydown', preventJwKeyEvent, true);
            });
        };
    }, [isActionElementFocus]);

    const handleCloseKeyUp = (e: React.KeyboardEvent, toastId: number, needsReceiptUpdate: boolean) => {
        if ((e.code === 'Space' || e.code === 'Enter')) {
            handleCloseButton(toastId, needsReceiptUpdate);
        }
    };

    useEffect(() => {
        const container = document.getElementById('watch-toast-container');
        const links = container?.querySelectorAll('a');
        const onLinkFocus = () => setIsActionElementFocus(true);
        const onLinkBlur = () => setIsActionElementFocus(false);
        const onSpaceKeyUp = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space') {
                e.preventDefault();
                (e.target as HTMLAnchorElement).click();
            }
        };
        links?.forEach(link => {
            link.addEventListener('focus', onLinkFocus, true);
            link.addEventListener('blur', onLinkBlur, true);
            link.addEventListener('keyup', onSpaceKeyUp, true);
        });

        return () => {
            links?.forEach(link => {
                link.removeEventListener('focus', onLinkFocus, true);
                link.removeEventListener('blur', onLinkBlur, true);
                link.removeEventListener('keyup', onSpaceKeyUp, true);
                setIsActionElementFocus(false);
            });
        };
    }, [toasts]);

    return (
        <div id='watch-toast-container' className='watch-toast-container'>
            {
                toasts.map((toast) => {
                    const toastCloseButtonOrEmpty = shouldShowCloseButton(toast) ?
                        <Toast.Button
                            onClick={() => handleCloseButton(toast.id, toast.needsReceiptUpdate)}
                            onKeyUp={(e) => handleCloseKeyUp(e, toast.id, toast.needsReceiptUpdate)}
                            onFocus={() => setIsActionElementFocus(true)}
                            onBlur={() => setIsActionElementFocus(false)}
                        /> : <></>;
                    const iconClassName = toast.iconName ?? toast.type;
                    return <div key={toast.id}>
                        {
                            toast.contentType === 'text' &&
                            <Toast.Root
                                key={toast.id}
                                align={toast.message?.length > TOAST_MESSAGE_MAX_LENGTH_INLINE ? 'stack' : 'inline'}
                                type={toast.type}
                            >
                                {toast.message?.length > TOAST_MESSAGE_MAX_LENGTH_INLINE && (
                                    <>
                                        <Toast.Header>
                                            <Toast.Icon className={iconClassName} />
                                            {toastCloseButtonOrEmpty}
                                        </Toast.Header>
                                        <Toast.Content>
                                            <p>{toast.message} {toast.cta && (<a href={toast.cta.link}>{toast.cta.text}</a>)}</p>
                                        </Toast.Content>
                                    </>
                                )}

                                {toast.message.length <= TOAST_MESSAGE_MAX_LENGTH_INLINE && (
                                    <>
                                        <Toast.Icon className={iconClassName} />
                                        <Toast.Content>
                                            <p>{toast.message} {toast.cta && (<a href={toast.cta.link}>{toast.cta.text}</a>)}</p>
                                        </Toast.Content>
                                        {toastCloseButtonOrEmpty}
                                    </>
                                )}
                            </Toast.Root>
                        }
                        {
                            toast.contentType === 'html' &&
                            <Toast.Root
                                key={toast.id}
                                align={toast.htmlContent.length > TOAST_MESSAGE_MAX_LENGTH_INLINE ? 'stack' : 'inline'}
                                type={toast.type}
                            >
                                {toast.htmlContent.length > TOAST_MESSAGE_MAX_LENGTH_INLINE && (
                                    <>
                                        <Toast.Header>
                                            <Toast.Icon className={iconClassName} />
                                            {toastCloseButtonOrEmpty}
                                        </Toast.Header>
                                        <Toast.Content>
                                            <div dangerouslySetInnerHTML={{ __html: toast.htmlContent ?? '' }}></div>
                                        </Toast.Content>
                                    </>
                                )}

                                {toast.htmlContent.length <= TOAST_MESSAGE_MAX_LENGTH_INLINE && (
                                    <>
                                        <Toast.Icon className={iconClassName} />
                                        <Toast.Content>
                                            <div dangerouslySetInnerHTML={{ __html: toast.htmlContent }}></div>
                                        </Toast.Content>
                                        {toastCloseButtonOrEmpty}
                                    </>
                                )}
                            </Toast.Root>
                        }
                    </div>;
                })
            }
        </div>
    );
};