import { apiSlice } from '..';
import { Tutorial } from '../generated';

const tutorialsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTutorials: builder.query<Array<Tutorial>, void>({
            query: () => '/headless/tutorials'
        })
    })
});

export const { useGetTutorialsQuery } = tutorialsSlice;
export default tutorialsSlice;