export enum MenuOptions {
    Credit = 1,
    Segments,
    Materials,
    Faculty,
    Notes,
    QandA,
    Support,
    EndOfVideo,
    RequestCredit,
    CreditSubmissionConfirmation,
}