import '../../styles/_support-panel.scss';

import React, { useMemo } from 'react';

import { useClearCmsCacheMutation } from '../../datasource/mutations/cmsCache';
import { useAppSelector } from '../../hooks';
import Button from '../Button/Button';

export const Debug = () => {

    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const str = useMemo(() => {
        return JSON.stringify(launchDetails, null, 4);
    }, [launchDetails]);

    const [clearCmsCache, { isLoading, isSuccess }] = useClearCmsCacheMutation();

    const onClickClearCacheButton = async () => {
        await clearCmsCache();
    };

    return (
        <div className="debug">
            <h3 className="support-panel__sub-panel__title">Debug</h3>
            <Button 
                action={onClickClearCacheButton}
                buttonClass={`mb-4 sticky-button ${isSuccess ? 'sticky-button--success' : ''}`}
                isLoading={isLoading}
                label={isSuccess ? 'CMS cache cleared, please refresh' : 'Click to clear CMS cache'}
            />
            <h4 className="paragraph-1--medium">Launch Details:</h4>
            <pre className="support-panel__text paragraph-1">
                {str}
            </pre>
        </div>
    );
};

export default Debug;
