import '../../styles/_support-panel.scss';

import React, { useMemo } from 'react';

import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { useGetBetaFeedbackPanelQuery } from '../../datasource/queries/cmsMessages';
import { SupportOptions } from '../../enums/SupportOptions';
import { useAppSelector } from '../../hooks';
import { useAnalytics } from '../../hooks/useAnalytics';
import Button from '../Button/Button';
import SmallPanel from '../SmallPanel/SmallPanel';
import BetaFeedback from './BetaFeedback';
import { ContactUs } from './ContactUs';
import { Debug } from './Debug';
import { ReportAProblem } from './ReportAProblem';
import { supportItems } from './SupportPanel.utils';

export type ISupportPanelProps = {
    onClose(): void;
    title?: string;
    setSubPanel: (option: SupportOptions | false) => void;
    subPanel?: SupportOptions | false;
};

export const SupportPanel = ({ onClose, title, setSubPanel, subPanel }: ISupportPanelProps) => {
    const { data: clientConfig } = useGetClientConfigQuery();
    const user = useAppSelector((state) => state.user);
    const {
        data: betaFeedbackPanel
    } = useGetBetaFeedbackPanelQuery();
    const { pushToDataLayer } = useAnalytics();

    const showBetaFeedbackPanel = betaFeedbackPanel &&
        !!betaFeedbackPanel.supportPanelOptionLabel?.length &&
        !!betaFeedbackPanel.panelTitle?.length &&
        !!betaFeedbackPanel.panelContent?.length;

    const selectedSubPanel = useMemo(() => {
        if (subPanel === false) return null;

        const subPanelItem = supportItems.find((item) => item.id === subPanel);

        switch (subPanel) {
            case SupportOptions.ReportAProblem: {
                return {
                    title: subPanelItem?.label,
                    component: () => (
                        <ReportAProblem
                            closeSubPanel={() => setSubPanel(false)}
                        />
                    ),
                };
            }
            case SupportOptions.SystemDiagnostics: {
                return {
                    title: subPanelItem?.label,
                    component: () => <div>This is the panel content.</div>,
                };
            }
            case SupportOptions.ContactUs: {
                return {
                    title: subPanelItem?.label,
                    component: ContactUs,
                };
            }
            case SupportOptions.Debug: {
                return {
                    title: subPanelItem?.label,
                    component: Debug,
                };
            }
            case SupportOptions.BetaFeedback: {
                return {
                    title: betaFeedbackPanel?.panelTitle,
                    component: () => <BetaFeedback
                        panelTitle={betaFeedbackPanel?.panelTitle ?? ''}
                        panelContent={betaFeedbackPanel?.panelContent ?? ''}
                    />,
                };
            }
            default: {
                return {
                    title: subPanelItem?.label,
                    component: () => <div>This is the panel content.</div>,
                };
            }
        }
    }, [subPanel, setSubPanel, betaFeedbackPanel]);

    const handleClose = () => {
        setSubPanel(false);
        onClose();
    };

    const handleHelpCenterButtonClick =  () =>  {
        window.open(
            clientConfig!.pliHelpCenterUrl as string,
            '_blank'
        );
        pushToDataLayer({ event: 'visit_help' });
    };

    return (
        <>
            <SmallPanel
                isOpen={selectedSubPanel !== null}
                onBack={() => setSubPanel(false)}
                onClose={handleClose}
                title={''}
                isSublevel={true}
                hasStickyButton={true}
            >
                {selectedSubPanel && <selectedSubPanel.component />}
            </SmallPanel>
            <>
                <h3 className="support-panel__title heading-5">{title}</h3>
                <div className="support-panel__button-container">
                    {supportItems
                        .filter(
                            (item) =>
                                (item.id !== SupportOptions.SystemDiagnostics && //remove SystemDiagnostics temporarily
                                (item.id !== SupportOptions.Debug || user.isPliEmployee) &&
                                (item.id !== SupportOptions.BetaFeedback || showBetaFeedbackPanel))
                        )
                        .map((supportItem) => {
                            return (
                                <Button
                                    key={supportItem.id}
                                    icon='forward-arrow'
                                    iconPosition='right'
                                    label={supportItem.id === SupportOptions.BetaFeedback ? betaFeedbackPanel?.supportPanelOptionLabel ?? supportItem.label : supportItem.label}
                                    buttonClass="support-panel__button"
                                    action={() => setSubPanel(supportItem.id)}
                                />
                            );
                        })}
                </div>
                <Button
                    icon='outbound-link'
                    iconPosition='right'
                    iconClass='ml-2'
                    label='Visit the PLI Help Center'
                    aria-label='Visit the PLI Help Center in a new tab'
                    buttonClass='support-panel__button--ghost paragraph-1--bold'
                    action={handleHelpCenterButtonClick}
                />
            </>
        </>
    );
};

export default SupportPanel;
