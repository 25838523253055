import JWPlayer from '@jwplayer/jwplayer-react';
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ScreenSizeQueries } from '../enums/ScreenSizeQueries';
import { addAnnouncement, removeToast } from '../stories/Store/toastSlice';
import { useAppDispatch, useAppSelector } from '.';
import { useMediaQuery } from './useMediaQuery';
import { useSlides } from './useSlides';

interface IMobileSlideToggleProps {
  playerRef: MutableRefObject<typeof JWPlayer>;
  toggleTranscriptButton: (showTranscript: boolean) => void;
}

export const useMobileSlideToggle = (props: IMobileSlideToggleProps) => {
    const { playerRef, toggleTranscriptButton } = props;
    const { currentSlide } = useSlides();

    const [viewSlides, setViewSlides] = useState(false);
    const [slidesEnabled, setSlidesEnabled] = useState(false);
    const [slidesNewlyEnabled, setSlidesNewlyEnabled] = useState(false);
    const prevSlidesEnabledRef = useRef<boolean | undefined>(undefined);

    const dispatch = useAppDispatch();
    const isSmallScreen = useMediaQuery(ScreenSizeQueries.JWSMALL);

    useEffect(() => {
        setSlidesEnabled((currentSlide?.slideNumber ?? 0) > 0);
    }, [currentSlide?.slideNumber]);

    const onMobileToggleSlides = (showSlides: boolean) => {
        setSlidesNewlyEnabled(false);
        toggleTranscriptButton(false);
        displayToggleSlidesButton(showSlides);
        setViewSlides(showSlides);
    };

    const showSlideToast = useCallback(
        (hasSlides: boolean, hasTimer?: boolean) => {
            const message = hasSlides
                ? 'Slides are now available'
                : 'Slides are not available';

            const newID = Date.now();

            isSmallScreen &&
        dispatch(
            addAnnouncement({ 
                contentType: 'text', 
                message: message, 
                id: newID, 
                needsReceiptUpdate: false 
            })
        );

            if (hasTimer) {
                setTimeout(() => {
                    dispatch(removeToast(newID));
                }, 3000);
            }
        },
        [dispatch, isSmallScreen]
    );

    const displayToggleSlidesButton = (
        showSlides: boolean,
        showDot?: boolean
    ) => {
        if (isSmallScreen && playerRef.current) {
            const buttonId = 'toggleSlideButton';

            const showSlidesButton =
        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3.10547H12.5V1.60547H7.5V3.10547ZM15 5.60547H5V4.10547H15V5.60547ZM16 8.77216H4V15.7722H16V8.77216ZM4 7.27216H2.5V8.77216V15.7722V17.2722H4H16H17.5V15.7722V8.77216V7.27216H16H4Z" fill="white"/></svg>';
            const showMediaButton =
        '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="MediaPlayer/Media"><path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M18.3327 3.33398H1.66602V16.6673H18.3327V3.33398ZM3.33268 5.00065H4.16602V6.75065H3.33268V5.00065ZM5.83268 8.25065V6.75065V5.00065H14.166V6.75065V8.25065V11.7507V13.2507V15.0007H5.83268V13.2507V11.7507V8.25065ZM15.8327 13.2507V15.0007H16.666V13.2507H15.8327ZM16.666 11.7507V8.25065H15.8327V11.7507H16.666ZM4.16602 8.25065V11.7507H3.33268V8.25065H4.16602ZM4.16602 13.2507H3.33268V15.0007H4.16602V13.2507ZM16.666 6.75065V5.00065H15.8327V6.75065H16.666ZM12.4993 10.0007L8.33268 7.50065V12.5007L12.4993 10.0007Z" fill="white"/></g></svg>';

            if (showDot) {
                showSlideToast(true, true);
            }

            playerRef.current.addButton(
                slidesEnabled && showSlides ? showMediaButton : showSlidesButton,
                slidesEnabled && `Show ${showSlides ? 'video' : 'slides'}`,
                () =>
                    slidesEnabled
                        ? onMobileToggleSlides(!showSlides)
                        : showSlideToast(false, true),
                buttonId,
                `pli-icon-${showSlides ? 'media' : 'slides'} ${
                    slidesEnabled ? '' : 'pli-icon-slides--disabled'
                } ${
                    !showSlides && slidesEnabled && showDot
                        ? 'pli-icon-slides--newly-enabled'
                        : ''
                }`
            );
        }
    };

    useEffect(() => {
        if (slidesEnabled !== prevSlidesEnabledRef.current) {
            if (slidesEnabled && (!prevSlidesEnabledRef.current || prevSlidesEnabledRef.current === undefined)) {
                setSlidesNewlyEnabled(slidesEnabled);
                displayToggleSlidesButton(viewSlides, true);
            } else {
                displayToggleSlidesButton(viewSlides);
            }
            prevSlidesEnabledRef.current = slidesEnabled;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slidesEnabled, slidesNewlyEnabled]);

    useEffect(() => {
        if (slidesEnabled && !viewSlides) {
            displayToggleSlidesButton(viewSlides, slidesNewlyEnabled);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlide]);

    return {
        displayToggleSlidesButton,
        viewSlides,
        setSlidesNewlyEnabled,
        onMobileToggleSlides,
        slidesEnabled,
        setSlidesEnabled,
    };
};

export default useMobileSlideToggle;
