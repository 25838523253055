import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { MenuOptions } from '../../enums/MenuOptions';

export type ExamStatusComponents = 'ReviewAndExamTab' | 'RequestCredit' |'CpeEndOfVideo';

type ExamStatusVisible = {
    [key in ExamStatusComponents] : boolean;
}

export type ExamStatusVisiblePayloadAction = {
    [key in keyof ExamStatusVisible]? : ExamStatusVisible[key];
}

export interface MenuState {
    smallPanelOpenCommand : MenuOptions | false;
    largePanelOpenCommand: MenuOptions | false;
    isSideBarDisabled: boolean;
    examStatusVisibility: ExamStatusVisible;
}

const initialState: MenuState = {
    smallPanelOpenCommand : false,
    largePanelOpenCommand: false,
    isSideBarDisabled: false,
    examStatusVisibility: {
        ReviewAndExamTab: false,
        RequestCredit: false,
        CpeEndOfVideo: false,
    }
};


const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setSmallPanelOpenCommand(state, action: PayloadAction<MenuOptions>) {
            state.smallPanelOpenCommand = action.payload;
        },
        clearSmallPanelOpenCommand(state) {
            state.smallPanelOpenCommand = false;
        },
        setLargePanelOpenCommand(state, action: PayloadAction<MenuOptions>) {
            state.largePanelOpenCommand = action.payload;
        },
        clearLargePanelOpenCommand(state) {
            state.largePanelOpenCommand = false;
        },
        setIsSideBarDisabled(state, action: PayloadAction<boolean>) {
            state.isSideBarDisabled = action.payload;
        },
        setIsExamStatusVisible(state, action: PayloadAction<ExamStatusVisiblePayloadAction>) {
            state.examStatusVisibility = { ...state.examStatusVisibility, ...action.payload };
        }
    },
});

export const { 
    setSmallPanelOpenCommand,
    clearSmallPanelOpenCommand, 
    setLargePanelOpenCommand, 
    clearLargePanelOpenCommand, 
    setIsSideBarDisabled, 
    setIsExamStatusVisible 
} = menuSlice.actions;

export const { reducer: menuReducer } = menuSlice;
