import { apiSlice } from '..';
import {
    AlertsAndAnnouncements,
    AttendanceDialogMessages,
    CreditProgressMessages,
    CreditReviewAndExamMessages,
    CreditSubmissionMessages,
    LicenseMessages,
    MobilePromotion,
    OffAirMessages,
    WatchBetaFeedbackPanel,
    WatchGreetingMessage,
} from '../generated';

const cmsMessagesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLicenseMessages: builder.query<LicenseMessages, void>({
            query: () => '/headless/license-messages',
        }),
        getCleDialogMessages: builder.query<AttendanceDialogMessages, void>({
            query: () => '/headless/attendance-dialog-messages',
        }),
        getCreditProgressMessages: builder.query<CreditProgressMessages, void>({
            query: () => 'headless/credit-progress-messages',
        }),
        getCreditReviewAndExamMessages: builder.query<CreditReviewAndExamMessages, void>({
            query: () => '/headless/credit-review-and-exam-messages',
        }),
        getCreditSubmissionMessages: builder.query<CreditSubmissionMessages, void>({
            query: () => 'headless/credit-submission-messages',
        }),
        getAlertsAndAnnouncements: builder.query<AlertsAndAnnouncements, void>({
            query: () => '/headless/alerts-and-announcements',
        }),
        getOffAirMessages: builder.query<OffAirMessages, void>({
            query: () => '/headless/off-air-messages',
        }),
        getBetaFeedbackPanel: builder.query<WatchBetaFeedbackPanel, void>({
            query: () => '/headless/beta-feedback-panel',
        }),
        getMobilePromotion: builder.query<MobilePromotion, void>({
            query: () => '/headless/mobile-promotion',
        }),
        getGreetingMessage: builder.query<WatchGreetingMessage, void>({
            query: () => '/headless/greeting-message',
        }),
    }),
});

export const {
    useGetLicenseMessagesQuery,
    useGetCleDialogMessagesQuery,
    useGetCreditProgressMessagesQuery,
    useGetCreditReviewAndExamMessagesQuery,
    useGetCreditSubmissionMessagesQuery,
    useLazyGetAlertsAndAnnouncementsQuery,
    useGetAlertsAndAnnouncementsQuery,
    useGetOffAirMessagesQuery,
    useGetBetaFeedbackPanelQuery,
    useGetMobilePromotionQuery,
    useGetGreetingMessageQuery,
} = cmsMessagesSlice;
export default cmsMessagesSlice;
