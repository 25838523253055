import JWPlayer from '@jwplayer/jwplayer-react';
import Cookies from 'js-cookie';
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { menuData } from '../stories/SettingsMenu/MenuData';
import { IMenuItemData } from '../stories/SettingsMenu/MenuItem';
import logger from '../utility/logger';
import { useAppSelector } from './';
import { useFetchTranscripts } from './useFetchTranscripts';

interface ISettingsProps {
  playerRef: MutableRefObject<typeof JWPlayer>;
}

export interface IFormatSettings {
  darkBackground: 'Dark' | 'Light';
  fontSize: 'XLarge' | 'Large' | 'Medium' | 'Small' | 'XSmall';
  lineSpacing?: '75%' | '50%' | '100%' | '125%' | '150%';
}

export interface IQualityLevel {
  bitrate?: number;
  label: string;
  width?: number;
  height?: number;
}

export interface IQualityLevels {
  levels: IQualityLevel[];
  currentQuality: number;
  type: string;
}

export const usePlayerSettings = (props: ISettingsProps) => {
    const { playerRef } = props;
    const captionCookieValue = {
        darkBackground: Cookies.get('captionsDarkBackground'),
        fontSize: Cookies.get('captionsFontSize'),
    };

    const transcriptCookieValue = useMemo(() => {
        return {
            darkBackground: Cookies.get('transcriptDarkBackground'),
            fontSize: Cookies.get('transcriptFontSize'),
            lineSpacing: Cookies.get('transcriptLineSpacing'),
        };
    }, []);

    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [captionsSettings, setCaptionsSettings] = useState<IFormatSettings>(
        captionCookieValue ?? { darkBackground: 'Dark', fontSize: 'Small' }
    );

    const [transcriptSettings, setTranscriptSettings] = useState<IFormatSettings>(
        transcriptCookieValue ?? {
            darkBackground: 'Dark',
            fontSize: 'Small',
            lineSpacing: '100%',
        }
    );

    const [qualitySettings, setQualitySettings] = useState<IQualityLevels | null>(
        null
    );
    const [settingsMenu, setSettingsMenu] = useState<IMenuItemData[] | null>(
        null
    );

    const { transcripts } = useFetchTranscripts();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const individualSk = useAppSelector((state) => state.user.individualSK);
    const settingsButtonRef = useRef<HTMLDivElement>();

    const handleQualityLevels = (levels: IQualityLevels) => {
        logger.log('JW Player Quality Levels Event', {
            individualSk: individualSk,
            itemSk: launchDetails.itemSk,
            currentQuality: playerRef.current.getCurrentQuality(),
            qualityLevels: levels,
        });
        const getQualityLevels = levels.levels.map((item) => {
            return { label: item['label'] };
        });
        setSettingsMenu([
            ...menuData,
            { label: 'Quality', subMenuItems: getQualityLevels },
        ]);
        setQualitySettings(levels);
    };

    const [isSettingsButtonFocus, setIsSettingsButtonFocus] = useState(false);
    const handleSettingsButtonRef = () => {
        settingsButtonRef.current = document.querySelector(
            '.pli-icon-settings'
        ) as HTMLDivElement;
        const onButtonFocus = () => setIsSettingsButtonFocus(true);
        const onButtonBlur = () => setIsSettingsButtonFocus(false);
        if (settingsButtonRef.current) {
            settingsButtonRef.current.addEventListener(
                'keyup',
                handleSettingsKeyUp
            );
            settingsButtonRef.current.addEventListener(
                'focus',
                onButtonFocus
            );
            settingsButtonRef.current.addEventListener(
                'blur',
                onButtonBlur
            );
        }

        return () => {
            settingsButtonRef.current?.removeEventListener(
                'keyup',
                handleSettingsKeyUp
            );
            settingsButtonRef.current?.removeEventListener(
                'focus',
                onButtonFocus
            );
            settingsButtonRef.current?.removeEventListener(
                'blur',
                onButtonBlur
            );
            setIsSettingsButtonFocus(false);
        };
    };

    const handleSettingsKeyUp = (e: Event) => {
        e.stopPropagation();
        const code = (e as KeyboardEvent).code;
        if (code === 'Space' || code === 'Enter') {
            setShowSettingsMenu((state) => !state);
        }
    };

    const handleCloseSettings = () => {
        setShowSettingsMenu(false);
        setTimeout(() => settingsButtonRef.current?.focus(), 200);
    };

    useEffect(() => {
        // Prevents keyboard controls on Setting button firing JWPlayer play/pause keyboard shortcuts
        const jwPlayerControls = document.querySelectorAll('.jwplayer');
        const preventJwKeyEvent = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter' || code === 'ArrowUp' || code === 'ArrowDown') {
                e.stopPropagation();
            }
        };

        if (isSettingsButtonFocus) {
            jwPlayerControls.forEach(elem => {
                elem.addEventListener('keydown', preventJwKeyEvent, true);
            });
        }

        return () => {
            jwPlayerControls.forEach(elem => {
                elem.removeEventListener('keydown', preventJwKeyEvent, true);
            });
        };
    }, [isSettingsButtonFocus]);

    const settingsButtonSvg = useMemo(() => {
        return '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="1 1 22 22" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.1134 7.65433C18.757 8.558 19.2051 9.61043 19.3963 10.75H22V13.25H19.3963C19.2051 14.3896 18.7569 15.4421 18.1134 16.3458L19.9548 18.1872L18.187 19.955L16.3456 18.1135C15.4419 18.757 14.3895 19.2051 13.25 19.3963V22H10.75V19.3963C9.61043 19.2051 8.558 18.757 7.65433 18.1134L5.81296 19.9548L4.04519 18.187L5.88656 16.3457C5.24305 15.442 4.79488 14.3896 4.6037 13.25H2V10.75H4.6037C4.79489 9.61039 5.24308 8.55793 5.88663 7.65424L4.04519 5.81281L5.81296 4.04504L7.65442 5.8865C8.55807 5.24302 9.61047 4.79488 10.75 4.6037V2H13.25V4.6037C14.3896 4.79488 15.442 5.24305 16.3457 5.88656L18.187 4.04523L19.9548 5.813L18.1134 7.65433ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" fill="white"/></svg>';
    }, []);

    const displayToggleSettingsButton = useCallback(() => {
        if (playerRef.current) {
            const buttonId = 'toggleSettingsButton';

            const captionsTracks = playerRef.current.getCaptionsList();

            if (
                (captionsTracks &&
          captionsTracks.length > 1 ||
          transcripts.length > 0) ||
        launchDetails?.isWebcast
            ) {
                playerRef.current.addButton(
                    settingsButtonSvg,
                    'Settings',
                    () => setShowSettingsMenu((state) => !state),
                    buttonId,
                    'pli-icon-settings'
                );
            }
        }
    }, [playerRef, settingsButtonSvg, launchDetails?.isWebcast, transcripts.length]);

    const cookieOptions = useMemo(
        () => ({
            sameSite: 'strict',
            expires: 365,
        }),
        []
    );

    const handleFontSize = (fontSize: string) => {
        switch (fontSize) {
            case 'XLarge':
                // returns 40px
                return 15.5;
                break;
            case 'Large':
                // returns 28px
                return 10.5;
                break;
            case 'Medium':
                // returns 16px
                return 6;
                break;
            case 'Small':
                // returns 14px;
                return 5.25;
                break;
            case 'XSmall':
                // returns 12px;
                return 4.5;
                break;
        }
    };

    useEffect(() => {
        Cookies.set(
            'captionsDarkBackground',
            captionsSettings.darkBackground ?? 'Dark',
            cookieOptions
        );
        Cookies.set(
            'captionsFontSize',
            captionsSettings.fontSize ?? 'Small',
            cookieOptions
        );
        if (playerRef.current) {
            const newFontSize = handleFontSize(captionsSettings.fontSize ?? 'Small');
            captionsSettings.darkBackground
                ? playerRef.current.setCaptions({
                    backgroundColor:
              captionsSettings.darkBackground === 'Dark'
                  ? '#000000'
                  : '#FFFFFF',
                    color:
              captionsSettings.darkBackground === 'Dark'
                  ? '#FFFFFF'
                  : '#000000',
                    fontSize: newFontSize,
                    backgroundOpacity: 80,
                })
                : playerRef.current.setCaptions({
                    backgroundColor: '#000000',
                    color: '#FFFFFF',
                    fontSize: newFontSize,
                    backgroundOpacity: 80,
                });
        }
    }, [playerRef, captionsSettings, cookieOptions]);

    useEffect(() => {
        Cookies.set(
            'transcriptDarkBackground',
            transcriptSettings.darkBackground ?? 'Dark',
            cookieOptions
        );
        Cookies.set(
            'transcriptFontSize',
            transcriptSettings.fontSize ?? 'Small',
            cookieOptions
        );
        Cookies.set(
            'transcriptLineSpacing',
            transcriptSettings.lineSpacing ?? '100%',
            cookieOptions
        );
    }, [transcriptSettings, cookieOptions, transcriptCookieValue]);

    return {
        showSettingsMenu,
        displayToggleSettingsButton,
        handleSettingsKeyUp,
        setShowSettingsMenu,
        handleCloseSettings,
        handleSettingsButtonRef,
        settingsButtonRef,
        setCaptionsSettings,
        captionsSettings,
        setTranscriptSettings,
        transcriptSettings,
        setSettingsMenu,
        settingsMenu,
        handleQualityLevels,
        qualitySettings,
        setQualitySettings,
        handleFontSize,
    };
};
