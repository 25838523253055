import React, { useEffect, useRef, useState } from 'react';

import { IndividualCreditAndLicenseInfo } from '../../datasource/generated/models/IndividualCreditAndLicenseInfo';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Button from '../Button/Button';
import { LICENSE_MODAL_ID } from '../LicenseModal/LicenseModal';
import { disableLicenseForCreditRequest, enableLicenseForCreditRequest } from '../Store/requestCreditSlice';
import Toggle from '../Toggle/Toggle';

export interface IRequestCreditLicenseCardProps {
    elementId: string,
    license: IndividualCreditAndLicenseInfo,
    jurisdictionName: string,
    licenseNoLabel: string,
    isLicenseNoRequired: boolean,
    onEditLicensePress: (license: IndividualCreditAndLicenseInfo, jurisdictionName: string) => void,
    isEnabled: boolean,
}

export const RequestCreditLicenseCard = ({
    elementId,
    license,
    jurisdictionName,
    licenseNoLabel,
    isLicenseNoRequired,
    onEditLicensePress,
    isEnabled,
} : IRequestCreditLicenseCardProps) => {

    const dispatch = useAppDispatch();
    const [toggleState, setToggleState] = useState(isEnabled);
    const toggleLabel = `${toggleState ? 'Deselect' : 'Select'} license ${jurisdictionName} for credit submission`;
    const cardRef = useRef<HTMLDivElement>(null);
    const editRef = useRef<HTMLButtonElement>(null);
    const focusTarget = useAppSelector((state) => state.requestCredit.licenseFocusTarget);

    useEffect(() => {
        if(!focusTarget) return;
        if(license.indvStateLicense_SK === focusTarget.licenseSk) {
            if(focusTarget.mode === 'card')
                cardRef.current?.focus();
            
            if(focusTarget.mode === 'edit-button')
                editRef.current?.focus();
        }
    }, [focusTarget, license.indvStateLicense_SK]);

    useEffect(() => {
        if (!license.indvStateLicense_SK) return;
        if (toggleState) {
            dispatch(enableLicenseForCreditRequest(license.indvStateLicense_SK));
        } else {
            dispatch(disableLicenseForCreditRequest(license.indvStateLicense_SK));
        }
    }, [dispatch, license.indvStateLicense_SK, toggleState]);

    const onEdit = () => {
        onEditLicensePress(license, jurisdictionName);
    };

    const showLicenseNumberError = isLicenseNoRequired && !license.stateLicenseNumber && isEnabled;

    const licenseInfoLabelClass = `sentence-label mb-0 ${toggleState ? 'text--medium-grey' : 'text--grey'}` ;
    const licenseInfoValueClass = `sentence-label mb-0 ${toggleState ? 'text--white' : 'text--grey'}`;
    const jurisdictionNameClass = `paragraph-2 mb-0 ${toggleState ? 'text--white' : 'text--grey'}`;
    const licenseInfoValueErrorClass = 'sentence-label text--system-red mb-0';

    const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        // onFocus is propagated, so the element is not semantically focusable but its children are
        <div id={elementId} className='request-credit-license-card' tabIndex={-1} ref={cardRef} onFocus={handleFocus}>
            <div className='request-credit-license-card__content'>
                <div className='request-credit-license-card__content__details'>
                    <Toggle 
                        id={`toggle-${jurisdictionName}`}
                        color='emerald' 
                        ariaLabel={toggleLabel} 
                        checked={toggleState} 
                        onChange={() => setToggleState(state => !state)}/>
                    <h4 className={jurisdictionNameClass}>{jurisdictionName}</h4>
                    <section className='request-credit-license-card__content__details__license-info'>
                        <p>
                            <span className={licenseInfoLabelClass}>{`${licenseNoLabel}: `}</span>
                            {
                                showLicenseNumberError ? 
                                    <span className={licenseInfoValueErrorClass}>Required</span>
                                    : <span className={licenseInfoValueClass}>{license.stateLicenseNumber}</span>
                            }
                        </p>
                        <p>
                            <span className={licenseInfoLabelClass}>Compliance group: </span>
                            <span className={licenseInfoValueClass}>{license.complianceGroup}</span>
                        </p>
                        <p>
                            <span className={licenseInfoLabelClass}>Admission year: </span>
                            <span className={licenseInfoValueClass}>{license.yearAdmitted}</span>
                        </p>
                    </section>
                </div>
                <Button 
                    buttonClass='request-credit-license-card__content__edit' 
                    label='Edit' 
                    action={onEdit} 
                    aria-label={`Edit ${jurisdictionName}`}
                    aria-haspopup={'dialog'}
                    aria-controls={LICENSE_MODAL_ID}
                    ref={editRef}
                />
            </div>
        </div>
    );
};

export default RequestCreditLicenseCard;