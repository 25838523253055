import '../../styles/_sidebar.scss';

import React, { RefObject, useLayoutEffect, useRef, useState } from 'react';

import { MenuOptions } from '../../enums/MenuOptions';
import { useAppSelector } from '../../hooks';
import { useMenuItems } from '../../hooks/useMenuItems';
import { topMenuItem } from '../MainMenu/MainMenu.utils';
import { MenuButton } from '../MenuButton/MenuButton';

export type ISideBarProps = React.PropsWithChildren<{
    isPanelOpen: boolean;
    onSmallPanelOpen(menuOption: MenuOptions): void;
    onLargePanelOpen(menuOption: MenuOptions): void;
    optionSelected?: MenuOptions | false;
    buttonRef?: RefObject<HTMLButtonElement>;
}>;

export const SideBar = ({
    onSmallPanelOpen,
    onLargePanelOpen,
    isPanelOpen,
    children,
    optionSelected,
    buttonRef,
}: ISideBarProps) => {

    const menuItems = useMenuItems();
    const isDisabled = useAppSelector((state) => state.menu.isSideBarDisabled);
    const [expanded, setExpanded] = useState(false);
    const touchTrack = useRef({ start: 0, end: 0 });

    const MIN_SWIPE_DISTANCE = 50;

    function onTouchStart(e: React.TouchEvent) {
        touchTrack.current.end = 0;
        touchTrack.current.start = e.targetTouches[0].clientX;
    }

    function onTouchMove(e: React.TouchEvent) {
        touchTrack.current.end = e.targetTouches[0].clientX;
    }

    function onTouchEnd() {
        if (isPanelOpen) return;

        const { start: touchStart, end: touchEnd } = touchTrack.current;

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
        const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;

        if (touchEnd === 0) {
            setExpanded(!expanded);
        } else if (isRightSwipe) {
            setExpanded(false);
        } else if (isLeftSwipe) {
            setExpanded(true);
        }
    }

    function handleExpandMenu() {
        if (isPanelOpen) return;

        setExpanded(true);
    }

    function handleCollapseMenu() {
        setExpanded(false);
    }

    useLayoutEffect(() => {
        if (isPanelOpen) setExpanded(false);
    }, [isPanelOpen]);

    useLayoutEffect(() => {
        // Move dialogs when sidebar is expanded or collapsed
        const dialogs = [
            { id: 'cle-dialog', menuExpandedClassName: 'cle-dialog--menu-expanded' },
            { id: 'cpe-dialog', menuExpandedClassName: 'cpe-dialog--menu-expanded' },
        ];
        dialogs.forEach((dialog) => {
            const element = document.getElementById(dialog.id);
            if (!element) return;
            if (expanded) {
                element.classList.add(dialog.menuExpandedClassName);
            } else {
                element.classList.remove(dialog.menuExpandedClassName);
            }
        });
    }, [expanded]);

    return (
        <>
            <div
                className={`sidebar__container 
                    ${expanded ? 'sidebar__container--expanded' : ''}`}
            >
                <div
                    id='sidebar'
                    className={`sidebar ${expanded ? 'sidebar--expanded' : ''}`}
                    onMouseOver={handleExpandMenu}
                    onMouseOut={handleCollapseMenu}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                    onFocus={handleExpandMenu}
                    onBlur={handleCollapseMenu}
                >
                    <MenuButton
                        ref={topMenuItem.id == optionSelected ? buttonRef : null}
                        onClick={() => onSmallPanelOpen(topMenuItem.id)}
                        ariaLabel={topMenuItem.label}
                        buttonClass={`
                            ${expanded ? 'menu-button--expanded' : 'menu-button--collapsed'} 
                            ${topMenuItem.id === optionSelected ? 'menu-button--selected' : ''} 
                            menu-button menu-button--top`}
                        iconClass={`${topMenuItem.iconClassName}`}
                        label={topMenuItem.label}
                        showLabel={expanded}
                        labelClass={`paragraph-1--medium ml-3 menu-button__text--top
                            ${expanded ? 'position-static' : 'position-absolute'}`}
                        hasTooltip={true}
                        isSelected={topMenuItem.id === optionSelected}
                        isCreditButton={true}
                        disabled={isDisabled}
                    />
                    <div className='menu-button__wrapper'>
                        <div className='d-flex flex-column w-100'>
                            {menuItems
                                .map((item, i, arr) => {
                                    const buttonClass = `${arr.length - 1 !== i ? 'menu-button--mb' : ''} 
                                        ${expanded ? 'menu-button--expanded' : 'menu-button--collapsed'} 
                                        ${item.id === optionSelected ? 'menu-button--selected' : ''} 
                                        menu-button`;
                                    return (
                                        <MenuButton
                                            ref={item.id == optionSelected ? buttonRef : null}
                                            onClick={() => onSmallPanelOpen(item.id)}
                                            ariaLabel={item.label}
                                            buttonClass={buttonClass}
                                            iconClass={item.iconClassName}
                                            label={item.label}
                                            showLabel={expanded}
                                            key={item.id}
                                            labelClass='paragraph-1--medium ml-3 position-absolute'
                                            hasTooltip={true}
                                            isSelected={item.id === optionSelected}
                                            disabled={isDisabled}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </>
    );
};

export default SideBar;
