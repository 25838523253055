import { IMenuItemData } from './MenuItem';

export const menuData: IMenuItemData[] = [
    {
        label: 'Captions',
        subMenuItems: [
            {
                label: 'Background color',
                subMenuItems: [{ label: 'Light' }, { label: 'Dark' }],
            },
            {
                label: 'Font size',
                subMenuItems: [
                    { label: 'XLarge' },
                    { label: 'Large' },
                    { label: 'Medium' },
                    { label: 'Small' },
                    { label: 'XSmall' },
                ],
            },
        ],
    },
    { 
        label: 'Transcript',
        subMenuItems: [
            {
                label: 'Background',
                subMenuItems: [{ label: 'Light' }, { label: 'Dark' }],
            },
            {
                label: 'Font size',
                subMenuItems: [
                    { label: 'Large' },
                    { label: 'Medium' },
                    { label: 'Small' },
                    { label: 'XSmall' },
                ],
            },
            {
                label: 'Line spacing',
                subMenuItems: [
                    { label: '50%' },
                    { label: '75%' },
                    { label: '100%' },
                    { label: '125%' },
                    { label: '150%' },
                ],
            },
        ],
    },
    // Quality is added via getting them from JWPlayer
];
