import '../../styles/_modal.scss';
import '../../styles/_license-review.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { IndividualCreditAndLicenseInfo } from '../../datasource/generated';
import { useGetLicenseMessagesQuery } from '../../datasource/queries/cmsMessages';
import { useGetLicensesQuery } from '../../datasource/queries/licenses';
import { useGetProgramCreditDetailsQuery } from '../../datasource/queries/programDetails';
import { useAppSelector } from '../../hooks';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { useScrollForMoreDetails } from '../../hooks/useScrollForMoreDetails';
import Button from '../Button/Button';
import { LicenseGrid } from '../LicenseGrid/LicenseGrid';
import { ILicenseModalFocusTarget, LICENSE_MODAL_HEADING_ID } from '../LicenseModal/LicenseModal';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';


export interface ILicenseReviewProps {
    onEditLicensePress: (license: IndividualCreditAndLicenseInfo, jurisdictionName?: string) => void;
    onAddLicensePress: () => void;
    onFinish: () => void,
    modalRef?: MutableRefObject<HTMLDivElement | undefined>,
    focusTarget?: ILicenseModalFocusTarget,
}

export const LicenseReview = ({
    onEditLicensePress,
    onAddLicensePress,
    onFinish,
    modalRef,
    focusTarget,
}: ILicenseReviewProps) => {
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);
    
    const {
        data: licenses,
        isLoading: isLoadingLicenses,
        isSuccess: isGetLicensesSuccess,
        isError: isGetLicensesError,
    } = useGetLicensesQuery(!encryptedRegistrationId ? skipToken : { encryptedRegistrationId });

    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const {
        data: creditDetails,
        isLoading: isLoadingCreditDetails,
        isSuccess: isGetCreditDetailsSuccess,
        isError: isGetCreditDetailsError,
    } = useGetProgramCreditDetailsQuery((launchDetails?.onDemandItemPk ? launchDetails.onDemandItemPk : launchDetails?.itemPk) ?? skipToken);

    const {
        data: licenseMessages,
        isLoading: isLoadingLicensesMessages,
    } = useGetLicenseMessagesQuery();

    const userHasNoLicenses = !licenses?.length;

    const licenseHeader = licenseMessages?.licenseReviewHeader ??
        'Before you start the program, here’s the credit information for the licenses in your profile';
    const noLicenseHeader = licenseMessages?.licenseReviewNoLicenseHeader ??
        'You don’t have any licenses on your profile';

    const licenseReviewHeader = userHasNoLicenses ? noLicenseHeader : licenseHeader;

    const affirmation = licenseMessages?.licenseReviewAffirmation ??
        'By continuing, you understand the credit status and attendance verification requirements.';

    const toReceiveACertificateFallback = '<p>To receive a certificate</p>' +
    '<ul>' +
    '<li>Regulators require attendance to be verified. You must track time by clicking the “Verify Attendance” button each time it appears.</li>' +
    '<li>Click the “Request Credit” link only once after completing the entire program or as much of the program as desired. You will receive your certificate of attendance via email.</li>' +
    '<li>PLI’s webcasts are single-user license products intended for an individual registrant only. Credit will be issued to the individual registered.</li>' +
    '<li>Detailed information can be found on <a href="test.pli.edu">PLI’s Credit FAQs.</a></li>' +
    '</ul>';

    const toReceiveACertificateHtml = launchDetails?.isPortable ? 
        licenseMessages?.licenseReviewCertificatePortable ?? toReceiveACertificateFallback : 
        licenseMessages?.licenseReviewCertificate ?? toReceiveACertificateFallback;

    const noLicensesBody = licenseMessages?.licenseReviewNoLicenseBody ??
        'In order to avoid certificate delays and automatically receive a certificate for participating in this and future programs, add a license to your PLI account. You can do so at any time through your Profile, or by clicking below.';
    
    const headerRef = useRef<HTMLHeadingElement | null>();
    const [isHeaderRefReady, setIsHeaderRefReady] = useState(false);
    
    const {
        ref: scrollRefs,
        showScrollForMoreDetails,
        scrollToTargetElement: scrollToAffirmation,
    } = useScrollForMoreDetails<HTMLDivElement, HTMLDivElement | HTMLParagraphElement, HTMLDivElement>();

    useMergeRefs(scrollRefs.container)
        .invoke(modalRef?.current);

    const isLoading = isLoadingLicenses || isLoadingCreditDetails || isLoadingLicensesMessages;

    useEffect(() => {
        if(!focusTarget || !isHeaderRefReady) return;

        if(headerRef.current && focusTarget.mode === 'header')
            headerRef.current.focus();
    }, [focusTarget, isHeaderRefReady]);

    return (
        <>
            {isLoading &&
                <div className='h-75'><Loader /></div>
            }

            {!isLoading &&
                <>
                    <div className='watch-modal__content'>
                        {(isGetLicensesError || isGetCreditDetailsError) &&
                            <>
                                <MainError errorContainer={ErrorContainer.PlayerModal} />
                                <div ref={scrollRefs.target} />
                            </>
                        }
                        {isGetLicensesSuccess && isGetCreditDetailsSuccess && !isLoadingLicensesMessages && (
                            <div className='license-review'>
                                <h1 
                                    id={LICENSE_MODAL_HEADING_ID} 
                                    className='heading-5' 
                                    tabIndex={-1}
                                    ref={(el) => { setIsHeaderRefReady(true); headerRef.current=el; }}
                                >
                                    {licenseReviewHeader}   
                                </h1>

                                <LicenseGrid
                                    userLicenses={licenses}
                                    programCreditDetails={creditDetails?.jurisdictions ?? []}
                                    onEditLicensePress={onEditLicensePress}
                                    onAddLicensePress={onAddLicensePress}
                                    noLicensesCopy={noLicensesBody}
                                    focusTarget={focusTarget}
                                />

                                <div className='license-review__certificate'
                                    dangerouslySetInnerHTML={{ __html: toReceiveACertificateHtml }} />

                                <p className='license-review__affirmation' ref={scrollRefs.target}>
                                    {affirmation}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className='watch-modal__footer' ref={scrollRefs.footer}>
                        <div className='watch-modal__footer__button-container'>
                            <Button buttonClass='sticky-button'
                                label={showScrollForMoreDetails ? 'Scroll for more details' : 'Start watching' }
                                aria-label={showScrollForMoreDetails ? 'Scroll for more details' : 'Start watching' }
                                action={showScrollForMoreDetails ? scrollToAffirmation : onFinish }
                                icon={showScrollForMoreDetails ? 'chevron-down' : undefined }
                                iconPosition='left' />
                        </div>
                    </div>
                </>
            }
        </>
    );

};