import '../../styles/_request-credit-cpe-block.scss';

import React, { useEffect } from 'react';

import { useAppDispatch } from '../../hooks';
import useCpeBlock from '../../hooks/useCpeBlock';
import useCpeExams from '../../hooks/useCpeExams';
import Button from '../Button/Button';
import { ReactComponent as CreditStatusSuccess } from '../LicenseGrid/LicenseCard/assets/Success.svg';
import { ReactComponent as CreditStatusWarning } from '../LicenseGrid/LicenseCard/assets/Warning.svg';
import { addErrorMessageElementId, removeErrorMessageElementId } from '../Store/requestCreditSlice';

const examNotPassedId = 'exam-not-passed';

export const RequestCreditCpeBlock = () => {
    const { isCpeCreditEnabled, licenseRequiringExam, cpeBlockDescription } = useCpeBlock();
    const cpeExams = useCpeExams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!cpeExams.areExamsPassed && isCpeCreditEnabled) {
            dispatch(addErrorMessageElementId(examNotPassedId));
        } else if ((cpeExams.areExamsPassed && isCpeCreditEnabled) || !isCpeCreditEnabled) {
            dispatch(removeErrorMessageElementId(examNotPassedId));
        }
    }, [dispatch, cpeExams.areExamsPassed, isCpeCreditEnabled]);

    return (
        <div className={`request-credit-cpe-block${!isCpeCreditEnabled ? ' request-credit-cpe-block__disabled' : ''}`}>
            <h3 className='paragraph-1 text--medium-grey m-0'>CPE Exam</h3>
            <div className='request-credit-cpe-block__container'>
                <p className='paragraph-2 m-0'>{cpeBlockDescription}</p>
                <div
                    className={`sentence-label ${cpeExams.areExamsPassed ? 'request-credit-cpe-block__container__passed' : 'request-credit-cpe-block__container__not-passed'}`}>
                    {
                        cpeExams.areExamsPassed ?
                            <CreditStatusSuccess width={16} height={16} aria-hidden={true} /> :
                            <CreditStatusWarning width={16} height={16} aria-hidden={true} />
                    }
                    <span>{cpeExams.areExamsPassed ? 'Passed exam' : 'Exam not passed'}</span>
                </div>
            </div>
            {
                !cpeExams.areExamsPassed && isCpeCreditEnabled &&
                <>
                    <Button
                        buttonClass='request-credit-cpe-block__button'
                        action={cpeExams.openExam}
                        label='Take CPE Exam'
                        aria-label='Open CPE Exam in new window'
                    />
                    <div id={examNotPassedId} className='request-credit-cpe-block__error sentence-label'>
                        <i aria-hidden={true} className='watch watch-alert' />
                        {`Error: You must pass the CPE exam to submit for ${licenseRequiringExam} credit`}
                    </div>
                </>
            }
        </div>
    );
};

export default RequestCreditCpeBlock;

