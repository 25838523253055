import { apiSlice } from '..';
import { ItemData } from '../generated';

export interface IItemDataUrlProps {
    itemPks: string[],
    populatePrice: boolean,
    limitPriceCalls: boolean,
    retrievePracticeAreas: boolean,
    retrieveIndustries: boolean,
    retrieveMaster: boolean,
    retrieveSeries: boolean
}

export interface IFacultiesItemDataUrlProps {
    individualSks: string[]
}

const itemDataSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProgramsItemDataByItemPks: builder.query<ItemData[], IItemDataUrlProps>({
            query: (props: IItemDataUrlProps) => {
                const itemPksParams = new URLSearchParams();
                if (props && props.itemPks && props.itemPks.length > 0) {
                    props.itemPks.forEach(value => itemPksParams.append('itemPks', value));
                }
                const queryPrefix = itemPksParams.toString() != '' ? '&' : '';
                const url = `/item-data/programs?${itemPksParams.toString()}${queryPrefix}populatePrice=${props.populatePrice}&limitPriceCalls=${props.limitPriceCalls}&retrievePracticeAreas=${props.retrievePracticeAreas}&retrieveIndustries=${props.retrieveIndustries}&retrieveMaster=${props.retrieveMaster}&retrieveSeries=${props.retrieveSeries}`;

                return { url };
            },
            keepUnusedDataFor: 86400, // 1 day (seconds)
        }),
        getPublicationsItemDataByItemPks: builder.query<ItemData[], IItemDataUrlProps>({
            query: (props: IItemDataUrlProps) => {
                const itemPksParams = new URLSearchParams();
                if (props && props.itemPks && props.itemPks.length > 0) {
                    props.itemPks.forEach(value => itemPksParams.append('itemPks', value));
                }
                const queryPrefix = itemPksParams.toString() != '' ? '&' : '';
                const url = `/item-data/publications?${itemPksParams.toString()}${queryPrefix}populatePrice=${props.populatePrice}&limitPriceCalls=${props.limitPriceCalls}&retrievePracticeAreas=${props.retrievePracticeAreas}&retrieveIndustries=${props.retrieveIndustries}&retrieveMaster=${props.retrieveMaster}&retrieveSeries=${props.retrieveSeries}`;
                             
                return { url };
            },
            keepUnusedDataFor: 86400, // 1 day (seconds)
        }),
        getFacultiesItemDataByIndividualSks: builder.query<ItemData[], IFacultiesItemDataUrlProps>({
            query: (props: IFacultiesItemDataUrlProps) => {
                const individualSksParams = new URLSearchParams();
                if (props && props.individualSks && props.individualSks.length > 0) {
                    props.individualSks.forEach(value => individualSksParams.append('individualSks', value));
                }
                const queryPrefix = individualSksParams.toString() != '' ? '?' : '';
                const url = `/item-data/faculties${queryPrefix}${individualSksParams.toString()}`;
                             
                return { url };
            },
            keepUnusedDataFor: 86400, // 1 day (seconds)
        }),
    })
});

export const { useLazyGetProgramsItemDataByItemPksQuery, useLazyGetPublicationsItemDataByItemPksQuery, useLazyGetFacultiesItemDataByIndividualSksQuery } = itemDataSlice;
export default itemDataSlice;
