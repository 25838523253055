import { useCallback } from 'react';

import { LaunchSegment, Thumbnail } from '../datasource/generated';
import { useAppSelector } from '../hooks';
import { LIVE_STATIC_IMAGE_SRC } from '../utility/imageUtils';

export const useSegmentImages = () => {   
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const cachedImages = useAppSelector((state) => state.player.cachedImages);

    const getSegmentImageUrl = useCallback((itemSk?: number) => {
        const segment = launchDetails?.segments?.find(
            (seg: LaunchSegment) => seg.itemSk === itemSk ?? launchDetails?.currentSegment
        );

        const video = launchDetails?.video
            ? launchDetails?.video
            : segment?.video;

        const thumbnail = video?.thumbnails?.find((item: Thumbnail) => item.resolution === '640x360');

        return thumbnail?.url ?? LIVE_STATIC_IMAGE_SRC;
    }, [launchDetails?.currentSegment, launchDetails?.segments, launchDetails?.video]);

    const getSegmentCachedImageUrl = useCallback((itemSk?: number) => {
        const imageUrl = getSegmentImageUrl(itemSk);
        const cachedImageUrl = cachedImages[imageUrl];
        return cachedImageUrl;
    }, [cachedImages, getSegmentImageUrl]);

    return {
        getSegmentImageUrl,
        getSegmentCachedImageUrl
    };
};
