import '../../styles/_text-input.scss';

import React, { useEffect, useState } from 'react';

export interface TextInputProps {
    name?: string;
    errorText?: string;
    initialValue?: string;
    inputLabel?: string;
    inputValueForParent?: (inputValue: any) => void;
    maxLength?: number;
    required?: boolean;
    showInputError: boolean;
    placeholder?: string;
    disabled?: boolean;
    variant?: 'default' | 'survey';
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            name,
            errorText,
            initialValue,
            inputLabel,
            inputValueForParent,
            required,
            showInputError,
            maxLength,
            placeholder,
            disabled,
            variant = 'default',
        },
        inputRef
    ) => {
        const [inputValue, setInputValue] = useState(initialValue ?? '');
        const [inputFocused, setInputFocused] = useState(false);

        useEffect(() => {
            setInputValue(currentValue => initialValue ?? currentValue);
            inputValueForParent?.(initialValue);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [initialValue]);

        const handleBlur = (e: React.FocusEvent) => {
            setInputFocused(false);
        };

        const uniqueId = `textInput-${Math.random().toString(16).slice(2)}`;

        const className = variant === 'survey' ? 'text-input--survey' : 
            inputFocused ? 'text-input--focused'
                : inputValue.length > 0
                    ? 'text-input--populated'
                    : '';
        const showError = showInputError ? 'text-input--has-error' : '';

        return (
            <>
                <><div
                    className={`text-input ${className} ${showError}`}
                >
                    {inputLabel &&
                    <label
                        id={`label-${uniqueId}`}
                        className='text-input__label sentence-label'
                        htmlFor={uniqueId}
                    >
                        {inputLabel}
                        {required && (
                            <span
                                aria-hidden='true'
                                title='required'
                                className='ml-1'
                            >
                                    *
                            </span>
                        )}
                    </label>}
                    <input
                        disabled={disabled}
                        className={`text-input__input ${variant === 'survey' ? 'text-input__input--survey' : 'paragraph-1'}`}
                        id={uniqueId}
                        name={name ?? uniqueId}
                        onBlur={handleBlur}
                        onFocus={() => setInputFocused(true)}
                        onChange={(e) => {
                            const { value } = e.currentTarget;
                            setInputValue(value);
                            inputValueForParent?.(value.trim());
                        }}
                        value={inputValue}
                        placeholder={inputFocused ? '' : placeholder ?? `${inputLabel} ${required ? '*' : ''}`}
                        ref={inputRef}
                        maxLength={maxLength ? maxLength : undefined}
                        required={required}
                        aria-describedby={showInputError ? `error-${uniqueId}` : `label-${uniqueId}`}
                        aria-invalid={showInputError} />
                </div><div
                    className={`sentence-label text-input__error ${showInputError ? 'text-input__error--visible' : ''}`}
                    aria-live='polite'
                >
                    <i className='watch watch-alert mr-1'></i>
                    <span id={`error-${uniqueId}`}>{errorText}</span>
                </div></>
            </>
        );
    }
);

TextInput.displayName = 'TextInput';
export default TextInput;
