import '../../styles/_transcripts.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useAppDispatch } from '../../hooks';
import { useFetchTranscripts } from '../../hooks/useFetchTranscripts';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { IFormatSettings } from '../../hooks/usePlayerSettings';
import useTranscripts from '../../hooks/useTranscripts';
import MenuButton from '../MenuButton/MenuButton';
import { setExternalSeekCommand } from '../Store/playerSlice';
import { ReactComponent as PoweredByPlusBlackText } from './assets/powered-by-plus-black-text.svg';
import { ReactComponent as PoweredByPlusWhiteText } from './assets/powered-by-plus-white-text.svg';
import { ReactComponent as VolumeIcon } from './assets/volume-max.svg';

export interface ITranscriptsProps {
    transcriptSettings: IFormatSettings;
}

export const Transcripts = (props: ITranscriptsProps) => {
    const { transcriptSettings } = props;
    const [lineHeight, setLineHeight] = useState('');

    const { transcripts } = useFetchTranscripts();
    const [focusedItem, setFocusedItem] = useState(0);
    const currentTranscriptRef = useRef<HTMLDivElement>(null);
    const outerContainerRef = useRef<HTMLDivElement>(null);
    const isFirstRender = useRef(true);
    const inactiveTimeoutRef = useRef<number>();
    const dispatch = useAppDispatch();
    const {
        currentTranscriptIndex,
        restoreAutoScroll,
        isAutoScrollOn,
        isCurrentTranscriptAbove,
    } = useTranscripts({ currentTranscriptRef, outerContainerRef });
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);

    useEffect(() => {
        outerContainerRef.current?.focus();
        setTimeout(() => isFirstRender.current = false, 2000);

        return () => clearTimeout(inactiveTimeoutRef.current);
    }, []);

    const handleMouseOver = (
        e:
            | React.FocusEvent<HTMLHeadingElement, Element>
            | React.MouseEvent<HTMLHeadingElement, MouseEvent>,
        index: number,
        currentTranscriptIndex: number
    ) => {
        if (index === currentTranscriptIndex) {
            return;
        }

        if (!e.currentTarget.classList.contains('muted')) {
            return;
        }

        e.currentTarget.classList.remove('muted');
    };

    const handleMouseLeave = (
        e:
            | React.FocusEvent<HTMLHeadingElement, Element>
            | React.MouseEvent<HTMLHeadingElement, MouseEvent>,
        index: number,
        currentTranscriptIndex: number
    ) => {
        if (index === currentTranscriptIndex) {
            return;
        }

        if (e.currentTarget.classList.contains('muted')) {
            return;
        }

        e.currentTarget.classList.add('muted');
    };

    const handleOptionKeydown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'ArrowUp' && focusedItem > 0) {
            e.preventDefault();
            setFocusedItem(focusedItem - 1);
        } else if (
            e.key === 'ArrowDown' &&
            focusedItem < transcripts.length - 1
        ) {
            e.preventDefault();
            setFocusedItem(focusedItem + 1);
        }
    };

    useEffect(() => {
        const transcriptText = document.querySelector('.transcript__text');
        if (transcriptText) {
            const currentFontSize =
                window.getComputedStyle(transcriptText).fontSize;
            let lineHeightDifference;

            if (transcriptSettings.fontSize === 'XSmall') {
                lineHeightDifference = 6;
            } else if (
                transcriptSettings.fontSize === 'Large' &&
                isLargeScreen
            ) {
                lineHeightDifference = 18;
            } else {
                lineHeightDifference = 12;
            }

            let lineSpace;
            switch (transcriptSettings.lineSpacing) {
                case '50%':
                    lineSpace =
                        parseFloat(currentFontSize) + lineHeightDifference - 8;
                    break;
                case '75%':
                    lineSpace =
                        parseFloat(currentFontSize) + lineHeightDifference - 4;
                    break;
                case '100%':
                    lineSpace =
                        parseFloat(currentFontSize) + lineHeightDifference;
                    break;
                case '125%':
                    lineSpace =
                        parseFloat(currentFontSize) + lineHeightDifference + 4;
                    break;
                case '150%':
                    lineSpace =
                        parseFloat(currentFontSize) + lineHeightDifference + 8;
                    break;
            }
            setLineHeight(`${lineSpace}px`);
            if (!isFirstRender.current) {
                setTimeout(() => {
                    restoreAutoScroll();
                }, 100);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcriptSettings, isLargeScreen]);

    const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
        const index = (event.currentTarget as HTMLDivElement)
            .getAttribute('data-index') ?? '0';
        dispatch(setExternalSeekCommand(transcripts[+index].position / 1000));
        restoreAutoScroll();
    }, [dispatch, restoreAutoScroll, transcripts]);

    const containerClassName = `transcripts ${transcriptSettings.darkBackground === 'Light'
        ? 'transcripts--light'
        : 'transcripts--dark'
    }`;

    const fontSizeClassName = `transcript__text ${transcriptSettings.fontSize === 'Large'
        ? 'heading-3--regular'
        : transcriptSettings.fontSize === 'Medium'
            ? 'heading-5--regular'
            : transcriptSettings.fontSize === 'Small'
                ? 'heading-6--regular'
                : transcriptSettings.fontSize === 'XSmall'
                    ? 'paragraph-1'
                    : ''
    }`;

    const handleInactiveStateToggling = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        clearTimeout(inactiveTimeoutRef.current);

        const playerElement = document.getElementById('player');
        playerElement?.classList.remove('jw-flag-user-inactive');

        inactiveTimeoutRef.current = setTimeout(() => {
            if (playerElement?.classList.contains('jw-flag-user-inactive')) {
                return;
            }

            playerElement?.classList.add('jw-flag-user-inactive');
        }, 2000) as unknown as number;
    };

    const [isRealTimeButtonFocus, setIsRealTimeButtonFocus] = useState(false);
    useEffect(() => {
        // Prevents keyboard controls on transcripts firing JWPlayer play/pause keyboard shortcuts
        const jwPlayerControls = document.querySelectorAll('.jwplayer');
        const preventJwKeyEvent = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter') {
                e.stopPropagation();
            }
        };
        if (isRealTimeButtonFocus) {
            jwPlayerControls.forEach(elem => {
                elem.addEventListener('keydown', preventJwKeyEvent, true);
            });
        }

        return () => {
            jwPlayerControls.forEach(elem => {
                elem.removeEventListener('keydown', preventJwKeyEvent, true);
            });
        };
    }, [isRealTimeButtonFocus]);

    const handleRestoreAutoScroll = (e: React.KeyboardEvent) => {
        if ((e.code === 'Space' || e.code === 'Enter')) {
            restoreAutoScroll();
        }
    };

    return (
        <>
            <div
                ref={outerContainerRef}
                className={containerClassName}
                onKeyDown={handleOptionKeydown}
                onClick={handleInactiveStateToggling}
                role='presentation'
            >
                <div className='transcripts__powered-by-plus'>
                    {transcriptSettings.darkBackground === 'Light' ? (
                        <PoweredByPlusBlackText />
                    ) : (
                        <PoweredByPlusWhiteText />
                    )}
                </div>
                <div className='transcripts__container'>
                    {transcripts &&
                        transcripts.map((item, index) => (
                            <div
                                className='transcripts__container__item'
                                key={item.position}
                                role='button'
                                onClick={handleClick}
                                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                    const code = event.code;
                                    if (code === 'Enter') {
                                        event.stopPropagation();
                                        handleClick(event);
                                    }
                                }}
                                data-index={index}
                                tabIndex={index}
                                aria-label={`${isFirstRender.current && index === currentTranscriptIndex ? 'Scroll to view transcript. Tap on portion of transcript to jump to section of video.' : ''} ${item.text}`}
                                ref={index === currentTranscriptIndex ? currentTranscriptRef : null}
                            >
                                {index === currentTranscriptIndex && (
                                    <VolumeIcon />
                                )}
                                <h5
                                    key={item.position}
                                    style={{ lineHeight: `${lineHeight}` }}
                                    className={`${fontSizeClassName} ${index === currentTranscriptIndex
                                        ? ''
                                        : 'muted'
                                    }`}
                                    onMouseOver={(e) =>
                                        handleMouseOver(
                                            e,
                                            index,
                                            currentTranscriptIndex
                                        )
                                    }
                                    onFocus={(e) =>
                                        handleMouseOver(
                                            e,
                                            index,
                                            currentTranscriptIndex
                                        )
                                    }
                                    onMouseLeave={(e) =>
                                        handleMouseLeave(
                                            e,
                                            index,
                                            currentTranscriptIndex
                                        )
                                    }
                                    onBlur={(e) =>
                                        handleMouseLeave(
                                            e,
                                            index,
                                            currentTranscriptIndex
                                        )
                                    }
                                >
                                    {item.text}
                                </h5>
                            </div>
                        ))}
                </div>
            </div>
            {!isAutoScrollOn.current && (
                <MenuButton
                    showLabel={true}
                    hasTooltip={false}
                    label='Return to real-time'
                    buttonClass='realtime-button'
                    iconClass={`watch watch-${isCurrentTranscriptAbove
                        ? 'chevron-up'
                        : 'chevron-down'
                    }`}
                    onClick={restoreAutoScroll}
                    onKeyUp={handleRestoreAutoScroll}
                    onFocus={() => setIsRealTimeButtonFocus(true)}
                    onBlur={() => setIsRealTimeButtonFocus(false)}
                />
            )}
        </>
    );
};

export default Transcripts;
