export const LIVE_STATIC_IMAGE_SRC = '/static/img/gradient-live.png';
export const OD_STATIC_IMAGE_SRC = '/static/img/gradient-od.png';
export const MP3_STATIC_IMAGE_SRC = '/static/img/gradient-mp3.png';

export interface CachedImage {
    requestedUrl: string,
    resultingUrl: string,
}

const cacheImage = (url: string): Promise<string> => {
    try {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.src = url;
            image.onload = () => {
                resolve(url);
            };
            image.onerror = () => {
                reject();
            };
        });
    } catch (error) {
        return new Promise(() => undefined);
    }
};

export const cacheImagesAsync = async (imageList: string[], fallbackImageUrl: string): Promise<CachedImage[]> => {
    try {
        const imagePromises : Promise<string>[] = [];
        for (const imgUrl of imageList) {
            imagePromises.push(cacheImage(imgUrl));
        }

        const promiseResults = await Promise.allSettled(imagePromises);
        const images: CachedImage[] = promiseResults.map((promise, index) => {
            if (promise.status === 'fulfilled') {
                return { requestedUrl: imageList[index], resultingUrl: promise.value };
            }

            return { requestedUrl: imageList[index], resultingUrl: fallbackImageUrl };
        });
        return images;
    } catch (error) {
        return imageList.map(imgUrl => ({ requestedUrl: imgUrl, resultingUrl: fallbackImageUrl }));
    }
};