import { MenuOptions } from '../../enums/MenuOptions';

export interface MenuItem {
    id: MenuOptions;
    label: string;
    iconClassName: string;
}

export const topMenuItem: MenuItem = {
    id: MenuOptions.Credit,
    label: 'Credit',
    iconClassName: 'watch watch-credits',
};

export const menuItemsOnDemand: MenuItem[] = [
    { id: MenuOptions.Segments, label: 'Segments', iconClassName: 'watch watch-segments', },
    { id: MenuOptions.Materials, label: 'Materials', iconClassName: 'watch watch-materials', },
    { id: MenuOptions.Faculty, label: 'Faculty', iconClassName: 'watch watch-faculty', },
    { id: MenuOptions.Notes, label: 'Notes', iconClassName: 'watch watch-notes', },
    { id: MenuOptions.QandA, label: 'Q&A', iconClassName: 'watch watch-qanda', },
    { id: MenuOptions.Support, label: 'Support', iconClassName: 'watch watch-support', },
];

export const menuItemsOnDemandGroupcast: MenuItem[] = menuItemsOnDemand.filter(i => i.id !== MenuOptions.Notes);

export const menuItemsLive: MenuItem[] = [
    { id: MenuOptions.QandA, label: 'Polls / Q&A', iconClassName: 'watch watch-qanda', },
    { id: MenuOptions.Faculty, label: 'Faculty', iconClassName: 'watch watch-faculty', },
    { id: MenuOptions.Segments, label: 'Schedule', iconClassName: 'watch watch-upcoming', },
    { id: MenuOptions.Materials, label: 'Materials', iconClassName: 'watch watch-materials', },
    { id: MenuOptions.Support, label: 'Support', iconClassName: 'watch watch-support', },
];

// For panels that should still be attached to the menu state, but do not have a visible menu option
export const phantomMenuItems: MenuItem[] = [
    { id: MenuOptions.EndOfVideo, label: '', iconClassName: '' },
    { id: MenuOptions.RequestCredit, label: '', iconClassName: '' },
];