/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InteractiveType {
    N_A = 'N_A',
    YES = 'Yes',
    NO = 'No',
}
