import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import logger from '../utility/logger';

const baseQuery = fetchBaseQuery({
    prepareHeaders: (headers) => {
        if (!headers.has('X-Requested-With')) {
            headers.set('X-Requested-With', 'XMLHttpRequest');
        }
        return headers;
    } });

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) {
        logger.error(`Error found in ${args} : ${api.endpoint}`, {
            error: result.error,
        }, false);
        if (result.error.status === 401) {
            window.location.reload();
        }
    } 
    return result;
};

// Define our single API slice object
export const apiSlice = createApi({
    // The cache reducer expects to be added at `state.api` (already default - this is optional)
    reducerPath: 'api',
    tagTypes: ['Licenses', 'Bookmarks', 'CleTimeRecords', 'CleSession'],
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({ })
});
