import '../../styles/_license-grid.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { LicenseToastProvider } from '../../contexts/LicenseToastContext';
import {
    CreditRegion,
    IndividualCreditAndLicenseInfo,
} from '../../datasource/generated';
import { ILicenseModalFocusTarget } from '../LicenseModal/LicenseModal';
import { LicenseButton } from './LicenseButton/LicenseButton';
import { LicenseCard } from './LicenseCard/LicenseCard';
import { LicenseToastContainer } from './LicenseToastContainer/LicenseToastContainer';

export interface ILicenseGridProps {
  userLicenses: IndividualCreditAndLicenseInfo[];
  programCreditDetails?: Array<CreditRegion>;
  onEditLicensePress: (
    license: IndividualCreditAndLicenseInfo,
    jurisdictionName?: string,
  ) => void;
  onAddLicensePress: () => void;
  noLicensesCopy: string;
  focusTarget?: ILicenseModalFocusTarget;
}

export const LicenseGrid = ({
    userLicenses,
    programCreditDetails,
    onEditLicensePress,
    onAddLicensePress,
    noLicensesCopy,
    focusTarget,
}: ILicenseGridProps) => {
    const userHasNoLicenses = !userLicenses.length;
    const maxLicensesToShowInitially = 5;
    // Initialize showAllLicenseCards = true when user has 5 or fewer licenses, false when user has > 5 licenses
    const [showAllLicenseCards, setShowAllLicenseCards] = useState(
        userLicenses.length <= maxLicensesToShowInitially,
    );
    // Only show See All/See Less button when user has more than 5 licenses
    const showSeeAllOrLessButton =
    userLicenses.length > maxLicensesToShowInitially;
    const seeAllOrLessButtonMode = !showAllLicenseCards ? 'expand' : 'collapse';
    const toggleShowAllLicenseCards = () => {
        setShowAllLicenseCards(!showAllLicenseCards);
    };
    const addButtonRef = useRef<HTMLButtonElement | null>();
    const [isAddButtonReady, setIsAddButtonReady] = useState(false);

    useEffect(() => {
        if (!focusTarget || !isAddButtonReady) return;

        if (addButtonRef.current && focusTarget.mode === 'add-button')
            addButtonRef.current.focus();
    }, [focusTarget, isAddButtonReady]);

    const licenseCards = useMemo(() => {
        const matchLicenseToCreditDetails = (
            license: IndividualCreditAndLicenseInfo,
        ) => {
            return programCreditDetails?.find(
                (jurisdiction) => jurisdiction.regionSk === license.creditRegion_SK,
            );
        };

        const cards = userLicenses.map((license) => {
            return (
                <li key={license.creditRegion_SK} tabIndex={-1}>
                    <LicenseCard
                        license={license}
                        creditDetails={matchLicenseToCreditDetails(license)}
                        onEditLicensePress={onEditLicensePress}
                        focusTarget={focusTarget}
                    />
                </li>
            );
        });
        return cards;
    }, [focusTarget, onEditLicensePress, programCreditDetails, userLicenses]);

    const licenseCardListRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
    // When 'See all' is pressed and reveals the complete list of license cards,
    // focus on the newly revealed (6th) license card for keyboard navigation/screen reader users
        if (showAllLicenseCards && showSeeAllOrLessButton) {
            const list = licenseCardListRef.current;
            const newlyRevealedLicenseCard = list?.children.item(
                maxLicensesToShowInitially,
            ) as HTMLElement;
            newlyRevealedLicenseCard.focus();
            newlyRevealedLicenseCard.scrollIntoView({ behavior: 'smooth' });
        }
    }, [licenseCards, showAllLicenseCards, showSeeAllOrLessButton]);

    return (
        <LicenseToastProvider>
            <div className="license-grid">
                <LicenseToastContainer />
                {!userHasNoLicenses ? (
                    <ul className="license-grid__list" ref={licenseCardListRef}>
                        {showAllLicenseCards
                            ? licenseCards
                            : licenseCards.slice(0, maxLicensesToShowInitially)}
                    </ul>
                ) : (
                    <p className="paragraph-1 text--medium-grey mb0">{noLicensesCopy}</p>
                )}

                <div className="license-grid__buttons">
                    {showSeeAllOrLessButton && (
                        <LicenseButton
                            mode={seeAllOrLessButtonMode}
                            action={toggleShowAllLicenseCards}
                        />
                    )}
                    <LicenseButton
                        mode={'add'}
                        action={onAddLicensePress}
                        ref={(el) => {
                            setIsAddButtonReady(true);
                            addButtonRef.current = el;
                        }}
                    />
                </div>
            </div>
        </LicenseToastProvider>
    );
};
