import '../../styles/_credit-progress-panel.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useGetCleTimeRecordsQuery } from '../../datasource/queries/cle';
import { useGetCreditProgressMessagesQuery, useGetCreditReviewAndExamMessagesQuery } from '../../datasource/queries/cmsMessages';
import { useGetLicensesQuery } from '../../datasource/queries/licenses';
import { MenuOptions } from '../../enums/MenuOptions';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { CreditTabSelection } from '../../enums/TabSelection';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FetchType, useAnalytics } from '../../hooks/useAnalytics';
import useCpeExams from '../../hooks/useCpeExams';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import { setSelectedTab } from '../Store/cleSlice';
import { setLargePanelOpenCommand } from '../Store/menuSlice';
import { Tabs } from '../Tabs/Tabs';
import { TimeCards } from '../TimeCards/TimeCards';
import { ReviewAndExamTab } from './components/ReviewAndExamTab';

export type ICreditProgressPanelProps = {
    onClose(): void;
};

export const CreditProgressPanel = ({ onClose }: ICreditProgressPanelProps) => {
    const dispatch = useAppDispatch();
    const selectedTab = useAppSelector((state) => state.cle.selectedTab);
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const tabsRef = useRef<HTMLButtonElement>(null);
    const { data } = useGetCreditProgressMessagesQuery();
    const [hasTimeCardsLoaded, setHasTimeCardsLoaded] = useState<boolean>(false);
    const [alreadySetDefaultTab, setAlreadySetDefaultTab] = useState<boolean>(false);
    const { data: reviewAndExamMessages } = useGetCreditReviewAndExamMessagesQuery();
    const useCpeExamsResponse = useCpeExams(true);
    const { exams, isSuccess: isGetExamsSuccess } = useCpeExamsResponse;
    const isPortable = useAppSelector((state) => state.player.launchDetails.isPortable);
    const hasCPE = useAppSelector((state) => state.player.launchDetails.hasCPE);
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);
    const { 
        data: cleTimeRecords, 
        isError, 
        isSuccess: isGetCleTimeRecordSuccess, 
        isLoading, 
        isFetching 
    } = useGetCleTimeRecordsQuery(encryptedRegistrationId ?? skipToken);

    const {
        data: licenses,
        isSuccess: isGetLicensesSuccess,
    } = useGetLicensesQuery(!encryptedRegistrationId ? skipToken : { encryptedRegistrationId });

    const { pushToDataLayer } = useAnalytics();

    useEffect(() => {
        if (!alreadySetDefaultTab && isGetLicensesSuccess && (!hasCPE || exams)) {
            const hasLicenseMatchingExam = exams?.some(exam => licenses.some(l => l.certType_SK === exam.certTypeSk));

            if (hasLicenseMatchingExam) {
                dispatch(setSelectedTab(CreditTabSelection.ReviewAndExam));
            } else if (isPortable) {
                dispatch(setLargePanelOpenCommand(MenuOptions.RequestCredit));
                pushToDataLayer({ event: 'request_credit_start', fetchType: FetchType.Program });
            }
            setAlreadySetDefaultTab(true);
        }
    }, [alreadySetDefaultTab, dispatch, exams, hasCPE, isGetLicensesSuccess, isPortable, licenses, pushToDataLayer]);

    const footerNote = data?.creditStatePanelRequestCreditMessage ?? 'Please only request credit when you have completed all segments you wish to submit.';

    const onRequestCreditClick = () => {
        dispatch(setLargePanelOpenCommand(MenuOptions.RequestCredit));
        pushToDataLayer({ event: 'request_credit_start', fetchType: FetchType.Program });
    };

    const shouldShowTabs = useMemo(
        () => (exams?.length ?? 0) > 0 
            && ((cleTimeRecords?.length ?? 0) > 0), 
        [cleTimeRecords?.length, exams?.length]);

    if (alreadySetDefaultTab 
            && selectedTab !== CreditTabSelection.ReviewAndExam
            && isGetCleTimeRecordSuccess 
            && cleTimeRecords.length === 0) 
    {
        dispatch(setLargePanelOpenCommand(MenuOptions.RequestCredit));
        pushToDataLayer({ event: 'request_credit_start', fetchType: FetchType.Program });
        return <></>;
    }
    
    return (
        <div className='credit-progress-panel'>
            {!isLargeScreen && <h3 className='heading-4 mb-4 pb-3'>Program Credit</h3>}
            {shouldShowTabs && (
                <Tabs.Root>
                    <Tabs.Option
                        onClick={() => dispatch(setSelectedTab(CreditTabSelection.Progress))}
                        isActive={selectedTab === CreditTabSelection.Progress}
                        ref={tabsRef}
                    >
                    Progress
                    </Tabs.Option>
                    {!isLargeScreen && <span className='spacer'></span>}
                    <Tabs.Option
                        onClick={() => dispatch(setSelectedTab(CreditTabSelection.ReviewAndExam))}
                        isActive={selectedTab === CreditTabSelection.ReviewAndExam}
                    >
                    Review & Exam
                    </Tabs.Option>
                    {!isLargeScreen && <span className='spacer--grow'></span>}
                </Tabs.Root>
            )}
            {(selectedTab === CreditTabSelection.Progress) && (
                <>
                    {(isError && !isLoading && !isFetching) &&
                        <MainError errorContainer={ErrorContainer.SmallPanel} marginRequired={true} />
                    }
                    {(isGetCleTimeRecordSuccess && !isLoading && !isFetching) && 
                        <>
                            <TimeCards onClose={onClose} onTimeCardsLoaded={setHasTimeCardsLoaded} shouldShowTitle={false} />
                            <div className='credit-progress-panel__button-container'>
                                <span className='sentence-label mb-4'>{footerNote}</span>
                                <Button
                                    buttonClass='sticky-button sticky-button__alt'
                                    label='Request Credit'
                                    action={onRequestCreditClick} />
                            </div>
                        </> 
                    }
                    {(isLoading || isFetching) && <Loader />}
                    
                </>
            )}
            {selectedTab === CreditTabSelection.ReviewAndExam && (
                <>
                    { isGetExamsSuccess
                        ? <ReviewAndExamTab footerNote={footerNote} reviewAndExamMessages={reviewAndExamMessages} useCpeExamsReponse={useCpeExamsResponse} />
                        : <MainError errorContainer={ErrorContainer.SmallPanel} marginRequired={true}/>
                    }
                </>

            )}
        </div>
    );
};

export default CreditProgressPanel;