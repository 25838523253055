import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';

import { useGetBookmarksByItemSkQuery } from '../datasource/queries/bookmarks';
import { setItemSk } from '../stories/Store/playerSlice';
import { useAppDispatch,useAppSelector } from '.';

export const useBookmarks = (itemSkProvided?: number) => {   
    const itemSk = useAppSelector((state) => state.player.itemSk);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (itemSkProvided) {
            dispatch(setItemSk(itemSkProvided));
        }
    }, [dispatch, itemSkProvided]);

    const response = useGetBookmarksByItemSkQuery(
        (itemSkProvided ?? itemSk) !== 0 ? 
            (itemSkProvided ?? itemSk) : skipToken);

    return {
        bookmarks: response.data,
        isLoadingBookmarks: response.isLoading,
        isGetBookmarksSuccess: response.isSuccess,
        isGetBookmarksError: response.isError,
        ...response
    };
};
