import { SupportOptions } from '../../enums/SupportOptions';

export interface SupportItem {
    id: SupportOptions;
    label: string;
}

export const supportItems: SupportItem[] = [
    { id: SupportOptions.ReportAProblem, label: 'Report a problem' },
    { id: SupportOptions.SystemDiagnostics, label: 'Run system diagnostics' },
    { id: SupportOptions.ContactUs, label: 'Contact us' },
    { id: SupportOptions.Debug, label: 'Debug info' },
    { id: SupportOptions.BetaFeedback, label: 'Beta feedback' },
];