import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ReportAProblemState {
  name: string;
  email: string;
  subject: string;
  question: string;
}

const initialState: ReportAProblemState = {
    name: '',
    email: '',
    subject: '',
    question: '',
};

const reportAProblemSlice = createSlice({
    name: 'reportAProblem',
    initialState,
    reducers: {
        setName(state, action: PayloadAction<string>) {
            state.name = action.payload;
        },
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setSubject(state, action: PayloadAction<string>) {
            state.subject = action.payload;
        },
        setQuestion(state, action: PayloadAction<string>) {
            state.question = action.payload;
        },
    },
});

export const { setName, setEmail, setSubject, setQuestion } =
  reportAProblemSlice.actions;

export const { reducer: reportAProblemReducer } = reportAProblemSlice;
