import { apiSlice } from '..';
import { Identity } from '../generated';

const identitySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIdentity: builder.query<Identity, void> ({
            query: () => '/identity/isauthorized'
        })
    })
});

export const { useLazyGetIdentityQuery } = identitySlice;
export default identitySlice;