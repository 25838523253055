import JWPlayer from '@jwplayer/jwplayer-react';
import { MutableRefObject, useEffect, useState } from 'react';

import { useGetClientConfigQuery } from '../datasource/queries/clientConfig';
import { MenuOptions } from '../enums/MenuOptions';
import { setLargePanelOpenCommand } from '../stories/Store/menuSlice';
import { useAppDispatch, useAppSelector } from '.';
import { useProgramDetails } from './useProgramDetails';

interface IUseLiveEndOfProgramTimerProps {
    playerRef: MutableRefObject<typeof JWPlayer>
}

export const useLiveEndOfProgramTimer = (props: IUseLiveEndOfProgramTimerProps) => {
    const { playerRef } = props;
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { programDetails } = useProgramDetails(launchDetails?.itemPk ?? '0');
    const [now, setNow] = useState(new Date());
    const [hasLiveEndOfVideoPanelBeenShown, setHasLiveEndOfVideoPanelBeenShown] = useState(false);
    const { data: clientConfig } = useGetClientConfigQuery();
    const dispatch = useAppDispatch();
     
    useEffect(() => {
        const intervalTime = 30 * 1000;

        if (launchDetails?.isWebcast) {
            const interval = setInterval(() => {
                setNow(new Date());
            }, intervalTime);

            const lastDailySegments = programDetails?.dailySegments?.map((a) => {
                if (a?.segments && a.segments.length > 0)
                    return a.segments[a.segments.length -1];
            });

            const lastSegment = lastDailySegments && lastDailySegments.length > 0 ? lastDailySegments[lastDailySegments.length - 1] : null;

            const programEndTime = lastSegment && lastSegment.endTimeUtc 
                ? new Date(lastSegment.endTimeUtc) 
                : (programDetails && programDetails?.eventEnd ? new Date(programDetails?.eventEnd) : null);

            if (programEndTime) {
                const gracePeriodMinutes = clientConfig?.gracePeriodMinutes ?? 0;
                const extendedEndTime = new Date(programEndTime.getTime() + gracePeriodMinutes * 60000);  
                const isStreamComplete = playerRef.current ? playerRef.current.getState() === 'complete' : false;
                
                if (now >= extendedEndTime || (isStreamComplete && now >= programEndTime)) {
                    if(!hasLiveEndOfVideoPanelBeenShown && !launchDetails.isOffAir) {
                        setHasLiveEndOfVideoPanelBeenShown(true);
                        dispatch(setLargePanelOpenCommand(MenuOptions.EndOfVideo));
                    }
                }
            }

            return () => clearInterval(interval);
        }
    }, [clientConfig?.gracePeriodMinutes, dispatch, hasLiveEndOfVideoPanelBeenShown, launchDetails.isOffAir, launchDetails?.isWebcast, now, playerRef, programDetails]);
};