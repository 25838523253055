import JWPlayer from '@jwplayer/jwplayer-react';

import { setShowDownloadTutorial } from '../stories/Store/downloadSlice';
import { ExternalFullscreenToggle, setExternalFullscreenToggle } from '../stories/Store/playerSlice';
import { useAppDispatch, useAppSelector } from '.';

export const useDownload = () => {
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const dispatch = useAppDispatch();

    const displayDownloadButton = (playerRef: (typeof JWPlayer)) => {
        if (launchDetails.isPortable && playerRef.current) {
            const buttonId = 'downloadButton';

            const downloadButton = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.9999 13.5858L7.99991 10.5858L6.58569 12L11.9999 17.4142L17.4141 12L15.9999 10.5858L12.9999 13.5858L12.9999 3H10.9999L10.9999 13.5858ZM4 21H20V19H4V21Z" fill="white"/></svg>';

            playerRef.current.removeButton(buttonId);

            playerRef.current.addButton(
                downloadButton,
                `Download ${launchDetails.isAudioOnly ? 'MP3' : 'MP4'}`,
                () => {
                    dispatch(setShowDownloadTutorial(true));
                    dispatch(setExternalFullscreenToggle(ExternalFullscreenToggle.off));
                },
                buttonId,
                'jw-icon-download'
            );
        }
    };

    const handleDownloadTutorialFinish = () => {
        dispatch(setShowDownloadTutorial(false));
        if (launchDetails?.video?.url) {
            window.open(launchDetails.video.url, '_blank');
        }
    };

    return {
        displayDownloadButton,
        handleDownloadTutorialFinish,
    };
};

export default useDownload;
