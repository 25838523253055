import '../../../styles/_toast.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { useLicenseToast } from '../../../contexts/LicenseToastContext';
import { Toast } from '../../Toast/Toast';

export const LicenseToastContainer = () => {
    const { toasts, removeToast } = useLicenseToast();
    const handleCloseButton = useCallback(
        (toastId: number) => {
            removeToast(toastId);
        },
        [removeToast]
    );
    const [isActionElementFocus, setIsActionElementFocus] = useState(false);

    const handleCloseKeyUp = (e: React.KeyboardEvent, toastId: number) => {
        if (e.code === 'Space' || e.code === 'Enter') {
            handleCloseButton(toastId);
        }
    };

    useEffect(() => {
    // Prevents keyboard controls on  firing JWPlayer play/pause keyboard shortcuts
        const jwPlayerControls = document.querySelectorAll('.jwplayer');
        const preventJwKeyEvent = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter') {
                e.stopPropagation();
            }
        };

        if (isActionElementFocus) {
            jwPlayerControls.forEach((elem) => {
                elem.addEventListener('keydown', preventJwKeyEvent, true);
            });
        }

        return () => {
            jwPlayerControls.forEach((elem) => {
                elem.removeEventListener('keydown', preventJwKeyEvent, true);
            });
        };
    }, [isActionElementFocus]);

    useEffect(() => {
        const container = document.getElementById('watch-license-toast-container');
        const links = container?.querySelectorAll('a');
        const onLinkFocus = () => setIsActionElementFocus(true);
        const onLinkBlur = () => setIsActionElementFocus(false);
        const onSpaceKeyUp = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space') {
                e.preventDefault();
                (e.target as HTMLAnchorElement).click();
            }
        };
        links?.forEach(link => {
            link.addEventListener('focus', onLinkFocus, true);
            link.addEventListener('blur', onLinkBlur, true);
            link.addEventListener('keyup', onSpaceKeyUp, true);
        });

        return () => {
            links?.forEach(link => {
                link.removeEventListener('focus', onLinkFocus, true);
                link.removeEventListener('blur', onLinkBlur, true);
                link.removeEventListener('keyup', onSpaceKeyUp, true);
                setIsActionElementFocus(false);
            });
        };
    }, [toasts]);

    return (
        <div role="region" aria-live="polite" id="watch-license-toast-container" className="license-grid-toast-container">
            {toasts.map((toast) => {
                return (
                    <div key={toast.id}>
                        <Toast.Root key={toast.id} align="stack" type={toast.type}>
                            <>
                                <Toast.Header>
                                    <Toast.Icon className="alert" />
                                    <Toast.Button
                                        onClick={() => handleCloseButton(toast.id)}
                                        onKeyUp={(e) => handleCloseKeyUp(e, toast.id)}
                                        onFocus={() => setIsActionElementFocus(true)}
                                        onBlur={() => setIsActionElementFocus(false)}
                                    />
                                </Toast.Header>
                                <Toast.Content>
                                    <p>
                                        {toast.message}{' '}
                                        {toast.cta && <a href={toast.cta.link}>{toast.cta.text}</a>}
                                    </p>
                                </Toast.Content>
                            </>
                        </Toast.Root>
                    </div>
                );
            })}
        </div>
    );
};
