import '../../styles/_faculty-panel.scss';

import React, { useEffect, useMemo } from 'react';

import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { SegmentsTabSelection } from '../../enums/TabSelection';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useFaculty } from '../../hooks/useFaculty';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useProgramDetails } from '../../hooks/useProgramDetails';
import { useTimeInterval } from '../../hooks/useTimeInterval';
import Faculty from '../Faculty/Faculty';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import { setSelectedTab } from '../Store/segmentSlice';
import { Tabs } from '../Tabs/Tabs';

export type IFacultyPanelProps = {
    title?: string;
    subtitle?: string;
    onSeeDetails(): void;
};

const ONE_SECOND_MS = 1000;

export const FacultyPanel = (props: IFacultyPanelProps) => {
    const { title, subtitle, onSeeDetails: handleSeeDetails } = props;
    const { faculty, isLoading, isError, isSuccess } = useFaculty();
    const { data: clientConfig } = useGetClientConfigQuery();
    const currentODPrgSegmentSk = useAppSelector((state) => state.player.currentPrgSegmentSk);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { programDetails } = useProgramDetails(launchDetails?.itemPk ?? '0');
    const isWebcast = useAppSelector((state) => state.player.launchDetails.isWebcast);
    const liveSegments = useMemo(() => {
        return programDetails?.dailySegments?.flatMap(it => it.segments ?? []) ?? [];
    },[programDetails?.dailySegments]);
    const segments = (isWebcast ? liveSegments : launchDetails.segments) ?? null;
    const currentTime = useTimeInterval(ONE_SECOND_MS, !isWebcast);
    const currentPrgSegmentSk = useMemo(() => {
        if (isWebcast) {
            const currentLiveSegment = liveSegments.find(segment => {
                if (!segment.startTimeUtc || !segment.endTimeUtc) return false;

                const currentTimeInSeconds = Math.floor(currentTime / 1000);
                const start = Math.floor(new Date(segment.startTimeUtc).getTime() / 1000);
                const end = Math.floor(new Date(segment.endTimeUtc).getTime() / 1000);

                return start <= currentTimeInSeconds && currentTimeInSeconds <= end;
            });
            return currentLiveSegment?.programSegmentSk ?? currentODPrgSegmentSk;
        } else {
            return currentODPrgSegmentSk;
        }
    }, [currentODPrgSegmentSk, currentTime, isWebcast, liveSegments]);
    const selectedTab = useAppSelector((state) => state.segment.selectedTab);
    const dispatch = useAppDispatch();
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const facultySegmentSks = useMemo(() => [...new Set(faculty?.map(it => it.prgSegmentSk))], [faculty]);

    const segmentHasNoFaculty = facultySegmentSks.length > 0 && !facultySegmentSks.includes(currentPrgSegmentSk);

    useEffect(() => {
        if ((segments ?? []).length <= 1 || segmentHasNoFaculty)
            dispatch(setSelectedTab(SegmentsTabSelection.FullProgram));
        else if (isWebcast)
            dispatch(setSelectedTab(SegmentsTabSelection.ThisSegment));
    }, [dispatch, isWebcast, segmentHasNoFaculty, segments]);

    const filteredFaculty = useMemo(() => {
        if (selectedTab === SegmentsTabSelection.FullProgram) return faculty ?? [];

        return faculty?.filter(f => f.prgSegmentSk === currentPrgSegmentSk) ?? [];
    }, [currentPrgSegmentSk, faculty, selectedTab]);

    const facultyGroups = useMemo(() => {
        const getFacultyForGroup = (group: string) => {
            return filteredFaculty
                .filter((f) => f.facultyType === group)
                .reduce((acc, facultyItem) => {
                    if (!acc.find((it) => facultyItem.individualSk === it.individualSk))
                        acc.push(facultyItem);
                    
                    return acc;
                }, [] as typeof filteredFaculty);
        };

        const groups = [...new Set(faculty?.map((f) => f.facultyType))];

        return groups.map((g) => {
            return {
                group: g,
                faculty: getFacultyForGroup(g ?? '')
            };
        }).filter(g => g.faculty.length > 0);
    }, [faculty, filteredFaculty]);

    const showTabs = segments && segments.length > 1 && !segmentHasNoFaculty;

    return (
        <>
            {isLoading && <Loader />}
            {isError && <MainError errorContainer={ErrorContainer.SmallPanel} />}
            {isSuccess && <>
                <div className='small-panel__heading'>
                    <h3 className='heading-5'>{title}</h3>
                    <h4 className='sentence-label text--medium-grey mb-0'>{subtitle}</h4>
                </div>
                {showTabs &&
                    <Tabs.Root>
                        <Tabs.Option
                            onClick={() => dispatch(setSelectedTab(SegmentsTabSelection.ThisSegment))}
                            isActive={selectedTab === SegmentsTabSelection.ThisSegment}
                        >
                            This segment
                        </Tabs.Option>
                        {!isLargeScreen && <span className='spacer'></span>}
                        <Tabs.Option
                            onClick={() => dispatch(setSelectedTab(SegmentsTabSelection.FullProgram))}
                            isActive={selectedTab === SegmentsTabSelection.FullProgram}
                        >
                            Full program
                        </Tabs.Option>
                        {!isLargeScreen && <span className='spacer--grow'></span>}
                    </Tabs.Root>
                }
                {
                    facultyGroups.map((facultyGroup, i) => {
                        return <div className={`faculty-panel__faculty-group ${!showTabs && 'no-margin-top'}`} key={i}>
                            {
                                facultyGroup.faculty.length > 0 &&
                                <div className="faculty-panel__faculty-group__title paragraph-2 text--medium-grey">
                                    {`${facultyGroup.group}s`}
                                </div>
                            }
                            <div className='faculty-panel__faculty-group__container'>
                                {
                                    facultyGroup.faculty.map((f, j) => {
                                        return <Faculty
                                            key={j}
                                            individualSk={f.individualSk}
                                            name={f.displayName ?? ''}
                                            institutionName={f.institutionName ?? ''}
                                            imageUrl={`${clientConfig?.baseContentUrl}/api/client/faculty-image-map/${f.individualSk}`}
                                            biography={f.biography ?? ''}
                                            onSeeDetails={handleSeeDetails}
                                        />;
                                    })
                                }
                            </div>
                        </div >;
                    })
                }
            </>}
        </>
    );
};

export default FacultyPanel;
