import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';

import { useGetFacultyByItemSkQuery } from '../datasource/queries/faculty';
import { setItemSk } from '../stories/Store/playerSlice';
import { useAppDispatch,useAppSelector } from '.';

export const useFaculty = (itemSkProvided?: number) => {   
    const itemSk = useAppSelector((state) => state.player.itemSk);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (itemSkProvided) {
            dispatch(setItemSk(itemSkProvided));
        }
    }, [dispatch, itemSkProvided]);

    const response = useGetFacultyByItemSkQuery((itemSkProvided ?? itemSk) || skipToken);

    return {
        faculty: response.data,
        isLoadingFaculty: response.isLoading,
        isGetFacultySuccess: response.isSuccess,
        isGetFacultyError: response.isError,
        ...response
    };
};
