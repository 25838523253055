import '../../styles/_typography.scss';
import '../../styles/_theme.scss';
import '../../styles/_submenu.scss';

import React, { useEffect, useRef, useState } from 'react';

export interface ISubmenuOption {
    label: string;
    action(): void;
}

export type ISubmenuProps = {
    ariaLabel?: string
    options: ISubmenuOption[];
};

export const Submenu = (props: ISubmenuProps) => {
    const { ariaLabel, options } = props;
    const [showSubMenu, setShowSubMenu] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleShowSubMenu = () => {
        setShowSubMenu(!showSubMenu);
    };

    useEffect(() => {
        if (showSubMenu) {
            buttonRef.current?.focus();
        }
    }, [showSubMenu]);

    return (
        <div className='submenu' onMouseLeave={() => setShowSubMenu(false)}>
            <button
                onClick={toggleShowSubMenu}
                className='submenu__button'
                aria-label={ariaLabel}
            >
                <span className='menu-button--dot menu-button--dot--small'></span>
                <span className='menu-button--dot menu-button--dot--small'></span>
                <span className='menu-button--dot menu-button--dot--small'></span>
            </button>
            {
                showSubMenu &&
                <div className='submenu__options'>
                    {
                        options.map((option, i) => {
                            return <button
                                key={i}
                                ref={i == 0 ? buttonRef : null}
                                className='paragraph-2'
                                aria-label={option.label}
                                onClick={option.action}
                                onBlur={() => i + 1 == options.length && setShowSubMenu(false)}
                            >
                                {option.label}
                            </button>;
                        })
                    }
                </div>
            }
        </div>
    );
};

export default Submenu;