import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';

import { useGetProgramDetailsQuery } from '../datasource/queries/programDetails';
import { setItemPk } from '../stories/Store/playerSlice';
import { useAppDispatch, useAppSelector } from '.';

export const useProgramDetails = (itemPkProvided?: string) => {
    const itemPk = useAppSelector((state) => state.player.itemPk);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (itemPkProvided) {
            dispatch(setItemPk(itemPkProvided));
        }
    }, [dispatch, itemPkProvided]);

    const currentItemPk = itemPkProvided ?? itemPk;

    const response = useGetProgramDetailsQuery(![0, '0', undefined].includes(currentItemPk) ? currentItemPk : skipToken);

    return {
        programDetails: response.data,
        isLoadingProgramDetails: response.isLoading,
        isGetProgramDetailsSuccess: response.isSuccess,
        isGetProgramDetailsError: response.isError,
        ...response
    };
};
