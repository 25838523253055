import '../../styles/_time-card.scss';
import '../../styles/_typography.scss';

import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { CleTimeRecord } from '../../datasource/generated';
import { useAppSelector } from '../../hooks';
import useChangeSegment from '../../hooks/useChangeSegment';
import { useProgramDetails } from '../../hooks/useProgramDetails';
import { useTimeInterval } from '../../hooks/useTimeInterval';
import { PercentCircle } from '../PercentCircle/PercentCircle';

export type ITimeCardProps = {
    timeRecord: CleTimeRecord;
    onClose: (() => void) | null;
    isSingleSegment: boolean;
};

const ONE_MINUTE_MS = 60 * 1000;

const PLAYBACK_THRESHOLD_PERCENT = 99;
const IN_PROGRESS_CLE_CLAMP_PERCENTAGE = 95;

export const TimeCard = ({ timeRecord, onClose, isSingleSegment } : ITimeCardProps) => {
    const { changeSegment } = useChangeSegment();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const recordProgress = useAppSelector((state) => state.player.playbackProgress.find(it => it.itemSk === timeRecord.item_SK), shallowEqual);

    const { data: programDetails } = useProgramDetails(launchDetails?.itemPk ?? '0');
    const currentTime = useTimeInterval(ONE_MINUTE_MS, !launchDetails?.isWebcast);

    const liveSegments = useMemo(() => {
        return (programDetails?.dailySegments ?? []).flatMap(it => it.segments ?? []);
    }, [programDetails?.dailySegments]);

    const progressTargetOD = (recordProgress?.totalTime ?? 0) * (PLAYBACK_THRESHOLD_PERCENT / 100);
    const percentComplete = useMemo(() => {
        if (timeRecord.isLive) {
            const liveSegment = liveSegments.find(it => it.programSegmentSk === timeRecord.prgSegment_SK);
            const startTimeUTC = new Date(liveSegment?.startTimeUtc ?? '');
            const endTimeUTC = new Date(liveSegment?.endTimeUtc ?? '');
            const totalDuration = endTimeUTC.getTime() - startTimeUTC.getTime();
            const remainingTime = endTimeUTC.getTime() - currentTime;
            if (currentTime < startTimeUTC.getTime())
                return 0;
            return totalDuration * (1 - PLAYBACK_THRESHOLD_PERCENT/100) < remainingTime
                ? Math.min(timeRecord.percentComplete ?? 0, IN_PROGRESS_CLE_CLAMP_PERCENTAGE)
                : timeRecord.percentComplete ?? 0;
        } else {
            return recordProgress?.position && recordProgress.position < progressTargetOD
                ? Math.min(timeRecord.percentComplete ?? 0, IN_PROGRESS_CLE_CLAMP_PERCENTAGE)
                : timeRecord.percentComplete ?? 0;
        }
    }, [currentTime, liveSegments, progressTargetOD, recordProgress?.position, timeRecord.isLive, timeRecord.percentComplete, timeRecord.prgSegment_SK]);

    const onClickSegment = useCallback(() => {
    
        if(timeRecord.prgSegment_SK && !isSingleSegment) {
            const segmentItemSk = launchDetails.segments
                ?.find(seg => seg.prgSegmentSk === timeRecord.prgSegment_SK)?.itemSk;
            if (segmentItemSk)
                changeSegment({ itemSk: segmentItemSk });
        }

        onClose?.();       
    }, [timeRecord.prgSegment_SK, isSingleSegment, onClose, launchDetails.segments, changeSegment]);
    
    const headingText = percentComplete === 100 
        ? 'Complete' 
        : (percentComplete === 0 
            ? 'Not started' 
            : (timeRecord.isLive ? 'Partially complete' : 'Continue'));
    const timeCardClass = timeRecord.isLive || !onClose ? 'time-card' : 'time-card time-card__on-demand';
    const completionClass = percentComplete === 100 
        ? 'time-card__details__completion-text-complete sentence-label' 
        : 'time-card__details__completion-text sentence-label';
    
    const isAccreditedSegment = timeRecord.trackCle ? true : false;
    const timecardHeadingText = isAccreditedSegment ? headingText : 'Not for credit';
        
    const ActionWrapper: React.ComponentType<PropsWithChildren> = useMemo(() => {
        return timeRecord.isLive || !onClose
            ? React.Fragment
            : ({ children }) => <button onClick={onClickSegment}>{children}</button>;
    }, [onClickSegment, onClose, timeRecord.isLive]);

    return (
        <li className={timeCardClass}>
            <ActionWrapper>
                <span className={'time-card__main'}>
                    <span className='time-card__details'>    
                        <span className='time-card__details__segment-title paragraph-2--medium'>{timeRecord.title}</span>
                        <span className={completionClass}>{timecardHeadingText}</span>
                    </span>
                    <PercentCircle percentCompleted={percentComplete} isPlayable={!timeRecord.isLive && !!onClose} isHidden={!isAccreditedSegment}/>
                </span>
            </ActionWrapper>
        </li>
    );
};