import '../../styles/_small-panel.scss';

import React, { useEffect, useRef } from 'react';

import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { trapFocus } from '../../utility/trapFocus';
import Loader from '../Loader/Loader';
import { NavBar } from '../NavBar/NavBar';

export type ISmallPanelProps = React.PropsWithChildren<{
    title: string;
    subtitle?: string;
    onBack?(): void;
    onClose(): void;
    isOpen?: boolean;
    isLoading?: boolean;
    isSublevel: boolean;
    scrollToTop?: boolean;
    hasStickyButton?: boolean;
}>;

export const SmallPanel = ({
    title,
    subtitle,
    onBack: handleBack,
    onClose: handleClose,
    isOpen,
    isLoading,
    isSublevel,
    scrollToTop,
    hasStickyButton,
    children
}: ISmallPanelProps) => {
    
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const bodyElement = useRef<HTMLDivElement>(null);
    if (scrollToTop) {
        bodyElement?.current?.scrollTo({ top: 0 });
    }

    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const backButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isOpen) {
            isLargeScreen || !backButtonRef.current ? closeButtonRef.current?.focus() : backButtonRef.current?.focus();
        }

    }, [isLargeScreen, isOpen, isSublevel]);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && document.activeElement) {
            trapFocus(document.activeElement, e, 'button, [href], input[checked], select, textarea, [tabindex]:not([tabindex="-1"])');
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={`small-panel ${isOpen ? 'small-panel--opened' : ''}`}
            onKeyDown={handleKeyDown}
            aria-hidden={!isOpen}
        >
            <NavBar.Root mode={isLargeScreen ? 'inlineTitle' : 'closeAndBack'}>
                {handleBack && (!isLargeScreen || isSublevel) && <NavBar.BackButton ref={backButtonRef} onClick={handleBack} spaced={!isLargeScreen} isShowing={isOpen ? true : false} />}
                {isLargeScreen && <NavBar.Title title={title} subtitle={subtitle} size='paragraph-1--medium' />}
                <NavBar.CloseButton ref={closeButtonRef} onClick={handleClose} spaced={!isLargeScreen} isShowing={isOpen ? true : false}/>
            </NavBar.Root>
            {
                isLoading &&
                <Loader />
            }
            {
                !isLoading &&
                <div ref={bodyElement} className={`small-panel__body ${hasStickyButton ? 'pb-0' : ''}`} aria-hidden={!isOpen}>
                    {children}
                </div>
            }
        </div >
    );
};

export default SmallPanel;