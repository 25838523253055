import React, { useEffect, useRef, useState } from 'react';

import { SFSurveySection } from '../../datasource/generated';
import { SurveyQuestion } from './SurveyQuestion';

type ISurveySectionProps = {
    section: SFSurveySection,
}

export const SurveySectionExpandable = ({ section }:ISurveySectionProps) => {

    const[isExpanded, setIsExpanded] = useState(section.collapsable ? false : true);
    const expandButtonLabel = isExpanded ? 'collapse section' : 'open section';

    const sectionRef = useRef<HTMLDivElement>(null);
    const expandedContent = useRef<HTMLDivElement>(null);
    
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (isExpanded) {
            expandedContent.current?.focus();
            sectionRef.current?.scrollIntoView( { behavior: 'smooth' } );  
        }   
    }, [isExpanded]);

    return (
        <div key={section.sk} >
            {section.collapsable && section.mobileSectionText && (
                <>
                    <button type="button" className='survey__button' onClick={toggleExpand} aria-label={expandButtonLabel}>
                        <h6 className='survey__mobile-section-text-collapsible'>
                            {section.mobileSectionText}
                            <i className={`watch watch-chevron-${isExpanded ? 'up' : 'down'}`} style={{ marginLeft: '5px' }} title={expandButtonLabel}>
                            </i>
                        </h6>
                    </button>
                  
                </>
            )}
            {section.mobileSectionText && !section.collapsable && (
                <div>
                    <div className='survey__mobile-section-text'>
                        {section.mobileSectionText}
                    </div>
                    {section.mobileSectionDescription && (
                        <p className='survey__mobile-section-description'>{section.mobileSectionDescription}</p>
                    )}
                </div>
            )}
            {section.mobileSectionDescription && 
                                            <><div className='survey__required'>*Required</div>
                                                <div className='survey__separator'></div>
                                            </>
            }
            
            <div ref={expandedContent} style={{ display: !isExpanded ? 'none' : '' }}>
                {
                    section?.surveyQuestions?.map((question) =>  {
                        return (
                            <div key={question.sk} >
                                <SurveyQuestion key={question.sk}
                                    question={question}
                                /> 
                            </div>
                        );
                    })
                }        
            </div>
        </div>
    );
};