import '../../styles/_segment-card.scss';

import React from 'react';

import {
    OD_STATIC_IMAGE_SRC,
} from '../../utility/imageUtils';

interface ISegmentCardProps {
    segmentTitle: string,
    subTitle: string,
    imageSrc: string,
    onSegmentCardPress: () => void,
}

export const SegmentCard = ({
    segmentTitle,
    subTitle,
    imageSrc,
    onSegmentCardPress,
}: ISegmentCardProps) => {

    const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = OD_STATIC_IMAGE_SRC;
    };

    const ariaLabel = `Play ${subTitle} ${segmentTitle}`;

    return (
        <button className="segment-card" onClick={onSegmentCardPress} aria-label={ariaLabel}>
            <span className='segment-card__details'>
                <span className='segment-card__details__title-text'>
                    <h3 className='paragraph-1 text--white mb-0'>{segmentTitle}</h3>
                    <h4 className='paragraph-2 text--medium-grey mb-0'>{subTitle}</h4>
                </span>
            </span>
            <span className='segment-card__thumbnail'>
                <img
                    src={imageSrc}
                    onError={handleError}
                    alt={`Thumbnail from next segment ${segmentTitle}`}
                />
                <span className='segment-card__thumbnail__play'>
                    <i className='watch watch-play'/>
                </span>
            </span>    
        </button>
    );

};

export default SegmentCard;