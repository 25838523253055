import JWPlayer from '@jwplayer/jwplayer-react';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { StsSession } from '../datasource/generated/models/StsSession';
import { useUpdateStsSessionsMutation } from '../datasource/mutations/stsSessions';
import logger from '../utility/logger';
import { useAppSelector } from '.';
import { MediaLogEventTypes, useLogMediaEvents } from './useLogMediaEvents';

interface IStsTimerProps {
    playerRef: MutableRefObject<typeof JWPlayer>
}

export const useStsTimer = (props: IStsTimerProps) => {

    const { playerRef } = props;
    
    const { logMediaEvent } = useLogMediaEvents();

    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const [isStsTimerRunning, setIsStsTimerRunning] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const [updateStsSessions] = useUpdateStsSessionsMutation();

    const handleSubmit = useCallback(async (stsSession: StsSession) => {
        try {
            await updateStsSessions([stsSession]);
            // Handle response if needed
        } catch (ex) {
            const error = ex as Error;
            logger.error('Error trying to track the STS record', { error });
            logMediaEvent(MediaLogEventTypes.ERROR, `sendSTSVerification(): ${error.message}`);
        }
    }, [logMediaEvent, updateStsSessions]);

    const stsIntervalLastFiredTime = useRef<Date>();

    const getStsIntervalElapsedMinutes = useCallback(() => {
        if (!stsIntervalLastFiredTime.current)
            return 0;
        const now = new Date();
        const ellapsedMillis = (now as unknown as number) - (stsIntervalLastFiredTime.current as unknown as number);
        const elapsedSeconds = Math.round(ellapsedMillis / 1000);
        const elapsedMinutes = Math.round(elapsedSeconds / 60);
        return elapsedMinutes;
    }, []);

    const stsTimerInterval = useRef<number>();

    const startStsTimer = () => {
        if (!isStsTimerRunning) {
            setIsStsTimerRunning(true);
            stsIntervalLastFiredTime.current = new Date();
        }
    };

    useEffect(() => {
        if (isStsTimerRunning && playerRef.current) {
            stsTimerInterval.current = setInterval(() => {
                handleSubmit({
                    // TODO: maintain encryptedStreamingClockId in state, since the submit cle can change it
                    encryptedStreamingClockId: launchDetails?.encryptedStreamingClockId,
                    position: launchDetails?.isWebcast ? 0 : Math.round(playerRef.current.getPosition()),
                    segmentSK: currentSegmentItemSk,
                    playedMinutesIncrement: isPlaying ? getStsIntervalElapsedMinutes() : 0,
                });
                stsIntervalLastFiredTime.current = new Date();
            }, 60 * 1000) as unknown as number;
        } else {
            clearInterval(stsTimerInterval.current);
        }

        return () => {
            clearInterval(stsTimerInterval.current);
        };

    }, [isStsTimerRunning, playerRef, currentSegmentItemSk, handleSubmit,
        launchDetails, getStsIntervalElapsedMinutes, isPlaying]);

    return {
        isStsTimerRunning,
        startStsTimer,
        setIsPlaying,
    };
};

export default useStsTimer;