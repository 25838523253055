import { apiSlice } from '..';
import { UpdateNotificationReceiptsRequest } from '../generated';

const notificationReceipts = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        updateNotificationReceipts: builder.mutation<null, UpdateNotificationReceiptsRequest>({
            query: (request) => ({
                url: '/media/notifications/receipt',
                method: 'POST',
                body: request,
            }),
        }),
    }),
});

export const { useUpdateNotificationReceiptsMutation } = notificationReceipts;
export default notificationReceipts.reducer;