import '../../styles/_segments-panel.scss';

import React, { useEffect } from 'react';

import { SegmentFormat } from '../../enums/SegmentFormat';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useProgramDetails } from '../../hooks/useProgramDetails';
import { useSegmentImages } from '../../hooks/useSegmentImages';
import { getSecondsFromRunningTimeString } from '../../utility/running-time';
import Segment from '../Segment/Segment';
import { setSelectedSegment } from '../Store/segmentSlice';

export type ISegmentDetailsProps = {
    onPlaySegment(): void;
};

export const SegmentDetails = (props: ISegmentDetailsProps) => {
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { programDetails } = useProgramDetails(launchDetails?.itemPk ?? '0');
    const segment = useAppSelector((state) => state.segment.selectedSegment);
    const dispatch = useAppDispatch();
    const forceSegmentDetails = Math.max(launchDetails?.segments?.length ?? 0, programDetails?.dailySegments?.[0].segments?.length ?? 0) <= 1 &&
        (launchDetails.isWebcast ? !!programDetails : true);
    const didImagesFinishCaching = useAppSelector((state) => state.player.didSegmentThumbnailsFinishedCaching);
    const { getSegmentCachedImageUrl, getSegmentImageUrl } = useSegmentImages();

    useEffect(() => {
        if (forceSegmentDetails) {
            if (launchDetails.segments && launchDetails.segments.some(x => x)) {
                dispatch(setSelectedSegment({
                    itemSk: launchDetails.segments[0].itemSk ?? 0,
                    prgSegmentSk: launchDetails.segments[0].prgSegmentSk ?? 0,
                    segmentNumber: 1,
                    segmentsTotal: 1,
                    imageUrl: didImagesFinishCaching ? getSegmentCachedImageUrl(launchDetails.segments[0].itemSk) : getSegmentImageUrl(launchDetails.segments[0].itemSk),
                    format: SegmentFormat.OnDemand,
                    title: launchDetails.segments[0].title ?? '',
                    runTimeSeconds: getSecondsFromRunningTimeString(launchDetails.segments[0].producedRunningTime ?? '') || 1,
                    description: launchDetails.segments[0].description ?? '',
                }));
            } else {
                dispatch(setSelectedSegment({
                    itemSk: launchDetails.itemSk ?? 0,
                    prgSegmentSk: 0,
                    segmentNumber: 1,
                    segmentsTotal: 1,
                    imageUrl: didImagesFinishCaching ? getSegmentCachedImageUrl(launchDetails.itemSk) : getSegmentImageUrl(launchDetails.itemSk),
                    format: launchDetails.isWebcast ? SegmentFormat.LiveWebcast : SegmentFormat.OnDemand,
                    startTime: programDetails?.formattedEventStart ?? '',
                    startTimeUtc: programDetails?.eventStart,
                    endTimeUtc: programDetails?.eventEnd,
                    title: launchDetails?.title ?? '',
                    runTimeSeconds: getSecondsFromRunningTimeString(launchDetails?.producedRunningTime ?? '') || 1,
                    description: launchDetails?.overview ?? programDetails?.overview ?? '',
                }));
            }
        }
    }, [dispatch, didImagesFinishCaching, getSegmentCachedImageUrl, programDetails?.overview,
        getSegmentImageUrl, forceSegmentDetails, launchDetails.isWebcast,
        launchDetails.segments, launchDetails?.title, launchDetails.itemSk,
        launchDetails?.overview, launchDetails?.producedRunningTime, props.onPlaySegment,
        programDetails?.formattedEventStart, programDetails?.eventStart, programDetails?.eventEnd
    ]);
    
    return (
        <div className='segment-details'>
            <Segment
                itemSk={segment?.itemSk ?? 0}
                prgSegmentSk={segment?.prgSegmentSk ?? 0}
                title={segment?.title ?? ''}
                format={segment?.format ?? SegmentFormat.OnDemand}
                imageUrl={segment?.imageUrl}
                runTimeSeconds={segment?.runTimeSeconds}
                segmentNumber={segment?.segmentNumber}
                segmentsTotal={segment?.segmentsTotal}
                isSegmentDetails={true}
                startTime={segment?.startTime}
                startTimeUtc={segment?.startTimeUtc}
                endTimeUtc={segment?.endTimeUtc}
                isSingleSegment={forceSegmentDetails}
                onPlaySegment={props.onPlaySegment}
                onSeeDetails={() => undefined}
            />
            {
                segment?.description &&
                <div className='segment-details__title paragraph-2--medium'>Overview</div>
            }
            <div className='paragraph-2 text--medium-grey' dangerouslySetInnerHTML={{ __html: segment?.description ?? '' }}></div>
        </div>
    );
};

export default SegmentDetails;