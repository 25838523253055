import { useCallback, useEffect, useState } from 'react';

import { CpeMarker } from '../datasource/generated/models/CpeMarker';
import { ExternalCommand, setExternalCommand } from '../stories/Store/playerSlice';
import { useAppDispatch, useAppSelector } from '.';
import useCpeExams from './useCpeExams';


export const useCpeMarkers = () => {
    const dispatch = useAppDispatch();

    const launchItemSk = useAppSelector((state) => state.player.launchDetails.itemSk);
    const cpeMarkers = useAppSelector((state) => state.player.launchDetails.video?.cpeMarkers);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const currentSegmentPosition = useAppSelector((state) => state.player.playbackProgress.find(x => x.itemSk === currentSegmentItemSk))?.position ?? 0;

    const { exams } = useCpeExams(false);

    const [activeMarker, setActiveMarker] = useState<CpeMarker | undefined>();
    const [nextMarker, setNextMarker] = useState<CpeMarker | undefined>();

    const [areCpeMarkersReady, setAreCpeMarkersReady] = useState(false);

    const hasCpeMarkers = cpeMarkers && cpeMarkers.length;
    const isCpeSegment = hasCpeMarkers && currentSegmentItemSk === launchItemSk;


    useEffect(() => {
        if (!areCpeMarkersReady || !isCpeSegment || !exams || exams.length === 0)
            return;

        const futureMarkers = cpeMarkers.filter(m => Math.round(m.position ?? 0) > (currentSegmentPosition + 1));

        if (futureMarkers.length) {
            const shouldUpdateNextMarker = !nextMarker || ((nextMarker.position ?? 0) > (futureMarkers[0].position ?? 0));
            if (shouldUpdateNextMarker) {
                setNextMarker(futureMarkers[0]);
                return;
            }
        }

        if (!nextMarker) return;
        const roundedNextMarkerPosition = Math.round(nextMarker.position ?? 0);
        if (currentSegmentPosition === roundedNextMarkerPosition) {
            dispatch(setExternalCommand(ExternalCommand.pause));
            setActiveMarker(nextMarker);
            setNextMarker(undefined);
        }

    }, [areCpeMarkersReady, cpeMarkers, currentSegmentPosition, dispatch, exams, isCpeSegment, nextMarker]);


    const dismissActiveMarker = () => {
        setActiveMarker(undefined);
    };

    const setCpeMarkersReady = useCallback(() => {
        setAreCpeMarkersReady(true);
    }, []);

    return {
        activeCpeMarker: activeMarker,
        dismissActiveCpeMarker: dismissActiveMarker,
        setCpeMarkersReady: setCpeMarkersReady,
    };
};