import '../../styles/_thumbnail.scss';

import Cookies from 'js-cookie';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';

import { Slide } from '../../datasource/generated';
import { useAppSelector } from '../../hooks';
import {
    LIVE_STATIC_IMAGE_SRC,
    OD_STATIC_IMAGE_SRC,
} from '../../utility/imageUtils';
import { sockets } from '../ThumbnailContainer/ThumbnailContainer';
import { VideoThumbnail } from '../VideoThumbnail/VideoThumbnail';
import ThumbnailControls from './ThumbnailControls';

export interface IThumbnailProps {
    activeThumbnail: boolean;
    currentSlide?: Slide | null;
    socket: string;
    handleSetTarget(target: string): void;
    handleMove(socket: string): void;
    handleSwap(): void;
    isSwapped: boolean;
    videoRef:
        | React.MutableRefObject<HTMLVideoElement | undefined>
        | React.RefObject<HTMLVideoElement | undefined>;
    handleShowControls(showControls: boolean): void;
    showControls: boolean;
}

export const Thumbnail = (props: IThumbnailProps) => {
    const {
        activeThumbnail,
        currentSlide,
        socket,
        handleSetTarget,
        handleMove,
        handleSwap,
        isSwapped,
        videoRef,
        handleShowControls,
        showControls,
    } = props;
    const [videoPlayerContainer, setVideoPlayerContainer] =
        useState<HTMLElement | null>(null);

    const activeCenterThumbnailRef = useRef<HTMLButtonElement>(null);

    const activeSocketCookie = Cookies.get('activeSocket');

    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const currentSegmentSk = useAppSelector((state) => state.player.currentSegmentItemSk);

    const hideDock = socket === 'center-left' || socket === 'center-right';

    const SOCKET_INDEX = sockets.indexOf(socket);

    useEffect(() => {
        if (hideDock && activeThumbnail && activeCenterThumbnailRef.current) {
            activeCenterThumbnailRef.current.focus();
        }
    }, [activeThumbnail, hideDock]);

    const handleKeydownShowThumbnail = useCallback(
        (e: Event) => {
            const key = (e as KeyboardEvent).key;
            if (key === 'Enter' || key === ' ') {
                e.preventDefault();
                e.stopPropagation();
                handleSetTarget(activeSocketCookie ?? 'bottom-right');
                handleShowControls(true);
            }
        },
        [activeSocketCookie, handleSetTarget, handleShowControls]
    );

    useEffect(() => {
        const showBtn = activeCenterThumbnailRef.current;
        activeThumbnail && showBtn?.addEventListener('keydown', handleKeydownShowThumbnail, true);

        return () => {
            showBtn?.removeEventListener(
                'keydown',
                handleKeydownShowThumbnail,
                true
            );
        };
    }, [handleKeydownShowThumbnail, activeThumbnail]);

    // if currentSlide is being checked in Player.tsx, no need to check it here again,
    // but keep for now as a precaution in case we decide to go back to empty thumbnail mode
    const slideTitle = useMemo(() => {
        if (currentSlide) {
            if (currentSlide.slideNumber ?? 0 > 0) {
                return `Slide number ${currentSlide.slideNumber} from PowerPoint presentation ${currentSlide.presentationName}`;
            }

            return 'Title slide';
        }

        return 'Slide';
    }, [currentSlide]);

    // if currentSlide is being checked in Player.tsx, no need to check it here again,
    // but keep for now as a precaution in case we decide to go back to empty thumbnail mode
    const slideUrl = useMemo(() => {
        if (!activeThumbnail) return null;

        if (
            currentSlide &&
            currentSlide.url &&
            (currentSlide.slideNumber ?? 0) > 0
        ) {
            return currentSlide.url;
        }

        return launchDetails?.isWebcast
            ? LIVE_STATIC_IMAGE_SRC
            : OD_STATIC_IMAGE_SRC;
    }, [activeThumbnail, currentSlide, launchDetails?.isWebcast]);

    useEffect(() => {
        setVideoPlayerContainer(
            (document
                .getElementById('player')
                ?.querySelector('.jw-media') as HTMLElement) ?? null
        );
    }, []);

    const VideoPlayerContainerPortal = useCallback(
        ({ children }: React.PropsWithChildren) =>
            videoPlayerContainer &&
            createPortal(children, videoPlayerContainer),
        [videoPlayerContainer]
    );

    const SlideImageWrapper = isSwapped
        ? VideoPlayerContainerPortal
        : React.Fragment;

    const shouldRenderImage = !hideDock || isSwapped;

    const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = launchDetails?.isWebcast
            ? LIVE_STATIC_IMAGE_SRC
            : OD_STATIC_IMAGE_SRC;
    };

    return (
        <div
            className={`thumbnail thumbnail--${socket} ${
                activeThumbnail ? 'thumbnail--active' : ''
            }`}
            tabIndex={hideDock ? -1 : 0}
            aria-label={slideTitle}
            onBlur={() => handleShowControls(false)}
        >
            {activeThumbnail && !hideDock && (
                <ThumbnailControls
                    socket={socket}
                    handleMove={handleMove}
                    handleSwap={handleSwap}
                    handleSetTarget={handleSetTarget}
                    movePositionAriaLabel={
                        SOCKET_INDEX < sockets.length - 1
                            ? sockets[SOCKET_INDEX + 1]
                            : sockets[0]
                    }
                    handleShowControls={handleShowControls}
                    showControls={activeThumbnail && showControls}
                    isSwapped={isSwapped}
                />
            )}

            {hideDock && (
                <div
                    className={`thumbnail__hide-dock ${
                        activeThumbnail ? 'thumbnail__hide-dock--active' : ''
                    }`}
                >
                    <button
                        className={`thumbnail__show-button ${
                            activeThumbnail
                                ? 'thumbnail__show-button--active'
                                : ''
                        }`}
                        onClick={() =>
                            handleSetTarget(
                                activeSocketCookie ?? 'bottom-right'
                            )
                        }
                        tabIndex={activeThumbnail ? 0 : -1}
                        aria-label='maximize thumbnail'
                        ref={
                            activeThumbnail
                                ? activeCenterThumbnailRef
                                : undefined
                        }
                    >
                        <i
                            className={
                                socket === 'center-left'
                                    ? 'watch watch-forward-arrow'
                                    : 'watch watch-forward-arrow rotate-180'
                            }
                        />
                    </button>
                </div>
            )}
            {activeThumbnail && (
                <>
                    {shouldRenderImage && (
                        <SlideImageWrapper>
                            <img
                                src={slideUrl ?? ''}
                                className={[
                                    'thumbnail__image',
                                    activeThumbnail &&
                                        'thumbnail__image--active',
                                    isSwapped && 'thumbnail__image--swapped',
                                ]
                                    .filter(Boolean)
                                    .join(' ')}
                                onError={handleError}
                                alt={slideTitle}
                            />
                        </SlideImageWrapper>
                    )}
                    {isSwapped && (
                        <VideoThumbnail
                            key={currentSegmentSk}
                            sourceRef={videoRef}
                            hidden={hideDock}
                            className={[
                                'thumbnail__image thumbnail__image--active',
                                hideDock && 'thumbnail__image--hidden',
                            ]
                                .filter(Boolean)
                                .join(' ')}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Thumbnail;
