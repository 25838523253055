import React, { useCallback, useEffect } from 'react';

/**
 * Hook that handles clicks outside of the passed ref(s)
 */
export const useOutsideClickHandler = (
    ref: React.RefObject<HTMLElement | undefined>,
    callback: (event?: MouseEvent) => void,
    secondaryRef?: React.RefObject<HTMLElement | undefined>
) => {
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (secondaryRef?.current) {
            if (
                secondaryRef.current &&
            !secondaryRef.current.contains(event.target as Node) &&
            ref.current &&
            !ref.current.contains(event.target as Node)
            ) {
                callback(event);
            }
        } else {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback(event);
            }
        }
    }, [callback, ref, secondaryRef]);

    useEffect(() => {

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return {
        handleClickOutside
    };
};
