import { apiSlice } from '..';
import { ExamUrl } from '../generated';
import { Exam } from '../generated/models/Exam';

export interface IExamUrlProps {
    itemSk: number,
    certTypeSk: number,
}

const examsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getExams: builder.query<Array<Exam>, number>({
            query: (itemSk: number) => `/exams?itemSk=${itemSk}`
        }),
        getExamLaunchUrl: builder.query<ExamUrl, IExamUrlProps>({
            query: (props: IExamUrlProps) => `/exams/launch-url?itemSk=${props.itemSk}&certTypeSk=${props.certTypeSk}`
        })
    })
});

export const { useLazyGetExamsQuery, useGetExamsQuery, useLazyGetExamLaunchUrlQuery } = examsSlice;
export default examsSlice;