import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect } from 'react';

import { history } from '../stories/Store/configureStore';
import logger from '../utility/logger';

export let appInsights: ApplicationInsights | undefined = undefined;

export const useApplicationInsights = () => {
    useEffect(() => {
        try {
            const reactPlugin = new ReactPlugin();
            appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: history },
                    },
                },
            });
            appInsights.loadAppInsights();
        } catch (e) {  
            logger.error('Error react AI initialization', {
                error: e,
            });
        } 
    }, []);
};

export default useApplicationInsights;
