/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum QuestionType {
    FACULTY = 'Faculty',
    TECHNICAL_PROBLEM = 'TechnicalProblem',
    MOBILE_FEEDBACK = 'MobileFeedback',
}
