import { bookmarkReducer, BookmarkState } from './bookmarkSlice';
import { cleReducer, CleState } from './cleSlice';
import { downloadReducer, DownloadState } from './downloadSlice';
import { facultyQuestionReducer } from './facultyQuestionSlice';
import { facultyReducer, FacultyState } from './facultySlice';
import { materialsReducer, MaterialsState } from './materialsSlice';
import { menuReducer, MenuState } from './menuSlice';
import { playerReducer, PlayerState } from './playerSlice';
import { prePlayerReducer, PrePlayerState } from './prePlayerSlice';
import {
    reportAProblemReducer,
    ReportAProblemState,
} from './reportAProblemSlice';
import { requestCreditReducer, RequestCreditState } from './requestCreditSlice';
import { segmentReducer, SegmentState } from './segmentSlice';
import { signalRReducer, SignalRState } from './signalRSlice';
import { toastReducer, ToastState } from './toastSlice';
import { userReducer, UserState } from './userSlice';

// The top-level state object
export interface ApplicationState {
    player: PlayerState | undefined;
    segment: SegmentState | undefined;
    prePlayer: PrePlayerState | undefined;
    user: UserState | undefined;
    reportAProblem: ReportAProblemState | undefined;
    cle: CleState | undefined;
    signalR: SignalRState | undefined;
    menu: MenuState | undefined;
    materials: MaterialsState | undefined;
    faculty: FacultyState | undefined;
    bookmark: BookmarkState | undefined;
    download: DownloadState | undefined;
    requestCredit: RequestCreditState | undefined;
    toast: ToastState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    player: playerReducer,
    segment: segmentReducer,
    prePlayer: prePlayerReducer,
    user: userReducer,
    reportAProblem: reportAProblemReducer,
    cle: cleReducer,
    menu: menuReducer,
    facultyQuestion: facultyQuestionReducer,
    materials: materialsReducer,
    faculty: facultyReducer,
    bookmark: bookmarkReducer,
    download: downloadReducer,
    requestCredit: requestCreditReducer,
    signalR: signalRReducer,
    toast: toastReducer, 
};
