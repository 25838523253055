import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import launchSlice from '../../datasource/mutations/launch';
import { TutorialType } from '../Tutorial/Tutorial';

export interface ShowTutorialState {
    tutorialType: TutorialType;
    showTutorial: boolean;
}

export interface PrePlayerState {
    showTutorial: Array<ShowTutorialState>;
    isTutorialComplete: boolean;
    isLicenseReviewComplete: boolean;
}

const initialState: PrePlayerState = {
    showTutorial: [
        {
            tutorialType: TutorialType.VerifyAttendanceTutorial,
            showTutorial: true,
        },
        {
            tutorialType: TutorialType.GroupcastTutorial,
            showTutorial: true,
        },
    ],
    isTutorialComplete: false,
    isLicenseReviewComplete: false,
};

const prePlayerSlice = createSlice({
    name: 'prePlayer',
    initialState,
    reducers: {
        setShowTutorial(state, action: PayloadAction<ShowTutorialState[]>) {
            state.showTutorial = action.payload;
        },
        setIsTutorialComplete(state, action: PayloadAction<boolean>) {
            state.isTutorialComplete = action.payload;
        },
        setIsLicenseReviewComplete(state, action: PayloadAction<boolean>) {
            state.isLicenseReviewComplete = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            launchSlice.endpoints.launch.matchFulfilled,
            (state, { payload }) => {
                if (payload.externalUrl) {
                    // skip tutorial for external programs
                    state.isTutorialComplete = true;
                    state.showTutorial.forEach(it => it.showTutorial = false);
                }
            }
        );
    }
});

export const { setShowTutorial, setIsTutorialComplete, setIsLicenseReviewComplete } = prePlayerSlice.actions;

export const { reducer: prePlayerReducer } = prePlayerSlice;
