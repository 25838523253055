import '../../styles/_off-air.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useGetOffAirMessagesQuery } from '../../datasource/queries/cmsMessages';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { appInsights } from '../../hooks/useApplicationInsights';
import logger from '../../utility/logger';
import Button from '../Button/Button';
import { setIsOnAir } from '../Store/playerSlice';

export const OffAir = () => {
    const dispatch = useAppDispatch();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const user = useAppSelector((state) => state.user);

    const {
        data: messages,
        isSuccess: isGetMessagesSuccess,
    } = useGetOffAirMessagesQuery();

    const isTooSoon = launchDetails.secondsUntilLive && launchDetails.secondsUntilLive > 0;
    const isTooLate = new Date() >= new Date(launchDetails.timeExpiresUtc ?? 0);

    const [timeAvailable, setTimeAvailable] = useState<Date>();
    const [secondsUntilLive, setSecondsUntilLive] = useState(launchDetails.secondsUntilLive ?? 0);
    const [isTimeAvailableReached, setIsTimeAvailableReached] = useState(false);

    const countdownTimeStamp = () => {
        if (secondsUntilLive <= 0) return '00:00:00';
        const hours = Math.floor(secondsUntilLive / 3600);
        const minutes = Math.floor((secondsUntilLive - hours * 3600) / 60);
        const seconds = Math.floor(secondsUntilLive - hours * 3600 - minutes * 60);

        const minutesSeconds = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        if (!hours) return minutesSeconds;
        return `${hours < 10 ? '0' : ''}${hours}:${minutesSeconds}`;
    };

    useEffect(() => {
        // Initialize timeAvailable
        if (isTooSoon && !timeAvailable && launchDetails.secondsUntilLive) {
            const availableDate = new Date();
            availableDate.setTime(availableDate.getTime() + launchDetails.secondsUntilLive * 1000);
            setTimeAvailable(availableDate);
        }
    }, [isTooSoon, launchDetails.secondsUntilLive, timeAvailable]);

    const availableCheckInterval = useRef<number>();
    useEffect(() => {
        // Every second, update secondsUntilLive to timeAvailable - Now
        if (isTooSoon && timeAvailable && !isTimeAvailableReached) {
            availableCheckInterval.current = setInterval(() => {
                const millisUntilLive = timeAvailable.getTime() - new Date().getTime(); 
                setSecondsUntilLive(millisUntilLive / 1000);
            }, 1000) as unknown as number;
        } else {
            clearInterval(availableCheckInterval.current);
        }
        return () => clearInterval(availableCheckInterval.current);
    }, [isTimeAvailableReached, isTooSoon, timeAvailable]);

    useEffect(() => {
        if (isTooSoon && timeAvailable && (secondsUntilLive <= 0 || user.isPliEmployee)) {
            setIsTimeAvailableReached(true);
            logger.log('TimeAvailable reached, switching to live stream', {
                individualSk: user.individualSK,
                itemSk: launchDetails.itemSk,
            });
        }
    }, [isTooSoon, launchDetails.itemSk, secondsUntilLive, timeAvailable, user.individualSK, user.isPliEmployee]);

    useEffect(() => {
        if (isTimeAvailableReached) {
            dispatch(setIsOnAir());
        }
    }, [dispatch, isTimeAvailableReached]);

    const tooSoonSolidText = messages?.tooSoonPrimaryMessage; 
    const tooSoonGradientTexts = messages?.tooSoonRotatingMessages ?? []; 

    const [currentIndex, setCurrentIndex] = useState(0);
    const textCycleIntervalRef = useRef<number>();
    useEffect(() => {
        if (isTooSoon && !isTimeAvailableReached && isGetMessagesSuccess && !!tooSoonGradientTexts.length) {
            textCycleIntervalRef.current = setInterval(() => {
                if (currentIndex === tooSoonGradientTexts.length -1)
                    setCurrentIndex(0);
                else
                    setCurrentIndex(currentIndex + 1);
            }, 8250) as unknown as number;
        }

        return () => clearInterval(textCycleIntervalRef.current);

    }, [currentIndex, isTooSoon, isTimeAvailableReached, tooSoonGradientTexts.length, isGetMessagesSuccess]);

    const tooLateSolidText = messages?.tooLatePrimaryMessage ?? 'Our live program has ended.';
    const tooLateGradientText = messages?.tooLateSecondaryMessage ?? 'We hope to see you next time.';
    const exploreButtonText = messages?.tooLateButtonLabel;
    const exploreButtonUrl = useMemo(() => {
        if (!isGetMessagesSuccess || !messages?.tooLateButtonUrl || !messages?.tooLateShowButton)
            return;
        try {
            new URL(messages.tooLateButtonUrl);
            return messages.tooLateButtonUrl;
        } catch {
            //
        }
        appInsights?.trackEvent({
            name: 'OffAir Too Late Button URL is not valid, button will not be displayed',
        });
    }, [isGetMessagesSuccess, messages?.tooLateButtonUrl, messages?.tooLateShowButton]);
    const showExploreButton = (messages?.tooLateShowButton && !!exploreButtonText && !!exploreButtonUrl) ?? false;

    const openButtonUrl = () => {
        if (!showExploreButton || !exploreButtonUrl) 
            return;
        window.open(exploreButtonUrl, '_blank');
    };

    return (
        <div className='off-air'>
            <div className='off-air__content'>
                {isTooSoon &&
                    <>
                        <div role='timer' 
                            aria-label='Time remaining before live webcast begins' 
                            className='off-air__content__countdown'>
                            {countdownTimeStamp()}
                        </div>

                        {tooSoonSolidText && 
                        <p className='off-air__content__solid-text'>
                            {tooSoonSolidText}
                        </p>}

                        {!!tooSoonGradientTexts.length &&
                         <p aria-live='polite'
                             className='off-air__content__gradient-text off-air__content__gradient-text--animated'>
                             {tooSoonGradientTexts[currentIndex]}
                         </p>}
                    </>
                }

                {isTooLate && 
                    <>
                        <p className='off-air__content__solid-text'>
                            {tooLateSolidText}
                        </p>

                        <p className='off-air__content__gradient-text pb-2'>
                            {tooLateGradientText}
                        </p>

                        {(showExploreButton && exploreButtonText) &&
                         <div className='off-air__content__button-container'>
                             <Button
                                 buttonClass='fluid-button fluid-button--large fluid-button--transparent'
                                 label={exploreButtonText}
                                 action={openButtonUrl}
                                 icon='outbound-link'
                                 iconPosition='right' 
                                 iconClass='ml-1'
                             /> 
                         </div>}
                    </>
                }

            </div>
        </div>
    );
};