import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SegmentsTabSelection } from '../../enums/TabSelection';

export interface MaterialsState {
    selectedTab: SegmentsTabSelection;
}

const initialState: MaterialsState = {
    selectedTab: SegmentsTabSelection.ThisSegment
};

const materialsSlice = createSlice({
    name: 'materials',
    initialState,
    reducers: {
        setSelectedTab(state, action: PayloadAction<SegmentsTabSelection>) {
            state.selectedTab = action.payload;
        },
    },
});

export const { setSelectedTab } = materialsSlice.actions;

export const { reducer: materialsReducer } = materialsSlice;