import { IStepInformation } from './Tutorial';


export const VerifyAttendanceTutorialSteps = [
    {
        headerText: 'Verify your attendance as you go',
        bodyText: 'Regulators require us to track your attendance in order to issue the credits you earn from watching. Simply click the “Verify Attendance” button any time you see it to do so.',
    },
    {
        headerText: 'Request credits once you\'re done',
        bodyText: 'The best way to ensure you receive full credits for everything you’ve watched is to wait until after completing the entire program (or as much of the program as desired) before clicking the “Request Credit” link. You’ll receive your certificate of attendance via email.',
    },
    {
        headerText: 'Credit is only issued to the registered user or member',
        bodyText: 'PLI\'s live and recorded content are single-user license products, which means they’re intended for an individual registrant only.',
    },
] as Array<IStepInformation>;
