import { apiSlice } from '..';

const trackCleSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTrackCle: builder.query<boolean, number>({
            query: (itemSk) => `/cle/track-cle?itemSk=${itemSk}`,
        }),
    })
});

export const { useLazyGetTrackCleQuery } = trackCleSlice;
export default trackCleSlice;