import 'bootstrap/scss/bootstrap.scss';

import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppProviders } from './AppProviders';
import App, { IAppProps, IIdentity } from './stories/App';
import { setUserInfo, syntheticEventHubEvent } from './utility/dataLayerUtils';
import logger from './utility/logger';


const props: IAppProps = { identity: { id: '', name: '', email: '', individualSK: 0, isAuthorized: false, 
    isPliEmployee: false, isGroupcast: false,  isIndividual: false, itemSK: 0, organizationSK: 0, isIM: false, isPM: false } };
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const urlParamsLC = new URLSearchParams(queryString.toLowerCase());

axios.get(urlParams.has('t') ? ('identity/isauthorized?t=' + urlParams.get('t')) : (
    urlParamsLC.has('itemsk') ? ('identity/isauthorized?itemsk=' + urlParamsLC.get('itemsk')) : 'identity/isauthorized'))
    .then(response => response.data as Promise<IIdentity>)
    .then(data => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const rootElement = document.getElementById('root')!;
        const root = createRoot(rootElement);
        props.identity = data;       
        setUserInfo(props.identity);
        syntheticEventHubEvent({ event: 'page_view' }); // TBD: To track all information needed on EventHub outside from GA4 (Screen/Page/User/Ip/Location/Browser/OS/referer/location)
        root.render(
            <AppProviders>
                <App {...props} />
            </AppProviders>
        );
    })
    .catch(function (error : Error) {
        // handle error
        logger.exception(error);
    })
    .finally(function () {
        // always executed
    });
