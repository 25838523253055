import '../../styles/_live-poll-qa-panel.scss';

import React from 'react';

import { useAppSelector } from '../../hooks';
import FacultyQuestionForm from '../OnDemandQuestionPanel/FacultyQuestionForm';
import SmallPanel from '../SmallPanel/SmallPanel';

export interface ILivePollingQAPanelProps {
    sessionUrl?: string;
    onClose: () => void;
}

export const LivePollingQAPanel = (props: ILivePollingQAPanelProps) => {
    const sessionUrl = useAppSelector((state) => state.player.launchDetails.pollSessionUrl);
    const iframeSrc = props.sessionUrl ?? sessionUrl;

    if (!iframeSrc) {
        return <SmallPanel
            isOpen={true}
            onBack={props.onClose}
            onClose={props.onClose}
            title={'Live Q&A'}
            isSublevel={false}
            hasStickyButton={true}
        >
            <FacultyQuestionForm isLive={true} />
        </SmallPanel>;
    }
    return <iframe title='Conferences.IO' src={iframeSrc} className='conferences-io-iframe'/>;
};

export default LivePollingQAPanel;