import { apiSlice } from '..';
import { DataSenderStatus } from '../generated';

const eventHubApiEndpoint = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        sendEvents: build.mutation<DataSenderStatus, string[]>({
            query: (body) => ({
                url: '/event-hub/send-events',
                method: 'POST',
                body,
            }),
        })
    }),
});

export const { useSendEventsMutation } = eventHubApiEndpoint;
export default eventHubApiEndpoint;
  