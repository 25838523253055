import '../../../styles/_credit-progress-panel.scss';

import React, { useEffect, useMemo } from 'react';

import { CreditReviewAndExamMessages } from '../../../datasource/generated';
import { MenuOptions } from '../../../enums/MenuOptions';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { FetchType, useAnalytics } from '../../../hooks/useAnalytics';
import { UseCpeExamsResponse } from '../../../hooks/useCpeExams';
import Button from '../../Button/Button';
import { setIsExamStatusVisible, setLargePanelOpenCommand } from '../../Store/menuSlice';
import { ReactComponent as CreditStatusApproved } from '../assets/credit-status-approved.svg';
import { ReactComponent as CreditStatusPending } from '../assets/credit-status-pending.svg';

interface ReviewAndExamTabProps {
    footerNote: string,
    reviewAndExamMessages: CreditReviewAndExamMessages | undefined,
    useCpeExamsReponse: UseCpeExamsResponse,
}

export const ReviewAndExamTab = ({ footerNote, reviewAndExamMessages, useCpeExamsReponse }: ReviewAndExamTabProps) => {
    const dispatch = useAppDispatch();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { exams, openExam, areExamsPassed } = useCpeExamsReponse;
    const { pushToDataLayer } = useAnalytics();
    
    useEffect(() => {
        dispatch(setIsExamStatusVisible({ ReviewAndExamTab : true }));
        
        return () => {
            dispatch(setIsExamStatusVisible({ ReviewAndExamTab : false }));
        };
    }, [dispatch]);

    const onTakeExamButtonClick = () => {
        if (areExamsPassed) {
            dispatch(setLargePanelOpenCommand(MenuOptions.RequestCredit));
            pushToDataLayer({ event: 'request_credit_start', fetchType: FetchType.Program });
            return;
        }

        openExam();
    };

    const buttonLabels = useMemo(() => {
        if (areExamsPassed) {
            const label = reviewAndExamMessages?.passedButtonText ?? 'Request credit';
            return {
                label: label,
                ariaLabel: label,
            };
        }
        return {
            label: reviewAndExamMessages?.notTakenAndNotPassedButtonText ?? 'Take Review & Exam',
            ariaLabel: 'Open CPE Review and Exam in new window',
        };
    }, [areExamsPassed,
        reviewAndExamMessages?.notTakenAndNotPassedButtonText,
        reviewAndExamMessages?.passedButtonText]);



    return (<>
        <div className={'segment-exam segment-exam__on-demand'}>
            {exams?.map(exam => (
                <div key={exam.examSk}>
                    <div className='segment-exam__details'>
                        <div className='segment-exam__details__completion-text sentence-label' aria-label='Currently watching'>Currently watching</div>
                        <div className='segment-exam__details__segment-title paragraph-2--medium' aria-label={launchDetails.title ?? ''}>{launchDetails.title}</div>
                        <div className='segment-exam__details__status sentence-label'>
                            {exam.passed && (
                                <>
                                    <CreditStatusApproved aria-disabled />
                                    <p>{reviewAndExamMessages?.passedStatusMessage ?? 'Exam passed'}</p>
                                </>
                            )}
                            {!exam.passed && (
                                <>
                                    <CreditStatusPending aria-disabled />
                                    <p>{reviewAndExamMessages?.notTakenAndNotPassedStatusMessage ?? 'Exam not passed'}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
        <div className='segment-exam__regulation-copy'>
            <p className='paragraph-2--regular'>{exams && exams[0]?.longDescription}</p>
        </div>
        <div className='credit-progress-panel__button-container'>
            <span className='sentence-label mb-4'>{footerNote}</span>
            <Button
                buttonClass='sticky-button sticky-button__alt'
                label={buttonLabels.label}
                action={onTakeExamButtonClick}
                aria-label={buttonLabels.ariaLabel}
            />
        </div>
    </>
    );
};