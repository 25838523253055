import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';

import { useLazyGetIdentityQuery } from '../datasource/queries/identity';
import logger from '../utility/logger';
import { useAppSelector } from '.';

const SECONDS_TO_THROTTLE_AUTH_CHECK = 5;

export const useAuthCheck = () => {
    const user = useAppSelector((state) => state.user);
    const [getIdentity]  = useLazyGetIdentityQuery();

    const handleAuthCheck = useCallback(async () => {
        // groupcast users don't need to be signed in
        if (user.isGroupcast) return;

        try {
            const identity = await getIdentity().unwrap();

            if (!identity.isAuthorized)
                throw new Error('User not authorized');
            if (identity.individualSK != user.individualSK)
                throw new Error('User individualSK is not valid anymore');
        } catch (e) {
            logger.log('User signed out during playtime', {
                individualSK: user.individualSK,
                reason: (e as Error).message,
            });
            window.location.reload();
        }
    }, [getIdentity, user.individualSK, user.isGroupcast]);

    useEffect(() => {
        const throttledAuthCheck = throttle(
            handleAuthCheck,
            SECONDS_TO_THROTTLE_AUTH_CHECK * 1000,
        );

        let timeout: ReturnType<typeof setTimeout>;

        function handleVisibilityChange() {
            if (document.hidden) return;

            clearTimeout(timeout);
        
            throttledAuthCheck();
            timeout = setTimeout(() => {
                throttledAuthCheck.flush();
            }, SECONDS_TO_THROTTLE_AUTH_CHECK * 1000);
        }

        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        return () => {
            clearTimeout(timeout);
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, [handleAuthCheck]);

    return { handleAuthCheck };
};
