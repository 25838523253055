import '../../styles/_button.scss';
import '../../styles/_mobile-promotion.scss';

import React from 'react';

import { ReactComponent as PLILogo } from '../assets/PLILogo.svg';
import Button from '../Button/Button';
import { useMobilePromotionCurtain } from './MobilePromotionCurtain.hook';

export interface IMobilePromotionCurtainProps {
    onContinueWatching(): void;
}

export const MobilePromotionCurtain: React.FC<IMobilePromotionCurtainProps> = ({ onContinueWatching }) => {
    const { isLoading, header, body, button, background, continueWatching } = useMobilePromotionCurtain();

    function handleButtonClick() {
        window.location.replace(button.url + window.location.search);
    }

    function handleContinueWatching(e: React.MouseEvent) {
        e.preventDefault();
        onContinueWatching();
    }

    return (
        <div className="watch-mobile-promotion__background" style={{ backgroundColor: background?.color }}>
            <div className="watch-mobile-promotion" style={{ backgroundColor: background?.color }}>
                {!isLoading && (
                    <>
                        <div className="watch-mobile-promotion__image" style={{ backgroundImage: background?.image ? `url("${background.image}")` : undefined }} />

                        <div className="watch-mobile-promotion__content">
                            <div className="watch-mobile-promotion__header-icon">
                                <PLILogo width="46" height={null as unknown as undefined} />
                            </div>
                            <h2 className="watch-mobile-promotion__header heading-3">{header}</h2>
                            <div className="watch-mobile-promotion__body" dangerouslySetInnerHTML={body} />

                            <div className="watch-mobile-promotion__sticky-bottom">
                                <Button label={button?.label} icon="outbound-link" iconPosition="right" buttonClass="sticky-button" action={handleButtonClick} />
                                {continueWatching && (
                                    <a href={window.location.href} className="watch-mobile-promotion__continue-link" onClick={handleContinueWatching}>
                                        {continueWatching.label}
                                    </a>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
