import React, { useEffect } from 'react';

import { useAppSelector } from '../../hooks';
import { useCpeMarkers } from '../../hooks/useCpeMarkers';
import CpeDialog from '../CpeDialog/CpeDialog';


export const CpeDialogWrapper = () => {
    const { activeCpeMarker, dismissActiveCpeMarker, setCpeMarkersReady } = useCpeMarkers();
    const isLicenseReviewComplete = useAppSelector((state) => state.prePlayer.isLicenseReviewComplete);
    
    useEffect(() => {
        if (isLicenseReviewComplete) {
            setCpeMarkersReady();
        }
    }, [ isLicenseReviewComplete, setCpeMarkersReady]);

    return (
        <CpeDialog activeCpeMarker={activeCpeMarker}
            onClose={dismissActiveCpeMarker} />
    );
};

export default CpeDialogWrapper;