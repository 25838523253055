import { apiSlice } from '..';
import { ClientConfiguration } from '../generated';

const clientConfigSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClientConfig: builder.query<ClientConfiguration, void>({
            query: () => '/configuration'
        }),
    })
});

export const { useGetClientConfigQuery } = clientConfigSlice;
export default clientConfigSlice;