import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IndividualCreditAndLicenseInfo } from '../datasource/generated/models/IndividualCreditAndLicenseInfo';
import { useGetCreditJurisdictionsQuery } from '../datasource/queries/creditjurisdictions';
import { useGetLicensesQuery } from '../datasource/queries/licenses';
import { useGetProgramCreditDetailsQuery } from '../datasource/queries/programDetails';
import { IRequestCreditLicenseCardProps } from '../stories/RequestCreditLicenseCard/RequestCreditLicenseCard';
import { addErrorMessageElementId, removeErrorMessageElementId, setIsAddingLicense, setIsEditingLicense } from '../stories/Store/requestCreditSlice';
import { useAppDispatch, useAppSelector } from '.';
import { useLicenseInfoUrl } from './useLicenseInfoUrl';

export type RequestCreditLicenseError = 'no-licenses' | 'no-selected-licenses' | 'license-number' | 'not-passed-exam' | 'no-cpe-credit';

export type LicenseValidationDetails = {
    cardIndex: number,
    id: number,
};

export const LicenseErrorMessageElementId = 'request-credit-license-error';

export const useRequestCreditLicenses = () => {

    const dispatch = useAppDispatch();

    const itemPk = useAppSelector((state) => state.requestCredit.programPk);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const {
        data: userLicenses,
        isSuccess: isGetLicensesSuccess,
    } = useGetLicensesQuery(!encryptedRegistrationId ? skipToken : { encryptedRegistrationId });

    const {
        data: creditDetails,
        isSuccess: isGetCreditDetailsSuccess,
    } = useGetProgramCreditDetailsQuery(launchDetails?.onDemandItemPk ? launchDetails?.onDemandItemPk : itemPk ?? skipToken);

    const { 
        data: serviceCreditJurisdictions,
        isSuccess: isGetServiceCreditJurisdictionsSuccess
    } = useGetCreditJurisdictionsQuery();

    const disabledIndvLicenseSks = useAppSelector((state) => state.requestCredit.disabledIndvLicenseSks);

    const licenseNumberInfoUrl = useLicenseInfoUrl();

    const isLoaded = isGetLicensesSuccess && isGetCreditDetailsSuccess && isGetServiceCreditJurisdictionsSuccess;

    const onAddLicensePress = () => {
        dispatch(setIsAddingLicense());
    };

    const onEditLicensePress = useCallback((license: IndividualCreditAndLicenseInfo, jurisdictionName: string) => {
        dispatch(setIsEditingLicense({ license, jurisdictionName }));
    }, [dispatch]);

    const maxLicensesToShowInitially = 5;
    const showExpandButton = userLicenses && userLicenses?.length > maxLicensesToShowInitially;
    const [showAllLicenses, setShowAllLicenses] = useState(userLicenses && userLicenses.length <= maxLicensesToShowInitially);
    const userHasNoLicenses = userLicenses && userLicenses.length === 0;
    const headingText = !userHasNoLicenses ? 'Select license(s) to submit for' : 'You must have at least one license to submit';

    const onExpandLicensesPress = () => {
        setShowAllLicenses(!showAllLicenses);
    };

    const [showAddButtonAsCard, setShowAddButtonAsCard] = useState(false);
    const [showExpandButtonAsCard, setShowExpandButtonAsCard] = useState(false);

    useEffect(() => {
        if (!userLicenses) return;
        const numberOfLicensesCurrentlyVisible = showAllLicenses ? userLicenses.length : Math.min(userLicenses.length, maxLicensesToShowInitially);
        if (numberOfLicensesCurrentlyVisible % 2 !== 0) {
            if (showExpandButton) {
                // Odd number of visible license cards > 5; show expand button as card; show add button below
                setShowExpandButtonAsCard(true);
                setShowAddButtonAsCard(false);
            } else {
                // Odd number of license cards < 5; show add button with cards
                setShowAddButtonAsCard(true);
            }
        } else {
            // Even number of visible license cards; show both buttons below cards
            setShowExpandButtonAsCard(false);
            setShowAddButtonAsCard(false);
        }
    }, [showAllLicenses, showExpandButton, userLicenses]);

    const [errorState, setErrorState] = useState<RequestCreditLicenseError | undefined>(undefined);
    const [missingLicenseNumberDetails, setMissingLicenseNumberDetails] = useState<LicenseValidationDetails[]>([]);
    
    const requestCreditLicenseCardProps : IRequestCreditLicenseCardProps[] = useMemo(() => {
        if (!isLoaded) return [];
        setMissingLicenseNumberDetails([]);
        return userLicenses?.map((license, index) => {
            const jurisdictionName =
                creditDetails?.jurisdictions
                    ?.find(jurisdiction => jurisdiction.regionSk === license.creditRegion_SK)?.description
                    ?? '';
            
            const serviceCreditJurisdiction = serviceCreditJurisdictions
                ?.find(credit => credit.creditRegion_SK === license.creditRegion_SK);

            const licenseNoLabel = serviceCreditJurisdiction?.creditRegionLicenseNumberName ?
                serviceCreditJurisdiction?.creditRegionLicenseNumberName : 'License number';

            const isLicenseNoRequired = serviceCreditJurisdiction
                ?.isLicenseNumberRequired ?? false;

            const isLicenseEnabled = disabledIndvLicenseSks.findIndex(al => al === license.indvStateLicense_SK) === -1;

            if (isLicenseNoRequired && !license.stateLicenseNumber && isLicenseEnabled) {
                setMissingLicenseNumberDetails(prevState => [
                    ...prevState,
                    {
                        cardIndex: index,
                        id: license.indvStateLicense_SK ?? 0,
                    },
                ]);
            }

            return {
                elementId: (license.indvStateLicense_SK ?? 0).toString(),
                license: license,
                jurisdictionName: jurisdictionName,
                licenseNoLabel: licenseNoLabel,
                isLicenseNoRequired: isLicenseNoRequired,
                onEditLicensePress: onEditLicensePress,
                isEnabled: isLicenseEnabled,
            };
        }) ?? [];
    }, [creditDetails?.jurisdictions, disabledIndvLicenseSks, isLoaded, onEditLicensePress, serviceCreditJurisdictions, userLicenses]);
    
    const activeIndvLicenses = useMemo(
        () => userLicenses?.filter(l => !disabledIndvLicenseSks.some(d => d === l.indvStateLicense_SK)),
        [disabledIndvLicenseSks, userLicenses]);

    useEffect(() => {
        if (userLicenses && userLicenses.length === 0) {
            setErrorState('no-licenses');
        } else if (missingLicenseNumberDetails.length > 0) {
            setErrorState('license-number');
        } 
        else if (activeIndvLicenses && !activeIndvLicenses.length) {
            setErrorState('no-selected-licenses');
        } else {
            setErrorState(undefined);
        }
    }, [activeIndvLicenses, missingLicenseNumberDetails.length, userLicenses]);

    useEffect(() => {
        if (errorState) {
            dispatch(addErrorMessageElementId(LicenseErrorMessageElementId));
        } else {
            dispatch(removeErrorMessageElementId(LicenseErrorMessageElementId));
        }
    }, [dispatch, errorState]);

    return {
        isLoaded: isLoaded,
        headingText: headingText,
        maxLicensesToShowInitially: maxLicensesToShowInitially,
        showAllLicenses: showAllLicenses,
        showExpandButton: showExpandButton,
        showExpandButtonAsCard: showExpandButtonAsCard,
        showAddButtonAsCard: showAddButtonAsCard,
        licenseCardProps: requestCreditLicenseCardProps,
        error: errorState,
        missingLicenseNumberDetails: missingLicenseNumberDetails,
        licenseNumberInfoUrl: licenseNumberInfoUrl,
        onAddLicensePress: onAddLicensePress,
        onExpandLicensesPress: onExpandLicensesPress,
    };

};

export default useRequestCreditLicenses;