import { apiSlice } from '..';
import { Question } from '../generated';

const questionsSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getQuestions: build.query<Array<Question>, number>({
            query: (itemSk?: number) => `/questions?itemSk=${itemSk}`,
        }),
    }),
});

export const { useGetQuestionsQuery } = questionsSlice;
export default questionsSlice;