import { KeyboardEvent } from 'react';

export const trapFocus = (focusElement: Element, e: KeyboardEvent, focusCandidateString: string) => {
    const focusableChildren = e.currentTarget.querySelectorAll(focusCandidateString);
    if (focusableChildren.length > 0) {
        const first = focusableChildren[0];
        const last = focusableChildren[focusableChildren.length - 1];
        if (focusElement === first && e.shiftKey) {
            e.preventDefault();
            (last as HTMLElement).focus();
        } else if (focusElement === last && !e.shiftKey) {
            e.preventDefault();
            (first as HTMLElement).focus();
        }
    }
};