import '../../styles/_segments-panel.scss';

import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import { SegmentFormat } from '../../enums/SegmentFormat';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useProgramDetails } from '../../hooks/useProgramDetails';
import { useSegmentImages } from '../../hooks/useSegmentImages';
import { cacheImagesAsync, LIVE_STATIC_IMAGE_SRC, OD_STATIC_IMAGE_SRC } from '../../utility/imageUtils';
import { getSecondsFromRunningTimeString } from '../../utility/running-time';
import Dropdown, { IDropdownOption } from '../Dropdown/Dropdown';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import Segment, { ISegmentProps } from '../Segment/Segment';
import { pushSegmentThumbnailsCachedImages } from '../Store/playerSlice';

export type ISegmentsPanelProps = {
    title?: string;
    subtitle?: string;
    onPlaySegment(): void;
    onSeeDetails(): void;
};

export const SegmentsPanel = (props: ISegmentsPanelProps) => {
    const { title, subtitle, onPlaySegment, onSeeDetails } = props;
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { programDetails, isGetProgramDetailsError, isGetProgramDetailsSuccess } = useProgramDetails(launchDetails?.itemPk ?? '0');
    const dispatch = useAppDispatch();
    const didImagesFinishCaching = useAppSelector((state) => state.player.didSegmentThumbnailsFinishedCaching);
    const [selectedDate, setSelectedDate] = useState('');
    const { getSegmentCachedImageUrl, getSegmentImageUrl } = useSegmentImages();

    const dates = useMemo(() => {
        return programDetails?.dailySegments?.map<IDropdownOption>((dailySegment) => {
            return { label: dailySegment.date ?? '', value: dailySegment.date ?? '' };
        });
    }, [programDetails]);

    const segments: ISegmentProps[] = useMemo(function buildSegments() {
        const segments: ISegmentProps[] = [];

        if (launchDetails?.isWebcast) {
            segments.push(...programDetails?.dailySegments?.filter(d => d.date == selectedDate || selectedDate == '')[0]?.segments?.map<ISegmentProps>((segment) => {
                return {
                    itemSk: segment.programSegmentSk ?? 0,
                    prgSegmentSk: segment.programSegmentSk ?? 0,
                    format: SegmentFormat.LiveWebcast,
                    title: segment.title ?? '',
                    startTime: segment.startTime ?? '',
                    startTimeUtc: segment.startTimeUtc,
                    endTimeUtc: segment.endTimeUtc,
                    description: segment.description ?? '',
                    onPlaySegment: () => undefined,
                    onSeeDetails
                };
            }) ?? []);
        } else {
            segments.push(...launchDetails?.segments?.map<ISegmentProps>((segment, i) => {
                return {
                    itemSk: segment.itemSk ?? 0,
                    itemPk: segment.itemPk ?? undefined,
                    prgSegmentSk: segment.prgSegmentSk ?? 0,
                    segmentNumber: i + 1,
                    segmentsTotal: launchDetails?.segments?.length,
                    imageUrl: didImagesFinishCaching ? getSegmentCachedImageUrl(segment.itemSk) : getSegmentImageUrl(segment.itemSk),
                    format: SegmentFormat.OnDemand,
                    title: segment.title ?? '',
                    runTimeSeconds: getSecondsFromRunningTimeString(segment.producedRunningTime ?? '') || 1,
                    description: segment.description ?? '',
                    onPlaySegment,
                    onSeeDetails
                };
            }) ?? []);
        }

        return segments;
    }, [selectedDate, getSegmentCachedImageUrl, getSegmentImageUrl, didImagesFinishCaching, launchDetails?.segments, launchDetails?.isWebcast, onPlaySegment, onSeeDetails, programDetails?.dailySegments]);

    useEffect(() => {
        async function effect() {
            if (!didImagesFinishCaching) {
                const cachedImages = await cacheImagesAsync(segments.map(seg => getSegmentImageUrl(seg.itemSk)), launchDetails?.isWebcast ? LIVE_STATIC_IMAGE_SRC : OD_STATIC_IMAGE_SRC);
                dispatch(pushSegmentThumbnailsCachedImages(cachedImages));
            }
        }

        effect();
    }, [dispatch, getSegmentImageUrl, didImagesFinishCaching, segments, launchDetails?.isWebcast]);

    const filterLiveSegments = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedDate(event.currentTarget.selectedOptions.item(0)?.value ?? '');
    };

    useEffect(() => {
        const selectToday = (date: string) => {
            const now = new Date();
            const liveDate = new Date(date);

            if (now.getDay() == liveDate.getDay() && now.getMonth() == liveDate.getMonth() && now.getFullYear() == liveDate.getFullYear()) {
                setSelectedDate(date);
            }
        };

        setSelectedDate(dates ? dates[0].value : '');
        dates?.forEach(d => selectToday(d.value));
    }, [dates]);

    const dropdownRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            {!didImagesFinishCaching &&
                <Loader />}
            {isGetProgramDetailsError && <MainError errorContainer={ErrorContainer.SmallPanel} />}
            {didImagesFinishCaching && isGetProgramDetailsSuccess &&
                <>
                    <div className='small-panel__heading'>
                        <h3 className={'heading-5'}>{title}</h3>
                        <h4 className='sentence-label text--medium-grey mb-0'>{subtitle}</h4>
                    </div>
                    {
                        dates && dates?.length > 1 &&
                        <Dropdown
                            items={dates}
                            onChange={filterLiveSegments}
                            dropdownLabel={selectedDate}
                            setSelection={(selection: string) =>
                                setSelectedDate(selection ?? '')
                            }
                            small={true}
                            ref={dropdownRef}
                            ariaLabel='Select a date to filter segments'
                        />
                    }
                    <div className='segments-panel'>
                        {
                            segments && segments.map((segment) => {
                                return <Segment
                                    key={segment.itemSk}
                                    itemSk={segment.itemSk}
                                    itemPk={segment.itemPk}
                                    prgSegmentSk={segment.prgSegmentSk}
                                    segmentNumber={segment.segmentNumber}
                                    segmentsTotal={segment.segmentsTotal}
                                    title={segment.title}
                                    runTimeSeconds={segment.runTimeSeconds}
                                    format={segment.format}
                                    description={segment.description}
                                    imageUrl={segment.imageUrl}
                                    startTime={segment.startTime}
                                    startTimeUtc={segment.startTimeUtc}
                                    endTimeUtc={segment.endTimeUtc}
                                    onPlaySegment={segment.onPlaySegment}
                                    onSeeDetails={segment.onSeeDetails}
                                />;
                            })
                        }
                    </div>
                </>}
        </>
    );


};

export default SegmentsPanel;