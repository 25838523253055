import '../../styles/_credit-progress-saved.scss';

import React from 'react';

import { useGetCreditProgressMessagesQuery } from '../../datasource/queries/cmsMessages';

export const CreditProgressSaved = () => {

    const { data } = useGetCreditProgressMessagesQuery();
    
    const text = data?.creditProgressSavedMessage ?? 'Credit progress saved';

    return <span className='credit-progress-saved'>
        <i className='watch watch-checkmark'/>
        {text}
    </span>;
};

export default CreditProgressSaved;