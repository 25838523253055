import '../../../styles/_license-button.scss';

import React, { ButtonHTMLAttributes, forwardRef } from 'react';

export interface ILicenseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    mode: 'add' | 'expand' | 'collapse'
    action: () => void;
}

export const LicenseButton = forwardRef<HTMLButtonElement, ILicenseButtonProps>(({
    mode,
    action,
    ...otherProps
}: ILicenseButtonProps, ref) => {

    return(
        <button className='license-button' onClick={action} {...otherProps} ref={ref}>
            {mode === 'add' && 
                <span className='license-button--add'>
                    <i className='watch watch-add' />
                    <p className='paragraph-2 mb-0'>
                        Add a license
                    </p>
                </span>}
            {mode === 'expand' &&
                <p className='link-2 mb-0'>
                    See all
                </p>
            }
            {mode === 'collapse' &&
                <p className='link-2 mb-0'>
                    See less
                </p>
            }
        </button>
    );
});

LicenseButton.displayName = 'LicenseButton';