import type { PayloadAction } from '@reduxjs/toolkit';
import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';

import { MultimediaNotification } from '../../datasource/generated';
import launchSlice from '../../datasource/mutations/launch';
import logger from '../../utility/logger';
import hubConnection from '../../utility/signalR';
import { setItemSk } from './playerSlice';

export enum SignalREventTypes {
    Announcement = 100,
    DeleteAnnouncement = 101,
    ChangeStream = 102,
}

interface SetAnnouncementActionDetails extends MultimediaNotification { 
    type: SignalREventTypes.Announcement 
}
interface SetAnnouncementAction {
    name: string;
    program_sk: number;
    type: SignalREventTypes.Announcement;
    actions: SetAnnouncementActionDetails[];
}

interface DeleteAnnouncementAction {
    name: string;
    program_sk: number;
    type: SignalREventTypes.DeleteAnnouncement;
    actions: { notificationId: number, type: SignalREventTypes.DeleteAnnouncement }[];
}

interface ChangeStreamAction {
    name: string;
    program_sk: number;
    type: SignalREventTypes.ChangeStream;
    actions: { url: string, type: SignalREventTypes.ChangeStream }[];
}

type SignalREvent = SetAnnouncementAction | DeleteAnnouncementAction | ChangeStreamAction;

export interface SignalRState {
    events: SignalREvent[];
    lastEvent?: SignalREvent;
    itemSk: number;
}

const initialState: SignalRState = {
    events: [],
    itemSk: 0,
};

const signalRSlice = createSlice({
    name: 'signalr',
    initialState,
    reducers: {
        receiveRMessage:(state, action: PayloadAction<SignalREvent>) => {
            if (action.payload.program_sk !== state.itemSk) {
                // logger.log('SignalR slice received message not for this program', {
                //     itemSk: state.itemSk,
                //     message: action.payload,
                // });
                return;
            }

            // logger.log('SignalR slice received message for this program', {
            //     itemSk: state.itemSk,
            //     message: action.payload,
            // });

            state.events.push(action.payload);
            state.lastEvent = action.payload;
        },
        sendRMessage: (_, _payload: PayloadAction<{ message: string }>) => {
            // empty
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setItemSk, (state, action) => {
            state.itemSk = action.payload;
        });

        builder
            .addMatcher(
                launchSlice.endpoints.launch.matchFulfilled,
                (state, { payload }) => {
                    state.itemSk = payload.itemSk!;
                }
            );
    }
});

export const { receiveRMessage, sendRMessage } = signalRSlice.actions;

const signalRListener = createListenerMiddleware();

signalRListener.startListening({
    actionCreator: sendRMessage,
    async effect({ payload }) {
        try {
            await hubConnection.invoke('SendMediaMessage', payload.message);
        } catch (error) {
            logger.error('Could not send SignalR message', {
                error,
                payload,
            });            
        }
    }
});

export const { middleware: signalRListenerMiddleware } = signalRListener;

export const { reducer: signalRReducer } = signalRSlice;
