import { useEffect, useMemo, useState } from 'react';

import { LaunchSegment } from '../datasource/generated';
import { useGetCreditProgressMessagesQuery } from '../datasource/queries/cmsMessages';
import { IEndOfVideoProps } from '../stories/EndOfVideoPanel/EndOfVideoPanel';
import { useAppSelector } from '.';
import useCpeExams from './useCpeExams';

export const useEndOfVideo = () => {
    const user = useAppSelector((state) => state.user);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const { data } = useGetCreditProgressMessagesQuery();

    const isSingleSegment = (launchDetails.segments?.length ?? 0) <= 1;

    const [currentSegment, setCurrentSegment] = useState<LaunchSegment | undefined>();
    const [nextSegment, setNextSegment] = useState<LaunchSegment | undefined>();
    const [title, setTitle] = useState<string>('');

    const { exams, areExamsPassed } = useCpeExams();
    
    const programFormat = launchDetails.isWebcast 
        ? 'Live'
        : isSingleSegment ? 
            (exams?.length ? 'CPE Segment' : 'OD Single-Segment' )
            : 'OD Multi-Segment';

    const nonTitleCTA = programFormat === 'OD Multi-Segment' 
        ? data?.endOfODProgramCTA ?? 'This completes the program. You can now request credit.'
        : undefined;

    const autoplay = programFormat === 'OD Multi-Segment' ? !launchDetails.isPromoted : undefined;
    const result : IEndOfVideoProps = useMemo(() => {
        return {
            title: title,
            programFormat: programFormat,
            currentSegment: currentSegment,
            nextSegment: nextSegment,
            nonTitleCta: nonTitleCTA,
            autoplay: autoplay,
            buttonText: {
                requestCredit: data?.requestCreditButtonText ?? 'Request credit',
                takeCpeExam: data?.takeCPEExamButtonText ?? 'Take CPE exam',
                dontTakeCpeExam: data?.dontTakeCPEExamButtonText ?? 'I don’t need CPE credit',
            },
            areExamsPassed: (exams?.length ? areExamsPassed: true),
            isGroupcast: user.isGroupcast,
        };
    }, [autoplay, 
        currentSegment, 
        data?.dontTakeCPEExamButtonText, 
        data?.requestCreditButtonText, 
        data?.takeCPEExamButtonText, 
        nextSegment, 
        nonTitleCTA, 
        programFormat, 
        title,
        areExamsPassed,
        exams,
        user.isGroupcast,
    ]);

    useEffect(() => {
        const getCurrentSegment = () => {
            if (programFormat === 'Live')
                return undefined;
            if (programFormat === 'OD Single-Segment' || programFormat === 'CPE Segment') {
                let currentSegment : LaunchSegment = {};
                if (launchDetails.video) {
                    currentSegment.itemSk = launchDetails.itemSk;
                    currentSegment.itemPk = launchDetails.itemPk;
                    currentSegment.title = launchDetails.title;
                } else if (launchDetails.segments) {
                    currentSegment = launchDetails.segments[0];
                }
                return currentSegment;
            }
            if (programFormat === 'OD Multi-Segment') {
                return launchDetails.segments?.find(x => x.itemSk === currentSegmentItemSk);
            }
        };
        setCurrentSegment(getCurrentSegment());
    }, [programFormat, 
        launchDetails.video,
        launchDetails.segments,
        launchDetails.itemSk,
        launchDetails.itemPk,
        launchDetails.title,
        currentSegmentItemSk
    ]);

    useEffect(() => {
        const getNextSegment = () => {
            if (programFormat === 'Live' || programFormat === 'OD Single-Segment' || !launchDetails.segments)
                return undefined;

            const currentSegmentIndex = launchDetails.segments?.findIndex(x => x.itemSk === currentSegmentItemSk);
            if (currentSegmentIndex + 1 < launchDetails.segments.length)
                return launchDetails.segments[currentSegmentIndex + 1];
            else
                return undefined;
        };
        setNextSegment(getNextSegment());
    }, [programFormat, launchDetails.segments, currentSegmentItemSk]);

    useEffect(() => {
        const segmentFromProgramFinishedTitle = data?.endOfSegmentHeaderForFullProgram ?? 'You finished this segment';
        const singleSegmentFinishedTitle = data?.endOfSegmentHeaderForSingleSegment ?? 'All done. You can now request credit.';
        const liveProgramFinishedTitle = data?.endOfLiveProgramHeader ?? 'You’ve completed this program and can now request credit.';
        const cpeSegmentExamNotPassedTitle = data?.endOfSegmentCPEExamNotPassedHeader ?? 'You finished this segment. Now it’s time to take the exam.';
        const cpeSegmentExamPassedTitle = data?.endOfSegmentCPEExamPassedHeader ?? 'Congratulations, you passed the exam. You can now request credit.';
        const getTitle = () => {
            switch (programFormat) {
                case 'Live':
                    return liveProgramFinishedTitle;
                case 'OD Single-Segment':
                    return singleSegmentFinishedTitle;
                case 'OD Multi-Segment':
                    return segmentFromProgramFinishedTitle;
                case 'CPE Segment':
                    return areExamsPassed ? cpeSegmentExamPassedTitle : cpeSegmentExamNotPassedTitle;
            }
        };
        setTitle(getTitle());
    }, [data?.endOfSegmentHeaderForFullProgram,
        data?.endOfSegmentHeaderForSingleSegment,
        data?.endOfLiveProgramHeader,
        data?.endOfSegmentCPEExamPassedHeader,
        data?.endOfSegmentCPEExamNotPassedHeader,
        programFormat,
        areExamsPassed,
    ]);

    return result;
};

export default useEndOfVideo;