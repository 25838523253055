import '../../styles/_radio-input.scss';
import '../../styles/_radio-group.scss';

import React from 'react';

type RadioInputProps = Omit<JSX.IntrinsicElements['input'], 'type' | 'ref'> & {
    variant: 'default' | 'rating';
};

export const RadioInput = React.forwardRef<HTMLInputElement, RadioInputProps>(({ children, variant, ...props }, ref) => {
    
    return (
        <><label className={`radio-input radio-input--${variant}`}>
            <input
                ref={ref}
                type='radio'
                {...props} />
            {variant === 'default' && <span className='radio-input__input'></span>}
            <span className='radio-input__label'>
                {children}
            </span>
            <div className='radio-input__title'>
                {(props.title === 'Poor' || props.title === 'Excellent') ? (
                    <div>{props.title}</div>
                ) : null}
            </div>
        </label>
        </> 
    );
    
    
});
RadioInput.displayName='RadioInput';