import { apiSlice } from '..';
import { IndividualCreditAndLicenseInfo } from '../generated';

const licensesSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLicenses: builder.query<Array<IndividualCreditAndLicenseInfo>, { encryptedRegistrationId: string | undefined }>({
            query: ({ encryptedRegistrationId }) => `/licenses?encryptedRegistrationId=${encryptedRegistrationId}`,
            providesTags: ['Licenses']
        }),
    })
});

export const { useGetLicensesQuery } = licensesSlice;
export default licensesSlice;