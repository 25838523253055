import { apiSlice } from '..';
import { MultimediaEventLog, MultimediaEventLogResponse } from '../generated';

const mediaEventsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendMediaEventLogs: builder.mutation<MultimediaEventLogResponse[], MultimediaEventLog[]>({
            query: (body) => ({
                url: '/media/event-logs/batch',
                method: 'POST',
                body,
                keepalive: true,
            }),
        })
    })
});

export const { useSendMediaEventLogsMutation } = mediaEventsSlice;
export default mediaEventsSlice;