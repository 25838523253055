import '../../styles/_tooltip.scss';

import React, { PropsWithChildren, useState } from 'react';

export interface IToolTipProps extends PropsWithChildren {
    alignment?: 'left' | 'right';
    toolTipText: string;
}

export const ToolTip = (props: IToolTipProps) => {
    const {
        alignment,
        toolTipText,
        children
    } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    
    const alignmentClass = alignment === 'left' ? 'simple-tooltip--left' : alignment === 'right' ? 'simple-tooltip--right' : 'simple-tooltip--center';
    return (
        <div
            className='simple-tooltip__wrapper'
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {children}
            {showTooltip && (
                <div className={`simple-tooltip paragraph-2 ${alignmentClass}`}>
                    {toolTipText}
                </div>
            )}
        </div>
    );
};

export default ToolTip;
