import '../../styles/_settings-menu.scss';

import JWPlayer from '@jwplayer/jwplayer-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useAppSelector } from '../../hooks';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { IFormatSettings, IQualityLevels, usePlayerSettings } from '../../hooks/usePlayerSettings';
import { trapFocus } from '../../utility/trapFocus';
import BackButton from '../BackButton/BackButton';
import CloseButton from '../CloseButton/CloseButton';
import { IMenuItemData, MenuItem } from './MenuItem';

export type ISettingsMenuProps = {
    handleClose(): void;
    isOpen: boolean;
    playerRef: React.RefObject<typeof JWPlayer>;
    settingsButtonRef:
        | React.MutableRefObject<HTMLDivElement | undefined>
        | React.RefObject<HTMLDivElement | undefined>;
    hasCaptions: boolean;
    menuData: IMenuItemData[];
    qualitySettings: IQualityLevels | null;
    hasTranscripts: boolean;
    handleTranscriptSettings(transcriptSettings: IFormatSettings): void;
    transcriptSettings: IFormatSettings;
};

export const SettingsMenu = (props: ISettingsMenuProps) => {    
    const {
        handleClose,
        isOpen,
        playerRef,
        settingsButtonRef,
        hasCaptions,
        menuData,
        qualitySettings,
        hasTranscripts,
        handleTranscriptSettings,
        transcriptSettings
    } = props;
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const isLive = launchDetails?.isWebcast;

    const settingsContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
    const firstSelectionRef = useRef<HTMLButtonElement>(null);

    const { handleClickOutside } = useOutsideClickHandler(
        settingsContainerRef,
        handleClose,
        settingsButtonRef
    );

    const [currentMenu, setCurrentMenu] = useState<IMenuItemData[]>(menuData);
    const [parentMenu, setParentMenu] = useState<IMenuItemData[]>(menuData);
    const [currentMenuLevel, setCurrentMenuLevel] = useState(0);
    const [currentMenuLabel, setCurrentMenuLabel] = useState('Settings');
    const [isButtonFocus, setIsButtonFocus] = useState(false);

    const {
        setCaptionsSettings,
        captionsSettings,
    } = usePlayerSettings({
        playerRef: playerRef,
    });

    const showBackButton = currentMenuLevel !== 0;

    const getParentLabelOfChildMenu = useCallback(
        (child: IMenuItemData, nestedArray: IMenuItemData[]): string | null => {
            for (const item of nestedArray) {
                if (item.subMenuItems) {
                    if (
                        item.subMenuItems.some((c) => c.label === child.label)
                    ) {
                        return item.label;
                    }
                    const parentLabel = getParentLabelOfChildMenu(
                        child,
                        item.subMenuItems
                    );
                    if (parentLabel) {
                        return parentLabel;
                    }
                }
            }
            return null;
        },
        []
    );

    useEffect(() => {
        if (isOpen) {
            firstSelectionRef.current?.focus();
            setCurrentMenuLabel(
                getParentLabelOfChildMenu(currentMenu[0], menuData) ||
                    'Settings'
            );
        }
    }, [
        isOpen,
        currentMenuLevel,
        currentMenu,
        getParentLabelOfChildMenu,
        menuData,
    ]);

    const handleTrapFocus = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && document.activeElement) {
            trapFocus(
                document.activeElement,
                e,
                'button, [href], input[checked], select, textarea, [tabindex]:not([tabindex="-1"])'
            );
        }
        if (e.key === 'Escape') {
            handleClose();
        }
    };

    const handleGoBack = useCallback(() => {
        if (currentMenuLevel === 0) {
            return;
        }
        if (currentMenuLevel === 1) {
            setCurrentMenuLevel(0);
            setCurrentMenu(menuData);
        } else {
            setCurrentMenuLevel(currentMenuLevel - 1);
            if (parentMenu) {
                setCurrentMenu(parentMenu);
                const findParent = findParentMenuItem(
                    menuData,
                    parentMenu[0].label
                );
                findParent && setParentMenu(findParent);
            }
        }
    }, [currentMenuLevel, parentMenu, menuData]);

    const handleCloseButtonKeyUp = useCallback(
        (e: React.KeyboardEvent) => {
            const code = e.code;
            if (code === 'Space') {
                handleClose();
            }
        },
        [handleClose]
    );

    const handleBackButtonKeyUp = useCallback(
        (e: React.KeyboardEvent) => {
            const code = e.code;
            if (code === 'Space') {
                handleGoBack();
            }
        },
        [handleGoBack]
    );

    useEffect(() => {
        const containerRef = settingsContainerRef.current;
        containerRef?.addEventListener('mouseup', handleClickOutside);

        return () => {
            () =>
                containerRef?.removeEventListener(
                    'mouseup',
                    handleClickOutside
                );
        };
    }, [handleClickOutside]);

    const findParentMenuItem = (menuItems: IMenuItemData[], label: string) => {
        let parent = null;
        const search = (arr: IMenuItemData[]) => {
            for (const item of arr) {
                if (item.label === label) {
                    parent = arr;
                }
                if (item.subMenuItems) {
                    search(item.subMenuItems);
                }
            }
        };
        search(menuItems);
        return parent;
    };

    const handleMenuItemClick = useCallback(
        (label: string, index?: number) => {
            const hasSubMenu = currentMenu
                .filter((x) => x.label === label)
                .some((x) => x.subMenuItems);
            if (currentMenuLevel === 0 && !hasSubMenu) {
                return;
            } else if (currentMenuLevel !== 0 && !hasSubMenu) {
                const parent = getParentLabelOfChildMenu(
                    parentMenu[0],
                    menuData
                );
                switch (currentMenuLevel === 1 ? currentMenuLabel : parent) {
                    case 'Captions':
                        if (label === 'Light' || label === 'Dark') {
                            setCaptionsSettings({
                                ...captionsSettings,
                                darkBackground: label,
                            });
                        } else if (
                            label === 'XLarge' ||
                            label === 'Large' ||
                            label === 'Medium' ||
                            label === 'Small' ||
                            label === 'XSmall'
                        ) {
                            setCaptionsSettings({
                                ...captionsSettings,
                                fontSize: label,
                            });
                        }
                        break;
                    case 'Transcript':
                        if (label === 'Light' || label === 'Dark') {
                            handleTranscriptSettings({
                                ...transcriptSettings,
                                darkBackground: label,
                            });
                        } else if (
                            label === 'Large' ||
                            label === 'Medium' ||
                            label === 'Small' ||
                            label === 'XSmall'
                        ) {
                            handleTranscriptSettings({
                                ...transcriptSettings,
                                fontSize: label,
                            });
                        } else if (
                            label === '75%' ||
                            label === '50%' ||
                            label === '100%' ||
                            label === '125%' ||
                            label === '150%'
                        ) {
                            handleTranscriptSettings({
                                ...transcriptSettings,
                                lineSpacing: label,
                            });
                        }
                        break;
                    case 'Quality':
                        playerRef.current.setCurrentQuality(index);
                        break;
                }
            } else {
                setCurrentMenuLevel(currentMenuLevel + 1);
                const selectedChild = currentMenu.find(
                    (item) => item.label === label
                );
                if (selectedChild && selectedChild.subMenuItems) {
                    setParentMenu(currentMenu);
                    setCurrentMenu(selectedChild.subMenuItems);
                }
            }
        },
        [
            playerRef,
            captionsSettings,
            currentMenu,
            currentMenuLevel,
            currentMenuLabel,
            setCaptionsSettings,
            getParentLabelOfChildMenu,
            parentMenu,
            menuData,
            handleTranscriptSettings,
            transcriptSettings
        ]
    );

    const renderMenuItems = useMemo(() => {
        const menu = isLive
            ? currentMenu.filter((x) => !hasCaptions && x.label !== 'Captions' && x.label !== 'Transcript')
            : currentMenu.filter((x) =>
                !hasCaptions
                    ? x.label !== 'Captions' && x.label !== 'Quality'
                    : !hasTranscripts ? x.label !== 'Transcript' && x.label !== 'Quality'
                        : x.label !== 'Quality'
            );

        return menu.map((menuItem, i) => {
            const parent = getParentLabelOfChildMenu(
                parentMenu[0],
                menuData
            );

            const handleSelectedOption: string[] =
                 qualitySettings &&
                      (currentMenuLabel === 'Quality' ||
                          menuItem.label === 'Quality')
                     ? [
                         qualitySettings.levels[
                             qualitySettings?.currentQuality
                         ].label,
                     ]
                     : Object.values(currentMenuLabel === 'Transcript' || menuItem.label === 'Transcript' ||
                        parent === 'Transcript' ? transcriptSettings : captionsSettings).map(String);
            return (
                <MenuItem
                    key={i}
                    label={menuItem.label}
                    subMenuItems={menuItem.subMenuItems}
                    handleMenuItemClick={handleMenuItemClick}
                    selectedOptions={handleSelectedOption}
                    ref={i === 0 ? firstSelectionRef : undefined}
                    index={i}
                    onFocus={() => setIsButtonFocus(true)}
                    onBlur={() => setIsButtonFocus(false)}
                />
            );
        });
    }, [
        captionsSettings,
        currentMenu,
        handleMenuItemClick,
        isLive,
        hasCaptions,
        currentMenuLabel,
        qualitySettings,
        transcriptSettings,
        getParentLabelOfChildMenu,
        menuData,
        parentMenu,
        hasTranscripts
    ]);

    useEffect(() => {
        // Prevents keyboard controls on menuItems firing JWPlayer play/pause keyboard shortcuts
        const jwPlayerControls = document.querySelectorAll('.jwplayer');
        const preventJwKeyEvent = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter' || code === 'ArrowUp' || code === 'ArrowDown') {
                e.stopPropagation();
            }
        };
        if (isButtonFocus) {
            jwPlayerControls.forEach(elem => {
                elem.addEventListener('keydown', preventJwKeyEvent, true);
            });
        }

        return () => {
            jwPlayerControls.forEach(elem => {
                elem.removeEventListener('keydown', preventJwKeyEvent, true);
            });
        };
    }, [isButtonFocus]);

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className='settings-menu'
            ref={settingsContainerRef}
            onKeyDown={handleTrapFocus}
        >
            <div
                className={`settings-menu__nav ${
                    showBackButton ? 'settings-menu__nav--has-back-btn' : ''
                }`}
            >
                {showBackButton && (
                    <BackButton
                        onClick={handleGoBack}
                        aria-label={'Return to settings menu'}
                        onFocus={() => setIsButtonFocus(true)}
                        onBlur={() => setIsButtonFocus(false)}
                        onKeyUp={handleBackButtonKeyUp}
                        buttonClass='back-button'
                    />
                )}
                <span className='settings-menu__title paragraph-1'>
                    {currentMenuLabel}
                </span>
                <CloseButton
                    onClick={handleClose}
                    onFocus={() => setIsButtonFocus(true)}
                    onBlur={() => setIsButtonFocus(false)}
                    onKeyUp={handleCloseButtonKeyUp}
                    buttonClass='close-button'
                />
            </div>

            <ul role='listbox' className='settings-menu__options'>
                {renderMenuItems}
            </ul>
        </div>
    );
};

export default SettingsMenu;
