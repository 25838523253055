import React, { forwardRef } from 'react';


export interface IAudioProps {
    file: string;
}

export const Audio = forwardRef<HTMLAudioElement, IAudioProps>(({
    file
} : IAudioProps, ref) => {

    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <audio src={file} preload='auto' ref={ref}/>;
});

Audio.displayName = 'Audio';

export default Audio;