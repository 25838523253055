import {
    createRouterMiddleware,
    createRouterReducer,
} from '@lagunovsky/redux-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { enhancer as withReduxEnhancer } from 'addon-redux';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import invariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

import { apiSlice } from '../../datasource';
import { ApplicationState, reducers } from './';
import { signalRListenerMiddleware } from './signalRSlice';

export const history = createBrowserHistory();

function configureAppStore(initialState?: ApplicationState) {
    const routerMiddleware = createRouterMiddleware(history);
    const reduxReducer = createRouterReducer(history);
    const rootReducer = combineReducers({
        ...reducers,
        router: reduxReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    });

    const store = configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            process.env.NODE_ENV === 'production'
                ? getDefaultMiddleware()
                    .prepend(routerMiddleware)
                    .concat(thunk)
                    .concat(apiSlice.middleware)
                    .concat([signalRListenerMiddleware])
                : getDefaultMiddleware()
                    .prepend(routerMiddleware)
                    .concat(thunk)
                    .concat(apiSlice.middleware)
                    .concat([signalRListenerMiddleware])
                    .concat(invariant()),
        devTools: process.env.NODE_ENV !== 'production',
        enhancers: [withReduxEnhancer],
    });

    return store;
}

export const store = configureAppStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred types from the configured store
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
