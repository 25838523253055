import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface BookmarkState {
  userNotes: string;
}

const initialState: BookmarkState = {
    userNotes: '',
};

const bookmarkSlice = createSlice({
    name: 'bookmark',
    initialState,
    reducers: {
        setUserNotes(state, action: PayloadAction<string>) {
            state.userNotes = action.payload;
        },
    },
});

export const { setUserNotes } = bookmarkSlice.actions;

export const { reducer: bookmarkReducer } = bookmarkSlice;
