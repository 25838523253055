import '../../styles/_credit-submission-confirmation-panel.scss';

import React from 'react';

import { useRequestCredit } from '../../hooks/useRequestCredit';
import Button from '../Button/Button';

export interface ICreditSubmissionConfimrationPanel {
    isStandalone?: boolean,
}

export const CreditSubmissionConfimrationPanel = ({ isStandalone }: ICreditSubmissionConfimrationPanel) => {
    const { messages } = useRequestCredit();
    const className = isStandalone? '--standalone' : '';

    return (
        <div className={`watch-modal__content credit-submission-confirmation-panel${className}`}>
            <div className='credit-submission-confirmation-panel__heading-container'>
                <h3 className='heading-4 mb-0'>{messages.confirmationTitle}</h3>
                <p className='text--medium-grey paragraph-1 mb-0'>{messages.confirmationBody}</p>
            </div>
            <Button
                icon='outbound-link'
                iconPosition='right'
                iconClass='ml-2'
                label={messages.confirmationButtonText}
                aria-label={messages.confirmationButtonText}
                buttonClass='credit-submission-confirmation-panel__button paragraph-1--bold'
                action={() =>
                    window.open(
                        messages.confirmationButtonUrl as string,
                        '_blank'
                    )
                }
            />
        </div>
    );
};

export default CreditSubmissionConfimrationPanel;