import { apiSlice } from '..';
import { Bookmark } from '../generated';

const bookmarksSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBookmarksByItemSk: builder.query<Bookmark[], number>({
            query: (itemSk: number) => `/bookmarks?itemSk=${itemSk}`,
            keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
            providesTags: ['Bookmarks']
        })
    })
});

export const { useGetBookmarksByItemSkQuery } = bookmarksSlice;
export default bookmarksSlice;
