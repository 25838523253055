import React, { ButtonHTMLAttributes, forwardRef } from 'react';

export interface IMenuItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    subMenuItems?: IMenuItemData[];
    handleMenuItemClick: (label: string, index?: number) => void;
    selectedOptions?: string[] | undefined;
    index?: number;
}

export interface IMenuItemData {
    label: string;
    subMenuItems?: IMenuItemData[];
}

export const MenuItem = forwardRef<HTMLButtonElement, IMenuItemProps>(
    (props: IMenuItemProps, ref) => {
        const { label, subMenuItems, handleMenuItemClick, selectedOptions, index, ...otherProps } =
            props;

        const matches: string[] = [];

        function checkForSelectedOptions(
            subArr: IMenuItemData[],
            options: string[]
        ) {
            for (let i = 0; i < subArr.length; i++) {
                const current = subArr[i];

                // If the current element has subMenuItems, recursively search them
                if (current.subMenuItems && current.subMenuItems.length > 0) {
                    checkForSelectedOptions(current.subMenuItems, [
                        ...options,
                        current.label,
                    ]);
                }

                // Check the current element for a match based on the options
                if (selectedOptions?.includes(current.label)) {
                    matches.push(current.label);
                }
            }
            return matches;
        }

        const handleKeyUp = (e: React.KeyboardEvent) => {
            if (e.code === 'Space') {
                handleMenuItemClick(label, index);
            }
        };

        const result: string[] | undefined =
            subMenuItems &&
            selectedOptions &&
            checkForSelectedOptions(subMenuItems, selectedOptions);

        const isSelected = selectedOptions?.some((option) => option === label);

        return (
            <li className='settings-menu__menu-item'>
                <button
                    role='option'
                    aria-selected={isSelected}
                    onClick={() => handleMenuItemClick(label, index)}
                    onKeyUp={handleKeyUp}
                    className={`settings-menu__option paragraph-1 ${
                        isSelected ? 'settings-menu__option--selected' : ''
                    }`}
                    ref={ref}
                    aria-label={`${label} ${isSelected ? 'selected' : ''}`}
                    {...otherProps}
                >
                    {label}
                    {subMenuItems && (
                        <span className='settings-menu__option__submenu'>
                            <span className='text--medium-grey'>
                                {result && result?.length > 1
                                    ? result?.join(' / ')
                                    : result}
                            </span>
                            <i className='watch watch-forward-arrow ml-2' />
                        </span>
                    )}
                    {isSelected && <i className='watch watch-checkmark' />}
                </button>
            </li>
        );
    }
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
