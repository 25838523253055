import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useConnectivity } from '../contexts/ConnectivityContext';
import { MultimediaEventLog } from '../datasource/generated';
import { useSendMediaEventLogsMutation } from '../datasource/mutations/mediaEvents';
import logger from '../utility/logger';
import { useAppSelector } from '.';

export enum MediaLogEventTypes {
    PROGRAM_LAUNCH = 4,
    START_VIDEO = 5,
    PLAYER_SELECTED = 6,
    ERROR = 10,
    BUFFERING = 21,
    CONNECTION_LOST = 27,
    CONNECTION_RESTORED = 28,
}

export const useLogMediaEvents = () => {
    const { connected } = useConnectivity();
    const connectedRef = useRef(connected);

    const [sendMediaEventLogs] = useSendMediaEventLogsMutation();

    const launchDetails = useAppSelector(state => state.player.launchDetails);
    const sessionGuid = useAppSelector((state) => state.cle.sessionGuid);

    const deferredLogs = useRef(new Array<MultimediaEventLog>());

    const persistEventLog = useCallback(async (event: MultimediaEventLog) => {
        try {
            const result = await sendMediaEventLogs([event]).unwrap();

            const anyFailed = result.some(it => !it.isSuccess);

            if (anyFailed)
                throw new Error('Media event log has failed');
        } catch (error) {
            logger.error('Error trying to send media event log', {
                event,
                error
            });
        }
    }, [sendMediaEventLogs]);

    const enqueueEventLog = useCallback(async (event: MultimediaEventLog) => {
        if (!connectedRef.current) {
            deferredLogs.current.push(event);
            return;
        }

        return persistEventLog(event);
    }, [persistEventLog]);

    const logMediaEvent = useCallback(async (eventType: MediaLogEventTypes, eventMessage: string, timestamp?: number, uniqueEventType = false) => {
        timestamp ??= Date.now();

        if (uniqueEventType && deferredLogs.current.some(ev => ev.eventType))
            return;

        const eventData: MultimediaEventLog = {
            encryptedRegistrationId: launchDetails.encryptedRegistrationId,
            eventType: eventType,
            eventMsg: eventMessage,
            indexGuid: uuidv4(),
            sessionGuid,
            isRecovered: false,
            utcTimeStamp: Math.floor(timestamp / 1000),
        };

        await enqueueEventLog(eventData);
    }, [enqueueEventLog, launchDetails.encryptedRegistrationId, sessionGuid]);

    useEffect(() => {
        connectedRef.current = connected;
        if (!connected) return;

        if (!deferredLogs.current.length) return;

        let event: MultimediaEventLog | undefined;
        // retry offline events when reconnected
        // eslint-disable-next-line no-cond-assign
        while(event = deferredLogs.current.shift()) {
            persistEventLog({ ...event, isRecovered: true });
        }
    }, [connected, persistEventLog]);

    return { logMediaEvent };
};
