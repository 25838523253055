import { useCallback, useEffect, useRef } from 'react';

import { useSendEventsMutation } from '../datasource/mutations/eventHub';
import { useLazyGetEventHubSASTokenQuery } from '../datasource/queries/eventHub';
import { useAppSelector } from '../hooks';

const intervalInSeconds = 5;
const extractSigValue = (inputString: string) => {
    if (inputString == null) return null;
    const match = inputString.match(/sig=([^&]+)/);
    return match ? match[1] : null;
};

// Send events to EventHubs reading window.eventHubDataLayer array every N seconds
export const useEventHubDataLayerConsumer = () => {  
    const lastIndexProcessed = useRef<number>(0);

    const [sendEvents] = useSendEventsMutation();
    const [getEventHubSASToken] = useLazyGetEventHubSASTokenQuery();
    const sessionGuid = useAppSelector((state) => state.cle.sessionGuid);
    
    const processEvents = useCallback(async () => {
        // console.debug('Calling useEventHubDataLayerConsumer');
        const currentLastIndex = window.eventHubDataLayer?.length;
        if (currentLastIndex > 0) {        
            const eventsToBeProcessed = window.eventHubDataLayer.slice(lastIndexProcessed.current, currentLastIndex) ?? [];
            if (eventsToBeProcessed.length > 0)
            {           
                try {
                    // console.debug(`Consuming window.eventHubDataLayer. Sending ${eventsToBeProcessed.length} event(s) to eventHub...`);
                    const sasToken = await getEventHubSASToken().unwrap();
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const analyticsEventHash = extractSigValue(sasToken.value!) ?? null;          
                    const analyticsSentEventTimestamp = new Date().getTime();               
                    const jsonEventsToBeProcessed = () => eventsToBeProcessed
                        .map(function (event) {
                            const newEvent = { ...event, analytics_event_hash: analyticsEventHash, analytics_sent_event_timestamp: analyticsSentEventTimestamp, sessionGuid: sessionGuid };
                            const jsonEvent = JSON.stringify(newEvent);               
                        
                            return jsonEvent;
                        });
                    const response = await sendEvents(jsonEventsToBeProcessed()).unwrap();             
                    if (response.dataSentOK) {
                        lastIndexProcessed.current = currentLastIndex;
                        return;
                    }
                    //console.warn(response);          
                } catch (error) {
                    // Handle error if needed on client. Errors and logging were handled on the backend
                    // console.warn(error);
                }     
            }
        }
    }, [getEventHubSASToken, sendEvents, sessionGuid]);

    useEffect(() => {
        const timerInterval = setInterval(async () => {
            processEvents();
        }, intervalInSeconds * 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [processEvents]);

    return {
        processEvents,
    };
};
