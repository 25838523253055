import DOMPurify from 'dompurify';
import { useMemo } from 'react';

import { useGetMobilePromotionQuery } from '../../datasource/queries/cmsMessages';
import platform from '../../utility/platform';

const sanitizer = DOMPurify.sanitize;

export function useMobilePromotionCurtain() {
    const canWatchOnWeb = useMemo(() => !['iPhone', 'iPod'].includes(platform.deviceType()),[]);

    const { data: mobilePromotion, isLoading } = useGetMobilePromotionQuery();

    const bodyText = useMemo(() => sanitizer(mobilePromotion?.bodyText ?? ''), [mobilePromotion?.bodyText]);

    return {
        isLoading,
        header: mobilePromotion?.header,
        body: { __html: bodyText },
        button: {
            label: mobilePromotion?.downloadButtonLabel ?? 'Open in the App',
            url: mobilePromotion?.mobileAppLaunchPageUrl ?? '/',
        },
        background: {
            color: mobilePromotion?.backgroundColor ?? '#000000',
            image: mobilePromotion?.backgroundImage,
        },
        continueWatching: canWatchOnWeb ? {
            label: mobilePromotion?.continueWatchingLabel,
        } : null,
    };
}