import '../../../../styles/_tutorial-animation.scss';

import { motion } from 'framer-motion';
import React from 'react';

import { ReactComponent as VolumeIcon } from './volume-max.svg';

const AuthCodeSVG: React.FC = (
) => {

    return (
        <div className='tutorial-animation__auth-code'>
            <svg width="300" height="300" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Audio announcing authorization code demostration</title>
                <g id="circles">
                    <motion.circle 
                        id="Ellipse 1693" 
                        className="outer-circle" 
                        animate={{ 
                            scale: [1, 1.3, 1],
                        }}
                        transition={{
                            duration: 2,
                            ease: 'easeOut',
                            times: [0, .5, 1],
                            repeat: 2,
                            delay: 2,
                            repeatDelay: 2,
                        }}
                        opacity="0.05" 
                        cx="75" 
                        cy="75" 
                        r="37.5" 
                        fill="white"/>
                    <motion.ellipse 
                        id="Ellipse 1693_2" 
                        className="inner-circle" 
                        animate={{ 
                            scale: [1, 1.15, 1],
                        }}
                        transition={{
                            duration: 2,
                            ease: 'easeOut',
                            times: [0, .5, 1],
                            repeat: 2,
                            delay: 2,
                            repeatDelay: 2,
                        }}
                        opacity="0.1" 
                        cx="74.9993" 
                        cy="74.9998" 
                        rx="31.6666"
                        ry="31.6666"
                        fill="white"/>
                    <circle 
                        id="Ellipse 1693_3" 
                        opacity="0.2"
                        cx="75"
                        cy="75.0002"
                        r="25" 
                        fill="white"/>
                </g>
            </svg>
            <VolumeIcon className='tutorial-animation__volume-icon'/>
        </div>
    );
};

export default AuthCodeSVG;
