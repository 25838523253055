import '../../styles/_loader.scss';

import React from 'react';


export enum LoaderSizes {
    Responsive,
    Small,
    Medium,
    Large
}

export interface ILoaderProps {
    size?: LoaderSizes;
    showBackdrop?: boolean;
    solidBackground?: boolean
}

export const Loader = (props: ILoaderProps) => {

    const { size = LoaderSizes.Responsive, showBackdrop = true, solidBackground = true } = props;

    const loaderSizeClassMap = {
        [LoaderSizes.Responsive]: '',
        [LoaderSizes.Small]: ' loader__spinner--sm',
        [LoaderSizes.Medium]: ' loader__spinner--md',
        [LoaderSizes.Large]: ' loader__spinner--lg',
    };

    const spinnerClassName = loaderSizeClassMap[size];

    const spinner = <div className={`loader__spinner${spinnerClassName}`} />;

    return (
        <>
            {showBackdrop && (
                <div className={`loader ${solidBackground ? 'loader__solid-background' : ''} d-flex align-items-center justify-content-center`}
                    role='img'
                    aria-label='Loading' 
                >
                    {spinner}
                </div>
            )}
            {!showBackdrop && spinner}
        </>
    );
};

export default Loader;