import '../../styles/_tutorial.scss';
import '../../styles/_modal.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';

import { useGetTutorialsQuery } from '../../datasource/queries/tutorials';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useDownload from '../../hooks/useDownload';
import Button from '../Button/Button';
import { NavBar } from '../NavBar/NavBar';
import { setShowDownloadTutorial } from '../Store/downloadSlice';
import {
    setIsLicenseReviewComplete,
    setIsTutorialComplete,
} from '../Store/prePlayerSlice';
import GroupcastAnimation from './Animation/GroupcastAnimation';
import Mp3Mp4Animation from './Animation/Mp3Mp4Animation';
import VerifyAttendanceAnimation from './Animation/VerifyAttendanceAnimation';
import { GroupcastTutorialSteps } from './GroupcastTutorial';
import { Mp3DownloadTutorialSteps } from './Mp3DownloadTutorial';
import { Mp3TutorialSteps } from './Mp3Tutorial';
import { Mp4DownloadTutorialSteps } from './Mp4DownloadTutorial';
import { Mp4TutorialSteps } from './Mp4Tutorial';
import { VerifyAttendanceTutorialSteps } from './VerifyAttendanceTutorial';

export interface IStepInformation {
    headerText: string;
    bodyText: string;
}

export enum TutorialType {
    VerifyAttendanceTutorial = 'VerifyAttendanceTutorial',
    GroupcastTutorial = 'GroupcastTutorial',
    Mp3Tutorial = 'Mp3Tutorial',
    Mp4Tutorial = 'Mp4Tutorial',
    Mp3DownloadTutorial = 'Mp3DownloadTutorial',
    Mp4DownloadTutorial = 'Mp4DownloadTutorial',
}

export const tutorialTypeCookiesMap = {
    [TutorialType.VerifyAttendanceTutorial]: 'verifyAttendanceTutorialCookie',
    [TutorialType.GroupcastTutorial]: 'groupcastTutorialCookie',
    [TutorialType.Mp3Tutorial]: 'mp3TutorialCookie',
    [TutorialType.Mp4Tutorial]: 'mp4TutorialCookie',
    [TutorialType.Mp3DownloadTutorial]: '',
    [TutorialType.Mp4DownloadTutorial]: '',
};

export interface ITutorialProps {
    type: TutorialType;
    onTutorialFinish: () => void;
    useContentFromApi: boolean;
    modalAppElement?: string;
}

export const Tutorial = ({
    type,
    onTutorialFinish,
    useContentFromApi,
    modalAppElement,
}: ITutorialProps) => {
    const dispatch = useAppDispatch();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { data: tutorials } = useGetTutorialsQuery(!useContentFromApi ? skipToken : undefined);
    const showPreplayerTutorial = useAppSelector((state) => state.prePlayer.showTutorial.find(x => x.tutorialType == type)?.showTutorial) ?? true;
    const isPreplayerTutorialComplete = useAppSelector((state) => state.prePlayer.isTutorialComplete);
    const showDownloadTutorial = useAppSelector((state) => state.download.showDownloadTutorial);
    const { handleDownloadTutorialFinish } = useDownload();

    const cookieOptions = useMemo(() => ({
        sameSite: 'strict',
        expires: 180
    }), []);

    useEffect(() => {
        dispatch(setIsTutorialComplete(!showPreplayerTutorial));
    }, [dispatch, showPreplayerTutorial]);

    const buildTutorialSteps = useCallback((type: TutorialType) => {
        const tutorialTypeStepsMap = {
            [TutorialType.VerifyAttendanceTutorial]: VerifyAttendanceTutorialSteps, 
            [TutorialType.GroupcastTutorial]: GroupcastTutorialSteps,
            [TutorialType.Mp3Tutorial]: Mp3TutorialSteps,
            [TutorialType.Mp4Tutorial]: Mp4TutorialSteps,
            [TutorialType.Mp3DownloadTutorial]: Mp3DownloadTutorialSteps,
            [TutorialType.Mp4DownloadTutorial]: Mp4DownloadTutorialSteps,
        };
        
        if (useContentFromApi) {

            const tutorialStepsFromApi = tutorials?.find(t => t.name == type)?.tutorialItems;

            if (tutorialStepsFromApi) {
                const tutorialStepsWithContentOverride: IStepInformation[] = [];
                tutorialTypeStepsMap[type].forEach((step, index) => {
                    if (index < tutorialStepsFromApi.length) {
                        tutorialStepsWithContentOverride.push({
                            ...step,
                            headerText: tutorialStepsFromApi[index].headerText ?? step.headerText,
                            bodyText: tutorialStepsFromApi[index].bodyText ?? step.bodyText,
                        });
                    } else {
                        tutorialStepsWithContentOverride.push(step);
                    }
                });
                return tutorialStepsWithContentOverride;
            }
        }
        return tutorialTypeStepsMap[type];
    }, [useContentFromApi, tutorials]);

    const downloadTutorialType = launchDetails.isPortable && launchDetails.isAudioOnly ?
        TutorialType.Mp3DownloadTutorial :
        TutorialType.Mp4DownloadTutorial;
    const steps = buildTutorialSteps(showDownloadTutorial ? downloadTutorialType : type);

    const [stepIndex, setStepIndex] = useState(0);

    const isLastStep = useMemo(() => {
        return stepIndex === steps?.length - 1;
    }, [steps, stepIndex]);

    const isFirstStep = useMemo(() => {
        return stepIndex === 0;
    }, [stepIndex]);

    const nextStep = useCallback(() => {
        const setCookie = () => {
            if (!showDownloadTutorial && type !== TutorialType.GroupcastTutorial) {
                Cookies.set(tutorialTypeCookiesMap[type], new Date().toDateString(), cookieOptions);
            }
        };

        if (!isLastStep)
            setStepIndex(stepIndex + 1);
        else {
            type === TutorialType.GroupcastTutorial && dispatch(setIsLicenseReviewComplete(true));
            dispatch(setIsTutorialComplete(true));
            showDownloadTutorial ? handleDownloadTutorialFinish() : onTutorialFinish();
            setCookie();
            setStepIndex(0);
            dispatch(setShowDownloadTutorial(false));
        }
    }, [isLastStep, stepIndex, showDownloadTutorial, type, cookieOptions, dispatch, handleDownloadTutorialFinish, onTutorialFinish]);

    const prevStep = useCallback(() => {
        if (!isFirstStep) {
            setStepIndex(stepIndex - 1);
        }
    }, [stepIndex, isFirstStep]);

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') {
            prevStep();
        }
        if (e.key === 'ArrowRight') {
            nextStep();
        }
    }, [nextStep, prevStep]);

    useEffect(() => {
        if (!isPreplayerTutorialComplete) {
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [handleKeyDown, isPreplayerTutorialComplete]);

    const stickyButtonLabels = useMemo(() => {
        const nextButtonLabels = {
            label: 'Next', ariaLabel: 'Next step',
        };
        const downloadButtonLabels = {
            label: 'Download', ariaLabel: 'Download media',
        };
        const startWatchingButtonLabels = {
            label: 'Start watching', ariaLabel: 'Start watching',
        };

        if (showDownloadTutorial) return downloadButtonLabels;
        if (type === TutorialType.GroupcastTutorial && isLastStep) return startWatchingButtonLabels;
        return nextButtonLabels;
    }, [isLastStep, showDownloadTutorial, type]);

    const navBackButtonAriaLabel = 'Return to previous step';
    const navCloseButtonAriaLabel = 'Close';

    ReactModal.setAppElement(modalAppElement ?? '#root');
    if (!steps?.length) return <></>;
    return (

        <ReactModal
            isOpen={!isPreplayerTutorialComplete || showDownloadTutorial} 
            key={stepIndex}
            className={'watch-modal tutorial-modal'}
            overlayClassName={'watch-modal__overlay'} >

            <div className={'watch-modal__nav'}>
                <NavBar.Root mode={showDownloadTutorial ? 'close' : 'back'} containerMode='modal'>
                    {!isFirstStep &&
                        <NavBar.BackButton onClick={prevStep} aria-label={navBackButtonAriaLabel} isShowing />
                    }
                    {showDownloadTutorial &&
                        <NavBar.CloseButton onClick={() => dispatch(setShowDownloadTutorial(false))} aria-label={navCloseButtonAriaLabel} isShowing />
                    }
                </NavBar.Root>
            </div >

            <div className='watch-modal__content tutorial-modal__content'>

                {
                    type === TutorialType.VerifyAttendanceTutorial ? (
                        <VerifyAttendanceAnimation 
                            stepIndex={stepIndex}
                        />
                    ) : type === TutorialType.GroupcastTutorial ? (
                        <GroupcastAnimation 
                            stepIndex={stepIndex}
                        />
                    ) : type === TutorialType.Mp4Tutorial || type === TutorialType.Mp3Tutorial ? (
                        <Mp3Mp4Animation 
                            stepIndex={stepIndex}
                            isMp4={type === TutorialType.Mp4Tutorial}
                        />
                    ) : null
                }
                <div className='tutorial-modal__content__text-block'>
                    <div className='tutorial-modal__content__text-block__header-with-eyebrow'>
                        {
                            steps.length > 1 &&
                            <div className='paragraph-2 text--medium-grey'>
                                {stepIndex + 1} of {steps.length}
                            </div>
                        }
                        <h1 className='heading-4 mb-0'>
                            {steps[stepIndex]?.headerText}
                        </h1>
                    </div>
                    <div className='paragraph-1'
                        dangerouslySetInnerHTML={{ __html: steps[stepIndex]?.bodyText }} />
                </div>
            </div>

            <div className='watch-modal__footer'>
                <div className='watch-modal__footer__button-container'>
                    <Button buttonClass='sticky-button'
                        label={stickyButtonLabels.label}
                        aria-label={stickyButtonLabels.ariaLabel}
                        action={nextStep} />
                </div>
            </div>

        </ReactModal >

    );

};

export default Tutorial;
