import { extractTranscriptionBlocks, ParsedTranscript } from '../../utility/transcriptionsUtils';
import { apiSlice } from '..';
import { StringWrapper } from '../generated';

const transcriptsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTranscripts: builder.query<ParsedTranscript[], string>({
            query: (itemPk: string) => `/media/by-pk/${itemPk}/transcript`,
            transformResponse({ value }: StringWrapper) {
                return value ? extractTranscriptionBlocks(value) : [];
            },
        })
    })
});

export const { useGetTranscriptsQuery } = transcriptsSlice;
export default transcriptsSlice;