import { useEffect, useState } from 'react';

export const useTimeInterval = (intervalMs: number, disabled = false) => {
    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        if (disabled) return;

        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, intervalMs);

        return () => {
            clearInterval(interval);
        };
    }, [disabled, intervalMs]);

    return currentTime;
};
