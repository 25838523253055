import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useReducer,
} from 'react';

import { Toast } from '../stories/Store/toastSlice';

interface LicenseToastContextProps {
  toasts: Toast[];
  addToast: (message: string) => void;
  removeToast: (toastId: number) => void;
}

interface AddToastAction {
  type: 'ADD_TOAST';
  payload: Toast;
}

interface RemoveToastAction {
  type: 'REMOVE_TOAST';
  payload: { id: number };
}

type ToastAction = AddToastAction | RemoveToastAction;

const LicenseToastContext = createContext<LicenseToastContextProps>({
    toasts: [],
    addToast: () => {
    // Initial dummy dispatch
    },
    removeToast: () => {
    // Initial dummy dispatch
    },
});

const toastReducer = (state: Toast[], action: ToastAction): Toast[] => {
    switch (action.type) {
        case 'ADD_TOAST':
            return [...state, action.payload];
        case 'REMOVE_TOAST':
            return state.filter((toast) => toast.id !== action.payload?.id);
        default:
            return state;
    }
};

interface LicenseToastProviderProps {
  children: React.ReactNode;
}

function LicenseToastProvider({
    children,
}: LicenseToastProviderProps): JSX.Element {
    const [toasts, dispatch] = useReducer(toastReducer, []);

    const addToast = useCallback(
        (message: string) => {
          if (toasts.some((toast) => toast.message === message)) return;
          const toastId = Math.floor(Math.random() * 1000);

            dispatch({
                type: 'ADD_TOAST',
                payload: {
                    id: toastId,
                    message: message,
                    type: 'announcement',
                    contentType: 'text',
                    htmlContent: '',
                    expirationTimeoutId: 0,
                    availableTimeoutId: 0,
                    needsReceiptUpdate: false,
                },
            });
        },
        [dispatch, toasts]
    );

    const removeToast = useCallback(
        (toastId: number) => {
            dispatch({ type: 'REMOVE_TOAST', payload: { id: toastId } });
        },
        [dispatch]
    );

    const value = useMemo(
        () => ({ toasts, addToast, removeToast }),
        [toasts, addToast, removeToast]
    );

    return (
        <LicenseToastContext.Provider value={value}>
            {children}
        </LicenseToastContext.Provider>
    );
}

const LicenseToastConsumer = LicenseToastContext.Consumer;
const useLicenseToast = () => useContext(LicenseToastContext);

export { LicenseToastConsumer, LicenseToastProvider, useLicenseToast };
