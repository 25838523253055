import '../../styles/_groupcast-credit-panel.scss';

import React from 'react';

import { useGetCreditProgressMessagesQuery } from '../../datasource/queries/cmsMessages';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useGroupcastPdfUrl } from '../../hooks/useGroupcastPdfUrl';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Button from '../Button/Button';

export const GroupcastCreditPanel = () => {

    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);

    const { data: creditProgressMessages } = useGetCreditProgressMessagesQuery();

    const groupcastInstructions = creditProgressMessages?.creditStatePanelGroupcastInstructions 
        ?? 'Click below to access a PDF document that includes detailed instructions, forms, and everything you need to request credit for this groupcast.';
    const groupcastPdfUrl = useGroupcastPdfUrl();

    const openGroupcastPdf = () => {
        window.open(groupcastPdfUrl, '_blank');
    };
    
    return (
        <div className='groupcast-credit-panel'>
            {!isLargeScreen && <h3 className='heading-4 mb-0'>Program Credit</h3>}
            <p className='paragraph-2 text--medium-grey mb-0'>{groupcastInstructions}</p>
            <div className='groupcast-credit-panel__button-container'>
                <Button
                    buttonClass='sticky-button sticky-button__alt'
                    label='Open PDF'
                    action={openGroupcastPdf}
                    icon='outbound-link'
                    iconPosition='right'
                />
            </div>
        </div>
    );
};

export default GroupcastCreditPanel;