import '../../../styles/_tutorial-animation.scss';

import { animate, motion, stagger } from 'framer-motion';
import React, { useEffect } from 'react';

import { ReactComponent as Asterisk } from './assets/asterisk.svg';
import AuthCodeSVG from './assets/authCodeSVG';

interface Mp3Mp4AnimationProps {
    stepIndex: number;
    isMp4: boolean;
}


const Mp3Mp4Animation: React.FC<Mp3Mp4AnimationProps> = (
    props: Mp3Mp4AnimationProps
) => {
    const { stepIndex, isMp4 } = props;

    useEffect(() => {
        if (stepIndex === 0) {
            if (isMp4) {
                animate('.tutorial-animation__dash', { opacity: [1, 0] }, { duration: .25, delay: stagger(1, { startDelay: 1 }), repeat: 0 });
                animate('.tutorial-animation__asterisk--animated', { opacity: [0, 1] }, { duration: .25, delay: stagger(1, { startDelay: 1 }), repeat: 0 });
            } else {
                // animate('.outer-circle', { scale: [1, 1.3] }, { duration: .25, delay: 1, repeat: Infinity });
            }
        }
    }, [stepIndex, isMp4]);

    return (
        <div className='tutorial-animation__container tutorial-animation__container--groupcast'>
            {stepIndex === 0 ? 
                isMp4 ? (
                    <div className='tutorial-animation__rectangle tutorial-animation__rectangle--dark-grey d-flex justify-content-between px-3 mb-0'
                        title='Video announcing authorization code demostration'>
                        <Asterisk className='tutorial-animation__asterisk'/>
                        <Asterisk className='tutorial-animation__asterisk'/>
                        <Asterisk className='tutorial-animation__asterisk'/>
                        <div className='tutorial-animation__asterisk-container'>
                            <div className='tutorial-animation__dash' />
                            <Asterisk className='tutorial-animation__asterisk tutorial-animation__asterisk--animated'/>
                        </div>
                        <div className='tutorial-animation__asterisk-container'>
                            <div className='tutorial-animation__dash' />
                            <Asterisk className='tutorial-animation__asterisk tutorial-animation__asterisk--animated'/>
                        </div>
                        <div className='tutorial-animation__asterisk-container'>
                            <div className='tutorial-animation__dash' />
                            <Asterisk className='tutorial-animation__asterisk tutorial-animation__asterisk--animated'/>
                        </div>
                    </div>
                ) : (
                    <AuthCodeSVG />
                ) : (
                    <>
                        <div className='tutorial-animation__circle tutorial-animation__circle--empty'></div>
                        <svg
                            role='img'
                            viewBox='0 0 64 64'
                            width='64px'
                            height='64px'
                            fill='none'
                            className='tutorial-animation__circle tutorial-animation__circle__svg'
                        >
                            <title>Submit credits demonstration</title>
                            <motion.circle
                                cx='32'
                                cy='32'
                                r='28'
                                stroke='#01C365'
                                data-fallback='edge'
                                strokeWidth='8px'
                                animate={{ pathLength: [0.75, 1.1, 1.1, 0.75] }}
                                transition={{
                                    duration: 6,
                                    delay: 1,
                                    ease: 'easeOut',
                                    times: [0, 0.25, 0.85, 1],
                                    repeat: 1,
                                    repeatDelay: 1,
                                }}
                            />
                        </svg>
                        <svg
                            className='tutorial-animation__checkmark'
                            xmlns='http://www.w3.org/2000/svg'
                            width='64'
                            height='64'
                            viewBox='0 0 64 64'
                            fill='none'
                        >
                            <motion.path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M44.3006 27.6304L30.1324 41.1051L21.6995 33.085L24.8006 29.8242L30.1324 34.8949L41.1995 24.3696L44.3006 27.6304Z'
                                fill='#01C365'
                                animate={{
                                    opacity: [0, 0, 1, 1, 0],
                                    scale: [1, 1.25, 1, 1, 1],
                                }}
                                transition={{
                                    duration: 6,
                                    delay: 1,
                                    ease: 'easeOut',
                                    times: [0, 0.25, 0.35, 0.9, 1],
                                    repeat: 1,
                                    repeatDelay: 1,
                                }}
                            />
                        </svg>
                    </>
                )}
            <div
                className='tutorial-animation__background-circle tutorial-animation__background-circle--groupcast'
            ></div>
        </div>
    );
};

export default Mp3Mp4Animation;
