import DOMPurify from 'dompurify';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { trapFocus } from '../../utility/trapFocus';
import Loader from '../Loader/Loader';

export interface IDropdownOption {
    value: string;
    label?: string;
}

interface IDropdownSearchProps {
    options: IDropdownOption[];
    setSelection: (selection: string) => void;
    dropdownLabel?: string;
    dropdownSelection?: string;
    showError?: boolean;
    required?: boolean;
    isLoading?: boolean;
    ariaLabel?: string;
}

export const DropdownSearch = (props: IDropdownSearchProps) => {
    const {
        options,
        setSelection,
        dropdownLabel,
        dropdownSelection,
        showError,
        required,
        isLoading,
        ariaLabel,
    } = props;

    const [searchQuery, setSearchQuery] = useState(dropdownSelection ?? '');
    const [showList, setShowList] = useState(false);
    const [visuallyFocusedItem, setVisuallyFocusedItem] = useState(-1);
    const [visuallyFocusInput, setVisuallyFocusedInput] = useState(false);

    const visuallyFocusedItemRef: React.RefObject<HTMLLIElement> = useRef(null);

    const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

    const dropdownContainerRef: React.RefObject<HTMLDivElement> = useRef(null);

    const firstSelectionRef: React.RefObject<HTMLLIElement> = useRef(null);

    const sanitizer = DOMPurify.sanitize;

    const filteredOptions = options.filter((option) =>
        option.value.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        // Update the focus on the focused list item
        if (visuallyFocusedItem === 0) {
            firstSelectionRef.current?.focus();
        } else if (visuallyFocusedItemRef.current) {
            visuallyFocusedItemRef.current.focus();
        }
    }, [visuallyFocusedItem]);

    const handleDropdownBlur = useCallback(() => {
        setShowList(false);
        if (searchQuery === '' && dropdownSelection) {
            setSelection('');
        }
        const valuesArray = options?.map((x) => x.value);
        if (!valuesArray?.includes(searchQuery)) {
            setSearchQuery(dropdownSelection ? dropdownSelection : '');
        }
    }, [dropdownSelection, options, searchQuery, setSelection]);

    const { handleClickOutside } = useOutsideClickHandler(
        dropdownContainerRef,
        handleDropdownBlur
    );

    useEffect(() => {
        if (showList) {
            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        } else if (!showList) {
            setVisuallyFocusedItem(-1);
        }
    }, [showList, handleClickOutside]);

    const handleInputKeydown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            e.preventDefault();
            const selectedOption = filteredOptions.find(
                (x) => x.value.toLowerCase() === searchQuery.toLowerCase()
            );
            setSelection(selectedOption ? selectedOption.value : '');
            setSearchQuery(selectedOption ? selectedOption.value : '');
            setShowList(false);
        } else if (e.key === 'Tab') {
            handleDropdownBlur();
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setVisuallyFocusedItem(0);
        } else {
            setShowList(true);
        }
    };

    useEffect(() => {
        setSearchQuery(dropdownSelection ?? '');
    }, [setSelection, dropdownSelection]);

    const handleSelectionClick = (option: string) => {
        if (inputRef.current === document.activeElement) {
            return;
        } else {
            option === dropdownSelection
                ? setSearchQuery(dropdownSelection)
                : setSelection(option);
            inputRef.current?.focus();
            setShowList(false);
        }
    };

    const handleTrapFocus = (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && document.activeElement) {
            trapFocus(
                document.activeElement,
                e,
                'button, [href], input[checked], select, textarea, [tabindex]:not([tabindex="-1"])'
            );
        }
        if (e.key === 'Escape') {
            setShowList(false);
        }
    };

    const handleBoldedSearchText = (text: string) => {
        if (searchQuery.length > 2) {
            const searchTermNoSpecChars = searchQuery
                .trim()
                .replace(/[°"§%()\]{}=\\?´`'#<>|,;.:+_-]+/g, '');
            const searchRegEx = new RegExp(searchTermNoSpecChars, 'i');
            const matchTerm = text.match(searchRegEx);
            const replacedText = matchTerm
                ? matchTerm[0]
                : text.indexOf(searchQuery) !== -1
                    ? searchQuery
                    : '';
            return text.replace(replacedText, `<b>${replacedText}</b>`);
        } else {
            return text;
        }
    };

    const handleOptionKeydown = (option: string, e: React.KeyboardEvent) => {
        e.stopPropagation();
        e.preventDefault();
        switch (e.key) {
            case 'ArrowUp':
                visuallyFocusedItem > 0 &&
                    setVisuallyFocusedItem(visuallyFocusedItem - 1);
                visuallyFocusedItem === 0 && setVisuallyFocusedItem(-1);
                firstSelectionRef.current === document.activeElement &&
                    inputRef.current?.focus();
                break;
            case 'ArrowDown':
                visuallyFocusedItem < filteredOptions.length - 1 &&
                    setVisuallyFocusedItem(visuallyFocusedItem + 1);
                break;
            case 'Enter':
            case ' ':
                option === dropdownSelection
                    ? setSearchQuery(dropdownSelection)
                    : setSelection(option);
                inputRef.current?.focus();
                setShowList(false);
                break;
            case 'Tab':
                setSelection(option);
                setShowList(false);
                break;
            case 'Escape':
                setShowList(false);
                inputRef.current?.focus();
                break;
        }
    };

    const inputId = `input-${Math.random().toString(16).slice(2)}`;
    const listId = `list-${Math.random().toString(16).slice(2)}`;

    return (
        <div ref={dropdownContainerRef}>
            <div
                className={`text-input ${
                    showList
                        ? 'text-input--focused dropdown--open'
                        : searchQuery.length > 0 && !visuallyFocusInput
                            ? 'text-input--populated'
                            : visuallyFocusInput
                                ? 'text-input--focused'
                                : ''
                } ${showError ? 'text-input--has-error' : ''}`}
            >
                <label
                    htmlFor={inputId}
                    className='text-input__label sentence-label'
                >
                    {dropdownLabel}
                    {required && (
                        <span
                            aria-hidden='true'
                            title='required'
                            className='ml-1'
                        >
                            *
                        </span>
                    )}
                </label>
                <input
                    role='combobox'
                    type='text'
                    id={inputId}
                    className='text-input__input paragraph-1'
                    placeholder={
                        showList
                            ? ''
                            : `${dropdownLabel} ${required ? '*' : ''}`
                    }
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    aria-describedby='error-${inputId}'
                    aria-required={required}
                    aria-invalid={required && searchQuery === ''}
                    aria-expanded={showList}
                    aria-autocomplete='both'
                    aria-label={ariaLabel}
                    aria-controls={listId}
                    onKeyDown={handleInputKeydown}
                    ref={inputRef}
                    onFocus={() => {
                        setShowList(true);
                        setVisuallyFocusedInput(true);
                    }}
                    onBlur={() => setVisuallyFocusedInput(false)}
                />
                <i
                    className={`dropdown__chevron watch ${
                        showList ? 'watch-chevron-up' : 'watch-chevron-down'
                    }`}
                    aria-hidden='true'
                />
            </div>
            {showList && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div className='position-relative' onKeyDown={handleTrapFocus}>
                    <ul
                        className={`dropdown__option-list-search ${
                            filteredOptions?.length === 0
                                ? 'dropdown__option-list--no-results'
                                : ''
                        }`}
                        role='listbox'
                        id={listId}
                        aria-label={dropdownLabel}
                    >
                        {filteredOptions?.length === 0 ? (
                            isLoading ? (
                                <Loader solidBackground={false} />
                            ) : (
                                <span className='paragraph-1 text--medium-grey'>
                                    No results found
                                </span>
                            )
                        ) : (
                            filteredOptions.map((option, i) => (
                                <li
                                    key={i}
                                    role='option'
                                    onClick={() =>
                                        handleSelectionClick(option.value)
                                    }
                                    aria-selected={
                                        dropdownSelection === option.value
                                    }
                                    ref={
                                        i === 0
                                            ? firstSelectionRef
                                            : i === visuallyFocusedItem
                                                ? visuallyFocusedItemRef
                                                : null
                                    }
                                    tabIndex={0}
                                    onKeyDown={(e) =>
                                        handleOptionKeydown(option.value, e)
                                    }
                                    aria-label={option.value}
                                    className={`dropdown__option-list-search__item ${
                                        i === visuallyFocusedItem
                                            ? 'dropdown__option-list-search__item--visually-focused'
                                            : ''
                                    }`}
                                >
                                    <span
                                        aria-label={option.value}
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizer(
                                                handleBoldedSearchText(
                                                    option.value
                                                )
                                            ),
                                        }}
                                    />
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            )}
            <div
                className={`sentence-label text-input__error ${
                    showError ? 'text-input__error--visible' : ''
                }`}
                aria-live='polite'
            >
                <i className='watch watch-alert mr-1'></i>
                <span id={`error-${inputId}`}>This field is required</span>
            </div>
        </div>
    );
};

export default DropdownSearch;
