import '../../../../styles/_tutorial-animation.scss';

import { motion } from 'framer-motion';
import React from 'react';

const CreditSVG: React.FC = (
) => {

    return (
        <div className='tutorial-animation__credits-wrapper' title='Access credit information demostration'>
            <motion.svg 
                role='img'
                id="a" 
                className="tutorial-animation__credits"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 273"
                animate={{ 
                    scale: [1.25, 1.25, 1.80, 1.80, 1.25],
                    translateX: [-113, -113, -170, -170, -113],
                    translateY: [100, 100, 160, 160, 100],
                }}
                transition={{
                    duration: 5,
                    ease: 'easeOut',
                    times: [0, .20, .30, .90, 1],
                    repeat: 2,
                    delay: 2,
                    repeatDelay: 2,
                }}>
                <g>
                    <polygon style={{ fill:'#53565a' }} className="d" points="373.97 0 0 0 0 273 265 273 265 0 373.97 0"/>
                    <path d="M375.51,0h-110.51V273h119V8.5C384,3.8,380.2,0,375.51,0Z"/>
                </g>
                <motion.rect 
                    style={{ fill:'#333' }} 
                    className="e" 
                    x="277.52" 
                    y="18.06" 
                    width="83.79" 
                    height="31.03"
                    rx="2.1" 
                    ry="2.1"
                    animate={{ 
                        opacity: [0, 0, .5, .5, 0, 0]
                    }}
                    transition={{
                        duration: 5,
                        ease: 'easeOut',
                        times: [0, .40, .50, .75, .85, 1],
                        repeat: 2,
                        delay: 2,
                        repeatDelay: 2,
                    }}>
                </motion.rect>
                <g>
                    <path style={{ fill:'#fff', fillRule:'evenodd' }} className="c" d="M297.47,169.73l5.15,3.01-5.15,3.01v-6.02Zm.94,1.64v2.75l2.35-1.37-2.35-1.37Z"/>
                    <path style={{ fill:'#fff', fillRule:'evenodd' }} className="c" d="M290.13,166.47h11.26v1.25h-11.26v-1.25Zm0,3.76h6.25v1.25h-6.25v-1.25Zm0,3.76h6.25v1.25h-6.25v-1.25Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M314.84,172.48c0,.27,.05,.51,.14,.7,.09,.19,.22,.35,.39,.48,.17,.12,.36,.21,.57,.27,.22,.05,.46,.08,.71,.08,.27,0,.51-.03,.7-.09,.19-.07,.35-.15,.47-.25s.21-.21,.26-.34c.05-.13,.08-.27,.08-.4,0-.27-.06-.47-.19-.6-.12-.13-.25-.23-.4-.28-.25-.09-.55-.18-.89-.26-.33-.09-.75-.2-1.24-.33-.31-.08-.56-.18-.77-.31-.2-.13-.36-.28-.48-.44-.12-.16-.21-.33-.26-.51-.05-.18-.07-.36-.07-.55,0-.36,.07-.67,.22-.93,.15-.27,.35-.49,.6-.66,.25-.17,.53-.3,.84-.38,.31-.09,.63-.13,.95-.13,.37,0,.72,.05,1.05,.15,.33,.09,.62,.24,.87,.43,.25,.19,.45,.43,.6,.72,.15,.28,.22,.61,.22,.98h-1.25c-.03-.46-.19-.79-.47-.97-.28-.19-.64-.29-1.07-.29-.15,0-.3,.02-.45,.05-.15,.03-.29,.08-.42,.15-.13,.07-.23,.16-.32,.28-.08,.12-.12,.27-.12,.45,0,.25,.08,.45,.23,.6,.16,.14,.37,.25,.62,.32,.03,0,.13,.04,.31,.09,.19,.05,.39,.1,.62,.16,.23,.06,.45,.12,.66,.18,.22,.05,.38,.09,.47,.12,.23,.07,.44,.17,.61,.3,.17,.13,.32,.27,.43,.44,.12,.16,.21,.33,.26,.52,.06,.19,.09,.37,.09,.56,0,.4-.08,.75-.25,1.03-.16,.28-.37,.51-.64,.69-.27,.18-.57,.31-.91,.39-.34,.09-.69,.13-1.04,.13-.41,0-.79-.05-1.15-.15-.36-.1-.67-.25-.94-.46s-.48-.47-.64-.79c-.16-.33-.24-.71-.25-1.15h1.25Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M323.9,171.82c-.01-.18-.05-.35-.12-.52-.06-.17-.15-.31-.26-.43-.11-.13-.24-.23-.4-.3-.15-.08-.33-.12-.52-.12s-.38,.04-.55,.11c-.16,.07-.3,.16-.42,.29-.11,.12-.21,.26-.28,.43-.07,.17-.1,.35-.11,.54h2.66Zm-2.66,.75c0,.2,.03,.39,.08,.58,.06,.19,.15,.35,.26,.49,.11,.14,.26,.25,.43,.34,.17,.08,.38,.12,.62,.12,.33,0,.6-.07,.8-.21,.21-.15,.36-.36,.46-.65h1.08c-.06,.28-.16,.53-.31,.75-.15,.22-.32,.41-.53,.56-.21,.15-.44,.26-.7,.33-.25,.08-.52,.12-.8,.12-.41,0-.77-.07-1.08-.2-.31-.13-.58-.32-.8-.56-.21-.24-.38-.53-.49-.86-.11-.33-.16-.7-.16-1.1,0-.37,.06-.71,.17-1.04,.12-.33,.29-.62,.5-.87,.22-.25,.48-.45,.79-.6s.65-.22,1.04-.22c.41,0,.77,.09,1.09,.26,.33,.17,.6,.39,.81,.67,.21,.28,.37,.61,.46,.97,.1,.36,.13,.74,.08,1.12h-3.8Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M330.69,174.6c0,.78-.22,1.36-.66,1.74-.43,.39-1.06,.58-1.88,.58-.26,0-.52-.03-.79-.08-.26-.05-.5-.14-.72-.27-.21-.13-.39-.29-.54-.5-.15-.21-.23-.46-.26-.76h1.14c.03,.16,.09,.29,.17,.39,.08,.1,.17,.18,.28,.23,.11,.06,.24,.1,.37,.11,.13,.02,.27,.03,.42,.03,.46,0,.8-.11,1.01-.34,.21-.23,.32-.55,.32-.98v-.79h-.02c-.16,.29-.38,.51-.66,.67-.27,.16-.57,.24-.89,.24-.41,0-.77-.07-1.06-.21-.29-.15-.53-.34-.72-.59-.19-.25-.32-.55-.41-.87s-.13-.68-.13-1.05c0-.35,.05-.68,.16-.99s.26-.59,.46-.82c.2-.24,.44-.43,.73-.57,.29-.14,.62-.21,.99-.21,.33,0,.63,.07,.9,.21,.27,.13,.48,.35,.63,.64h.02v-.71h1.14v4.91Zm-2.53-.63c.25,0,.47-.05,.64-.15,.18-.11,.32-.24,.43-.41,.11-.17,.19-.37,.24-.58,.05-.22,.08-.44,.08-.66s-.03-.43-.08-.64c-.05-.21-.14-.39-.25-.55-.11-.16-.25-.29-.43-.38-.17-.09-.38-.14-.63-.14s-.47,.05-.64,.16c-.17,.11-.31,.25-.42,.42-.11,.17-.18,.36-.23,.58-.05,.21-.07,.43-.07,.64s.03,.42,.08,.63c.05,.2,.13,.38,.24,.54,.11,.16,.25,.29,.42,.39,.17,.1,.38,.15,.62,.15Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M331.84,169.68h1.08v.72h.03c.09-.13,.18-.24,.28-.35,.1-.11,.21-.2,.33-.27,.13-.07,.27-.13,.43-.17,.16-.05,.34-.07,.55-.07,.31,0,.6,.07,.87,.21,.27,.14,.47,.36,.58,.65,.19-.27,.42-.48,.67-.63,.25-.15,.57-.23,.95-.23,.55,0,.97,.13,1.27,.4,.31,.27,.46,.71,.46,1.34v3.58h-1.14v-3.03c0-.21,0-.39-.02-.56-.01-.17-.05-.32-.12-.44-.06-.13-.15-.22-.28-.29-.13-.07-.3-.1-.52-.1-.39,0-.67,.12-.84,.36-.17,.24-.26,.58-.26,1.02v3.04h-1.14v-3.33c0-.36-.07-.63-.2-.81-.13-.19-.36-.28-.71-.28-.15,0-.29,.03-.43,.09-.13,.06-.25,.15-.36,.26-.1,.11-.18,.25-.25,.42-.06,.17-.09,.36-.09,.57v3.08h-1.14v-5.18Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M344.09,171.82c-.01-.18-.05-.35-.12-.52-.06-.17-.15-.31-.26-.43-.11-.13-.24-.23-.4-.3-.15-.08-.33-.12-.52-.12s-.38,.04-.55,.11c-.16,.07-.3,.16-.42,.29-.11,.12-.21,.26-.28,.43-.07,.17-.1,.35-.11,.54h2.66Zm-2.66,.75c0,.2,.03,.39,.08,.58,.06,.19,.15,.35,.26,.49s.26,.25,.43,.34c.17,.08,.38,.12,.62,.12,.33,0,.6-.07,.8-.21,.21-.15,.36-.36,.46-.65h1.08c-.06,.28-.16,.53-.31,.75-.15,.22-.32,.41-.53,.56-.21,.15-.44,.26-.7,.33-.25,.08-.52,.12-.8,.12-.41,0-.77-.07-1.08-.2-.31-.13-.58-.32-.8-.56-.21-.24-.38-.53-.49-.86-.11-.33-.16-.7-.16-1.1,0-.37,.06-.71,.17-1.04,.12-.33,.29-.62,.5-.87,.22-.25,.48-.45,.79-.6s.65-.22,1.04-.22c.41,0,.77,.09,1.09,.26,.33,.17,.6,.39,.81,.67,.21,.28,.37,.61,.46,.97,.1,.36,.13,.74,.08,1.12h-3.8Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M346.1,169.68h1.08v.76l.02,.02c.17-.29,.4-.51,.68-.67,.28-.17,.59-.25,.93-.25,.57,0,1.01,.15,1.34,.44,.33,.29,.49,.74,.49,1.32v3.56h-1.14v-3.26c-.01-.41-.1-.7-.26-.88-.16-.19-.41-.28-.75-.28-.19,0-.37,.04-.52,.11-.15,.07-.28,.16-.39,.29-.11,.12-.19,.26-.25,.43-.06,.17-.09,.34-.09,.53v3.06h-1.14v-5.18Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M351.33,169.68h.86v-1.55h1.14v1.55h1.03v.85h-1.03v2.77c0,.12,0,.22,0,.31,.01,.09,.04,.16,.07,.22,.04,.06,.1,.11,.17,.14,.07,.03,.17,.04,.3,.04h.24c.08,0,.16-.02,.24-.04v.88c-.13,.01-.25,.03-.37,.04-.12,.01-.24,.02-.37,.02-.3,0-.54-.03-.73-.08-.18-.06-.32-.14-.43-.25-.1-.11-.17-.25-.21-.42-.03-.17-.05-.36-.06-.57v-3.06h-.86v-.85Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M356.04,173.2c.03,.33,.16,.57,.38,.7,.22,.13,.48,.2,.79,.2,.11,0,.23,0,.36-.02,.14-.02,.27-.05,.39-.1,.12-.05,.22-.11,.29-.2,.08-.09,.12-.21,.11-.36,0-.15-.06-.27-.16-.36-.1-.09-.23-.17-.39-.22-.15-.06-.33-.11-.53-.15-.2-.04-.4-.08-.61-.13-.21-.05-.42-.1-.62-.17-.19-.07-.37-.16-.53-.27-.15-.11-.28-.26-.37-.43-.09-.18-.14-.4-.14-.66,0-.28,.07-.51,.2-.7,.14-.19,.31-.35,.52-.46,.21-.12,.45-.2,.7-.25,.26-.05,.51-.07,.74-.07,.27,0,.52,.03,.76,.09,.25,.05,.47,.14,.66,.27,.2,.13,.36,.29,.49,.5,.13,.2,.22,.44,.25,.73h-1.19c-.05-.27-.18-.46-.38-.55-.19-.09-.42-.14-.67-.14-.08,0-.18,0-.29,.02-.11,.01-.21,.04-.31,.08-.09,.03-.17,.09-.24,.16-.07,.07-.1,.16-.1,.27,0,.14,.05,.25,.14,.34,.1,.09,.23,.16,.38,.22,.16,.05,.34,.1,.54,.14,.2,.04,.41,.08,.62,.13,.21,.05,.41,.1,.61,.17,.2,.07,.38,.16,.53,.27,.16,.11,.29,.26,.38,.43,.1,.17,.15,.39,.15,.64,0,.31-.07,.57-.21,.78-.14,.21-.32,.39-.55,.52-.22,.13-.47,.23-.74,.29-.27,.06-.54,.09-.81,.09-.33,0-.63-.04-.91-.11-.27-.07-.51-.18-.72-.33-.2-.15-.36-.34-.48-.56-.11-.23-.17-.49-.18-.8h1.14Z"/>
                    <path style={{ fill:'#fff', fillRule:'evenodd' }} className="c" d="M290.75,222.74v6.77c.66-.25,1.41-.39,2.19-.39s1.53,.14,2.19,.39v-6.77c-.59-.32-1.35-.51-2.19-.51s-1.6,.2-2.19,.51Zm5.63,0v6.77c.66-.25,1.41-.39,2.19-.39s1.53,.14,2.19,.39v-6.77c-.59-.32-1.35-.51-2.19-.51s-1.6,.2-2.19,.51Zm-.63-1.08c-.79-.43-1.77-.68-2.81-.68-1.2,0-2.32,.33-3.16,.89l-.28,.19v9.65l.97-.65c.62-.41,1.48-.68,2.47-.68s1.85,.27,2.47,.68l.35,.23,.35-.23c.62-.41,1.48-.68,2.47-.68s1.85,.27,2.47,.68l.97,.65v-9.65l-.28-.19c-.84-.56-1.96-.89-3.16-.89-1.04,0-2.02,.25-2.81,.68Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M314,222.83h1.76l1.97,5.6h.02l1.92-5.6h1.74v7.16h-1.19v-5.52h-.02l-1.98,5.52h-1.03l-1.98-5.52h-.02v5.52h-1.19v-7.16Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M327.08,228.84c0,.14,.02,.24,.05,.3,.04,.06,.11,.09,.22,.09h.12s.1,0,.16-.02v.79s-.09,.03-.16,.04c-.06,.02-.12,.04-.19,.05-.07,.01-.13,.02-.2,.03-.07,0-.12,0-.17,0-.23,0-.43-.05-.58-.14-.15-.09-.25-.26-.3-.49-.23,.22-.51,.38-.84,.48-.33,.1-.64,.15-.95,.15-.23,0-.46-.03-.67-.1-.21-.06-.4-.15-.57-.27-.16-.13-.29-.28-.39-.47-.09-.19-.14-.42-.14-.67,0-.32,.06-.58,.17-.78,.12-.2,.27-.36,.46-.47,.19-.11,.41-.19,.64-.24,.24-.05,.48-.09,.72-.12,.21-.04,.4-.07,.59-.08,.19-.02,.35-.05,.49-.09,.15-.04,.26-.1,.34-.18,.09-.09,.13-.21,.13-.38,0-.15-.04-.27-.11-.36-.07-.09-.15-.16-.26-.21-.1-.05-.21-.09-.34-.1-.13-.02-.25-.03-.36-.03-.32,0-.58,.07-.79,.2-.21,.13-.32,.34-.35,.62h-1.14c.02-.33,.1-.61,.24-.83,.14-.22,.32-.4,.53-.53,.22-.13,.47-.23,.74-.28,.27-.05,.55-.08,.84-.08,.25,0,.5,.03,.75,.08,.25,.05,.47,.14,.66,.26,.2,.12,.36,.28,.48,.47,.12,.19,.18,.42,.18,.69v2.67Zm-1.14-1.44c-.17,.11-.39,.18-.64,.21-.25,.02-.51,.05-.76,.1-.12,.02-.24,.05-.35,.09-.11,.03-.21,.08-.3,.15-.09,.06-.16,.14-.21,.25-.05,.1-.07,.22-.07,.37,0,.13,.04,.23,.11,.32,.07,.09,.16,.16,.26,.21,.11,.05,.22,.08,.34,.1,.13,.02,.24,.03,.34,.03,.13,0,.26-.02,.41-.05,.15-.03,.28-.09,.41-.17,.13-.08,.24-.18,.33-.3,.09-.13,.13-.28,.13-.46v-.85Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M327.79,224.81h.86v-1.55h1.14v1.55h1.03v.85h-1.03v2.77c0,.12,0,.22,0,.31,.01,.09,.04,.16,.07,.22,.04,.06,.1,.11,.17,.14,.07,.03,.17,.04,.3,.04h.24c.08,0,.16-.02,.24-.04v.88c-.13,.01-.25,.03-.37,.04-.12,.01-.24,.02-.37,.02-.3,0-.54-.03-.73-.08-.18-.06-.32-.14-.43-.25-.1-.11-.17-.25-.21-.42-.03-.17-.05-.36-.06-.57v-3.06h-.86v-.85Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M335.19,226.94c-.01-.18-.05-.35-.12-.52-.06-.17-.15-.31-.26-.43-.11-.13-.24-.23-.4-.3-.15-.08-.33-.12-.52-.12s-.38,.04-.55,.11c-.16,.07-.3,.16-.42,.29-.11,.12-.21,.26-.28,.43-.07,.17-.1,.35-.11,.54h2.66Zm-2.66,.75c0,.2,.03,.4,.08,.58,.06,.19,.15,.35,.26,.49,.11,.14,.26,.25,.43,.34,.17,.08,.38,.12,.62,.12,.33,0,.6-.07,.8-.21,.21-.15,.36-.36,.46-.65h1.08c-.06,.28-.16,.53-.31,.75-.15,.22-.32,.41-.53,.56-.21,.15-.44,.26-.7,.33-.25,.08-.52,.12-.8,.12-.41,0-.77-.07-1.08-.2-.31-.13-.58-.32-.8-.56-.21-.24-.38-.53-.49-.86-.11-.33-.16-.7-.16-1.1,0-.37,.06-.71,.17-1.04,.12-.33,.29-.62,.5-.87,.22-.25,.48-.45,.79-.6,.31-.15,.65-.22,1.04-.22,.41,0,.77,.09,1.09,.26,.33,.17,.6,.39,.81,.67,.21,.28,.37,.6,.46,.97,.1,.36,.13,.74,.08,1.12h-3.8Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M337.2,224.81h1.07v1h.02c.03-.14,.1-.28,.19-.41,.1-.13,.22-.25,.35-.36,.14-.11,.29-.2,.46-.27,.17-.07,.34-.1,.51-.1,.13,0,.22,0,.27,.01,.05,0,.11,.01,.16,.02v1.1c-.08-.01-.16-.02-.25-.03-.08-.01-.16-.02-.24-.02-.19,0-.38,.04-.55,.12-.17,.07-.31,.19-.44,.34-.13,.15-.23,.33-.3,.55-.07,.22-.11,.47-.11,.76v2.47h-1.14v-5.18Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M340.75,222.83h1.14v1.08h-1.14v-1.08Zm0,1.97h1.14v5.18h-1.14v-5.18Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M347.46,228.84c0,.14,.02,.24,.05,.3,.04,.06,.11,.09,.22,.09h.12s.1,0,.16-.02v.79s-.09,.03-.16,.04c-.06,.02-.12,.04-.19,.05-.07,.01-.13,.02-.2,.03-.07,0-.12,0-.17,0-.23,0-.43-.05-.58-.14-.15-.09-.25-.26-.3-.49-.23,.22-.51,.38-.84,.48-.33,.1-.64,.15-.95,.15-.23,0-.46-.03-.67-.1-.21-.06-.4-.15-.57-.27-.16-.13-.29-.28-.39-.47-.09-.19-.14-.42-.14-.67,0-.32,.06-.58,.17-.78,.12-.2,.27-.36,.46-.47,.19-.11,.41-.19,.64-.24,.24-.05,.48-.09,.72-.12,.21-.04,.4-.07,.59-.08,.19-.02,.35-.05,.49-.09,.15-.04,.26-.1,.34-.18,.09-.09,.13-.21,.13-.38,0-.15-.04-.27-.11-.36-.07-.09-.15-.16-.26-.21-.1-.05-.21-.09-.34-.1-.13-.02-.25-.03-.36-.03-.32,0-.58,.07-.79,.2-.21,.13-.32,.34-.35,.62h-1.14c.02-.33,.1-.61,.24-.83,.14-.22,.32-.4,.53-.53,.22-.13,.47-.23,.74-.28,.27-.05,.55-.08,.84-.08,.25,0,.5,.03,.75,.08,.25,.05,.47,.14,.66,.26,.2,.12,.36,.28,.48,.47,.12,.19,.18,.42,.18,.69v2.67Zm-1.14-1.44c-.17,.11-.39,.18-.64,.21-.25,.02-.51,.05-.76,.1-.12,.02-.24,.05-.35,.09-.11,.03-.21,.08-.3,.15-.09,.06-.16,.14-.21,.25-.05,.1-.07,.22-.07,.37,0,.13,.04,.23,.11,.32,.07,.09,.16,.16,.26,.21,.11,.05,.22,.08,.34,.1,.13,.02,.24,.03,.34,.03,.13,0,.26-.02,.41-.05,.15-.03,.28-.09,.41-.17,.13-.08,.24-.18,.33-.3,.09-.13,.13-.28,.13-.46v-.85Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M348.72,222.83h1.14v7.16h-1.14v-7.16Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M351.96,228.33c.03,.33,.16,.57,.38,.7,.22,.13,.48,.2,.79,.2,.11,0,.23,0,.36-.02,.14-.02,.27-.05,.39-.1,.12-.05,.22-.11,.29-.2,.08-.09,.12-.21,.11-.36,0-.15-.06-.27-.16-.36-.1-.09-.23-.17-.39-.22-.15-.06-.33-.11-.53-.15-.2-.04-.4-.08-.61-.13-.21-.05-.42-.1-.62-.17-.19-.07-.37-.16-.53-.27-.15-.11-.28-.26-.37-.43-.09-.18-.14-.4-.14-.66,0-.28,.07-.51,.2-.7,.14-.19,.31-.35,.52-.46,.21-.12,.45-.2,.7-.25,.26-.05,.51-.07,.74-.07,.27,0,.52,.03,.76,.09,.25,.05,.47,.14,.66,.27,.2,.13,.36,.29,.49,.5,.13,.2,.22,.44,.25,.73h-1.19c-.05-.27-.18-.46-.38-.55-.19-.09-.42-.14-.67-.14-.08,0-.18,0-.29,.02-.11,.01-.21,.04-.31,.08-.09,.03-.17,.09-.24,.16-.07,.07-.1,.16-.1,.27,0,.14,.05,.25,.14,.34,.1,.09,.23,.16,.38,.22,.16,.05,.34,.1,.54,.14,.2,.04,.41,.08,.62,.13,.21,.05,.41,.1,.61,.17,.2,.07,.38,.16,.53,.27,.16,.11,.29,.26,.38,.43,.1,.17,.15,.39,.15,.64,0,.31-.07,.57-.21,.78-.14,.21-.32,.39-.55,.52-.22,.13-.47,.23-.74,.29-.27,.06-.54,.09-.81,.09-.33,0-.63-.04-.91-.11-.27-.07-.51-.18-.72-.33-.2-.15-.36-.34-.48-.56-.11-.23-.17-.49-.18-.8h1.14Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M318.47,32.43c-.05-.21-.13-.4-.22-.57-.09-.17-.21-.32-.36-.45-.15-.13-.32-.22-.52-.29-.19-.07-.42-.11-.68-.11-.38,0-.71,.08-.99,.23-.27,.15-.5,.36-.67,.62-.17,.25-.3,.54-.39,.87-.08,.33-.12,.66-.12,1s.04,.67,.12,1c.09,.33,.22,.62,.39,.88,.17,.25,.4,.46,.67,.61,.28,.15,.61,.23,.99,.23,.28,0,.53-.05,.74-.14,.21-.1,.4-.23,.55-.4,.15-.17,.27-.37,.36-.6,.09-.23,.14-.47,.17-.73h1.22c-.03,.43-.12,.82-.28,1.17-.15,.35-.36,.66-.63,.92-.27,.25-.58,.45-.94,.59-.36,.14-.76,.21-1.19,.21-.53,0-1.01-.1-1.44-.29-.42-.2-.78-.47-1.07-.81-.29-.34-.52-.74-.68-1.19-.15-.45-.23-.94-.23-1.45s.08-.99,.23-1.44c.16-.45,.39-.85,.68-1.19,.29-.34,.65-.61,1.07-.81,.43-.2,.91-.3,1.44-.3,.4,0,.78,.06,1.13,.17,.35,.11,.66,.27,.93,.48,.27,.21,.49,.46,.66,.76,.17,.3,.27,.64,.31,1.03h-1.25Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M320.71,32.13h1.07v1h.02c.03-.14,.1-.28,.19-.41,.1-.13,.22-.25,.35-.36,.14-.11,.29-.2,.46-.27,.17-.07,.34-.1,.51-.1,.13,0,.22,0,.27,.01l.16,.02v1.1c-.08-.01-.16-.02-.25-.03-.08-.01-.16-.02-.24-.02-.19,0-.38,.04-.55,.12-.17,.07-.31,.19-.44,.34-.13,.15-.23,.33-.3,.55-.07,.22-.11,.47-.11,.76v2.47h-1.14v-5.18Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M327.6,34.26c-.01-.18-.05-.35-.12-.52-.06-.17-.15-.31-.26-.43-.11-.13-.24-.23-.4-.3-.15-.08-.33-.12-.52-.12s-.38,.04-.55,.11c-.16,.07-.3,.16-.42,.29-.11,.12-.21,.26-.28,.43-.07,.17-.1,.35-.11,.54h2.66Zm-2.66,.75c0,.2,.03,.39,.08,.58,.06,.19,.15,.35,.26,.49,.11,.14,.26,.25,.43,.34,.17,.08,.38,.12,.62,.12,.33,0,.6-.07,.8-.21,.21-.15,.36-.36,.46-.65h1.08c-.06,.28-.16,.53-.31,.75-.15,.22-.32,.41-.53,.56-.21,.15-.44,.26-.7,.33-.25,.08-.52,.12-.8,.12-.41,0-.77-.07-1.08-.2-.31-.13-.58-.32-.8-.56-.21-.24-.38-.53-.49-.86-.11-.33-.16-.7-.16-1.1,0-.37,.06-.71,.17-1.04,.12-.33,.29-.62,.5-.87,.22-.25,.48-.45,.79-.6,.31-.15,.65-.22,1.04-.22,.41,0,.77,.09,1.09,.26,.33,.17,.6,.39,.81,.67,.21,.28,.37,.6,.46,.97,.1,.36,.13,.73,.08,1.12h-3.8Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M334.48,37.31h-1.08v-.7h-.02c-.15,.3-.38,.52-.67,.65-.29,.13-.6,.19-.93,.19-.41,0-.76-.07-1.07-.21-.3-.15-.55-.34-.75-.59s-.35-.54-.45-.87c-.1-.34-.15-.7-.15-1.09,0-.47,.06-.87,.19-1.21,.13-.34,.29-.62,.5-.84,.21-.22,.45-.38,.72-.48,.27-.11,.55-.16,.83-.16,.16,0,.32,.02,.49,.05,.17,.03,.33,.07,.48,.14,.15,.07,.29,.15,.42,.26,.13,.1,.24,.22,.33,.36h.02v-2.65h1.14v7.16Zm-3.98-2.54c0,.22,.03,.44,.08,.65,.06,.21,.15,.4,.26,.57,.12,.17,.27,.3,.45,.4,.18,.1,.39,.15,.64,.15s.47-.05,.65-.16c.19-.11,.34-.25,.45-.42,.12-.17,.21-.37,.26-.58,.06-.22,.09-.44,.09-.67,0-.57-.13-1.02-.39-1.34-.25-.32-.6-.48-1.04-.48-.27,0-.49,.06-.68,.17-.18,.11-.33,.25-.45,.43-.11,.17-.2,.37-.25,.6-.05,.22-.07,.45-.07,.68Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M335.75,30.15h1.14v1.08h-1.14v-1.08Zm0,1.97h1.14v5.18h-1.14v-5.18Z"/>
                    <path style={{ fill:'#fff' }} className="b" d="M337.62,32.13h.86v-1.55h1.14v1.55h1.03v.85h-1.03v2.77c0,.12,0,.22,0,.31,.01,.09,.04,.16,.07,.22,.04,.06,.1,.11,.17,.14,.07,.03,.17,.04,.3,.04h.24c.08,0,.16-.02,.24-.04v.88c-.13,.01-.25,.03-.37,.04-.12,.01-.24,.02-.37,.02-.3,0-.54-.03-.73-.08-.18-.06-.32-.14-.43-.25-.1-.11-.17-.25-.21-.42-.03-.17-.05-.36-.06-.57v-3.06h-.86v-.85Z"/>
                    <path style={{ fill:'#fff', fillRule:'evenodd' }} className="c" d="M298.49,39.25c-.92,.45-1.84,.62-2.86,.58-1.02-.04-2.02-.34-2.89-.87s-1.61-1.27-2.12-2.15c-.52-.88-.8-1.88-.83-2.9s.2-2.03,.67-2.94c.47-.91,1.16-1.69,2-2.26,.85-.57,1.83-.92,2.84-1.02,1.01-.1,1.68-.08,2.63,.33,.32,.11,.63,.26,.93,.42l.1,.06-.59,1.05-.1-.06c-.24-.13-.49-.25-.75-.34h0c-.75-.32-1.27-.34-2.09-.26-.82,.08-1.6,.36-2.28,.82-.68,.46-1.23,1.08-1.61,1.81-.37,.73-.56,1.54-.54,2.36,.02,.82,.25,1.62,.66,2.33,.41,.71,1,1.3,1.7,1.72,.7,.42,1.5,.66,2.32,.69,.82,.03,1.56-.1,2.29-.47h0c.25-.12,.49-.26,.71-.41l.1-.07,.69,.99-.1,.07c-.28,.2-.57,.37-.88,.51Zm1.51-9.97l.09,.08c.25,.24,.47,.49,.68,.77,.21,.28,.39,.57,.54,.87l.05,.1-1.08,.55-.05-.1c-.12-.24-.27-.48-.43-.7-.16-.22-.35-.43-.54-.61l-.08-.08,.84-.87Zm.43,8.52l-.92-.79,.08-.09c.18-.21,.34-.43,.48-.67,.14-.24,.26-.48,.36-.74l.04-.11,1.13,.44-.04,.11c-.12,.32-.27,.62-.45,.92s-.38,.57-.6,.83l-.08,.09Zm1.37-5.48l.03,.11c.07,.33,.12,.67,.14,1.01,.02,.34,0,.68-.03,1.02v.12l-1.22-.14v-.12c.05-.27,.06-.54,.04-.82-.01-.27-.05-.55-.11-.81l-.03-.11,1.18-.26Zm-3.43,.51l-3.15,3.23-1.77-1.74,.85-.86,.9,.88,2.3-2.36,.87,.84Z"/></g>
            </motion.svg>
        </div>
    );
};

export default CreditSVG;
