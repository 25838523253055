/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventOrigin {
    PLIONLINE = 'PLIOnline',
    MOBILE = 'Mobile',
    WATCH = 'Watch',
}
