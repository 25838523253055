import '../../styles/_auth-code.scss';

import React, { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { addErrorMessageElementId, removeErrorMessageElementId, setAuthorizationCode } from '../Store/requestCreditSlice';

export type IAuthCodeProps = {
    initialValue?: string;
};

const authCodeInstructionId = 'auth-code-instruction';
const authCodeInputFeedbackId = 'auth-code-input-feedback';

export const AuthCode = (props: IAuthCodeProps) => {
    const {
        initialValue,
    } = props;

    const userInput = useAppSelector((state) => state.requestCredit.authorizationCode);
    const authCodes = useAppSelector((state) => state.requestCredit.authCodes);
    const dispatch = useAppDispatch();

    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

    const [value, setValue] = useState([
        (initialValue && initialValue[0]) ?? userInput?.toString()[0] ?? '',
        (initialValue && initialValue[1]) ?? userInput?.toString()[1] ?? '',
        (initialValue && initialValue[2]) ?? userInput?.toString()[2] ?? '',
        (initialValue && initialValue[3]) ?? userInput?.toString()[3] ?? '',
        (initialValue && initialValue[4]) ?? userInput?.toString()[4] ?? '',
        (initialValue && initialValue[5]) ?? userInput?.toString()[5] ?? '',
    ]);

    const refs = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
    ];

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (!isNaN(+event.currentTarget.value)) {
            const newValue = value.map((v, i) => {
                if (i === index) {
                    return event.currentTarget.value;
                } else {
                    return v;
                }
            });

            setValue(newValue);

            if (event.currentTarget.value && index !== refs.length-1) {
                refs[index + 1].current?.focus();
            } else if (index !== 0 && !newValue[index] && !newValue[index-1]) {
                refs[index-1].current?.focus();
            }
        }
    };

    const handleDeleteKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace') {
            if (!value[index] && index !== 0) {
                refs[index-1].current?.focus();
            }
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        const data = event.clipboardData.getData('text');
        if (!isNaN(+data)) {
            let dataIndex = 0;
            const newValue = value.map((v, i) => {
                if (i >= index) {
                    return data[dataIndex++];
                } else {
                    return v;
                }
            });

            setValue(newValue);
            refs[dataIndex+index-1].current?.focus();
            event.preventDefault();
        }
    };

    useEffect(() => {
        const userInput = value.join('');

        dispatch(setAuthorizationCode(userInput ? parseInt(userInput) : undefined));
        if (userInput.length >= 6) {
            const authCodematch = authCodes?.some(x => x.toString() === userInput);
            setIsValid(authCodematch ?? false);
        } else {
            setIsValid(undefined);
        }
    }, [dispatch, authCodes, value]);

    useEffect(() => {
        if (isValid === undefined) {
            dispatch(addErrorMessageElementId(authCodeInstructionId));
        } else if (isValid) {
            dispatch(removeErrorMessageElementId(authCodeInstructionId));
            dispatch(removeErrorMessageElementId(authCodeInputFeedbackId));
        } else if (!isValid) {
            dispatch(addErrorMessageElementId(authCodeInputFeedbackId));
        }
    }, [dispatch, isValid]);

    return (
        <div className='auth-code'>
            <div id={authCodeInstructionId} className='paragraph-1 text--medium-grey'>Input your authorization code</div>
            <div className='auth-code__inputs'>
                <input aria-label='input first digit' type='text' placeholder='-' maxLength={1} value={value[0]} onChange={(event) => handleValueChange(event, 0)} ref={refs[0]} onKeyDown={(event) => handleDeleteKeyDown(event, 0)} onPaste={(event) => handlePaste(event, 0)} />
                <input aria-label='input second digit' type='text' placeholder='-' maxLength={1} value={value[1]} onChange={(event) => handleValueChange(event, 1)} ref={refs[1]} onKeyDown={(event) => handleDeleteKeyDown(event, 1)} onPaste={(event) => handlePaste(event, 1)} />
                <input aria-label='input third digit' type='text' placeholder='-' maxLength={1} value={value[2]} onChange={(event) => handleValueChange(event, 2)} ref={refs[2]} onKeyDown={(event) => handleDeleteKeyDown(event, 2)} onPaste={(event) => handlePaste(event, 2)} className='mr-3' />
                <input aria-label='input fourth digit' type='text' placeholder='-' maxLength={1} value={value[3]} onChange={(event) => handleValueChange(event, 3)} ref={refs[3]} onKeyDown={(event) => handleDeleteKeyDown(event, 3)} onPaste={(event) => handlePaste(event, 3)} />
                <input aria-label='input fifth digit' type='text' placeholder='-' maxLength={1} value={value[4]} onChange={(event) => handleValueChange(event, 4)} ref={refs[4]} onKeyDown={(event) => handleDeleteKeyDown(event, 4)} onPaste={(event) => handlePaste(event, 4)} />
                <input aria-label='input sixth digit' type='text' placeholder='-' maxLength={1} value={value[5]} onChange={(event) => handleValueChange(event, 5)} ref={refs[5]} onKeyDown={(event) => handleDeleteKeyDown(event, 5)} onPaste={(event) => handlePaste(event, 5)} />
            </div>
            {
                isValid !== undefined &&
                <div id={authCodeInputFeedbackId} className={`${isValid ? 'auth-code__success' : 'auth-code__error'} sentence-label`}>
                    <i aria-hidden={true} className={`watch ${isValid ? 'watch-checkmark' : 'watch-alert'}`} />
                    {isValid ? 'Authorization code match! Thank you.' : 'Authorization code is incorrect.'}
                </div>
            }
        </div>
    );
};

export default AuthCode;
