import React, { useRef } from 'react';
import ReactModal from 'react-modal';

import ModalPanelContext from '../../contexts/ModalPanelContext';
import { NavBar } from '../NavBar/NavBar';

export type IModalPanelProps = ReactModal.Props & {
    title?: string;
    subtitle?: string;
    noNav?: boolean;
    onNavBack?: (() => void) | null;
}

export function ModalPanel({ isOpen, title, subtitle, noNav = false, onNavBack, children, contentRef, ...props }: IModalPanelProps) {
    const $contentRef = useRef<HTMLDivElement>();

    return (
        <ReactModal isOpen={isOpen}
            {...props}
            className={`watch-modal d-flex flex-column ${props.className ?? ''}`.trim()}
            overlayClassName={`watch-modal__overlay ${props.overlayClassName ?? ''}`.trim()}
            contentRef={ref => {
                contentRef?.(ref);
                $contentRef.current = ref;
            }}
        >
            {!noNav && (
                <div className="watch-modal__nav">
                    <NavBar.Root mode="back" containerMode="modal">
                        {!!title && <NavBar.Title title={title} subtitle={subtitle} size='heading-4' />}
                        {onNavBack && <NavBar.BackButton onClick={onNavBack} aria-label={'Return to previous screen'} isShowing/>}
                    </NavBar.Root>
                </div>
            )}

            <ModalPanelContext.Provider value={$contentRef}>
                {children}
            </ModalPanelContext.Provider>
        </ReactModal>
    );
}