import { useEffect, useRef, useState } from 'react';

const SAFETY_THRESHOLD = 18; // px

type E = HTMLElement;
type Default = HTMLDivElement;

export function useScrollForMoreDetails<
    Container extends E = Default,
    Target extends E = Default,
    Footer extends E = Default,
>() {
    const container = useRef<Container>(null);
    const target = useRef<Target>(null);
    const footer = useRef<Footer>(null);

    const [showScrollForMoreDetails, setShowScrollForMoreDetails] = useState(true);
    const scrollToTargetElement = () => {
        target.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        let observer: IntersectionObserver | null = null;

        // use an interval to ensure the refs have already been added to the DOM
        const watcher = setInterval(() => {
            if (!target.current || !container?.current || !footer.current) return;
            clearInterval(watcher);

            observer = new IntersectionObserver(entries => {
                for (const entry of entries) {
                    if (entry.target !== target.current) continue;

                    setShowScrollForMoreDetails(!entry.isIntersecting);
                }
            }, {
                threshold: 1,
                root: container.current,
                rootMargin: `0px 0px -${footer.current.offsetHeight - SAFETY_THRESHOLD}px 0px`,
            });

            observer.observe(target.current);
        }, 10);

        return () => {
            setShowScrollForMoreDetails(true);
            clearInterval(watcher);
            observer?.disconnect();
        };
    }, []);

    return {
        ref: { container, target, footer },
        showScrollForMoreDetails,
        scrollToTargetElement,
    };
}