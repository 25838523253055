import { apiSlice } from '..';
import { ComplianceAndStateAndCreditClassInfo } from '../generated';

const creditJurisdictionsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCreditJurisdictions: builder.query<Array<Required<ComplianceAndStateAndCreditClassInfo>>, void>({
            query: () => '/credit-jurisdictions',
        })
    })
});

export const { useGetCreditJurisdictionsQuery } = creditJurisdictionsSlice;
export default creditJurisdictionsSlice;