import '../../styles/_dropdown.scss';

import React, {
    forwardRef,
    SelectHTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { trapFocus } from '../../utility/trapFocus';

export interface IDropdownOption {
    value: string;
    label?: string;
}

export interface IDropdownProps
    extends SelectHTMLAttributes<HTMLSelectElement> {
    items?: IDropdownOption[];
    small?: boolean;
    dropdownLabel?: string;
    dropdownSelection?: string;
    setSelection?: (selection: string) => void;
    required?: boolean;
    showError?: boolean;
    ariaLabel?: string;
}

export const Dropdown = forwardRef<HTMLButtonElement, IDropdownProps>(
    (props: IDropdownProps, ref) => {
        const {
            items,
            small = false,
            dropdownLabel,
            setSelection,
            required,
            showError,
            ariaLabel,
            dropdownSelection,
        } = props;
        const [showList, setShowList] = useState(false);
        const [visuallyFocusedItem, setVisuallyFocusedItem] = useState(-1);

        const firstSelectionRef: React.RefObject<HTMLLIElement> = useRef(null);

        const dropdownContainerRef: React.RefObject<HTMLDivElement> =
            useRef(null);

        const visuallyFocusedItemRef: React.RefObject<HTMLLIElement> =
            useRef(null);

        const buttonRef: React.RefObject<HTMLButtonElement> =
            useRef(null);

        const dropdownButtonRef = (ref as React.RefObject<HTMLButtonElement>) ?? buttonRef;
        const isSmallScreen = useMediaQuery(ScreenSizeQueries.SMALL);

        const { handleClickOutside } = useOutsideClickHandler(
            dropdownContainerRef,
            () => setShowList(false)
        );

        useEffect(() => {
            // Update the focus on the focused list item
            if (visuallyFocusedItem === 0) {
                firstSelectionRef.current?.focus();
            } else if (visuallyFocusedItemRef.current) {
                visuallyFocusedItemRef.current.focus();
            }
        }, [visuallyFocusedItem]);

        useEffect(() => {
            if (showList) {
                if (visuallyFocusedItem === -1) {
                    firstSelectionRef?.current?.focus();
                    setVisuallyFocusedItem(0);
                } else {
                    visuallyFocusedItemRef?.current?.focus();
                }
                    
                document.addEventListener('mouseup', handleClickOutside);

                return () => {
                    document.removeEventListener('mouseup', handleClickOutside);
                };
            } else {
                setVisuallyFocusedItem(-1);
            }
        }, [showList, handleClickOutside, visuallyFocusedItem]);

        const handleKeyDown = (e: React.KeyboardEvent) => {
            if (e.key === 'Tab' && document.activeElement) {
                trapFocus(
                    document.activeElement,
                    e,
                    'button, [href], input[checked], select, textarea, [tabindex]:not([tabindex="-1"])'
                );
            }
            if (e.key === 'Escape') {
                setShowList(false);
            }
        };

        const buttonId = `listDropdown-${Math.random().toString(16).slice(2)}`;

        const handleSelection = (selection: string) => {
            setSelection!(selection);
            setShowList(false);
            dropdownButtonRef?.current?.focus();
        };

        const handleOptionKeydown = (
            option: string,
            e: React.KeyboardEvent
        ) => {
            e.stopPropagation();
            e.preventDefault();
            switch (e.key) {
                case 'ArrowUp':
                    visuallyFocusedItem > 0 &&
                        setVisuallyFocusedItem(visuallyFocusedItem - 1);
                    visuallyFocusedItem === 0 && setVisuallyFocusedItem(-1);
                    break;
                case 'ArrowDown':
                    items &&
                        visuallyFocusedItem < items.length - 1 &&
                        setVisuallyFocusedItem(visuallyFocusedItem + 1);
                    break;
                case 'Enter':
                case ' ':
                    setSelection!(option);
                    setShowList(false);
                    dropdownButtonRef?.current?.focus();
                    break;
                case 'Tab':
                case 'Escape':
                    setShowList(false);
                    dropdownButtonRef?.current?.focus();
                    break;
            }
        };

        const handleButtonKeydown = (e: React.KeyboardEvent) => {
            if ((e.key === 'Enter' || e.key === ' ') && !isSmallScreen) {
                e.preventDefault();
                setShowList(state => !state);
            } else if (e.key === 'Tab') {
                setShowList(false);
            }
        };

        return (
            <div
                className={`dropdown ${
                    showList ? 'dropdown--open' : 'dropdown--closed'
                }`}
                ref={dropdownContainerRef}
            >
                <button
                    className={`${
                        dropdownSelection ? 'dropdown__button--populated' : ''
                    } ${showError ? 'dropdown__button--has-error' : ''} ${
                        small
                            ? 'dropdown__button--small paragraph-2'
                            : 'dropdown__button paragraph-1'
                    }`}
                    id={buttonId}
                    onClick={() => setShowList(!showList)}
                    ref={ref === null ? buttonRef : ref}
                    aria-describedby={
                        showError ? `error-${buttonId}` : buttonId
                    }
                    aria-label={`${ariaLabel} ${required ? 'required' : ''}`}
                    disabled={!items || items.length < 1}
                    onKeyDown={handleButtonKeydown}
                >
                    <span className='dropdown__label'>
                        <span
                            className={`${
                                dropdownSelection ? 'sentence-label mb-1' : ''
                            }`}
                        >
                            {dropdownLabel}
                            {required &&
                                !items?.find(
                                    (x) => x.value === dropdownLabel
                                ) && (
                                <span
                                    aria-hidden='true'
                                    title='required'
                                    className='ml-1'
                                >
                                        *
                                </span>
                            )}
                        </span>
                        {dropdownSelection && (
                            <span className='dropdown__selection'>
                                {dropdownSelection}
                            </span>
                        )}
                    </span>
                    <i
                        className={
                            showList
                                ? 'd-flex align-self-center watch watch-chevron-up'
                                : 'd-flex align-self-center watch watch-chevron-down'
                        }
                        aria-hidden='true'
                    />
                </button>
                {showList && (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div onKeyDown={handleKeyDown}>
                        <ul
                            role='listbox'
                            className={`${
                                small
                                    ? 'dropdown__option-list--small'
                                    : 'dropdown__option-list'
                            }`}
                            aria-label={dropdownLabel}
                        >
                            {items &&
                                items.map((selection, i) => {
                                    return (
                                        <li
                                            key={i}
                                            role='option'
                                            className={`${
                                                small
                                                    ? 'dropdown__option-list--small__button paragraph-2'
                                                    : 'dropdown__option-list__list-item paragraph-1'
                                            } ${
                                                i === visuallyFocusedItem
                                                    ? 'dropdown__option-list__list-item--visually-focused'
                                                    : ''
                                            }`}
                                            ref={
                                                i === 0
                                                    ? firstSelectionRef
                                                    : i === visuallyFocusedItem
                                                        ? visuallyFocusedItemRef
                                                        : null
                                            }
                                            onClick={() =>
                                                handleSelection(selection.value)
                                            }
                                            tabIndex={0}
                                            aria-selected={
                                                selection.value ===
                                                dropdownSelection
                                            }
                                            onKeyDown={(e) =>
                                                handleOptionKeydown(
                                                    selection.value,
                                                    e
                                                )
                                            }
                                        >
                                            {selection.value}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                )}
                <div
                    className={`sentence-label text-input__error ${
                        showError ? 'text-input__error--visible' : ''
                    }`}
                >
                    <i className='watch watch-alert mr-1'></i>
                    <span id={`error-${buttonId}`}>This field is required</span>
                </div>
            </div>
        );
    }
);
Dropdown.displayName = 'Dropdown';

export default Dropdown;
