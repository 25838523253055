import { apiSlice } from '..';
import { CleSession, CleTimeRecord } from '../generated';

const cleSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCleTimeRecords: builder.query<CleTimeRecord[], string>({
            query: (encryptedRegistrationId) => `/cle/cle-time-records?encryptedRegistrationId=${encryptedRegistrationId}`,
            providesTags: ['CleTimeRecords'],
        }),
        getCleSession: builder.query<CleSession, string>({
            query: (encryptedRegistrationId) => `/cle/session?encryptedRegistrationId=${encryptedRegistrationId}`,
            providesTags: ['CleSession'],
        })
    })
});

export const { useGetCleTimeRecordsQuery, useGetCleSessionQuery } = cleSlice;
export default cleSlice;