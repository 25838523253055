import { useCallback, useEffect, useRef } from 'react';

import { useSavePlaybackProgressMutation } from '../datasource/mutations/playbackProgress';
import { PlaybackPosition, updatePlaybackProgress } from '../stories/Store/playerSlice';
import { useAppDispatch, useAppSelector } from '.';
import { useAnalytics } from './useAnalytics';
import { useThrottleCallback } from './useThrottleCallback';

export type SetPlaybackProgressProps = {
    position: number;
    postImmediately?: boolean;
  } & ({
    shouldTrackVideoProgress: true;
    duration: number;
  } | {
    shouldTrackVideoProgress?: false;
    duration?: null | undefined;
  });

type PartialPlaybackPosition = Omit<PlaybackPosition, 'totalTime'>

export const usePlaybackProgress = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const { trackVideoProgress } = useAnalytics();
    const SAVE_PROGRESS_INTERVAL = 30;

    const isWebcast = useAppSelector((state) => state.player.launchDetails.isWebcast);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const currentSegmentPlaybackProgress = useAppSelector((state) => 
        state.player.playbackProgress.find(x => x.itemSk === currentSegmentItemSk));
    const lastPersistedProgress = useRef<PartialPlaybackPosition>();

    const [savePlaybackProgress] = useSavePlaybackProgressMutation();
    
    const persistPlaybackProgress = useThrottleCallback(
        useCallback((progress: PartialPlaybackPosition) => {
            savePlaybackProgress(progress);
            lastPersistedProgress.current = progress;
        }, [savePlaybackProgress]),
        500
    );

    const setPlaybackProgress = ({ position, shouldTrackVideoProgress, postImmediately = false, duration = 0 }: SetPlaybackProgressProps) => {
        if (isWebcast) return;
        const progress : PartialPlaybackPosition = { itemSk: currentSegmentItemSk, position: Math.round(position) };

        // This check is made because jwPlayer onTime fires more than once per second
        // and do not want to update state or POST multiple times for the same second value.
        if (progress.position === currentSegmentPlaybackProgress?.position) 
            return;

        dispatch(updatePlaybackProgress(progress));

        const positionDelta =
            lastPersistedProgress.current?.itemSk === progress.itemSk
                ? Math.abs(
                    progress.position - (lastPersistedProgress.current?.position ?? 0),
                )
                : SAVE_PROGRESS_INTERVAL;

        if (positionDelta >= SAVE_PROGRESS_INTERVAL && !user.isGroupcast) {
            if (postImmediately || progress.position > 0) {
                persistPlaybackProgress(progress);
                if (shouldTrackVideoProgress) {
                    trackVideoProgress({ position, duration: duration as number });
                }
            }
        } 
    };

    const postLatestPlaybackProgress = () => {
        if (!isWebcast && currentSegmentPlaybackProgress?.position && 
            currentSegmentPlaybackProgress?.itemSk &&
            !user.isGroupcast)
            persistPlaybackProgress(currentSegmentPlaybackProgress);
    };

    useEffect(() => {
        lastPersistedProgress.current = currentSegmentPlaybackProgress;
    
        // Only refresh the last persisted progress on segment change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSegmentPlaybackProgress?.itemSk]);

    return {
        setPlaybackProgress,
        postLatestPlaybackProgress,
    };
};

export default usePlaybackProgress;