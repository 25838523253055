import '../../styles/_modal.scss';
import '../../styles/_license-add-or-edit.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { IndividualCreditAndLicenseInfo } from '../../datasource/generated';
import {
    useAddLicenseMutation,
    useUpdateLicenseMutation,
} from '../../datasource/mutations/licenses';
import { useGetCleSessionQuery } from '../../datasource/queries/cle';
import { useGetCreditJurisdictionsQuery } from '../../datasource/queries/creditjurisdictions';
import { useGetLicensesQuery } from '../../datasource/queries/licenses';
import { useAppSelector } from '../../hooks';
import { useLicenseInfoUrl } from '../../hooks/useLicenseInfoUrl'; 
import Button from '../Button/Button';
import Dropdown, { IDropdownOption } from '../Dropdown/Dropdown';
import DropdownSearch from '../DropdownSearch/DropdownSearch';
import { LICENSE_MODAL_HEADING_ID } from '../LicenseModal/LicenseModal';
import TextInput from '../TextInput/TextInput';

export interface IAddOrEditLicenseProps {
    license?: IndividualCreditAndLicenseInfo | null;
    jurisdictionName?: string;
    navigateAfterSave: (indvStateLicense_SK: number) => void
    setIsDeletingLicense: Dispatch<SetStateAction<boolean>>,
    validateLicenseNumber: boolean,
}
interface CreditType {
    creditClassSk: number,
    creditType: string
}

interface FormErrors {
    showYearAdmittedError: boolean,
    showCreditTypeError: boolean,
    showJurisdictionError: boolean,
    showLicenseNumberError: boolean,
}

export const AddOrEditLicense = ({
    license,
    jurisdictionName,
    navigateAfterSave,
    setIsDeletingLicense,
    validateLicenseNumber,
}: IAddOrEditLicenseProps ) => {
    const [creditType, setCreditType] = useState<string | null>(null);
    const [jurisdiction, setJurisdiction]= useState<string | null>(null);
    const [complianceGroup, setComplianceGroup]= useState<string | null>(null);
    const [licenseNumber, setLicenseNumber] = useState('');
    const [yearAdmitted, setYearAdmitted] = useState('');

    const [formErrors, setFormErrors] = useState<FormErrors>({
        showYearAdmittedError: false,
        showCreditTypeError: false,
        showJurisdictionError: false,
        showLicenseNumberError: false,
    });

    const addOrEditTimeout = 1600;
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);
    const { data: cleSession } = useGetCleSessionQuery(encryptedRegistrationId ?? '');

    const { data: allCredits, isLoading } = useGetCreditJurisdictionsQuery();
    const { data: userLicenses } = useGetLicensesQuery(!encryptedRegistrationId ? skipToken : { encryptedRegistrationId });

    const [isSubmitError, setIsSubmitError] = useState(false);

    const isYearAdmittedValid = (yearAdmitted.length === 0)
        || (Number(yearAdmitted) > 1900 && Number(yearAdmitted) <= (new Date()).getFullYear());

    useEffect(() => {
        const errorElement = document.querySelector('.text-input--has-error');
        if (errorElement) {
            errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);
   
    useEffect(() => {
        if(license) {
            if(license.state) {
                setJurisdiction(license.state);
            }

            if(license.complianceGroup) {
                setComplianceGroup(license.complianceGroup);
            }
        }
    }, [license]);

    const filteredCredits = !license ?
        allCredits?.filter(credit => !userLicenses?.some(license => license.statePK === credit.state_PK)) : allCredits;

    const creditTypes =
        ((filteredCredits || [])
            .map(credit => {
                const creditType: CreditType = {
                    creditClassSk: credit.creditClass_SK,
                    creditType: credit.creditType ?? ''
                };
                return creditType;
            }))
            .filter((value, index, self) => self.map(x => x.creditClassSk).indexOf(value.creditClassSk) == index);

    const [
        addLicense,
        { isLoading: addLicenseIsLoading, isSuccess: addLicenseIsSuccess, isError: isAddLicenseError },
    ] = useAddLicenseMutation();

    const [
        updateLicense,
        { isLoading: updateLicenseIsLoading, isSuccess: updateLicenseIsSuccess, isError: isEditLicenseError },
    ] = useUpdateLicenseMutation();

    const user = useAppSelector((state) => state.user);

    const onAddLicensePress = async () => {
        setIsSubmitError(false);
        const chosenJurisdiction = allCredits?.find(credit => jurisdiction === credit.stateName);
        const chosenComplianceGroupSk = allCredits
            ?.find(credit => jurisdiction === credit.stateName)?.complianceGroups
            ?.find(group => group.shortDescription === complianceGroup)?.complianceGroup_SK;

        license = {
            indvStateLicense_SK: 0,
            individual_SK: cleSession?.individual_SK,
            creditType: creditType,
            creditClass_SK: chosenJurisdiction?.creditClass_SK,
            state: jurisdiction,
            statePK: chosenJurisdiction?.state_PK,
            stateLicenseNumber: licenseNumber,
            yearAdmitted: yearAdmitted,
            complianceGroup: complianceGroup,
            complianceGroup_SK: chosenComplianceGroupSk,
            creditRegion: chosenJurisdiction?.creditRegionShortDescription,
            creditRegion_SK: chosenJurisdiction?.creditRegion_SK
        };

        try {
            const addedLicense = await addLicense({ license, encryptedRegistrationId });
            if ('data' in addedLicense) {
                setTimeout(() => {
                    navigateAfterSave(addedLicense.data.indvStateLicense_SK ?? 0);
                }, addOrEditTimeout);
            } else {
                setIsSubmitError(true);
            }
        } catch {
            setIsSubmitError(true);
        }

    };

    const onUpdateLicensePress = async (license: IndividualCreditAndLicenseInfo) => {
        setIsSubmitError(false);
        const chosenComplianceGroupSk = allCredits
            ?.find(credit => jurisdiction === credit.stateName)?.complianceGroups
            ?.find(group => group.shortDescription === complianceGroup)?.complianceGroup_SK;

        const licenseToUpdate : IndividualCreditAndLicenseInfo = {
            ...license,
            complianceGroup: complianceGroup,
            complianceGroup_SK: chosenComplianceGroupSk,
            yearAdmitted: yearAdmitted,
            stateLicenseNumber: licenseNumber,
        };

        try {
            const updatedLicense = await updateLicense({ license: licenseToUpdate, encryptedRegistrationId });
            if ('data' in updatedLicense) {
                setTimeout(() => {
                    navigateAfterSave(licenseToUpdate.indvStateLicense_SK ?? 0);
                }, addOrEditTimeout);
            }  else {
                setIsSubmitError(true);
            }
        } catch {
            setIsSubmitError(true);
        }
       
    };

    const creditTypeOptions : IDropdownOption[] = useMemo(() => creditTypes.map(creditType =>
    {
        const dropDownOption: IDropdownOption = {
            label: creditType.creditClassSk.toString(),
            value: creditType.creditType
        };
        return dropDownOption;
    }), [creditTypes]);

    const jurisdictionOptions : IDropdownOption[] = useMemo(() =>
    {
        return filteredCredits ?
            filteredCredits.filter(credit => credit.creditType === creditType)
                .map(credit => {
                    const dropDownOption : IDropdownOption = {
                        label: credit.state_PK ?? '',
                        value: credit.stateName ?? '',
                    };
                    return dropDownOption;
                })
            : [];

    }, [filteredCredits, creditType]);

    const complianceGroupOptions : IDropdownOption[] = useMemo(() =>
    {
        return filteredCredits ?
            filteredCredits.filter(credit => credit.stateName === jurisdiction)
                .flatMap(credit => {
                    return credit.complianceGroups ?
                        credit.complianceGroups.map(group => {
                            const dropDownOption : IDropdownOption = {
                                label: group.complianceGroup_PK ?? '',
                                value: group.shortDescription ?? '',
                            };
                            return dropDownOption;
                        })
                        : [];
                }) : [];
    }, [filteredCredits, jurisdiction]);

    const isLicenseNumberRequired =
        (validateLicenseNumber &&
            filteredCredits?.find(credit => credit.stateName === jurisdiction)?.isLicenseNumberRequired)
        || false;

    useEffect(() => {
        if (creditTypeOptions.length === 1) {
            setCreditType(creditTypeOptions[0].value);
        }

        if (complianceGroupOptions.length === 1) {
            setComplianceGroup(complianceGroupOptions[0].value);
        }

    }, [complianceGroupOptions, creditTypeOptions]);

    useEffect(() => {
        if (jurisdictionOptions.length === 1) {
            setJurisdiction(jurisdictionOptions[0].value);
        }
    
    }, [
        jurisdictionOptions,
        jurisdiction,
        creditType
    ]);

    const hasMissingFields = () => {
        return (license && isLicenseNumberRequired && !licenseNumber) ||
            (!license && (!creditType
                || !jurisdiction
                || isLicenseNumberRequired && !licenseNumber));
    };

    const licenseNumberName = allCredits?.find(credit => jurisdiction === credit.stateName)?.creditRegionLicenseNumberName;
    
    const handleSubmit = () => {
        const showYearAdmittedError = yearAdmitted.length > 0 && !isYearAdmittedValid;
        const showCreditTypeError = !license && !creditType;
        const showJurisdictionError = !license && !jurisdiction;
        const showLicenseNumberError = isLicenseNumberRequired && !licenseNumber;

        setFormErrors({ showYearAdmittedError, showCreditTypeError, showJurisdictionError, showLicenseNumberError });

        if(hasMissingFields()
        || showYearAdmittedError
        || showCreditTypeError
        || showJurisdictionError
        || showLicenseNumberError) return;

        license ? onUpdateLicensePress(license) : onAddLicensePress();
    };

    const fallbackEditJurisdictionName = license && `${license.creditType == 'MCLE' ? 'CLE' : license.creditType} for ${license.state}`;

    const handleCreditTypeSelection = (selection: string) => {
        setCreditType(selection ?? '');
        setJurisdiction(null);
        setComplianceGroup(null);
    };

    return (
        <>
            <form
                className="license-add-or-edit__form"
                onSubmit={(e) => e.preventDefault()}
                noValidate
            >
                <div className='watch-modal__content license-add-or-edit__container'>
                    <div>
                        <h1 id={LICENSE_MODAL_HEADING_ID} className='heading-4'>
                            {license
                                ? `${jurisdictionName ?? fallbackEditJurisdictionName}`
                                : 'Add a license'}
                        </h1>
                        <p className='paragraph-2 license-add-or-edit__required mb-0'>* Indicates required field</p>
                    </div>

                    <div>
                        { !license && (
                            <div>

                                <DropdownSearch 
                                    options={[...creditTypeOptions.values()]} 
                                    setSelection={handleCreditTypeSelection}
                                    dropdownLabel={'Credit type'}
                                    dropdownSelection={creditType ?? ''}
                                    required={true}
                                    isLoading={isLoading}
                                    showError={formErrors.showCreditTypeError}
                                    ariaLabel={'Credit type'}
                                />
                                <DropdownSearch 
                                    options={[...jurisdictionOptions.values()]} 
                                    setSelection={(selection: string) =>
                                        setJurisdiction(selection ?? '')
                                    }
                                    dropdownLabel='Jurisdiction'
                                    dropdownSelection={jurisdiction ?? ''}
                                    required={true}
                                    isLoading={isLoading}
                                    showError={formErrors.showJurisdictionError}
                                    ariaLabel={'Jurisdiction'}
                                />
                            </div>
                        )}

                        <TextInput
                            errorText={'This field is required'}
                            initialValue={license?.stateLicenseNumber ?? ''}
                            inputLabel={licenseNumberName ? licenseNumberName : 'License number'}
                            inputValueForParent={(text) => setLicenseNumber(text)}
                            showInputError={isLicenseNumberRequired && formErrors.showLicenseNumberError}
                            required={isLicenseNumberRequired}
                        />
                        <TextInput
                            errorText={'Please supply a valid year'}
                            initialValue={license?.yearAdmitted ?? ''}
                            inputLabel={'Year admitted/licensed'}
                            inputValueForParent={(text) => setYearAdmitted(text)}
                            showInputError={formErrors.showYearAdmittedError}
                            required={false}
                        />
                        {(complianceGroupOptions.length > 0 || license?.complianceGroup) &&
                        <Dropdown
                            items={[...complianceGroupOptions.values()]}
                            dropdownLabel='Compliance group'
                            dropdownSelection={complianceGroup ?? ''}
                            setSelection={(selection: string) =>
                                setComplianceGroup(selection ?? '')
                            }
                            required={false}
                            ariaLabel={'Compliance group'}
                        />
                        }
                        { license && (
                            <Button
                                buttonClass='border-0 license-add-or-edit__link-button'
                                label='Remove from my profile'
                                action={() => setIsDeletingLicense(true)}
                            />
                        )}                        
                    </div>
                </div>
                <div className='watch-modal__footer'>
                    <div className='watch-modal__footer__button-container'>
                        {
                            (isAddLicenseError || isEditLicenseError || isSubmitError) && 
                            <div className='pb-1'>
                                <i className='watch watch-alert pr-1 text--system-red' aria-hidden={true}/>
                                <span id='save-license-error' className='sentence-label text--system-red'>
                                    Error: There was a problem saving your license. Please try again later.
                                </span>
                            </div>
                        }
                        <a href={useLicenseInfoUrl()} className='border-0 license-add-or-edit__link-button' 
                            target='_blank' rel='noopener noreferrer'>
                                Don’t know your license number? Go here
                            <i className='watch watch-outbound-link' aria-hidden='true'></i>
                        </a>
                        
                        <Button
                            buttonClass={`sticky-button mt-4 ${
                                addLicenseIsSuccess || updateLicenseIsSuccess
                                    ? 'sticky-button--success'
                                    : ''
                            }`}
                            label={addLicenseIsSuccess || updateLicenseIsSuccess
                                ? 'Your profile has been updated'
                                : (license ? 'Save to my profile' : 'Add to my profile')}
                            action={handleSubmit}
                            typeSubmit={true}
                            icon={addLicenseIsSuccess || updateLicenseIsSuccess ? 'checkmark' : undefined}
                            iconPosition={addLicenseIsSuccess || updateLicenseIsSuccess ? 'left' : undefined}
                            isLoading={addLicenseIsLoading || updateLicenseIsLoading}
                            aria-describedby={(isAddLicenseError || isEditLicenseError || isSubmitError) ? 'save-license-error' : ''}
                            isSuccessState = {addLicenseIsSuccess || updateLicenseIsSuccess}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};
