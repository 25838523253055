import '../../styles/_bookmarks-panel.scss';

import React, { useMemo } from 'react';

import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { SegmentsTabSelection } from '../../enums/TabSelection';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useBookmarks } from '../../hooks/useBookmarks';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Bookmark from '../Bookmark/Bookmark';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import { setUserNotes } from '../Store/bookmarkSlice';
import { setSelectedTab } from '../Store/segmentSlice';
import { Tabs } from '../Tabs/Tabs';

export type IBookmarksPanelProps = {
    title?: string;
    subtitle?: string;
    onPlaySegment(): void;
    onBookmarkChange(option: 'add' | 'update' | 'delete', bookmarkId?: number, userNotes?: string, timestamp?: string): void;
};

export const BookmarksPanel = (props: IBookmarksPanelProps) => {
    const { title, subtitle, onBookmarkChange: handleBookmarkChange, onPlaySegment: handlePlaySegment } = props;
    const { bookmarks, isLoading, isSuccess, isError } = useBookmarks();
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const selectedTab = useAppSelector((state) => state.segment.selectedTab);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const dispatch = useAppDispatch();

    const segmentbookmarks = useMemo(() => {
        return bookmarks?.filter(b => b.webSegmentSk == currentSegmentItemSk || selectedTab);
    }, [selectedTab, bookmarks, currentSegmentItemSk]);

    const handleAddBookmark = () => {
        dispatch(setUserNotes(''));
        handleBookmarkChange('add');
    };

    return (
        <div className='bookmarks-panel'>
            {isLoading && <Loader />}
            {isSuccess && (
                <>
                    <div className='bookmarks-panel__bookmarks'>
                        <div className='small-panel__heading'>
                            <h3 className='heading-5'>{title}</h3>
                            <h4 className='sentence-label text--medium-grey mb-0'>{subtitle}</h4>
                        </div>
                        {
                            bookmarks && bookmarks.length > 0 && launchDetails?.segments && launchDetails?.segments.length > 1 &&
                    <Tabs.Root>
                        <Tabs.Option
                            onClick={() => dispatch(setSelectedTab(SegmentsTabSelection.ThisSegment))}
                            isActive={selectedTab === SegmentsTabSelection.ThisSegment}
                        >
                            This segment
                        </Tabs.Option>
                        {!isLargeScreen && <span className='spacer'></span>}
                        <Tabs.Option
                            onClick={() => dispatch(setSelectedTab(SegmentsTabSelection.FullProgram))}
                            isActive={selectedTab === SegmentsTabSelection.FullProgram}
                        >
                            Full program
                        </Tabs.Option>
                        {!isLargeScreen && <span className='spacer--grow'></span>}
                    </Tabs.Root>
                        }
                        {
                            segmentbookmarks && segmentbookmarks.length > 0 && segmentbookmarks.map((bookmark, i) => {
                                return <Bookmark
                                    key={i}
                                    bookmarkId={bookmark.bookmarkId ?? 0}
                                    webSegmentSk={bookmark.webSegmentSk ?? 0}
                                    dateCreated={bookmark.dateCreated ?? ''}
                                    clipPosition={bookmark.clipPosition ?? 0}
                                    userNotes={bookmark.userNotes ?? ''}
                                    segmentTitle={selectedTab ? launchDetails.segments?.find(s => s.itemSk == bookmark.webSegmentSk)?.title ?? '' : ''}
                                    onBookmarkChange={handleBookmarkChange}
                                    onPlaySegment={handlePlaySegment}
                                />;
                            })
                        }
                        {
                            segmentbookmarks && segmentbookmarks.length == 0 &&
                    <div className='paragraph-2 mt-3 text--medium-grey'>You currently have no saved notes. To create your first note, click on the ‘New note’ button below.</div>
                        }
                    </div>
                    <div className='bookmarks-panel__button-container'>
                        <Button
                            label='New Note'
                            aria-label='Add new note'
                            buttonClass='sticky-button sticky-button__alt'
                            action={handleAddBookmark}
                        />
                    </div>
                </>
            )}
            {isError && <MainError errorContainer={ErrorContainer.SmallPanel} />}
        </div>
    );
};

export default BookmarksPanel;
