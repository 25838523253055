import '../../styles/_support-panel.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Question, QuestionType } from '../../datasource/generated';
import { useAddQuestionMutation } from '../../datasource/mutations/questions';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import logger from '../../utility/logger';
import Button from '../Button/Button';
import { Dropdown } from '../Dropdown/Dropdown';
import {
    setEmail,
    setName,
    setQuestion,
    setSubject,
} from '../Store/reportAProblemSlice';
import TextArea from '../TextArea/TextArea';
import { TextInput } from '../TextInput/TextInput';

export type IReportAProblemProps = {
    closeSubPanel(): void;
};

interface IErrorStates {
    name: boolean;
    email: boolean;
    subject: boolean;
    question: boolean;
}

export const ReportAProblem = ({ closeSubPanel }: IReportAProblemProps) => {
    const user = useAppSelector((state) => state.user);
    const reportAProblemState = useAppSelector((state) => state.reportAProblem);
    const dispatch = useAppDispatch();
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const { pushToDataLayer } = useAnalytics();

    const [success, setSuccess] = useState(false);
    const [formErrorStates, setFormErrorStates] = useState<IErrorStates>({
        name: false,
        email: false,
        subject: false,
        question: false,
    });
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [submitQuestion, { isLoading }] = useAddQuestionMutation();

    const currentSegmentItemSk = useAppSelector(
        (state) => state.player.currentSegmentItemSk
    );

    useEffect(() => {
        const textInputErrorElement = document.querySelector('.text-input--has-error');
        const textAreaErrorElement = document.querySelector('.text-area--has-error');
        const dropdownErrorElement = document.querySelector('.dropdown__button--has-error');
        
        if (textInputErrorElement) {
            textInputErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (textAreaErrorElement) {
            textAreaErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (dropdownErrorElement) {
            dropdownErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [formErrorStates]);

    const itemSk = useAppSelector((state) => state.player.itemSk);

    //replace with Epi
    const subjectOptions = [
        { value: 'Streaming Media', label: 'Streaming Media' },
        { value: 'Slides and Polling', label: 'Slides and Polling' },
        {
            value: 'Course Handbook/Slides/Other Materials',
            label: 'Course Handbook/Slides/Other Materials',
        },
        {
            value: 'Credit Information/Usage History',
            label: 'Credit Information/Usage History',
        },
        { value: 'Other', label: 'Other' },
    ];

    const handleResetForm = useCallback(() => {
        dispatch(setName(user.name));
        dispatch(setEmail(user.email));
        dispatch(setSubject(''));
        dispatch(setQuestion(''));
    }, [dispatch, user.name, user.email]);

    const handleSubmitForm = useCallback(async () => {
        try {
            //passing all params to email (email only renders question)
            const newQuestionText = `<div>From: ${
                reportAProblemState.email
            },</div>
                <div>individualSK: ${user.individualSK},</div>
                <div>itemSK: ${itemSk},</div> 
                <div>SegmentSK: ${currentSegmentItemSk ?? ''},</div>
                <div>Subject: ${reportAProblemState.subject},</div> 
                <div>Question: ${reportAProblemState.question}</div>`;

            const requestInput: Question = {
                itemSK: itemSk,
                individualSK: user.individualSK,
                segmentSK: currentSegmentItemSk ?? null,
                questionText: newQuestionText,
                emailFrom: reportAProblemState.email,
                questionType: QuestionType.TECHNICAL_PROBLEM,
            };
            const response = await submitQuestion(requestInput);
            if (!response || !('data' in response) || !response.data) {
                logger.error('Error trying to send a question', {
                    individualSk: user.individualSK,
                    itemSk: itemSk,
                    requestInput: requestInput,
                });
            }
            pushToDataLayer({ event: 'support_submit' });
            setSuccess(true);
            setTimeout(() => {
                closeSubPanel();
                handleResetForm();
            }, 1000);
        } catch (e) {
            logger.error('Error trying to send a question', {
                individualSk: user.individualSK,
                itemSk: itemSk,
                error: e,
            });
        }
    }, [
        submitQuestion,
        reportAProblemState.email,
        user.individualSK,
        reportAProblemState.question,
        reportAProblemState.subject,
        itemSk,
        currentSegmentItemSk,
        closeSubPanel,
        handleResetForm,
        pushToDataLayer,
    ]);

    const emailRegex = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}/);

    const handleSubmitClick = useCallback(() => {
        //validate before submission
        const isValid =
            reportAProblemState.name.length > 0 &&
            emailRegex.test(reportAProblemState.email.toLowerCase()) &&
            reportAProblemState.subject.length > 0 &&
            reportAProblemState.question.length > 0;

        if (isValid) {
            handleSubmitForm();
        } else {
            setFormErrorStates({
                name: reportAProblemState.name.length === 0,
                email: !emailRegex.test(
                    reportAProblemState.email.toLowerCase()
                ),
                subject: reportAProblemState.subject.length === 0,
                question: reportAProblemState.question.length === 0,
            });
        }
    }, [
        emailRegex,
        reportAProblemState.email,
        reportAProblemState.name,
        reportAProblemState.subject,
        reportAProblemState.question,
        handleSubmitForm,
    ]);

    const handleSetName = (name: string) => {
        dispatch(setName(name));
        formErrorStates.name &&
            reportAProblemState.name.length > 0 &&
            setFormErrorStates({ ...formErrorStates, name: false });
    };

    const handleSetEmail = (email: string) => {
        dispatch(setEmail(email));

        if (formErrorStates.email) {
            emailRegex.test(reportAProblemState.email.toLowerCase()) &&
                setFormErrorStates({ ...formErrorStates, email: false });
        }
    };

    const handleSetSubjectSelection = (selection: string) => {
        dispatch(setSubject(selection ?? ''));
        setFormErrorStates({ ...formErrorStates, subject: false });
    };

    const handleSetQuestion = (question: string) => {
        dispatch(setQuestion(question));
        setFormErrorStates({ ...formErrorStates, question: false });
    };

    const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        // onFocus is propagated, so the element is not semantically focusable but its children are
        <div className='support-panel__sub-panel' onFocus={handleFocus}>
            <div className='support-panel__sub-panel__title'>
                <h3
                    className={`${
                        isLargeScreen ? 'paragraph-1--medium' : 'heading-5'
                    }`}
                >
                    Report a problem
                </h3>
                <span className='sentence-label'>* Indicates required field</span>
            </div>

            <form
                className='support-panel__form'
                onSubmit={(e) => e.preventDefault()}
                noValidate
            >
                <div>
                    <TextInput
                        errorText='This field is required'
                        initialValue={
                            reportAProblemState.name !== ''
                                ? reportAProblemState.name
                                : (user.isGroupcast ? '' : user.name)
                        }
                        inputLabel='Name'
                        inputValueForParent={handleSetName}
                        showInputError={formErrorStates.name}
                        required={true}
                    />
                    <TextInput
                        errorText='Please enter a valid email address'
                        initialValue={
                            reportAProblemState.email !== ''
                                ? reportAProblemState.email
                                : user.email
                        }
                        inputLabel='Email'
                        inputValueForParent={handleSetEmail}
                        showInputError={formErrorStates.email}
                        required={true}
                    />
                    <Dropdown
                        items={subjectOptions}
                        dropdownLabel='Subject'
                        dropdownSelection={reportAProblemState.subject ?? ''}
                        setSelection={handleSetSubjectSelection}
                        showError={formErrorStates.subject}
                        required={true}
                        ariaLabel={reportAProblemState.subject ? `Selected Subject: ${reportAProblemState.subject}` : 'Select Subject'}
                    />
                    <TextArea
                        currentValue={reportAProblemState.question}
                        updateValue={handleSetQuestion}
                        showTextAreaError={formErrorStates.question}
                        maxLength={500}
                        required={true}
                        textAreaLabel='Message'
                        ariaLabel='Enter issue description, 500 characters maximum'
                        ref={textAreaRef}
                    />
                </div>
                <div className='support-panel__sub-panel__button-container'>
                    <Button
                        type='button'
                        label={success ? 'Sent' : 'Send'}
                        buttonClass={`sticky-button ${
                            success ? 'sticky-button--success' : ''
                        }`}
                        typeSubmit={true}
                        action={handleSubmitClick}
                        icon={success ? 'checkmark' : undefined}
                        iconPosition='left'
                        isLoading={isLoading}
                        aria-label={success ? 'Sent successfully' : 'Send'}
                        isSuccessState= {success}
                    />
                </div>
            </form>
        </div>
    );
};

export default ReportAProblem;
