import { useCallback } from 'react';

import { MultimediaNotification } from '../datasource/generated';
import { useGetAlertsAndAnnouncementsQuery } from '../datasource/queries/cmsMessages';
import { addAlert, addAnnouncement, Announcement, removeToast,Toast } from '../stories/Store/toastSlice';
import { formatErrorMessage } from '../utility/toastUtils';
import { useAppDispatch } from '.';

function htmlDecode(input: string) {
    const doc = new DOMParser()
        .parseFromString(input, 'text/html');
    return doc.documentElement.textContent ?? '';
}

export const useToasts = () => {
    const dispatch = useAppDispatch();
    const { data: alertsAndAnnoncements } = useGetAlertsAndAnnouncementsQuery();

    const addToastsFromNotifications = useCallback((notifications: MultimediaNotification[]) => {
        const parsed: (Announcement | null)[] = notifications.map(notification => {
            const availableDate = Date.parse(notification.availableDate ?? '');
            const expirationDate = Date.parse(notification.expirationDate ?? '');

            const timeToAvailableDateInMs = Date.now() - availableDate;
            const isAfterAvailableDate = timeToAvailableDateInMs >= 0;
        
            const timeToExpirationDateInMs = expirationDate - Date.now();
            const isBeforeExpirationDate = timeToExpirationDateInMs >= 0;
        
            let expirationTimeoutId = 0;
            if (isBeforeExpirationDate) {
                expirationTimeoutId = setTimeout(
                    () => {
                        dispatch(removeToast(notification.notificationId!));
                    },
                    Math.abs(timeToExpirationDateInMs)) as unknown as number;
            }
            
            let availableTimeoutId = 0;
            if (isBeforeExpirationDate && !isAfterAvailableDate) {
                availableTimeoutId = setTimeout(
                    () => dispatch(addAnnouncement({ 
                        id: notification.notificationId!, 
                        contentType: 'html', 
                        htmlContent: htmlDecode(notification.notificationText ?? ''), 
                        expirationTimeoutId: expirationTimeoutId,
                    })),
                    Math.abs(timeToAvailableDateInMs)) as unknown as number;
            }
            
            const shouldShowAnnouncement = isAfterAvailableDate && isBeforeExpirationDate;
            if (shouldShowAnnouncement) {
                return { 
                    id: notification.notificationId!, 
                    contentType: 'html' as const, 
                    htmlContent: htmlDecode(notification.notificationText ?? ''), 
                    cta: undefined,
                    message: '',
                    type: 'announcement' as const, 
                    availableTimeoutId: availableTimeoutId,
                    expirationTimeoutId: expirationTimeoutId,
                };
            }
            
            return null;
        });
        
        const filtered = parsed.filter((toast): toast is Toast => !!toast);
        
        filtered.map(t => dispatch(addAnnouncement(t)));
    },
    [dispatch]);

    
    const showAlertToast = useCallback(async (event: any) => {
        let message = 'Something wrong happened';
        switch (event.code) {
            case 221000:
            case 230002:
            case 232002:
                message = formatErrorMessage(alertsAndAnnoncements?.internetConnectionErrorMessage ?? message, event.code);
                break;
        
            default:
                message = formatErrorMessage(alertsAndAnnoncements?.defaultJWPlayerErrorMessage ?? message, event.code);
        }

        dispatch(addAlert({ contentType: 'html', htmlContent: message }));
    }, [alertsAndAnnoncements?.defaultJWPlayerErrorMessage, alertsAndAnnoncements?.internetConnectionErrorMessage, dispatch]);
            
    return {
        addToastsFromNotifications,
        showAlertToast,
    };
};