import { apiSlice } from '..';
import { Bookmark } from '../generated';

const bookmarksApiEndpoint = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        addBookmark: build.mutation<boolean, Bookmark>({
            query: (body) => ({
                url: '/bookmarks',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Bookmarks'],
        }),
        updateBookmark: build.mutation<boolean, Bookmark>({
            query: (body) => ({
                url: '/bookmarks',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Bookmarks'],
        }),
        deleteBookmark: build.mutation<boolean, number>({
            query: (bookmarkId: number) => ({
                url: `/bookmarks/${bookmarkId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Bookmarks'],
        })
    }),
    overrideExisting: true,
});

export const { useAddBookmarkMutation, useUpdateBookmarkMutation, useDeleteBookmarkMutation } = bookmarksApiEndpoint;
export default bookmarksApiEndpoint;
  