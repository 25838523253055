import '../../styles/_toast.scss';

import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';

import CloseButton from '../CloseButton/CloseButton';

const Icon = ({ className }: { className: string }) => {
    return (
        <i className={`watch watch-${className}`}></i>
    );
};

const Content = ({ children }: PropsWithChildren) => {
    return (
        <div className='watch-toast__content'>
            { children }
        </div>
    );
};

const Button = ({ children, ...otherProps }: PropsWithChildren & ButtonHTMLAttributes<HTMLButtonElement> ) => {
    return (
        <CloseButton 
            buttonClass='close-button'
            {...otherProps}
        />
    );
};

interface ToastRootProps extends PropsWithChildren {
    align?: 'inline' | 'stack',
    type: 'alert' | 'announcement',
}

const Root = ({ children, align = 'inline', type }: ToastRootProps) => {
    return (
        <div className={`watch-toast watch-toast__${align} watch-toast__${type}`}>
            {children}
        </div>
    );
};

const Header = ({ children }: PropsWithChildren) => {
    return (
        <div className={'watch-toast__header'}>
            {children}
        </div>
    );
};

export const Toast = {
    Root,
    Icon,
    Button,
    Content,
    Header,
};