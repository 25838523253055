import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { CleTimeStamp } from '../../datasource/generated';
import { CreditTabSelection } from '../../enums/TabSelection';

export interface CleTimerEvent {
    registrationId: string,
    itemSk: number,
    weight: number,
    indexGuid: string,
    displayDateSeconds: number,
    sequence: number,
    minWeight: number,
    maxWeight: number,
}

export interface CleState {
    sessionGuid: string;
    timeStampEvent: CleTimerEvent | null;
    failedTimeStamps: Array<CleTimeStamp>;
    selectedTab: CreditTabSelection;
}

const initialState: CleState = {
    sessionGuid: '',
    timeStampEvent: null,
    failedTimeStamps: new Array<CleTimeStamp>(),
    selectedTab: CreditTabSelection.Progress,
};

const cleSlice = createSlice({
    name: 'cle',
    initialState,
    reducers: {
        setSessionGuid(state, action: PayloadAction<string>) {
            state.sessionGuid = action.payload;
        },
        setTimeStampEvent(state, action: PayloadAction<CleTimerEvent>) {
            state.timeStampEvent = { ...action.payload };
        },
        clearTimeStampEvent(state) {
            state.timeStampEvent = null;
        },
        setFailedTimeStamps(state, action: PayloadAction<Array<CleTimeStamp>>) {
            state.failedTimeStamps = action.payload;
        },
        setSelectedTab(state, action: PayloadAction<CreditTabSelection>) {
            state.selectedTab = action.payload;
        },
    },
});

export const { setSessionGuid, setTimeStampEvent, clearTimeStampEvent, setFailedTimeStamps, setSelectedTab } = cleSlice.actions;

export const { reducer: cleReducer } = cleSlice;
