import { useGetCmsSettingsQuery } from '../datasource/queries/cmsSettings';

export const useLicenseInfoUrl = () => {
    const {
        data: watchSettings
    } = useGetCmsSettingsQuery();

    try {
        if (watchSettings?.licenseInfoUrl && new URL(watchSettings.licenseInfoUrl)) {
            return watchSettings.licenseInfoUrl;
        }
    } catch {
        //
    } 
    return 'https://www.pli.edu/attorney_search';

};