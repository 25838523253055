import { throttle } from 'lodash-es';
import React, { createContext, useContext, useEffect, useState } from 'react';

/** Same used by [react-native-netinfo](https://github.com/react-native-netinfo/react-native-netinfo?tab=readme-ov-file#netinfoconfiguration) */
const CONNECTION_POLLING_URL = 'https://clients3.google.com/generate_204';
const CONNECTION_POLLING_TIMEOUT = 15_000; // ms
const POLLING_INTERVAL = 60_000; // ms

export interface ConnectivityContext {
    connected: boolean;
}

const connectivityContext = createContext<ConnectivityContext>({
    connected: true,
});

export const ConnectivityProvider = ({ children }: React.PropsWithChildren) => {
    const [connected, setConnected] = useState(navigator.onLine);

    useEffect(() => {
        let unmounted = false;
        const checkInternetReachability = throttle(() => {
            checkInternetReachability.cancel();

            if (!navigator.onLine) {
                setConnected(false);
                return;
            }

            const abort = new AbortController();

            const timeout = setTimeout(() => abort.abort(), CONNECTION_POLLING_TIMEOUT);

            fetch(CONNECTION_POLLING_URL, {
                signal: abort.signal,
                method: 'HEAD',
                mode: 'no-cors',
            }).then(() => {
                if (unmounted) return;
                setConnected(true);
            }).catch(() => {
                console.log('offline');
                setConnected(false);
            }).finally(() => {
                clearTimeout(timeout);
            });
            
            return () => {
                unmounted = false;
                clearTimeout(timeout);
            };
        }, 10_000);

        window.addEventListener('offline', checkInternetReachability);
        window.addEventListener('online', checkInternetReachability);

        const pollingInterval = setInterval(checkInternetReachability, POLLING_INTERVAL);
        
        const cleanup = checkInternetReachability();
        return () => {
            window.removeEventListener('offline', checkInternetReachability);
            window.removeEventListener('online', checkInternetReachability);
            clearInterval(pollingInterval);
            cleanup?.();
        };
    }, []);

    return (
        <connectivityContext.Provider value={{ connected }}>
            {children}
        </connectivityContext.Provider>
    );
};

export const useConnectivity = () => useContext(connectivityContext);
