import React, { useEffect } from 'react';

interface IRedirectProps {
   url: string;
}

export const Redirect = (props: IRedirectProps) => {
    const { url } = props;
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return <h5>Redirecting...</h5>;
};
