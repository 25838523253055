import { IStepInformation } from './Tutorial';

export const GroupcastTutorialSteps = [
    {
        headerText: 'Confirm credit eligibility',
        bodyText: 'If you and/or the groupcast attendees are seeking credit, please verify this program’s eligibility for credit and the expiration date in your jurisdiction before hosting or attending.',
    },
    {
        headerText: 'Access everything you need',
        bodyText: 'You’ll be able to easily find all the details, instructions, and forms you’ll need for groupcast viewing under the “Credit” icon on the side navigation.',
    },
    {
        headerText: 'Submit for credit within 2 days',
        bodyText: 'Once the groupcast is complete, the required forms will need to be completed and sent in. If you are the host, ensure all necessary forms are filled out so all attendees receive their credit. If you’re an attendee, the groupcast host will do this for you.',
    },

] as Array<IStepInformation>;
