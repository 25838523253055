import '../../styles/_toggle.scss';

import React from 'react';

export interface IToggleProps {
    id: string;
    name?:string;
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (e:React.SyntheticEvent<HTMLInputElement>) => void;
    color: 'amethyst' | 'emerald';
    ariaLabel?: string;
    cssClass?: string;
    isWithinPlayerPortal?: boolean;
    showCheckmark?: boolean;
    size?: 'default' | 'large';
    value?: string;
    required?: boolean;
}

export const Toggle = React.forwardRef<HTMLInputElement, IToggleProps>((props,ref) => {
    const { id, name, checked, defaultChecked, onChange, color, ariaLabel, cssClass, isWithinPlayerPortal, showCheckmark=true, size='default', value = 'on', required = false } = props;

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!isWithinPlayerPortal) return;
        if (e.code === 'Space') {
            onChange?.(e);
        }
    };

    return (
        <input
            ref={ref}
            id={id}
            name={name}
            className={`toggle toggle--${color} toggle--${size} ${showCheckmark ? 'toggle--checkmark': ''} ${cssClass ?? ''}`}
            type='checkbox'
            checked={checked}
            defaultChecked={defaultChecked}
            onKeyUp={onKeyUp}
            onChange={onChange}
            aria-label={ariaLabel}
            required={required}
            value={value}
        />
    );
});
Toggle.displayName='Toggle';

export default Toggle;
