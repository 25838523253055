import '../../styles/_survey.scss';

import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import CreditSubmissionConfimrationPanel from '../CreditSubmissionConfirmationPanel/CreditSubmissionConfirmationPanel';
import ProgramEvaluationSurvey from '../ProgramEvaluationSurvey/ProgramEvaluationSurvey';
import { setEncryptedRegistrationId } from '../Store/requestCreditSlice';

export type ISurveyProps = {
    rid?: string;
}

export function SurveyPage({ rid }: ISurveyProps) { 
    const dispatch = useAppDispatch();
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const [shouldShowConfirmation, setShouldShowConfirmation] = React.useState(false);

    useEffect(() => {
        
        const params = new URL(location.href).searchParams;

        const encryptedRegistrationId = rid ?? params.get('rid');

        if (encryptedRegistrationId)
            dispatch(setEncryptedRegistrationId(encryptedRegistrationId));
    }, [dispatch, rid]);

    const handleShowSurveyConfirmation = () => {
        setShouldShowConfirmation(true);
    };

    return (
        <div className='survey__standalone'>
            {shouldShowConfirmation ? (
                <CreditSubmissionConfimrationPanel isStandalone={true}/>
            ) : (
                <ProgramEvaluationSurvey encryptedRegistrationId={encryptedRegistrationId} isStandalone={true} 
                    onSurveySubmissionSuccess={handleShowSurveyConfirmation} />
            )}
        </div>
    );
}