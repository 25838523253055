import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { MenuOptions } from '../enums/MenuOptions';
import { setSmallPanelOpenCommand } from '../stories/Store/menuSlice';
import { ExternalFullscreenToggle, setExternalFullscreenToggle } from '../stories/Store/playerSlice';
import { useAppDispatch, useAppSelector } from '.';
import { MediaLogEventTypes, useLogMediaEvents } from './useLogMediaEvents';

export const usePollingIframe = (setPollingIframe : Dispatch<SetStateAction<React.JSX.Element | undefined>>) => {
    const dispatch = useAppDispatch();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const { logMediaEvent } = useLogMediaEvents();

    useEffect(() => {

        const pollEventListener = (event: MessageEvent) => {
            if (!launchDetails.pollSessionUrl) return;

            try {
                const eventOrigin = new URL(launchDetails.pollSessionUrl).origin;
                if (event.origin === eventOrigin && !launchDetails.isOffAir) {
                    if (event.data.event === 'poll_ready') {
                        dispatch(setSmallPanelOpenCommand(MenuOptions.QandA));
                        dispatch(setExternalFullscreenToggle(ExternalFullscreenToggle.off));
                    }
                }
            } catch (err) {
                const error = err as Error;
                logMediaEvent(MediaLogEventTypes.ERROR, `Polling Detector onMessage(): ${error.message}`);
            }
        };

        if (launchDetails.isWebcast && !!launchDetails.pollSessionUrl) {
            const iframe = <iframe 
                id='hiddenCioPollingIframe' 
                title='Hidden Conferences.IO IFrame'
                src={launchDetails.pollSessionUrl} 
                aria-hidden={true} 
                style={{ 'display': 'none' }} />;
            setPollingIframe(iframe);
            window.addEventListener('message', pollEventListener);
        }

        return () => {
            setPollingIframe(undefined);
            window.removeEventListener('message', pollEventListener);
        };
    }, [dispatch, launchDetails.isOffAir, launchDetails.isWebcast, launchDetails.pollSessionUrl, logMediaEvent, setPollingIframe]);
};

export default usePollingIframe;