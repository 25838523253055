import JWPlayer from '@jwplayer/jwplayer-react';
import initJWPlayerMux from '@mux/mux-data-jwplayer';
import { MutableRefObject, useCallback } from 'react';

import { LaunchSegment } from '../datasource/generated';
import { useGetClientConfigQuery } from '../datasource/queries/clientConfig';
import { useAppSelector } from '.';

export const useMux = () => {
    const { data: clientConfig } = useGetClientConfigQuery();
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const individualSK = useAppSelector((state) => state.user.individualSK);

    const initializeMux = useCallback((playerRef: MutableRefObject<typeof JWPlayer>, segment?: LaunchSegment) => {
        if (!clientConfig?.mux?.isEnabled || !initJWPlayerMux) {
            return;
        }

        const playerInitTime = initJWPlayerMux.utils.now();
        initJWPlayerMux(playerRef.current, {
            debug: process.env.NODE_ENV !== 'production',
            data: {
                env_key: clientConfig.mux.envKey,

                // Site Metadata
                viewer_user_id: `${individualSK}`,

                // Metadata
                player_name: 'Watch player',
                player_version: clientConfig.pliVersion,
                player_init_time: playerInitTime,

                // Video Metadata
                video_id: segment?.itemPk ?? launchDetails?.itemPk,
                video_title: segment?.title ?? launchDetails?.title,
                video_series: launchDetails?.title,
                video_stream_type: launchDetails?.isWebcast ? 'live' : 'on-demand',
                video_cdn: segment?.video?.cdn ?? launchDetails?.video?.cdn,
            }
        }, playerRef.current);
    }, [
        clientConfig?.mux?.envKey,
        clientConfig?.mux?.isEnabled,
        clientConfig?.pliVersion,
        individualSK,
        launchDetails?.isWebcast,
        launchDetails?.itemPk,
        launchDetails?.title,
        launchDetails?.video?.cdn]);

    const emitVideoChangeEvent = useCallback((playerRef: MutableRefObject<typeof JWPlayer>, segment: LaunchSegment) => {
        if (!clientConfig?.mux?.isEnabled || !initJWPlayerMux) {
            return;
        }

        playerRef.current.mux.emit('videochange', {
            video_id: segment?.itemPk,
            video_title: segment?.title,
            video_series: launchDetails?.title,
            video_stream_type: launchDetails?.isWebcast ? 'live' : 'on-demand',
            video_cdn: segment?.video?.cdn
        });
    }, [clientConfig?.mux?.isEnabled, launchDetails?.isWebcast, launchDetails?.title]);

    return {
        initializeMux,
        emitVideoChangeEvent,
    };
};