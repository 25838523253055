import '../../styles/_question-card.scss';

import React, { useEffect, useRef, useState } from 'react';

import { Question } from '../../datasource/generated';

export type IQuestionCardProps = {
    question: Question;
};

export const QuestionCard = (props: IQuestionCardProps) => {
    const { question } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const expandButtonLabel = isExpanded ? 'Collapse replies' : 'View replies';
    const cardRef = useRef<HTMLDivElement>(null);
    const expandedContent = useRef<HTMLDivElement>(null);
    const expandLabel = `${question.responses?.length} ${question.responses?.length === 1 ? 'reply' : 'replies'}`;

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const formattedDate = new Date(question.createDate ?? '').toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });

    useEffect(() => {
        if (isExpanded) {
            expandedContent.current?.focus();
            cardRef.current?.scrollIntoView( { behavior: 'smooth' } );  
        }   
    }, [isExpanded]);

    return (
        <li className='question-card' key={question.questionID}>        
            <button key={question.questionID} tabIndex={0} onClick={toggleExpand} aria-label={expandButtonLabel} >
                <p className='sentence-label question-card__posted-date mb-2'>Posted {formattedDate}</p>
                <p className='mb-2'>{question.questionText}</p>
                <span className='question-card__body'>
                    <span className='question-card__body__summary'>
                        <p className='paragraph-2--bold question-card__body__summary__responses'>{expandLabel}</p>
                        <span className='question-card__body__toggle'>
                            <i className={`watch watch-chevron-${isExpanded ? 'up' : 'down'}`}></i>
                        </span>                      
                    </span>
                </span>
            </button>                
                
            {isExpanded &&
                    <div className='question-card__expanded question-card__response__container mt-3' ref={expandedContent}>
                        {
                            question.responses?.map(response => {
                                return (
                                    <div key={response.responseID} >
                                        <div className='sentence-label question-card__response__responder mb-2'>{response.responderName} replied</div>
                                        <p className='question-card__response' >{response.responseText}</p>
                                    </div>
                                );
                            })
                        }        
                    </div>
            }
        </li>
    );
};

export default QuestionCard;