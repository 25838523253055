import '../styles/app.scss';

import React, { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { useAppDispatch } from '../hooks';
import  useApplicationInsights, { appInsights }  from '../hooks/useApplicationInsights';
import { useAuthCheck } from '../hooks/useAuthCheck';
import { useEventHubDataLayerConsumer } from '../hooks/useEventHubDataLayerConsumer';
import Home from './Home/Home';
import { Redirect } from './Redirect/Redirect';
import { RequestCreditsPage } from './RequestCreditsPage/RequestCreditsPage';
import { setUser } from './Store/userSlice';
import { SurveyPage } from './SurveyPage/SurveyPage';

export interface IAppProps {
    identity: IIdentity
}
export interface IIdentity {
    name: string;
    email: string;
    id: string;
    individualSK: number;
    isAuthorized: boolean;
    isPliEmployee: boolean;
    isGroupcast: boolean;
    isIndividual: boolean;
    itemSK: number;
    organizationSK: number;
    isPM: boolean;
    isIM: boolean;
}

function isLoggedIn(identity: IIdentity) {
    // console.info("auth: " + identity);
    return identity.isAuthorized;
}


export default function App(props: IAppProps) {
    useApplicationInsights();
    useAuthCheck();
    const { processEvents } = useEventHubDataLayerConsumer();
    const dispatch = useAppDispatch();
    const individualSk = props.identity.individualSK.toString();

    useEffect(() => {
        dispatch(setUser(props.identity));
        if(individualSk){
            appInsights?.setAuthenticatedUserContext(individualSk);
        }
        else {
            appInsights?.clearAuthenticatedUserContext();
        }
    }, [dispatch, individualSk, props.identity]);

    useEffect(() => {
        window.onbeforeunload = () => {
            processEvents();
        };
    }, [processEvents]);

    useEffect(() => {
        document.title = 'Practising Law Institute CLE Now';
    }, []);

    return (
        <Routes>
            <Route path='/' element={!isLoggedIn(props.identity) ? (<Redirect url="/login" />) : <Outlet />}>
                <Route index element={<Home />} />
                <Route path='mcle' element={<RequestCreditsPage />} />
                <Route path='survey' element={<SurveyPage />} />
            </Route>
        </Routes>
    );
}
