import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';

import { ICreditSubmissionProps } from '../datasource/mutations/creditSubmission';
import { useGetCreditSubmissionMessagesQuery } from '../datasource/queries/cmsMessages';
import { useGetLicensesQuery } from '../datasource/queries/licenses';
import { ExternalCommand, setExternalCommand } from '../stories/Store/playerSlice';
import { useAppDispatch, useAppSelector } from '.';

export const useRequestCredit = () => {
    const dispatch = useAppDispatch();
    const isWebcast = useAppSelector((state) => state.player.launchDetails.isWebcast ?? false);
    const licenses = useAppSelector((state) => state.requestCredit.licenses);
    const disabledIndvLicenseSks = useAppSelector((state) => state.requestCredit.disabledIndvLicenseSks);
    const authCode = useAppSelector((state) => state.requestCredit.authorizationCode);
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const { isError: isGetLicensesError } = useGetLicensesQuery(!encryptedRegistrationId ? skipToken : { encryptedRegistrationId });

    const activeIndvLicenses = useMemo(
        () => licenses.filter(l => !disabledIndvLicenseSks.some(d => d === l.indvStateLicense_SK)),
        [disabledIndvLicenseSks, licenses]);

    const creditSubmissionProps : ICreditSubmissionProps | undefined = useMemo(() => {
        if (!encryptedRegistrationId)
            return;
        if (!activeIndvLicenses.length)
            return;

        return {
            encryptedRegistrationId: encryptedRegistrationId,
            requestedIndvLicenseSks: activeIndvLicenses.map(l => l.indvStateLicense_SK ?? 0),
            authorizationCode: authCode,
        };
    }, [activeIndvLicenses, encryptedRegistrationId, authCode]);

    const submissionMessages = useGetCreditSubmissionMessagesQuery();
    const messages = useMemo(() => {
        return {
            title: submissionMessages.data?.requestCreditPanelTitle ?? 'Here’s what you’re submitting',
            subTitle: submissionMessages.data?.requestCreditPanelSubtitle ?? 'Please verify the below information, and only request credit once you’ve completed all segments you’d like to submit.',
            acknowledgement: submissionMessages.data?.requestCreditAcknowledgement ?? 'By clicking Continue I have acknowledged receipt of the program materials, electronically or otherwise, and certify that I have participated in the above program for the time indicated. Therefore, I request that I be awarded the applicable number of credits for this program.',
            acknowledgementPortable: submissionMessages.data?.requestCreditDownloadAcknowledgement ?? 'By clicking Continue I have acknowledged receipt of the program materials, electronically or otherwise, and certify that I have participated in the above program for the time indicated. Therefore, I request that I be awarded the applicable number of credits for this program.',
            confirmationTitle: submissionMessages.data?.requestCreditConfirmationTitle ?? 'All done. Thanks for watching.',
            confirmationBody: submissionMessages.data?.requestCreditConfirmationBody ?? 'This is confirmation that your information has been accepted. You\'ll receive your Certificate of Completion via email within 48 hours.',
            confirmationButtonText: submissionMessages.data?.requestCreditButtonText ?? 'Explore more programs at PLI',
            confirmationButtonUrl: submissionMessages.data?.requestCreditConfirmationButtonUrl ?? 'https://www.pli.edu',
        };
    }, [submissionMessages]);

    const errorMessageElementIds = useAppSelector((state) => state.requestCredit.errorMessageElementIds);
    const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(false);
    useEffect(() => {
        if (errorMessageElementIds.length > 0) {
            setIsContinueButtonDisabled(true);
        } else {
            setIsContinueButtonDisabled(false);
        }
    }, [errorMessageElementIds]);

    useEffect(() => {
        !isWebcast && dispatch(setExternalCommand(ExternalCommand.pause));
    }, [dispatch, isWebcast]);

    return {
        messages,
        isContinueButtonDisabled,
        errorMessageElementIds,
        creditSubmissionProps,
        isGetLicensesError,
    };
};