import '../../styles/_main-menu.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { MenuOptions } from '../../enums/MenuOptions';
import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { SupportOptions } from '../../enums/SupportOptions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useFaculty } from '../../hooks/useFaculty';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useMenuItems } from '../../hooks/useMenuItems';
import { usePlayerElement } from '../../hooks/usePlayerElement';
import { useProgramDetails } from '../../hooks/useProgramDetails';
import BookmarkEditor, { IBookmarkEditorProps } from '../BookmarkEditor/BookmarkEditor';
import BookmarksPanel from '../BookmarksPanel/BookmarksPanel';
import CreditProgressPanel from '../CreditProgressPanel/CreditProgressPanel';
import CreditSubmissionConfimrationPanel from '../CreditSubmissionConfirmationPanel/CreditSubmissionConfirmationPanel';
import { EndOfVideoPanelWrapper } from '../EndOfVideoPanelWrapper/EndOfVideoPanelWrapper';
import FacultyDetail from '../FacultyDetail/FacultyDetail';
import FacultyPanel from '../FacultyPanel/FacultyPanel';
import { GroupcastCreditPanel } from '../GroupcastCreditPanel/GroupcastCreditPanel';
import { LargePanel } from '../LargePanel/LargePanel';
import LivePollingQAPanel from '../LivePollingQAPanel/LivePollingQAPanel';
import { MaterialsPanel } from '../MaterialsPanel/MaterialsPanel';
import { ModalPanel } from '../ModalPanel/ModalPanel';
import OnDemandQuestionPanel from '../OnDemandQuestionPanel/OnDemandQuestionPanel';
import OverlayMenu from '../OverlayMenu/OverlayMenu';
import { RequestCreditPanel } from '../RequestCreditPanel/RequestCreditPanel';
import SegmentDetails from '../SegmentDetails/SegmentDetails';
import SegmentsPanel from '../SegmentsPanel/SegmentsPanel';
import SideBar from '../SideBar/SideBar';
import SmallPanel from '../SmallPanel/SmallPanel';
import { setUserNotes } from '../Store/bookmarkSlice';
import { clearLargePanelOpenCommand, clearSmallPanelOpenCommand } from '../Store/menuSlice';
import SupportPanel from '../SupportPanel/SupportPanel';

export type IMainMenuProps = {
    standalone?: { type: 'large' | 'small', menu: MenuOptions };
    setIsMenuOpen?: (menuOpen: boolean) => void;
}

export function MainMenu({ standalone, setIsMenuOpen }: IMainMenuProps) {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const { faculty } = useFaculty();
    const { focusPlayerElement } = usePlayerElement();
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);
    const [currentSmallPanelContent, setCurrentSmallPanelContent] = useState<MenuOptions | false>(standalone?.type === 'small' ? standalone.menu : false);
    const [currentLargePanelContent, setCurrentLargePanelContent] = useState<MenuOptions | false>(standalone?.type === 'large' ? standalone.menu : false);
    const [currentPanel, setCurrentPanel] = useState<'none' | 'small' | 'large'>(standalone?.type ?? 'none');
    const [currentBookmarkOption, setBookmarkCurrentOption] = useState<IBookmarkEditorProps>({ option: 'none' });
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { programDetails } = useProgramDetails(launchDetails?.itemPk ?? undefined);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const [showOverlayMenu, setShowOverlayMenu] = useState(false);
    const [isSubLevel, setIsSublevel] = useState(false);
    const [currentSupportSubPanel, setCurrentSupportSubPanel] = useState<SupportOptions | false>(false);
    const [showQAForm, setShowQAForm] = useState<boolean>(false);
    const forceSegmentDetails = Math.max(launchDetails?.segments?.length ?? 0, programDetails?.dailySegments?.[0].segments?.length ?? 0) <= 1 &&
        (launchDetails.isWebcast ? !!programDetails : true);

    const forceFacultyDetails = (faculty?.length ?? 0) === 1;
    const { pushToDataLayer } = useAnalytics();
    const smallPanelOpenCommand = useAppSelector((state) => state.menu.smallPanelOpenCommand);
    const largePanelOpenCommand = useAppSelector((state) => state.menu.largePanelOpenCommand);

    const largePanelOnCloseFocusTarget = useRef<'credit' | 'player' | null>(null);
    const creditButtonRef = useRef<HTMLButtonElement | null>(null);

    const menuItems  = useMenuItems();

    useEffect(() => {
        if (smallPanelOpenCommand) {
            setCurrentSmallPanelContent(smallPanelOpenCommand);
            setCurrentLargePanelContent(false);
            dispatch(clearSmallPanelOpenCommand());
        }
    }, [dispatch, smallPanelOpenCommand]);

    useEffect(() => {
        if (largePanelOpenCommand) {
            setCurrentLargePanelContent(largePanelOpenCommand);
            setCurrentSmallPanelContent(false);
            dispatch(clearLargePanelOpenCommand());

            if(buttonRef.current && largePanelOnCloseFocusTarget.current === 'credit') {
                const tempRef = buttonRef.current;
                creditButtonRef.current = tempRef;
            }
        }
    }, [dispatch, largePanelOpenCommand]);

    useEffect(() => {
        const isMenuOpen = currentPanel !== 'none';
        if (currentPanel === 'small' || currentPanel === 'none') {
            setCurrentLargePanelContent(false);
        }
        if (currentPanel === 'large' || currentPanel === 'none') {
            setCurrentSmallPanelContent(false);
        }
        setIsMenuOpen?.(isMenuOpen);
    }, [currentPanel, setIsMenuOpen]);

    const handleFocusOnPanelClose = useCallback(() => {
        // Setting focus when closing small screens gets handled in OverlayMenu
        if(isLargeScreen) {
            switch(largePanelOnCloseFocusTarget.current)
            {
                case('credit'):
                    creditButtonRef.current?.focus();
                    break;
                case('player'):
                    focusPlayerElement();
                    break;
                default:
                    buttonRef.current?.focus();
                    break;
            }
        }
    }, [focusPlayerElement, isLargeScreen]);

    const closeAll = useCallback(() => {
        handleFocusOnPanelClose();
        setIsSublevel(false);
        setCurrentPanel('none');
        setShowOverlayMenu(false);
        setBookmarkCurrentOption({ option: 'none' });
        setCurrentLargePanelContent(false);
        setCurrentSmallPanelContent(false);
    }, [handleFocusOnPanelClose]);

    const handlePlaySegment = useCallback(() => {
        setShowOverlayMenu(false);
        if (!isLargeScreen) {
            setCurrentPanel('none');
        }
    }, [isLargeScreen]);

    const handleBookmarkChange = useCallback((option: 'add' | 'update' | 'delete', bookmarkId?: number, userNotes?: string, timestamp?: string) => {
        if (option === 'update') {
            dispatch(setUserNotes(userNotes ?? ''));
        }
        setBookmarkCurrentOption({ bookmarkId, option, timestamp });
        setIsSublevel(true);
    }, [dispatch]);

    const handleSeeSublevel = () => {
        setIsSublevel(true);
    };

    const handleBackButton = () => {
        if (isSubLevel) {
            setIsSublevel(false);

            if (currentSmallPanelContent === MenuOptions.Notes) {
                setBookmarkCurrentOption({ option: 'none' });
                dispatch(setUserNotes(''));
            }

            return;
        }

        setCurrentPanel('none');
    };

    const buttonRef: React.RefObject<HTMLButtonElement> = useRef(null);

    const smallPanelItem = useMemo(() => {
        if (currentSmallPanelContent === false) return null;
        largePanelOnCloseFocusTarget.current = null;

        setCurrentPanel('small');
        const menuItem = menuItems.find(
            (item) => item.id === currentSmallPanelContent
        );

        switch (currentSmallPanelContent) {
            case MenuOptions.Segments: {
                const subtitle = `${
                    launchDetails?.isWebcast ? 'Live on' : 'Recorded'
                } ${launchDetails?.recordDate}\n${
                    launchDetails?.recordLocation ?? 'Online'
                }`;
                return {
                    title: forceSegmentDetails || isSubLevel ? '' : menuItem?.label ?? '',
                    subtitle: forceSegmentDetails || isSubLevel ? '' : subtitle,
                    component: () => isSubLevel || forceSegmentDetails
                        ? <SegmentDetails onPlaySegment={handlePlaySegment} />
                        : <SegmentsPanel title={menuItem?.label} subtitle={subtitle} onPlaySegment={handlePlaySegment} onSeeDetails={handleSeeSublevel} />
                };
            }
            case MenuOptions.Faculty: {
                return {
                    title: isSubLevel || forceFacultyDetails ? '' : menuItem?.label ?? '',
                    subtitle: '',
                    component: () => isSubLevel || forceFacultyDetails ?
                        <FacultyDetail /> :
                        <FacultyPanel title={menuItem?.label} onSeeDetails={handleSeeSublevel} />,
                };
            }
            case MenuOptions.Notes: {
                if (currentBookmarkOption.option != 'none' && !isSubLevel) {
                    setIsSublevel(true);
                }

                const currentTime =
            document.querySelector('.jw-text-elapsed')?.textContent;
                return {
                    title: isSubLevel ? '' : menuItem?.label ?? '',
                    subtitle: '',
                    hasStickyButton: true,
                    component: () =>
                        currentBookmarkOption.option != 'none' ? (
                            <BookmarkEditor
                                segmentTitle={
                                    launchDetails.segments?.find(
                                        (s) => s.itemSk == currentSegmentItemSk
                                    )?.title ?? ''
                                }
                                timestamp={currentBookmarkOption.timestamp ?? currentTime ?? ''}
                                bookmarkId={currentBookmarkOption.bookmarkId}
                                option={currentBookmarkOption.option}
                                closeSubPanel={() => { setIsSublevel(false); setBookmarkCurrentOption({ option: 'none' }); }}
                            />
                        ) : (
                            <BookmarksPanel
                                title={menuItem?.label}
                                onPlaySegment={handlePlaySegment}
                                onBookmarkChange={handleBookmarkChange}
                            />
                        ),
                };
            }
            case MenuOptions.Support: {
                return {
                    title: menuItem?.label ?? '',
                    hasStickyButton: true,
                    component: () => (
                        <SupportPanel
                            title={menuItem?.label}
                            onClose={closeAll}
                            setSubPanel={(e) => setCurrentSupportSubPanel(e)}
                            subPanel={currentSupportSubPanel}
                        />
                    ),
                };
            }
            case MenuOptions.QandA: {
                return {
                    title: launchDetails.isWebcast && !launchDetails.pollSessionUrl ? 'Live Q&A' : menuItem?.label ?? '',
                    hasStickyButton: true,
                    component: () =>
                        launchDetails.isWebcast ? (
                            <LivePollingQAPanel onClose={closeAll} />
                        ) : (
                            <OnDemandQuestionPanel
                                title={menuItem?.label}
                                onClose={closeAll}
                                setShowQAForm={setShowQAForm}
                                showQAForm={showQAForm}
                            />
                        ),
                };
            }
            case MenuOptions.Materials: {
                return {
                    title: menuItem?.label ?? '',
                    component: () => <MaterialsPanel title={menuItem?.label} />,
                };
            }
            case MenuOptions.Credit: {
                largePanelOnCloseFocusTarget.current = 'credit';
                return {
                    title: menuItem?.label ?? 'Program progress',
                    hasStickyButton: true,
                    component: () => user.isGroupcast ? 
                        <GroupcastCreditPanel/>
                        : <CreditProgressPanel onClose={closeAll} />,
                };
            }
            default: {
                return {
                    title: menuItem?.label,
                    subtitle: 'Subtitle',
                    component: () => <div>This is the panel content.</div>,
                };
            }
        }
    }, [
        currentSmallPanelContent, 
        menuItems, 
        launchDetails.isWebcast, 
        launchDetails?.recordDate, 
        launchDetails?.recordLocation, 
        launchDetails.segments, 
        isSubLevel, 
        handlePlaySegment,
        handleBookmarkChange,
        currentSegmentItemSk, 
        closeAll, 
        currentSupportSubPanel, 
        showQAForm, 
        forceSegmentDetails,
        forceFacultyDetails,
        currentBookmarkOption,
        launchDetails.pollSessionUrl,
        user.isGroupcast,
    ]);

    const largePanelItem = useMemo(() => {
        
        if (currentLargePanelContent === false) return null;

        setCurrentPanel('large');

        switch (currentLargePanelContent) {
            case MenuOptions.EndOfVideo: {
                largePanelOnCloseFocusTarget.current = 'player';
                return {
                    component: () => 
                        <EndOfVideoPanelWrapper closeAll={closeAll} />                    
                };
            }
            case MenuOptions.RequestCredit: {
                largePanelOnCloseFocusTarget.current = 'credit';
                return {
                    component: () => 
                        <RequestCreditPanel onClose={standalone?.type !== 'large' ? closeAll : null} />
                };
            }
            case MenuOptions.CreditSubmissionConfirmation: {
                largePanelOnCloseFocusTarget.current = 'credit';
                return {
                    component: () => 
                        <CreditSubmissionConfimrationPanel />
                };
            }
        }
    }, [
        currentLargePanelContent,
        closeAll,
        standalone?.type,
    ]);
    

    const Menu = useMemo(
        () => (isLargeScreen ? SideBar : OverlayMenu),
        [isLargeScreen]
    );

    const handleNavigationEvents = (optionId: MenuOptions) => {
        switch (optionId) {
            case MenuOptions.Segments: {
                launchDetails.isWebcast ?
                    pushToDataLayer({ event: 'player > schedule' }) :
                    pushToDataLayer({ event: 'player > segments' });
                break;
            }
            case MenuOptions.Faculty: {
                pushToDataLayer({ event: 'player > faculty' });
                break;
            }
            case MenuOptions.Credit: {
                pushToDataLayer({ event: 'player > credit_button' });
                break;
            }
            case MenuOptions.QandA: {
                launchDetails.isWebcast ?
                    pushToDataLayer({ event: 'player > interact' }) :
                    pushToDataLayer({ event: 'player > qa' });
                break;
            }
            case MenuOptions.Notes: {
                pushToDataLayer({ event: 'player > notes' });
                break;
            }
            case MenuOptions.Support: {
                pushToDataLayer({ event: 'player > support' });
                break;
            }
        }
    };

    const toggleSmallPanel = ( optionId: MenuOptions ) => {
        if (currentSmallPanelContent === optionId && isLargeScreen) {
            setCurrentSmallPanelContent(false);
            setCurrentPanel('none');
            setIsSublevel(false);
        } else {
            setCurrentSmallPanelContent(optionId);
            setCurrentPanel('small');
            setIsSublevel(false);
            handleNavigationEvents(optionId);
        }
    };

    return (
        <>
            {!standalone?.type ? (
                <>
                    <div
                        className={` main-menu__spacer ${currentPanel === 'small' ? 'main-menu__spacer--open' : ''} `}
                    ></div>
                    <div className={`main-menu ${!isLargeScreen ? 'position-static' : ''}`}>
                        <Menu
                            onSmallPanelOpen={(optionId) => toggleSmallPanel(optionId)}
                            onLargePanelOpen={(optionId) => { setCurrentLargePanelContent(optionId); setCurrentPanel('large'); handleNavigationEvents(optionId); }}
                            isPanelOpen={currentPanel !== 'none'}
                            onShowMenuClick={(showMenu: boolean) =>
                                setShowOverlayMenu(showMenu)
                            }
                            isMenuOpen={showOverlayMenu}
                            optionSelected={currentPanel === 'small' ? currentSmallPanelContent : currentLargePanelContent}
                            buttonRef={buttonRef}
                        ></Menu>
                    </div>
                    {/* Had to separate to allow animation (component mounted/unmounted)*/}
                    <SmallPanel
                        isOpen={currentPanel === 'small'}
                        title={smallPanelItem?.title ?? ''}
                        subtitle={smallPanelItem?.subtitle ?? ''}
                        onBack={currentSmallPanelContent === MenuOptions.Credit ? undefined : handleBackButton}
                        onClose={closeAll}
                        isSublevel={isSubLevel}
                        scrollToTop={isSubLevel}
                        hasStickyButton={smallPanelItem?.hasStickyButton ?? false}
                    >
                        {smallPanelItem && <smallPanelItem.component />}
                    </SmallPanel>
                    <LargePanel
                        isOpen={currentPanel === 'large'}
                        title={''}
                        onClose={closeAll}
                    >
                        {largePanelItem && <largePanelItem.component />}
                    </LargePanel>
                </>
            ) : (
                <ModalPanel
                    isOpen
                    title={currentPanel === 'small' ? smallPanelItem?.title : ''}
                    subtitle={smallPanelItem?.subtitle}
                    className="watch-modal--standalone"
                >
                    {largePanelItem && <largePanelItem.component />}
                    {smallPanelItem && <smallPanelItem.component />}
                </ModalPanel>
            )}
        </>
    );
}
