import { PlaybackPosition } from '../../stories/Store/playerSlice';
import { apiSlice } from '..';

type PlaybackPositionRequest = Omit<PlaybackPosition, 'totalTime'>

const playbackProgressSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        savePlaybackProgress: builder.mutation<boolean, PlaybackPositionRequest>({
            query: (args: PlaybackPositionRequest) => {
                const { itemSk, position } = args;
                return {
                    method: 'POST',
                    url: `/playback-progress?itemSk=${itemSk}&progressSeconds=${position}`
                };
            }
        })
    })
});

export const { useSavePlaybackProgressMutation } = playbackProgressSlice;
export default playbackProgressSlice;
