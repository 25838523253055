import { apiSlice } from '..';
import { SASToken } from '../generated';

const eventHubSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEventHubSASToken: builder.query<SASToken, void>({
            query: () => '/event-hub/sas-token',
        }),
    })
});

export const { useLazyGetEventHubSASTokenQuery } = eventHubSlice;
export default eventHubSlice;
