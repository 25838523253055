import { apiSlice } from '..';
import { Question } from '../generated';

const questionsApiEndpoint = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        addQuestion: build.mutation<boolean, Question>({
            query: (body) => ({
                url: '/questions',
                method: 'POST',
                body,
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useAddQuestionMutation } = questionsApiEndpoint;
export default questionsApiEndpoint;
  