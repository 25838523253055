import { MutableRefObject, RefCallback, RefObject } from 'react';

export function useMergeRefs<T>(
    ...refs: Array<RefObject<T> | MutableRefObject<T> | RefCallback<T> | null>
) {
    function mergeRefs(element: T | null) {
        if (!element) return;
        for (const ref of refs) {
            if (typeof ref === 'function') ref(element);
            else if (ref) Object.assign(ref, { current: element });
        }
    }

    mergeRefs.invoke = function (element: T | null | undefined) {
        this(element ?? null);
        return this;
    };

    return mergeRefs;
}
