export class WorkerInterval {
    private worker?: Worker;
    private blobURL?: string;
    private fallbackInterval?: ReturnType<typeof setInterval>;

    constructor(callback: (timestamp: number) => void, intervalMs: number) {
        if (false === 'Worker' in window) {
            // fallback if WebWorkers is not supported
            this.fallbackInterval = setInterval(() => callback(performance.now()), intervalMs);
            return this;
        }

        const blob = new Blob([`setInterval(() => postMessage(Date.now()), ${intervalMs});`], { type: 'text/javascript' });
        this.blobURL = URL.createObjectURL(blob);
        this.worker = new Worker(this.blobURL);
        this.worker.onmessage = () => {
            callback(performance.now());
        };
    }

    stop() {
        this.worker?.terminate();
        if (this.blobURL)
            URL.revokeObjectURL(this.blobURL);
        if (this.fallbackInterval)
            clearInterval(this.fallbackInterval);
    }
}