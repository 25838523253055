import { apiSlice } from '..';
import { IndividualCreditAndLicenseInfo } from '../generated';

const licensesSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        deleteLicense: builder.mutation<boolean, { licenseSk: number, encryptedRegistrationId: string | undefined }>({
            query: ({ licenseSk, encryptedRegistrationId }) => ({
                url: `/licenses?licenseSk=${licenseSk}&encryptedRegistrationId=${encryptedRegistrationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Licenses'],
        }),
        addLicense: builder.mutation<IndividualCreditAndLicenseInfo, { license: IndividualCreditAndLicenseInfo, encryptedRegistrationId: string | undefined }>({
            query: ({ license, encryptedRegistrationId }) => ({
                url: `/licenses?encryptedRegistrationId=${encryptedRegistrationId}`,
                method: 'POST',
                body: license
            }),
            invalidatesTags: ['Licenses'],
        }),
        updateLicense: builder.mutation<IndividualCreditAndLicenseInfo, { license: IndividualCreditAndLicenseInfo, encryptedRegistrationId: string | undefined }>({
            query: ({ license, encryptedRegistrationId }) => ({
                url: `/licenses?encryptedRegistrationId=${encryptedRegistrationId}`,
                method: 'PUT',
                body: license
            }),
            invalidatesTags: ['Licenses'],
        }),
    }),
    overrideExisting: true,
});

export const { useDeleteLicenseMutation, useAddLicenseMutation, useUpdateLicenseMutation } = licensesSlice;
export default licensesSlice;