import '../../../styles/_tutorial-animation.scss';

import { motion } from 'framer-motion';
import React from 'react';

import CreditSVG from './assets/creditSVG';

interface GroupcastAnimationProps {
    stepIndex: number;
}

const GroupcastAnimation: React.FC<GroupcastAnimationProps> = (
    props: GroupcastAnimationProps
) => {
    const { stepIndex } = props;

    return (
        <div className='tutorial-animation__container tutorial-animation__container--groupcast'>
            {stepIndex === 0 ? (
                <>
                    <div
                        className='tutorial-animation__rectangle tutorial-animation__rectangle--gray'
                    />
                    <motion.div
                        className='tutorial-animation__rectangle tutorial-animation__rectangle--gray'
                        title='Confirm credit eligibility demostration'
                        animate={{ 
                            scale: [1, 1, 1.1, 1, 1, 1],
                            opacity: [.5, .5, 1, 1, 1, .5]
                        }}
                        transition={{
                            duration: 3,
                            ease: 'easeOut',
                            times: [0, .2, .4, .5, .9, 1],
                            repeat: 2,
                            delay: 2,
                            repeatDelay: 2,
                        }}
                    >
                        <motion.svg
                            animate={{ 
                                opacity: [0, 0, 1, 1, 1, 0]
                            }}
                            transition={{
                                duration: 3,
                                ease: 'easeOut',
                                times: [0, .2, .4, .5, .9, 1],
                                repeat: 2,
                                delay: 2,
                                repeatDelay: 2,
                            }}
                            width="18" 
                            height="18" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10" fill="#01C365"/>
                            <path d="M8 13L10.2857 15L16 10" stroke="black" strokeWidth="2" strokeLinecap="square"/>
                        </motion.svg>
                    </motion.div>
                    <div
                        className='tutorial-animation__rectangle tutorial-animation__rectangle--gray'
                    />
                </>
            ) : stepIndex === 1 ? (
                <CreditSVG />
            ) : (
                <>
                    <div className='tutorial-animation__circle tutorial-animation__circle--empty'></div>
                    <svg
                        viewBox='0 0 64 64'
                        width='64px'
                        height='64px'
                        fill='none'
                        className='tutorial-animation__circle tutorial-animation__circle__svg'
                    >
                        <title>Submit credits demonstration</title>
                        <motion.circle
                            cx='32'
                            cy='32'
                            r='28'
                            stroke='#01C365'
                            data-fallback='edge'
                            strokeWidth='8px'
                            animate={{ pathLength: [0.75, 1.1, 1.1, 0.75] }}
                            transition={{
                                duration: 6,
                                delay: 1,
                                ease: 'easeOut',
                                times: [0, 0.25, 0.85, 1],
                                repeat: 1,
                                repeatDelay: 1,
                            }}
                        />
                    </svg>
                    <svg
                        className='tutorial-animation__checkmark'
                        xmlns='http://www.w3.org/2000/svg'
                        width='64'
                        height='64'
                        viewBox='0 0 64 64'
                        fill='none'
                    >
                        <motion.path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M44.3006 27.6304L30.1324 41.1051L21.6995 33.085L24.8006 29.8242L30.1324 34.8949L41.1995 24.3696L44.3006 27.6304Z'
                            fill='#01C365'
                            animate={{
                                opacity: [0, 0, 1, 1, 0],
                                scale: [1, 1.25, 1, 1, 1],
                            }}
                            transition={{
                                duration: 6,
                                delay: 1,
                                ease: 'easeOut',
                                times: [0, 0.25, 0.35, 0.9, 1],
                                repeat: 1,
                                repeatDelay: 1,
                            }}
                        />
                    </svg>
                </>
            )}
            <div
                className='tutorial-animation__background-circle tutorial-animation__background-circle--groupcast'
            ></div>
        </div>
    );
};

export default GroupcastAnimation;
