import type { Player as IVSPlayer, PlayerEventType as IVSEvents } from 'amazon-ivs-player';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Slide } from '../datasource/generated';
import { useGetSlidesByItemSkQuery } from '../datasource/queries/slides';
import { useAppSelector } from '.';

export const useSlides = () => {
    const ivsPlayer = useRef<IVSPlayer>();

    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const currentSegmentPosition = useAppSelector((state) => state.player.playbackProgress.find(x => x.itemSk === currentSegmentItemSk))?.position ?? 0;

    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const [currentSlide, setCurrentSlide] = useState<Slide | null>(null);

    const {
        data: slides,
        refetch
    } = useGetSlidesByItemSkQuery(
        {
            itemSk: launchDetails?.itemSk ?? 0,
            current: launchDetails?.isWebcast ?? false,
        }, { skip: !launchDetails?.itemSk });

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden && launchDetails?.isWebcast) {
                refetch();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [launchDetails?.isWebcast, refetch]);

    useEffect(() => {
        if (launchDetails?.isWebcast && slides && slides.length > 0)
            setCurrentSlide(slides[0]);
    }, [launchDetails?.isWebcast, slides]);

    useEffect(() => {
        if (!launchDetails?.isWebcast)
            setCurrentSlide(null);
    }, [currentSegmentItemSk, launchDetails?.isWebcast]);

    const currentSegmentSlides = useMemo(() => {
        const isSingleSegment = !launchDetails?.segments || launchDetails?.segments.length === 1;

        if (isSingleSegment)
            return slides;

        return slides?.filter((slide) => slide.itemSk === currentSegmentItemSk);

    }, [currentSegmentItemSk, launchDetails?.segments, slides]);


    useEffect(() => {
        if (currentSegmentSlides && !launchDetails?.isWebcast) {

            const ceilPosition = Math.ceil(currentSegmentPosition);

            let foundSlide = null;
            for (const slide of currentSegmentSlides) {
                if ((slide?.position ?? 0) < ceilPosition) {
                    if (!foundSlide || ceilPosition > (foundSlide?.position ?? 0)) {
                        foundSlide = slide;
                    }
                }
            }

            if (foundSlide) {
                if (currentSlide?.syncEventId !== foundSlide.syncEventId) {
                    setCurrentSlide(foundSlide);
                }
            } else {
                setCurrentSlide(null);
            }
        }
    }, [currentSegmentPosition, currentSegmentSlides, currentSlide, launchDetails?.isWebcast]);


    const handleProviderPlayer = (player: any) => {
        ivsPlayer.current = player.ivsPlayer;
        const ivsEvents = player.ivsEvents as { PlayerEventType: typeof IVSEvents };

        ivsPlayer.current?.addEventListener(
            ivsEvents.PlayerEventType.TEXT_METADATA_CUE,
            function (cue: any) {
                const slide = JSON.parse(cue.text) as Slide;

                if (slide && slide.url)
                    setCurrentSlide(slide);
            });
    };

    return {
        ivsPlayer,
        currentSlide,
        handleProviderPlayer,
        currentSegmentSlides,
        slides,
    };
};
