import '../../styles/_license-button.scss';
import '../../styles/_request-credit-licenses.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useAppSelector } from '../../hooks';
import useCpeBlock from '../../hooks/useCpeBlock';
import useCpeExams from '../../hooks/useCpeExams';
import { LicenseErrorMessageElementId, RequestCreditLicenseError, useRequestCreditLicenses }  from '../../hooks/useRequestCreditLicenses';
import { LicenseButton } from '../LicenseGrid/LicenseButton/LicenseButton';
import { ReactComponent as CreditStatusWarning } from '../LicenseGrid/LicenseCard/assets/Warning.svg';
import { LICENSE_MODAL_ID } from '../LicenseModal/LicenseModal';
import Loader from '../Loader/Loader';
import RequestCreditLicenseCard from '../RequestCreditLicenseCard/RequestCreditLicenseCard';

type ILicenseErrorMessageMap = {
    [key in RequestCreditLicenseError]: string;
};

const licenseErrorMessageMap : ILicenseErrorMessageMap = {
    ['no-licenses']: 'You need to add at least one license to submit for credit.',
    ['no-selected-licenses']: 'Error: Please select at least one license to continue.',
    ['license-number']: 'Error: License number required by one or more jurisdictions to submit for credit.',
    ['not-passed-exam']: 'You are not be able to submit for {licenseName} credit because you have not passed the CPE exam.',
    ['no-cpe-credit']: 'You will not receive credit for CPE.',
};

interface IRequestCreditLicensesProps {
    onLicensesLoaded: (newValue: boolean) => void;
}

export const RequestCreditLicenses = ({
    onLicensesLoaded
} : IRequestCreditLicensesProps) => {

    const { 
        isLoaded, 
        headingText,
        maxLicensesToShowInitially,
        showAllLicenses,
        showExpandButton,
        showExpandButtonAsCard,
        showAddButtonAsCard,
        licenseCardProps,
        error,
        missingLicenseNumberDetails,
        licenseNumberInfoUrl,
        onAddLicensePress, 
        onExpandLicensesPress 
    } = useRequestCreditLicenses();

    useEffect(() => {
        onLicensesLoaded(isLoaded);
    }, [isLoaded, onLicensesLoaded]);

    const licensesErrorMessage = error ? licenseErrorMessageMap[error] : undefined;
    const isLicenseNumberError = error === 'license-number';
    const licenseErrorClass = `text--system-red request-credit-licenses__error ${isLicenseNumberError ? 'request-credit-licenses__error--license-number' : ''}`;
    const errorAriaDetails = isLicenseNumberError ? missingLicenseNumberDetails.map(x => x.id.toString()).join(' ') : '';
    const cpeExams = useCpeExams();
    const { isCpeCreditEnabled, licenseRequiringExam } = useCpeBlock();
    const cpeWarningMessage = isCpeCreditEnabled ?
        (!cpeExams.areExamsPassed ? licenseErrorMessageMap['not-passed-exam'].replace('{licenseName}', licenseRequiringExam) : undefined) :
        (cpeExams.exams?.some(x => x) ? licenseErrorMessageMap['no-cpe-credit'] : undefined);
    const headerRef = useRef<HTMLHeadElement | null>();
    const addButtonRef = useRef<HTMLButtonElement | null>();
    const [isHeaderRefReady, setIsHeaderRefReady] = useState(false);
    const [isAddButtonReady, setIsAddButtonReady] = useState(false);
    const focusTarget = useAppSelector((state) => state.requestCredit.licenseFocusTarget);

    useEffect(() => {
        // If there is an error in a license that is hidden due to 'SeeAll/SeeLess' button,
        // 'SeeAll' must be trigerred
        const hiddenInvalidLicenseExists = 
            isLicenseNumberError &&
            !showAllLicenses && 
            missingLicenseNumberDetails.findIndex(x => x.cardIndex > (maxLicensesToShowInitially - 1)) > -1;

        hiddenInvalidLicenseExists && onExpandLicensesPress();
        
    }, [isLicenseNumberError, maxLicensesToShowInitially, missingLicenseNumberDetails, onExpandLicensesPress, showAllLicenses]);

    useEffect(() => {
        if(!focusTarget || !isHeaderRefReady) return;

        if(headerRef.current && focusTarget.mode === 'header')
            headerRef.current.focus();
    }, [focusTarget, isHeaderRefReady]);

    useEffect(() => {
        if(!focusTarget || !isAddButtonReady) return;

        if(addButtonRef.current && focusTarget.mode === 'add-button')
            addButtonRef.current.focus();
    }, [focusTarget, isAddButtonReady]);

    const cards = useMemo(() => {
        const cards = 
            licenseCardProps.map(x => 
                <RequestCreditLicenseCard 
                    key={x.license.indvStateLicense_SK}
                    elementId={x.elementId}
                    license={x.license}
                    jurisdictionName={x.jurisdictionName}
                    licenseNoLabel={x.licenseNoLabel}
                    isLicenseNoRequired={x.isLicenseNoRequired}
                    onEditLicensePress={x.onEditLicensePress}
                    isEnabled={x.isEnabled}
                />);
        return cards;
    }, [licenseCardProps]);

    const visibleCards = showAllLicenses ? cards : cards.slice(0, maxLicensesToShowInitially);

    const addButton = <LicenseButton key='add' mode='add' action={onAddLicensePress} 
        aria-controls={LICENSE_MODAL_ID} aria-haspopup={'dialog'} ref={(el) => { setIsAddButtonReady(true); addButtonRef.current=el; }}
    />;
    const expandButton = <LicenseButton key='expand' mode={showAllLicenses ? 'collapse' : 'expand'} action={onExpandLicensesPress} />;

    return (
        <section className='request-credit-licenses'>
            <h3  
                tabIndex={-1} 
                className='paragraph-1 text--medium-grey mb-0'
                ref={(el) => { setIsHeaderRefReady(true); headerRef.current=el; }}
            >{headingText}</h3>
            { !isLoaded ? <Loader /> : 
                <>
                    {
                        visibleCards.map((card, idx) => {
                            if (idx % 2 !== 0) return null;
                            return (
                                <div key={idx} className='request-credit-licenses__card-row'>
                                    {card}
                                    {
                                    // If there is an odd number of license cards; show the expand/add button with cards
                                        idx + 1 < visibleCards.length ? visibleCards[idx + 1] : 
                                            (showAddButtonAsCard && addButton) || 
                                            (showExpandButton && showExpandButtonAsCard && expandButton)
                                    }
                                </div>
                            );
                        })
                    }
                    {showExpandButton && !showExpandButtonAsCard && expandButton}
                    {!showAddButtonAsCard && addButton}
                    {error && 
                        <div className={licenseErrorClass}>
                            <i className='watch watch-alert' aria-hidden={true} />
                            <div className='request-credit-licenses__error__messages'>
                                <span id={LicenseErrorMessageElementId} aria-details={errorAriaDetails}>{licensesErrorMessage}</span>
                                {isLicenseNumberError &&
                                    <span>
                                        <a href={licenseNumberInfoUrl} target='_blank' rel='noopener noreferrer'>
                                            Don’t know your license number? Go here.
                                        </a>
                                    </span>
                                }
                            </div>
                            
                        </div>}
                    {!!cpeExams.exams?.length && cpeWarningMessage &&
                        <div className='request-credit-licenses__warning'>
                            <CreditStatusWarning width={16} height={16} aria-hidden={true} />
                            <div className='request-credit-licenses__warning__messages sentence-label'>
                                <span>{cpeWarningMessage}</span>
                            </div>
                        </div>}
                </>
            }
        </section>
    );
};

export default RequestCreditLicenses;