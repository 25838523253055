import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useAppSelector } from '.';
import { useFetchTranscripts } from './useFetchTranscripts';

interface ITranscriptsProps {
    currentTranscriptRef: MutableRefObject<HTMLDivElement | null>,
    outerContainerRef: MutableRefObject<HTMLDivElement | null>,
}

const isElementAbove = (el: any) => {
    const top = el.getBoundingClientRect().top;
    let rect;
    el = el.parentNode ?? new Element();
    do {
        rect = el.getBoundingClientRect();
        if (top <= rect.bottom === false)
            return false;
        el = el.parentNode;
    } while (el != document.body);
    // Check it's within the document viewport
    return top <= document.documentElement.clientHeight;
};

const isElementInView = (el: any) => {
    if (el.current) {
        const { top, bottom } = el.current.getBoundingClientRect();
        const isElementVisible: boolean =
          top >= 0 &&
          bottom <= (window.innerHeight || document.documentElement.clientHeight);

        return isElementVisible;
    }

    return false;
};

export const useTranscripts = (props: ITranscriptsProps) => {
    const { currentTranscriptRef, outerContainerRef } = props;

    const [currentTranscriptIndex, setCurrentTranscriptIndex] = useState(0);
    const { transcripts } = useFetchTranscripts();
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const currentSegmentPlaybackProgress = useAppSelector((state) =>
        state.player.playbackProgress.find(x => x.itemSk === currentSegmentItemSk));

    const isAutoScrolling = useRef(false);
    const isResizing = useRef(false);
    const isAutoScrollOn = useRef(true);
    const [isCurrentTranscriptAbove, setIsCurrentTranscriptAbove] = useState(false);

    const getCurrentTranscriptIndex = useCallback(() => {
        let currentIndex = 0;
        const position = (currentSegmentPlaybackProgress?.position ?? 0) * 1000;
        for (let i = 0; i < transcripts?.length ?? 0; i++) {
            if (i === transcripts.length - 1 && +transcripts[i].position < position) currentIndex = i;
            else if (+transcripts[i].position > position) {
                currentIndex = Math.max(i - 1, 0);
                break;
            }
        }

        return currentIndex;
    }, [currentSegmentPlaybackProgress?.position, transcripts]);

    useEffect(() => {
        const currentIndex = getCurrentTranscriptIndex();

        if (currentIndex !== currentTranscriptIndex) {
            setCurrentTranscriptIndex(currentIndex);
        }
    }, [currentTranscriptIndex, currentTranscriptRef, getCurrentTranscriptIndex]);

    const handleScroll = useCallback(() => {
        if (isResizing.current) {
            return;
        }

        if (isAutoScrolling.current) {
            isAutoScrolling.current = false;
            return;
        }
        const currentTranscriptElement = outerContainerRef.current?.querySelector(`[data-index="${currentTranscriptIndex}"]`);
        if (!currentTranscriptElement) return;

        const isAbove = isElementAbove(currentTranscriptElement);
        setIsCurrentTranscriptAbove(isAbove);
        const isElementVisible = isElementInView(currentTranscriptRef);
        
        isAutoScrollOn.current = isElementVisible;
    }, [currentTranscriptIndex, currentTranscriptRef, outerContainerRef]);

    useEffect(() => {

        const handleResizeStart = (event: Event) => {
            if ((event.target as Element).matches('.main-menu__spacer')){
                setTimeout(() => isResizing.current = true, 0);
            }
        };

        const handleResizeEnd = (event: Event) => {
            if ((event.target as Element).matches('.main-menu__spacer')){
                setTimeout(() => isResizing.current = false, 0);
            }
        };

        const container = outerContainerRef.current;
        outerContainerRef.current?.addEventListener('scroll', handleScroll);
        window.addEventListener('transitionstart', handleResizeStart);
        window.addEventListener('transitionend', handleResizeEnd);

        return () => {
            container?.removeEventListener('scroll', handleScroll);
            window.removeEventListener('transitionstart', handleResizeStart);
            window.removeEventListener('transitionend', handleResizeStart);
        };
    }, [currentTranscriptIndex, outerContainerRef, handleScroll]);

    const scrollToCurrentTranscript = useCallback(() => {
        const currentTranscript = outerContainerRef.current
            ?.querySelector(`[data-index="${currentTranscriptIndex}"]`);
        currentTranscript?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        (currentTranscript as HTMLDivElement)?.focus();
        isAutoScrolling.current = true;
    }, [currentTranscriptIndex, outerContainerRef]);

    useEffect(() => {
        if (isAutoScrollOn.current) {
            scrollToCurrentTranscript();
        }
    }, [scrollToCurrentTranscript]);

    const restoreAutoScroll = useCallback(() => {
        scrollToCurrentTranscript();
        setIsCurrentTranscriptAbove(false);
        isAutoScrollOn.current = true;
    }, [isAutoScrollOn, scrollToCurrentTranscript, setIsCurrentTranscriptAbove]);

    return {
        currentTranscriptIndex,
        scrollToCurrentTranscript,
        isCurrentTranscriptAbove,
        setIsCurrentTranscriptAbove,
        isAutoScrollOn,
        restoreAutoScroll,
    };
};

export default useTranscripts;