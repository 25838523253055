import { useGetCmsSettingsQuery } from '../datasource/queries/cmsSettings';
import logger from '../utility/logger';

export const useGroupcastPdfUrl = () => {
    const {
        data: watchSettings
    } = useGetCmsSettingsQuery();

    try {
        if (watchSettings?.groupcastPdfUrl && new URL(watchSettings.groupcastPdfUrl)) {
            return watchSettings.groupcastPdfUrl;
        }
    } catch {
        //
    }

    logger.log('Missing Groupcast PDF Content Edit, using default');

    return 'https://download.pli.edu/WebContent/resources/PLIGroupcastInfoAndSignInSheets.pdf?fallback=true';
};
