import { apiSlice } from '..';
import { Slide } from '../generated';

export interface IGetSlidesArguments {
    itemSk: number,
    current: boolean
}

const slidesSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSlidesByItemSk: builder.query<Slide[], IGetSlidesArguments>({
            query: (args: IGetSlidesArguments) => {
                const { itemSk, current } = args;
                return {
                    url: `/slides?itemSk=${itemSk}&current=${current}`,
                    keepUnusedDataFor: Number.MAX_SAFE_INTEGER
                };
            }
        })
    })
});

export const { useGetSlidesByItemSkQuery } = slidesSlice;
export default slidesSlice;
