import { apiSlice } from '..';
import { Faculty } from '../generated';

const facultySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFacultyByItemSk: builder.query<Faculty[], number>({
            query: (itemSk: number) => `/faculty?itemSk=${itemSk}`,
            keepUnusedDataFor: Number.MAX_SAFE_INTEGER
        })
    })
});

export const { useGetFacultyByItemSkQuery } = facultySlice;
export default facultySlice;
