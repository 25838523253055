import '../../styles/_cpe-dialog.scss';

import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { CpeMarker } from '../../datasource/generated';
import useCpeExams from '../../hooks/useCpeExams';
import { usePlayerElement } from '../../hooks/usePlayerElement';
import Button from '../Button/Button';
import CloseButton from '../CloseButton/CloseButton';

export interface ICpeDialogProps {
    activeCpeMarker?: CpeMarker,
    onClose: () => void,
}
export const CpeDialog = ({
    activeCpeMarker,
    onClose,
}: ICpeDialogProps) => {

    const cpeDialogRef = useRef<HTMLDivElement>(null);

    const { playerElement } = usePlayerElement();

    const { openExam } = useCpeExams();

    const previousActiveElement = useRef<HTMLElement>();

    useEffect(() => {
        // Prevents keyboard controls on CleDialog inputs from firing JWPlayer play/pause keyboard shortcuts
        const preventJwKeyEvent = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter' || code === 'ArrowUp' || code === 'ArrowDown') {
                e.stopPropagation();
            }
        };

        const jwPlayerControls = document.querySelectorAll('.jwplayer');

        if (activeCpeMarker && cpeDialogRef.current) {

            jwPlayerControls.forEach(elem => {
                elem.addEventListener('keydown', preventJwKeyEvent, true);
            });

            previousActiveElement.current = document.activeElement as HTMLElement;

            cpeDialogRef.current.focus();

            // Don't render dialog over sidebar when sidebar is expanded
            const sidebar = document.getElementById('sidebar');
            if (sidebar && sidebar.classList.contains('sidebar--expanded')) {
                cpeDialogRef.current.classList.add('cpe-dialog--menu-expanded');
            }
        } else {
            if (!previousActiveElement) playerElement?.focus();
            previousActiveElement.current?.focus();
            previousActiveElement.current = undefined;
        }

        return () => {
            jwPlayerControls.forEach(elem => {
                elem.removeEventListener('keydown', preventJwKeyEvent, true);
            });
        };
    }, [activeCpeMarker, playerElement]);

    useLayoutEffect(() => {
        // Move CPE dialog if CLE dialog is present
        const cleDialog = document.getElementById('cle-dialog');
        const cpeDialog = document.getElementById('cpe-dialog');
        if (cleDialog && activeCpeMarker && cpeDialog) {
            cpeDialog.classList.add('cpe-dialog--cle-dialog-open');
        }
    }, [activeCpeMarker]);

    const handleCloseKeyUp = (e: React.KeyboardEvent) => {
        if ((e.code === 'Space' || e.code === 'Enter')) {
            onClose();
        }
    };

    if (!activeCpeMarker)
        return <></>;

    return createPortal(
        <div className='cpe-dialog' id='cpe-dialog' role='document' ref={cpeDialogRef} tabIndex={-1}>
            <div>
                <div className='cpe-dialog__close-btn-container'>
                    <CloseButton
                        onClick={onClose}
                        aria-label='Close CPE Review and Exam dialog'
                        buttonClass='close-button p-0'
                        onKeyUp={handleCloseKeyUp}
                    />
                </div>
                <div className='cpe-dialog__content'>
                    <h1 className='heading-5 mb-0'>CPE Review and Exam</h1>
                    <p className='text--silver paragraph-2 mb-0'>{activeCpeMarker.announcement}</p>
                </div>
            </div>
            <div className='cpe-dialog__btn-container'>
                <Button
                    buttonClass='cpe-dialog__button sticky-button'
                    label='Let’s go'
                    aria-label='Open CPE Review and Exam in new window'
                    action={openExam}
                    isPlayerElementButton={true}
                />
            </div>

        </div>,
        playerElement ?? document.body
    );
};

export default CpeDialog;

