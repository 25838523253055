/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum STINDVSTATELICENSE_LICENSETYPE {
    STATE_BAR_LAWYER = 'State_BarLawyer',
    CPA = 'CPA',
    CPD = 'CPD',
    ISS = 'ISS',
    CPRE = 'CPRE',
    HR = 'HR',
    IRS_CE = 'IRS_CE',
    CFE_CPE = 'CFE_CPE',
    CPD_GENERAL = 'CPD_General',
    CCB_CEU = 'CCB_CEU',
    CFP_CE = 'CFP_CE',
    ABA_CE = 'ABA_CE',
    CAMS = 'CAMS',
    SHRM = 'SHRM',
    PARALEGAL_CLE = 'Paralegal_CLE',
    SW_CE = 'SW_CE',
}
