import '../../styles/_question-panel.scss';
import '../../styles/_question-card.scss';

import React from 'react';

import { useGetQuestionsQuery } from '../../datasource/queries/questions';
import { useAppSelector } from '../../hooks';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import SmallPanel from '../SmallPanel/SmallPanel';
import FacultyQuestionForm from './FacultyQuestionForm';
import QuestionCard from './QuestionCard';

export type IOnDemandQuestionlProps = {
    title?: string;
    onClose(): void;
    setShowQAForm: (showQAForm: boolean) => void;
    showQAForm?: boolean;
};

export const OnDemandQuestionPanel = ({ title, onClose, setShowQAForm, showQAForm }: IOnDemandQuestionlProps) => {
    const itemSk = useAppSelector((state) => state.player.itemSk);
    const {
        data: questions,
        isLoading: isLoadingQuestions,
        isSuccess: isGetQuestionsSuccess,
        isError: isGetQuestionsError,
    } = useGetQuestionsQuery(itemSk);

    const questionsUnavailableMessage = 'There are no questions for faculty at the moment.';

    const questionCards = questions?.map((question, i) => {
        return (
            question.responses 
                ? <QuestionCard question={question} key={i}/>
                : <></>
        );
    });

    const handleClose = () => {
        onClose();
    };

    const newQuestionForm = 
            <SmallPanel
                isOpen={showQAForm}
                onBack={() => setShowQAForm(false)}
                onClose={handleClose}
                title={''}
                isSublevel={true}
                hasStickyButton={true}
            >
                {showQAForm && <FacultyQuestionForm closeSubPanel={() => setShowQAForm(false)} />}
            </SmallPanel>
    ;

    const onAskaQuestionClick = () => {
        setShowQAForm(true);
    };

    return (
        <div className='question-panel'>
            <div className='small-panel__heading mb-4 pb-3'>
                <h3 className='heading-5 mb-0'>{title}</h3>
            </div>
            {isLoadingQuestions &&
                <div className='h-75'><Loader /></div>
            }

            {showQAForm && newQuestionForm}

            {isGetQuestionsError && !showQAForm &&
                <MainError errorContainer={ErrorContainer.SmallPanel} />
            }

            {isGetQuestionsSuccess && questionCards && questionCards?.length > 0 && !showQAForm && 
                <ul className='question-panel__question-list'>{ questionCards }</ul>
            }

            {isGetQuestionsSuccess && questionCards && questionCards?.length === 0 && !showQAForm &&
                <div className='question-panel__empty-list'>
                    <p className='paragraph-1'>{questionsUnavailableMessage}</p>
                </div>
            }
            {isGetQuestionsSuccess &&
                <div className='question-panel__button-container'>
                    <Button 
                        buttonClass='sticky-button sticky-button__alt' 
                        label='Ask Faculty a Question' 
                        action={onAskaQuestionClick} 
                    />
                </div>
            }
        </div>
    );
};

export default OnDemandQuestionPanel;

