import { set } from 'lodash';

export function extractObjectFromFormData<T extends object>(form: FormData) {
    const resultObject = {} as T;

    for (const key of form.keys()) {
        const value = form.get(key);

        set(resultObject, key, value);
    }

    return resultObject;
}