import React from 'react';
import { z } from 'zod';

import { SFSurveyQuestion } from '../../datasource/generated';

export enum SurveyQuestionType {
    RADIO_GROUP = 'Radio Group',
    RADIO_GROUP_RATING = 'Radio Group - Rating',
    TEXT = 'Text',
}

export const isQuestionRequired = (q: SFSurveyQuestion) =>
    q.surveyQuestionTitle === 'Marketing Consent' ||
    q.surveyQuestionType === SurveyQuestionType.RADIO_GROUP_RATING;

type SurveyContext = {
    formErrors: z.ZodIssue[];
};

export const surveyContext = React.createContext({} as SurveyContext);