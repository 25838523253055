import '../../styles/_close-button.scss';

import React, { ButtonHTMLAttributes, forwardRef } from 'react';

export interface ICloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    buttonClass: string;
    ariaLabel?: string;
}

export const CloseButton = forwardRef<HTMLButtonElement, ICloseButtonProps>((props: ICloseButtonProps, ref) => {
    const { buttonClass, ariaLabel, ...otherProps } = props;

    return (
        <button
            ref={ref}
            className={buttonClass}
            aria-label={ariaLabel ?? 'Close'}
            {...otherProps}
        >
            <i className='watch watch-close'></i>
        </button>
    );
});
CloseButton.displayName = 'CloseButton';

export default CloseButton;