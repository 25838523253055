import '../../styles/_support-panel.scss';

import React from 'react';

export const ContactUs = () => {
    return (
        <div className='support-panel__contact-us'>
            <h3 className="support-panel__sub-panel__title">Contact Us</h3>
            <h4 className="paragraph-1--medium">Call Technical Support</h4>
            <div className="support-panel__text paragraph-1">
                <a
                    href="tel:8002604754"
                    className="support-panel__link"
                    aria-label="Call: 1-800-260-4754"
                >
                    1-800-260-4754
                </a>
                <span>Monday through Friday</span>
                <span>9 a.m. - 6 p.m. ET</span>
            </div>
            <h4 className="paragraph-1--medium">Email us directly</h4>
            <a
                href="mailto:online@pli.edu"
                className="support-panel__link paragraph-1"
                aria-label="Send Email to: online@pli.edu"
            >
                online@pli.edu
            </a>
        </div>
    );
};

export default ContactUs;
