import { apiSlice } from '..';
import { WatchSettings } from '../generated';

const cmsSettingsSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCmsSettings: builder.query<WatchSettings, void>({
            query: () => '/headless/settings',
        }),
    }),
});

export const { useGetCmsSettingsQuery } = cmsSettingsSlice;
export default cmsSettingsSlice;