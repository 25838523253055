import JWPlayer from '@jwplayer/jwplayer-react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useAppSelector } from '../hooks';
import { useAnalytics } from './useAnalytics';
import { useCaptions } from './useCaptions';
import { useFetchTranscripts } from './useFetchTranscripts';
import useMobileSlideToggle from './useMobileSlideToggle';
import { useUpdateEffect } from './useUpdateEffect';

export const useTranscriptButton = (playerRef: (typeof JWPlayer)) => {
    const { onMobileToggleSlides } = useMobileSlideToggle({
        playerRef: playerRef,
        toggleTranscriptButton: () => onToggleTranscript(false),
    });
    const { displayToggleCCButton } = useCaptions({
        playerRef: playerRef
    });

    const [showTranscript, setShowTranscript] = useState(false);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { transcripts, isFetching } = useFetchTranscripts();

    const { pushToDataLayer } = useAnalytics();

    const onToggleTranscript = useCallback((showTranscriptLocal: boolean) => {
        if (showTranscriptLocal) {
            pushToDataLayer({  event: 'view_transcript' });
            onMobileToggleSlides(false);       
        }
        setShowTranscript(showTranscriptLocal);        
    
    }, [onMobileToggleSlides, pushToDataLayer]);

    const displayToggleTranscriptButton = useCallback((showTranscriptLocal: boolean) => {
        if (!launchDetails?.isWebcast && playerRef.current) {
            const tracks = playerRef.current.getCaptionsList();
            displayToggleCCButton(!showTranscriptLocal && tracks && tracks?.length > 1);
            const buttonId = 'toggleTranscriptButton';

            const transcriptButton = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Layer_1" x="0" y="0" style="enable-background:new 0 0 24 24" version="1.1" viewBox="0 0 24 24"><style>.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}</style><path d="M3 2h15v9h-2V4H5v15h6v2H3V2zm4 4h7v2H7V6zm0 3h7v2H7V9zm5 3H7v2h5v-2zm1 5c0-2.2 1.8-4 4-4s4 1.8 4 4v4h-2.7v-3.6h1.8V17c0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1v.4h1.8V21H13v-4z" class="st0"/><path d="M3 2h15v9h-2V4H5v15h6v2H3V2zm4 4h7v2H7V6zm0 3h7v2H7V9zm5 3H7v2h5v-2zm1 5c0-2.2 1.8-4 4-4s4 1.8 4 4v4h-2.7v-3.6h1.8V17c0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1v.4h1.8V21H13v-4z" class="st0"/></svg>';
            const disabledTranscriptButton = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Layer_1" x="0" y="0" style="enable-background:new 0 0 24 24" version="1.1" viewBox="0 0 24 24"><style>.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#888888}</style><path d="M3 2h15v9h-2V4H5v15h6v2H3V2zm4 4h7v2H7V6zm0 3h7v2H7V9zm5 3H7v2h5v-2zm1 5c0-2.2 1.8-4 4-4s4 1.8 4 4v4h-2.7v-3.6h1.8V17c0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1v.4h1.8V21H13v-4z" class="st0"/><path d="M3 2h15v9h-2V4H5v15h6v2H3V2zm4 4h7v2H7V6zm0 3h7v2H7V9zm5 3H7v2h5v-2zm1 5c0-2.2 1.8-4 4-4s4 1.8 4 4v4h-2.7v-3.6h1.8V17c0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1v.4h1.8V21H13v-4z" class="st0"/></svg>';
            const videoButton = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" fill-rule="evenodd" d="M22 4H2v16h20V4ZM4 6h1v2.25H4V6Zm3 3.75V6h10v12H7V9.75Zm12 6V18h1v-2.25h-1Zm1-1.5v-4.5h-1v4.5h1ZM5 9.75v4.5H4v-4.5h1Zm0 6H4V18h1v-2.25Zm15-7.5V6h-1v2.25h1ZM15 12l-5-3v6l5-3Z" clip-rule="evenodd"/><path fill="#fff" fill-rule="evenodd" d="M22 4H2v16h20V4ZM4 6h1v2.25H4V6Zm3 3.75V6h10v12H7V9.75Zm12 6V18h1v-2.25h-1Zm1-1.5v-4.5h-1v4.5h1ZM5 9.75v4.5H4v-4.5h1Zm0 6H4V18h1v-2.25Zm15-7.5V6h-1v2.25h1ZM15 12l-5-3v6l5-3Z" clip-rule="evenodd"/></svg>';

            if (!launchDetails.isPortable)
                playerRef.current.addButton(
                    transcripts.length > 0
                        ? showTranscriptLocal ? videoButton : transcriptButton
                        : disabledTranscriptButton,
                    transcripts.length > 0
                        ? `Turn on ${showTranscriptLocal ? 'video' : 'transcript'}`
                        : 'Transcript not yet available',
                    transcripts.length > 0
                        ? () => onToggleTranscript(!showTranscriptLocal)
                        : () => { return; },
                    buttonId,
                    showTranscriptLocal ? 'jw-icon-media' : 'jw-icon-transcripts'
                );
        }
    }, [displayToggleCCButton, launchDetails.isPortable, launchDetails?.isWebcast, onToggleTranscript, playerRef, transcripts.length]);

    const transcriptButtonRef = useRef<HTMLDivElement>();
    const handleTranscriptsButtonRef = useCallback(() => {
        const handleTranscriptKeyUp = (e: Event) => {
            const code = (e as KeyboardEvent).code;
            if (code === 'Space' || code === 'Enter') {
                onToggleTranscript(!showTranscript);
            }
        };

        transcriptButtonRef.current = document.querySelector(
            '.jw-icon-transcripts, .jw-icon-media'
        ) as HTMLDivElement;
        const stopPropagation = (e: Event) => e.stopPropagation();
        if (transcriptButtonRef.current) {
            transcriptButtonRef.current.addEventListener(
                'keyup',
                handleTranscriptKeyUp
            );
            transcriptButtonRef.current.addEventListener(
                'keydown',
                stopPropagation
            );
        }

        return () => {
            transcriptButtonRef.current?.removeEventListener(
                'keyup',
                handleTranscriptKeyUp
            );
            transcriptButtonRef.current?.removeEventListener(
                'keydown',
                stopPropagation
            );
        };
    }, [onToggleTranscript, showTranscript]);

    useEffect(() => {
        displayToggleTranscriptButton(showTranscript);
        handleTranscriptsButtonRef();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ showTranscript, transcripts ]);

    useUpdateEffect(() => {
        if (isFetching) return;

        if (!transcripts.length)
            setShowTranscript(false);
    }, [isFetching, transcripts.length]);

    return {
        showTranscript,
        onToggleTranscript,
        displayToggleTranscriptButton,
        handleTranscriptsButtonRef,
    };
};

export default useTranscriptButton;