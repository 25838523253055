import { IStepInformation } from './Tutorial';


export const Mp4TutorialSteps = [
    {
        headerText: 'Note your authorization code',
        bodyText: 'During the program, an authorization code will be announced. Take note of this code so you can provide it later to verify your attendance.',
    },
    {
        headerText: 'Submit credits in the player',
        bodyText: 'When you finish watching the segment, simply open the player and submit your credit request by clicking on the “Credit” panel.',
    },
] as Array<IStepInformation>;
