import '../../styles/_program-evaluation-survey.scss';
import '../../styles/_typography.scss';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useRef, useState } from 'react';

import { useGetCleSessionQuery } from '../../datasource/queries/cle';
import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { MenuOptions } from '../../enums/MenuOptions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import logger from '../../utility/logger';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { setIsSideBarDisabled, setLargePanelOpenCommand } from '../Store/menuSlice';

export interface IProgramEvaluationSurvey {
    encryptedRegistrationId?: string,
    isStandalone?: boolean,
    onSurveySubmissionSuccess?: () => void;

}

const CREATE_COMPONENT_TIMEOUT_SECONDS = 10;

export const ProgramEvaluationSurvey  = ({ encryptedRegistrationId, isStandalone, onSurveySubmissionSuccess } : IProgramEvaluationSurvey) => {
    const dispatch = useAppDispatch();

    const className = `program-evaluation-survey${isStandalone? '--standalone' : ''}`;
    const footerPadding = isStandalone? 'pb-4 pl-2 pr-2' : '';
    
    const individualSK: number = useAppSelector((state) => state.user.individualSK);
    const { data: clientConfig } = useGetClientConfigQuery();
    const { data: cleSession } = useGetCleSessionQuery(encryptedRegistrationId ?? skipToken);

    const [isSurveySubmitting, setIsSurveySubmitting] = useState(false);
    const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
    const [isSurveyLoading, setIsSurveyLoading] = useState(false);
    const [didSurveyLoadFail, setDidSurveyLoadFail] = useState(false);

    const surveyUrl = clientConfig?.salesForceSurveySiteDomainUrl ?? '';
    const scriptSrc = `${surveyUrl}/lightning/lightning.out.js`;
    const checkIntervalRef = useRef<ReturnType<typeof setInterval>>();
    const createComponentPromise = useRef<{ resolve(a: unknown): void, reject(reason?: unknown): void }>();
    const componentRef = useRef<any>();
    const surveyReference = useRef<any>();
    const formAssemblyLink = `${clientConfig?.formAssemblySiteDomainUrl}?tfa_171=${cleSession?.sourceItem_PK}&tfa_173=${individualSK}&tfa_191=${cleSession?.item_PK}&tfa_220=${cleSession?.registrationID}`;

    const finalizeMobileSurveySubmit = () => {
        if(onSurveySubmissionSuccess){
            onSurveySubmissionSuccess();
        }
        const EVENT_CLOSE = {
            event: 'CLOSE'
        };
        if(window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(EVENT_CLOSE));
        }
        else {
            alert('window.ReactNativeWebView is not defined');
        }
    };

    const finalizeWatchSurveySubmit = () => {
        setTimeout(() => {
            dispatch(setIsSideBarDisabled(false));
            dispatch(setLargePanelOpenCommand(MenuOptions.CreditSubmissionConfirmation));
        }, 1000);
    };

    useEffect(() => {
        const isValidUrl = (urlString: string) => {
            try { 
                return Boolean(new URL(urlString)); 
            }
            catch(e){ 
                return false; 
            }
        };

        if (surveyReference.current || cleSession === null || cleSession === undefined) return;
    
        const surveyErrorCallback = (err: unknown) => {
            logger.exception(err as Error);
            setDidSurveyLoadFail(true);
        };

        const inputParams = {
            surveyName: 'Program Evaluation',
            identifiers: {
                registrationId: cleSession?.registrationID,
                individualId: individualSK,
                productId: Number(cleSession?.item_PK),
                sourceProgramId: cleSession?.sourceItem_PK,
                darkMode: true
            },
            loadFromLocalStorage: false,
            supressSpinner: true 
        };

        function validateCreationOfCmp() {
            window.addEventListener('xhr:error', onXHRError);
            const startValidationTime = Date.now();
    
            checkIntervalRef.current = setInterval(() => {
                const surveyContent = document.getElementById('surveyContent')?.querySelector('c-survey-page .survey .surveySection');
                const surveyContentLoaded = !!surveyContent;
                if (!componentRef.current || !surveyContentLoaded) {
                    const auraErrorMessage = document.getElementById('auraErrorMessage');
                    if (auraErrorMessage && auraErrorMessage.innerHTML) {
                        createComponentPromise.current?.reject({
                            isSuccess: false,
                            errors: ['unable to create component'],
                        });
                    } else if (Date.now() - startValidationTime >= CREATE_COMPONENT_TIMEOUT_SECONDS * 1000) {
                        createComponentPromise.current?.reject({
                            isSuccess: false,
                            errors: ['creating component has timed out']
                        });
                    }
                } else {
                    window.removeEventListener('xhr:error', onXHRError);
                    createComponentPromise.current?.resolve(componentRef.current);
                }
            }, 250);
        }

        function onXHRError(e: any) {
            const url = e.detail.originalArgs[1];
            if (!url.includes(surveyUrl)) return;
            
            createComponentPromise.current?.reject({
                isSuccess: false,
                errors: ['failed to load component'],
            });
        }

        function createComponent(
            appName: string,
            componentName: string,
            inputParams: object, 
            elementId: string,
            siteEndpoint: string
        ): Promise<any> {
            siteEndpoint;

            return new Promise((resolve, reject) => {
                try {
                    createComponentPromise.current = {
                        resolve: resolve,
                        reject: reject,
                    };

                    $Lightning.use(appName, () => {
                        $Lightning.createComponent(
                            componentName,
                            inputParams,
                            elementId,
                            (cmp: any) => {
                                componentRef.current = cmp;
                            },
                            siteEndpoint
                        );
                    });

                    validateCreationOfCmp();
                } catch (err) {
                    createComponentPromise.current?.reject(err);
                    setDidSurveyLoadFail(true);
                }
            }).finally(cleanup);

            function cleanup() {
                if (checkIntervalRef.current) clearInterval(checkIntervalRef.current);
                window.removeEventListener('xhr:error', onXHRError);
            }
        }

        function initSalesForceSurvey() {
            setIsSurveyLoading(true);
            createComponent('c:surveyDependencies', 'c:surveyPage', inputParams, 'surveyContent', surveyUrl)
                .then((cmpRef: any) => {
                    surveyReference.current = cmpRef;                   
                })
                .catch((err: any) => {
                    surveyErrorCallback(err);
                })
                .finally(() => {
                    setIsSurveyLoading(false);
                });
        }

        if (!isValidUrl(scriptSrc)) return;
    
        const script = document.createElement('script');
    
        script.src = scriptSrc;
        script.async = true;
        script.addEventListener('load', onLoad);
        script.onerror = () => setDidSurveyLoadFail(true);
        function onLoad() { 
            initSalesForceSurvey();
        }
        
        document.body.appendChild(script);
   
        return () => {
            script.removeEventListener('load', onLoad);
            document.body.removeChild(script);
            if (checkIntervalRef.current) clearInterval(checkIntervalRef.current);
        };
    }, [cleSession, individualSK, scriptSrc, surveyUrl]);

    function handleError(err: any) {       
        if(err.errors.length > 0)
        {
            let firstErrorElement: HTMLElement | null = null;
            
            pageLoop : for (const surveyPage of document.querySelectorAll('c-survey-page')) {
                for (const surveySection of surveyPage.shadowRoot?.querySelectorAll('c-survey-section') ?? []) {
                    for (const surveyQuestion of surveySection?.shadowRoot?.querySelectorAll('c-survey-question') ?? []) {
                        if (surveyQuestion.shadowRoot?.querySelector('#' + err.errors[0].id)) {
                            firstErrorElement = surveyQuestion as HTMLElement;
                            break pageLoop;
                        }
                    }
                }    
            }

            if(firstErrorElement) {
                firstErrorElement.scrollIntoView( { behavior: 'smooth' });
            }
        }
    }

    function validateSurvey(): void {
        if(isSurveySubmitting) return;

        if (surveyReference.current) {
            surveyReference.current.validate()
                .then((msg: any) => {     
                    setIsSurveySubmitting(true);   
                    return surveyReference.current.submit()
                        .then(() => {
                            setIsSurveySubmitting(false);
                            setIsSurveySubmitted(true);
                            setTimeout(() => {
                                dispatch(setIsSideBarDisabled(false));
                                dispatch(setLargePanelOpenCommand(MenuOptions.CreditSubmissionConfirmation));
                            }, 1000);

                            if(isStandalone){
                                finalizeMobileSurveySubmit();
                            }
                               
                        })
                        .catch((err: any) => {
                            if (err && !err.isSuccess && err.errors[0] && err.errors[0].status !== 500) {
                                handleError(err);
                            }
                        });
                        
                }).catch((err: any) => {
                    handleError(err);
                });
        } else {
            dispatch(setLargePanelOpenCommand(MenuOptions.CreditSubmissionConfirmation));
        }
    }

    return (
        <>
            { isSurveyLoading &&
                <div className='h-75'>
                    <Loader />
                </div>
            }

            <div className={`watch-modal__content ${className}`}>
                {!didSurveyLoadFail && <div id='surveyContent'></div>}

                { didSurveyLoadFail &&
                <div className='mt-4 pt-4 mb-4 pb-4 heading-3'>
                    Please <a href={formAssemblyLink} target='_blank' rel='noopener noreferrer'>click here</a> to complete the program evaluation in order to complete your credit request.
                    <div className={`watch-modal__footer pt-4 ${footerPadding}`}>
                        <div className='watch-modal__footer__button-container'> 
                            <Button
                                label={ 'Submit Request' }
                                buttonClass= 
                                    {'sticky-button program-evaluation-survey__button paragraph-1--bold mt-3'}
                                typeSubmit={true}
                                action={isStandalone? finalizeMobileSurveySubmit : finalizeWatchSurveySubmit}
                                aria-label={'Submit credit request'}
                            />
                        </div>
                    </div>
                </div>
                }
            </div>

            { !didSurveyLoadFail && surveyReference.current &&
                <div className={`watch-modal__footer ${footerPadding}`}>
                    <div className='watch-modal__footer__button-container'> 
                        <Button
                            label={isSurveySubmitted ? 'Survey submitted' : 'Submit Request' }
                            buttonClass= 
                                {`sticky-button ${                              
                                    isSurveySubmitted
                                        ? 'sticky-button--success'
                                        : 'program-evaluation-survey__button paragraph-1--bold mt-3'
                                }`}
                            typeSubmit={true}
                            action={validateSurvey}
                            isLoading={isSurveySubmitting}
                            aria-label={isSurveySubmitted ? 'Survey submitted' : 'Submit credit request'}
                            icon={isSurveySubmitted ? 'checkmark' : undefined}
                            iconPosition={isSurveySubmitted ? 'left' : undefined}
                            isSuccessState={isSurveySubmitted}
                        />
                    </div> 
                </div>
            }
        </>
             
    );
};

export default ProgramEvaluationSurvey;
