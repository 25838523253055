import '../../../styles/_tutorial-animation.scss';

import { motion } from 'framer-motion';
import React from 'react';

interface VerifyAttendanceAnimationProps {
    stepIndex: number;
}

const VerifyAttendanceAnimation: React.FC<VerifyAttendanceAnimationProps> = (
    props: VerifyAttendanceAnimationProps
) => {
    const { stepIndex } = props;

    return (
        <div className='tutorial-animation__container'>
            {stepIndex === 0 || stepIndex === 2 ? (
                <>
                    <motion.div
                        className='tutorial-animation__text-container'
                        title={stepIndex === 0
                            ? 'Verify your attendance demonstration'
                            : 'Submit credit request demonstration'}
                        animate={{ scale: [1, 1, 0.8, 0.8, 1, 1] }}
                        transition={{
                            duration: 5,
                            ease: 'easeInOut',
                            times: [0, 0.30, 0.35, 0.6, 0.65, 1],
                            repeat: 1,
                            repeatDelay: 1,
                        }}
                    >
                        <motion.span
                            className='tutorial-animation__text'
                            animate={{ opacity: [1, 1, 0, 0, 1] }}
                            transition={{
                                duration: 5,
                                ease: 'easeInOut',
                                times: [0, 0.30, 0.35, 0.95, 1],
                                repeat: 1,
                                repeatDelay: 1,
                                exit: { opacity: 1 },
                            }}
                        >
                            {stepIndex === 0
                                ? 'Verify attendance'
                                : 'Submit credit request'}
                        </motion.span>
                        <motion.i
                            className='watch watch-checkmark'
                            animate={{ opacity: [0, 0, 0, 0, 0, 1, 1, 0] }}
                            transition={{
                                duration: 5,
                                ease: 'easeInOut',
                                times: [0, 0.05, 0.45, 0.5, 0.6, 0.65, 0.95, 1],
                                repeat: 1,
                                repeatDelay: 1,
                            }}
                        />
                    </motion.div>
                </>
            ) : (
                <>
                    <div className='tutorial-animation__circle tutorial-animation__circle--empty'></div>
                    <svg
                        viewBox='0 0 64 64'
                        width='64px'
                        height='64px'
                        fill='none'
                        className='tutorial-animation__circle tutorial-animation__circle__svg'
                    >
                        <title>Submit credits demonstration</title>
                        <motion.circle
                            cx='32'
                            cy='32'
                            r='28'
                            stroke='#01C365'
                            data-fallback='edge'
                            strokeWidth='8px'
                            animate={{ pathLength: [0.75, 1.1, 1.1, 0.75] }}
                            transition={{
                                duration: 6,
                                delay: 1,
                                ease: 'easeOut',
                                times: [0, 0.25, 0.85, 1],
                                repeat: 1,
                                repeatDelay: 1,
                            }}
                        />
                    </svg>
                    <svg
                        className='tutorial-animation__checkmark'
                        xmlns='http://www.w3.org/2000/svg'
                        width='64'
                        height='64'
                        viewBox='0 0 64 64'
                        fill='none'
                    >
                        <motion.path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M44.3006 27.6304L30.1324 41.1051L21.6995 33.085L24.8006 29.8242L30.1324 34.8949L41.1995 24.3696L44.3006 27.6304Z'
                            fill='#01C365'
                            animate={{
                                opacity: [0, 0, 1, 1, 0],
                                scale: [1, 1.25, 1, 1, 1],
                            }}
                            transition={{
                                duration: 6,
                                delay: 1,
                                ease: 'easeOut',
                                times: [0, 0.25, 0.35, 0.9, 1],
                                repeat: 1,
                                repeatDelay: 1,
                            }}
                        />
                    </svg>
                </>
            )}
            <div
                className={`tutorial-animation__background-circle ${
                    stepIndex === 0
                        ? 'tutorial-animation__background-circle--live-gradient'
                        : stepIndex === 1
                            ? ''
                            : 'tutorial-animation__background-circle--secondary-smoke'
                }`}
            ></div>
        </div>
    );
};

export default VerifyAttendanceAnimation;
