import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { LaunchDetails, LaunchSegment } from '../../datasource/generated';
import creditSubmissionSlice from '../../datasource/mutations/creditSubmission';
import launchSlice from '../../datasource/mutations/launch';
import { CachedImage } from '../../utility/imageUtils';

export enum ExternalCommand {
    play,
    pause,
    noop,
}

export enum ExternalFullscreenToggle {
    on,
    off,
    noop,
}

export interface PlaybackPosition {
    itemSk: number,
    position: number,
    totalTime: number,
}

export type PossibleThreshold = 10 | 25 | 50 | 75

export interface PlayerState {
    itemSk: number,
    itemPk: string,
    currentSegmentItemSk: number;
    playbackProgress: PlaybackPosition[];
    progressTracked: { 
        [itemSk: number]: PossibleThreshold[] 
    };
    launchDetails: LaunchDetails;
    isFetchingLaunchDetails: boolean;
    hasLoadedLaunchDetails: boolean;
    currentPrgSegmentSk: number;
    didPosterImageFinishCaching: boolean;
    didSegmentThumbnailsFinishedCaching: boolean;
    cachedImages: {
        [requestedUrl: string]: string
    };
    sessionGuid: string;
    isPlayerElementReady: boolean;
    externalCommand: ExternalCommand;
    externalFullscreenToggle: ExternalFullscreenToggle;
    externalSeekCommand?: number;
    requestStartTime?: number;
    lastVideoStartedFrom: 'auto_played' | 'end_of_video_promotion' | 'segments_panel' | 'notes'
}

const initialState: PlayerState = {
    itemSk: 0,
    itemPk: '0',
    currentSegmentItemSk: 0,
    playbackProgress: [],
    progressTracked: {},
    launchDetails: {},
    isFetchingLaunchDetails: false,
    hasLoadedLaunchDetails: false,
    currentPrgSegmentSk: 0,
    didPosterImageFinishCaching: false,
    didSegmentThumbnailsFinishedCaching: false,
    cachedImages: {},
    sessionGuid: '',
    isPlayerElementReady: false,
    externalCommand: ExternalCommand.noop,
    externalFullscreenToggle: ExternalFullscreenToggle.noop,
    externalSeekCommand: undefined,
    requestStartTime: undefined,
    lastVideoStartedFrom: 'auto_played',
};

const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        setItemSk(state, action: PayloadAction<number>) {
            state.itemSk = action.payload;
        },
        setItemPk(state, action: PayloadAction<string>) {
            state.itemPk = action.payload;
        },
        setCurrentSegmentItemSk(state, action: PayloadAction<number>) {
            state.currentSegmentItemSk = action.payload;
        },
        setCurrentPrgSegmentSk(state, action: PayloadAction<number>) {
            state.currentPrgSegmentSk = action.payload;
        },
        pushPosterImageCachedImages(state, action: PayloadAction<CachedImage[]>) {
            action.payload.map((obj) => {
                state.cachedImages[obj.requestedUrl] = obj.resultingUrl;
            });
            state.didPosterImageFinishCaching = true;
        },
        pushSegmentThumbnailsCachedImages(state, action: PayloadAction<CachedImage[]>) {
            action.payload.map((obj) => {
                state.cachedImages[obj.requestedUrl] = obj.resultingUrl;
            });
            state.didSegmentThumbnailsFinishedCaching = true;
        },
        setSessionGuid(state, action: PayloadAction<string>) {
            state.sessionGuid = action.payload;
        },
        setPlayerElementReady(state) {
            state.isPlayerElementReady = true;
        },
        setExternalCommand(state, action: PayloadAction<ExternalCommand>) {
            state.externalCommand = action.payload;
        },
        setExternalFullscreenToggle(state, action: PayloadAction<ExternalFullscreenToggle>) {
            state.externalFullscreenToggle = action.payload;
        },
        setExternalSeekCommand(state, action: PayloadAction<number | undefined>) {
            state.externalSeekCommand = action.payload;
        },
        setRequestStartTime(state, action: PayloadAction<number | undefined>) {
            state.requestStartTime = action.payload;
        },
        updatePlaybackProgress(state, action: PayloadAction<Omit<PlaybackPosition, 'totalTime'>>) {
            const index = state.playbackProgress.findIndex(x => x.itemSk === action.payload.itemSk);
            if (index > -1)
                state.playbackProgress[index].position = action.payload.position;
        },
        setIsOnAir(state) {
            state.launchDetails.isOffAir = false;
        },
        setLaunchDetails(state, action: PayloadAction<LaunchDetails>) {
            // For storybook
            state.launchDetails = action.payload;
        },
        setVideoUrl(state, action: PayloadAction<string>) {
            if (!state.launchDetails.video?.url){
                return;
            }
            state.launchDetails.video.url = action.payload;
        },
        setProgressTracked(state, { payload }: PayloadAction<{ itemSk: number, threshold: PossibleThreshold }>) {
            if (!state.progressTracked[payload.itemSk]) {
                state.progressTracked[payload.itemSk] = [];
            }

            state.progressTracked[payload.itemSk].push(payload.threshold);
        },
        setLastVideoStartedFrom(state, { payload }: PayloadAction<typeof state.lastVideoStartedFrom>) {
            state.lastVideoStartedFrom = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                launchSlice.endpoints.launch.matchPending,
                (state) => {
                    state.isFetchingLaunchDetails = true;
                }
            )
            .addMatcher(
                launchSlice.endpoints.launch.matchFulfilled,
                (state, { payload }) => {
                    state.hasLoadedLaunchDetails = true;
                    state.itemSk = payload.itemSk!;
                    state.itemPk = payload.itemPk!;
                    state.currentSegmentItemSk = payload.currentSegment!;
                    state.currentPrgSegmentSk = payload.segments?.find((s: LaunchSegment) => s.itemSk == payload.currentSegment)?.prgSegmentSk ?? 0;
                    state.launchDetails = payload;
                    state.isFetchingLaunchDetails = false;
                    if (payload.isWebcast) {
                        state.playbackProgress = [];
                    } else if (payload.video) {
                        const totalTime = getTotalDurationInSeconds(payload.producedRunningTime ?? '');
                        state.playbackProgress = [
                            { 
                                itemSk: payload.currentSegment!, 
                                position: payload.video.position ?? 0, 
                                totalTime 
                            }
                        ];
                    } else {
                        state.playbackProgress = payload.segments?.map(segment => {
                            const totalTime = getTotalDurationInSeconds(segment.producedRunningTime ?? '');
                            const segmentPlaybackPosition: PlaybackPosition = {
                                itemSk: segment.itemSk!,
                                position: segment.video?.position ?? 0,
                                totalTime
                            };
                            return segmentPlaybackPosition;
                        }) ?? [];
                    }
                }
            )
            .addMatcher(
                launchSlice.endpoints.launch.matchRejected,
                (state) => {
                    state.hasLoadedLaunchDetails = true;
                }
            )
            .addMatcher(
                creditSubmissionSlice.endpoints.submitCreditRequest.matchFulfilled,
                (state, { payload }) => {
                    state.launchDetails.encryptedRegistrationId = payload.encryptedRegistrationId;
                }
            );
    },
});

const getTotalDurationInSeconds = (producedRunningTime: string) => {
    const time = producedRunningTime?.split(':');
    const hoursInSeconds = parseInt(time ? time[0] : '0') * 60 * 60;
    const minutesInSeconds = parseInt(time ? time[1] : '0') * 60;
    const seconds = parseInt(time ? time[2] : '0');

    return hoursInSeconds + minutesInSeconds + seconds;
};

export const {
    setItemSk,
    setItemPk,
    setCurrentSegmentItemSk,
    setCurrentPrgSegmentSk,
    pushPosterImageCachedImages,
    pushSegmentThumbnailsCachedImages,
    setSessionGuid,
    setPlayerElementReady,
    setExternalCommand,
    setExternalFullscreenToggle,
    setExternalSeekCommand,
    setRequestStartTime,
    updatePlaybackProgress,
    setIsOnAir,
    setLaunchDetails,
    setVideoUrl,
    setProgressTracked,
    setLastVideoStartedFrom,
} = playerSlice.actions;

export const { reducer: playerReducer } = playerSlice;
