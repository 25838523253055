import { ReduxRouter } from '@lagunovsky/redux-react-router';
import React from 'react';
import { Provider } from 'react-redux';

import { ConnectivityProvider } from './contexts/ConnectivityContext';
import { history, store } from './stories/Store/configureStore';

export const AppProviders = ({ children }: React.PropsWithChildren) => {
    return (
        <ConnectivityProvider>
            <Provider store={store}>
                <ReduxRouter history={history}>
                    {children}
                </ReduxRouter>
            </Provider>
        </ConnectivityProvider>
    );
};