import '../../styles/_question-panel.scss';
import '../../styles/_typography.scss';

import React, { useMemo, useRef, useState } from 'react';

import { LaunchSegment, Question, QuestionType } from '../../datasource/generated';
import { useAddQuestionMutation } from '../../datasource/mutations/questions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAnalytics } from '../../hooks/useAnalytics';
import Button from '../Button/Button';
import Dropdown, { IDropdownOption } from '../Dropdown/Dropdown';
import {
    setEmail,
    setMessageText,
    setProgramTitle,
    setSegmentTitle,
    setTopic,
} from '../Store/facultyQuestionSlice';
import TextArea from '../TextArea/TextArea';
import TextInput from '../TextInput/TextInput';

export type IFacultyQuestionFormProps = {
    closeSubPanel?: () => void,
    isLive?: boolean
}

export const FacultyQuestionForm = ({ closeSubPanel, isLive = false }: IFacultyQuestionFormProps) => {
    const facultyQuestionState = useAppSelector((state) => state.facultyQuestion);
    const user = useAppSelector((state) => state.user);
    const { pushToDataLayer } = useAnalytics();
    const email: string = useAppSelector((state) => state.user.email);
    const itemSk: number = useAppSelector((state) => state.player.itemSk);    const defaultProgramTitle : string | null | undefined = useAppSelector((state) => state.player.launchDetails.title);
    const segments : LaunchSegment[] | null | undefined = useAppSelector((state) => state.player.launchDetails.segments);

    const [showTopicError, setShowTopicError] = useState(false);
    const [showMessageTextError, setShowMessageTextError] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const footerText = 'Note: This is a public forum and your question may be viewable by all attendees. However, if your question is posted, it will be done so anonymously.';
    const serverError = 'Error: Question was unable to save. Please try again later.';
    const dispatch = useAppDispatch();
    const isSingleSegment = !segments || segments.length === 1;
    const missingMessageText : boolean = !facultyQuestionState.messageText || facultyQuestionState.messageText === '';
    const missingEmail : boolean = user.isGroupcast && facultyQuestionState.email === '';
    const missingTopic : boolean = isSingleSegment 
        ? !facultyQuestionState.segmentTitle || facultyQuestionState.segmentTitle === ''
        : !facultyQuestionState.topic || facultyQuestionState.topic === ''; 
    const hasMissingFields : boolean = isLive ? missingMessageText : missingMessageText || missingTopic || missingEmail;
    const individualSK = user.individualSK;

    const [
        addQuestion,
        { isLoading: addQuestionIsLoading, isError: addQuestionError },
    ] = useAddQuestionMutation();
    const [success, setSuccess] = useState(false);
    const topicOptions : IDropdownOption[] = useMemo(() =>
    {              
        const generalQuestion : IDropdownOption = {
            label: '0',
            value: 'General Question'
        };
 
        const topicOptions = segments
            ? segments.map((segment) => ({
                label: segment.itemSk?.toString() ?? '',
                value: segment.title ?? '',
            }))
            : [];

        return [generalQuestion, ...topicOptions];
        
    }, [segments]);

    const handleSubmit = (async () => {
        const handleResetForm = (() => {
            dispatch(setEmail(''));
            dispatch(setTopic(''));
            dispatch(setMessageText(''));  
            setSuccess(false); 
        });

        setShowMessageTextError(missingMessageText);
        setShowEmailError(missingEmail);
        segments && setShowTopicError(missingTopic);

        if (!hasMissingFields) {
            const chosenSegmentSk = facultyQuestionState.topic 
                ? parseInt(topicOptions.find(option => option.value === facultyQuestionState.topic)?.label ?? '')
                : segments?.find(segment => segment.title === facultyQuestionState.segmentTitle)?.itemSk;
          
            const question: Question = {
                itemSK: itemSk,
                segmentSK: chosenSegmentSk,
                individualSK: individualSK,
                questionText: facultyQuestionState.messageText,
                emailFrom: !missingEmail ? facultyQuestionState.email : email,
                questionType: QuestionType.FACULTY
            };
    
            const response = await addQuestion(question);
            if (!response || !('data' in response) || !response.data || addQuestionError) {
                return;
            }

            setSuccess(true);

            pushToDataLayer({ event: 'qa_submit' });

            setTimeout(() => {
                closeSubPanel && closeSubPanel();
                handleResetForm();
            }, 1000);
        }
    });
    
    return (
        <>           
            <form
                className='question-form'
                onSubmit={(e) => e.preventDefault()}
                noValidate
            >
                <div>
                    <h1 className='paragraph-1 text--white'>Ask faculty a question</h1>
                    <p className='paragraph-2 mb-2'>Your question will be forwarded to the corresponding member of the faculty</p>
                    <p className='question-form__required sentence-label'>* Indicates required field</p>
                </div>
                <div className='question-form__body'>
                    <TextInput
                        errorText={'Required'}
                        initialValue={
                            facultyQuestionState.programTitle !== ''
                                ? facultyQuestionState.programTitle
                                : (defaultProgramTitle ? defaultProgramTitle : '')
                        }
                        inputLabel={'Program'}
                        inputValueForParent={(text) => dispatch(setProgramTitle(text))}
                        showInputError={false}
                        required={true}
                        disabled={true}
                    />

                    { user.isGroupcast &&
                        <TextInput
                            errorText={'Required'}
                            initialValue={''}
                            inputLabel={'Email'}
                            inputValueForParent={(text) => dispatch(setEmail(text))}
                            showInputError={showEmailError}
                            required={true}
                            disabled={false}
                        />
                    }

                    { segments && !isSingleSegment && !isLive &&
                    <Dropdown
                        items={[...topicOptions.values()]}
                        dropdownLabel='Topic'
                        dropdownSelection={facultyQuestionState.topic && facultyQuestionState.topic !== '' 
                            ? facultyQuestionState.topic 
                            : 'Select a topic...'}
                        setSelection={(selection: string) =>
                            dispatch(setTopic(selection ?? ''))
                        }
                        required={segments !== null}
                        ariaLabel={'Topic'}
                        showError={showTopicError}
                    />
                    }
                
                    { isSingleSegment && !isLive &&
                    <TextInput
                        initialValue={defaultProgramTitle ?? ''}
                        inputLabel={'Topic'}
                        inputValueForParent={(text) => dispatch(setSegmentTitle(text))}
                        showInputError={false}
                        required={true}
                        errorText='Required'
                        disabled={true}
                    />
                    }    

                    <TextArea
                        currentValue={facultyQuestionState.messageText}
                        updateValue={(text) => dispatch(setMessageText(text))}
                        showTextAreaError={showMessageTextError}
                        maxLength={500}
                        required={true}
                        textAreaLabel='Message'
                        ariaLabel='Enter question for faculty, 500 characters maximum'
                        ref={textAreaRef}
                    />
                    {
                        addQuestionError &&                       
                        <div className='question-form__footer__error sentence-label mb-2'>
                            <i className='watch watch-alert pr-1'/>
                            {serverError}
                        </div>
                    }
                </div>
    
                <div className='question-form__footer__button-container'>  
                    <p className='question-form__footer__note sentence-label mb-4'>{footerText}</p>
                    <Button 
                        buttonClass={`sticky-button ${                              
                            success
                                ? 'sticky-button--success'
                                : ''
                        }`}
                        label={success ? 'Sent' : 'Send'}
                        aria-label={'Send question'}
                        action={handleSubmit} 
                        typeSubmit={true}
                        icon={success ? 'checkmark' : undefined}
                        iconPosition={success ? 'left' : undefined}
                        isLoading={addQuestionIsLoading}
                        isSuccessState={success}
                    />
                </div>

            </form>
        </>
    );
};

export default FacultyQuestionForm;
