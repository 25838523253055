import { skipToken } from '@reduxjs/toolkit/dist/query';

import { useGetLicensesQuery } from '../datasource/queries/licenses';
import { useGetProgramCreditDetailsQuery } from '../datasource/queries/programDetails';
import { useAppSelector } from '.';
import useCpeExams from './useCpeExams';

export const useCpeBlock = () => {
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const {
        data: creditDetails,
    } = useGetProgramCreditDetailsQuery((launchDetails?.onDemandItemPk ? launchDetails.onDemandItemPk : launchDetails?.itemPk) ?? skipToken);
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);
    
    const { data: licenses } = useGetLicensesQuery(!encryptedRegistrationId ? skipToken : { encryptedRegistrationId });
    const disabledLicenses = useAppSelector((state) => state.requestCredit.disabledIndvLicenseSks);
    const cpeExams = useCpeExams();

    const licenseRequiringExam = creditDetails?.jurisdictions
        ?.find(jurisdiction => jurisdiction.regionSk === licenses?.find(l =>
            cpeExams.exams?.some(e => e.certTypeSks?.includes(l.certType_SK ?? 0))
        )?.creditRegion_SK)?.description
        ?? '';

    const isCpeCreditEnabled = licenses?.some(l =>
        cpeExams?.exams?.some(e => e.certTypeSks?.includes(l.certType_SK ?? 0)) &&
        !disabledLicenses.some(dl => dl === l.indvStateLicense_SK)
    );

    const cpeBlockDescription = cpeExams?.exams?.find(e => licenses?.some(l => e.certTypeSks?.includes(l.certType_SK ?? 0)))?.longDescription;

    return {
        isCpeCreditEnabled,
        licenseRequiringExam,
        cpeBlockDescription,
    };
};

export default useCpeBlock;
