import { apiSlice } from '..';

const cmsCacheSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        clearCmsCache: builder.mutation<boolean, void>({
            query: () => ({
                url: '/headless/clear-cache',
                method: 'POST',
            })
        })
    })
});

export const { useClearCmsCacheMutation } = cmsCacheSlice;
export default cmsCacheSlice;