import React from 'react';

import useEndOfVideo from '../../hooks/useEndOfVideo';
import EndOfVideoPanel from '../EndOfVideoPanel/EndOfVideoPanel';

interface IEndOfVideoPanelWrapperProps {
    closeAll(): void;
}
export const EndOfVideoPanelWrapper = (props: IEndOfVideoPanelWrapperProps) => {
    const endOfVideoProps = useEndOfVideo();

    return <EndOfVideoPanel 
        title={endOfVideoProps.title}
        nonTitleCta={endOfVideoProps.nonTitleCta}
        programFormat={endOfVideoProps.programFormat}
        currentSegment={endOfVideoProps.currentSegment}
        nextSegment={endOfVideoProps.nextSegment}
        autoplay={endOfVideoProps.autoplay}        
        onPlaySegment={props.closeAll}
        buttonText={endOfVideoProps.buttonText}
        areExamsPassed={endOfVideoProps.areExamsPassed}
        isGroupcast={endOfVideoProps.isGroupcast}           
    />;
};