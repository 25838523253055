import '../../styles/_segments-panel.scss';
import '../../styles/_typography.scss';
import '../../styles/_theme.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { SegmentFormat } from '../../enums/SegmentFormat';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FetchType, useAnalytics } from '../../hooks/useAnalytics';
import useChangeSegment from '../../hooks/useChangeSegment';
import { MP3_STATIC_IMAGE_SRC, OD_STATIC_IMAGE_SRC } from '../../utility/imageUtils';
import { setCurrentPrgSegmentSk, setLastVideoStartedFrom } from '../Store/playerSlice';
import { setSelectedSegment } from '../Store/segmentSlice';

export type ISegmentProps = {
    itemSk: number;
    itemPk?: string;
    prgSegmentSk: number;
    title: string;
    format: SegmentFormat;
    runTimeSeconds?: number;
    segmentNumber?: number;
    segmentsTotal?: number;
    description?: string;
    imageUrl?: string;
    startTime?: string;
    startTimeUtc?: string;
    endTimeUtc?: string;
    isSegmentDetails?: boolean;
    isSingleSegment?: boolean;
    onPlaySegment?(): void;
    onSeeDetails?(): void;
};

export const Segment = (props: ISegmentProps) => {
    const [isCurrentlyWatching, setIsCurrentlyPlaying] = useState(false);
    const { itemSk, prgSegmentSk, title, format, runTimeSeconds = 1, segmentNumber, segmentsTotal, description, imageUrl, startTime, startTimeUtc, endTimeUtc, isSegmentDetails, isSingleSegment, onPlaySegment: handlePlaySegment, onSeeDetails: handleSeeDetails } = props;
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const [isLive, setIsLive] = useState(false);
    const dispatch = useAppDispatch();
    const segmentRef = useRef<HTMLDivElement>(null);
    const { changeSegment } = useChangeSegment();
    const segmentProgressSeconds = Math.min(useAppSelector((state) => state.player.playbackProgress.find(x => x.itemSk === itemSk))?.position ?? 0, runTimeSeconds);
    const segmentProgressMinutes = Math.round(segmentProgressSeconds / 60);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const isMp3 = launchDetails.isPortable && launchDetails.isAudioOnly;
    const { pushToDataLayer } = useAnalytics();

    const runTimeMinutes = Math.max(1, Math.round(runTimeSeconds / 60));

    const onClickSegment = useCallback(() => {
        pushToDataLayer({ event: 'segment_thumbnail', fetchType: FetchType.Segment, value: itemSk });
        changeSegment({ itemSk: itemSk });
        if (handlePlaySegment) {
            const startedFrom = 'segments_panel';

            dispatch(setLastVideoStartedFrom(startedFrom));
            
            handlePlaySegment();
            pushToDataLayer({ event: 'video_start', additionalParams: { started_from: startedFrom } });
            
        }
    }, [changeSegment, dispatch, handlePlaySegment, itemSk, pushToDataLayer]);

    const seeDetails = useCallback(() => {
        dispatch(setSelectedSegment({ itemSk, prgSegmentSk, format, title, description, imageUrl, startTime, startTimeUtc, endTimeUtc, runTimeSeconds, segmentNumber, segmentsTotal }));
        if (handleSeeDetails) {
            pushToDataLayer({ event: 'view_segment_details', fetchType: FetchType.Segment, value: itemSk });
            handleSeeDetails();
        }
    }, [pushToDataLayer, description, dispatch, endTimeUtc, format, handleSeeDetails, imageUrl, itemSk, prgSegmentSk, runTimeSeconds, segmentNumber, segmentsTotal, startTime, startTimeUtc, title]);

    useEffect(() => {
        setIsCurrentlyPlaying(currentSegmentItemSk == itemSk);
    }, [itemSk, currentSegmentItemSk]);

    const checkIfSegmentIsLive = useCallback(() => {
        const now = new Date();
        const start = new Date(startTimeUtc ?? '');
        const end = new Date(endTimeUtc ?? '');
        setIsLive(now > start && now < end);
    }, [endTimeUtc, startTimeUtc]);

    useEffect(() => {
        if (isLive) {
            setTimeout(() => segmentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 1000);
            dispatch(setCurrentPrgSegmentSk(prgSegmentSk));
        }
    }, [dispatch, isLive, prgSegmentSk]);

    useEffect(() => {
        if (format === SegmentFormat.LiveWebcast) {
            checkIfSegmentIsLive();
            const interval = setInterval(() => {
                checkIfSegmentIsLive();
            }, 30000);

            return () => clearInterval(interval);
        }
    }, [format, checkIfSegmentIsLive]);

    useEffect(() => {
        if (isCurrentlyWatching) {
            segmentRef?.current?.scrollIntoView({ block: 'nearest' });
        }
    }, [isCurrentlyWatching]);

    return (
        <div id={prgSegmentSk.toString()} ref={segmentRef} className={`segment${format == SegmentFormat.LiveWebcast ? ' segment__live' : ''}${isSegmentDetails ? ' segment__details' : ''}`}>
            {
                format == SegmentFormat.OnDemand &&
                <div className={`segment__container${isSegmentDetails ? ' segment__details__container' : ''}`}>
                    {
                        imageUrl &&
                        <button onClick={onClickSegment} className={`segment__thumbnail${isCurrentlyWatching ? ' segment__thumbnail__currently-watching' : ''}${isSegmentDetails ? ' segment__details__thumbnail' : ''}`}>
                            <img
                                className={`segment__thumbnail__image${isSegmentDetails ? ' segment__details__thumbnail__image' : ''}`}
                                src={isMp3 ? MP3_STATIC_IMAGE_SRC : imageUrl}
                                alt='Segment thumbnail'
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = OD_STATIC_IMAGE_SRC;
                                }} />
                            {
                                isCurrentlyWatching &&
                                <span className={`segment__thumbnail__badge${isSegmentDetails ? ' segment__details__thumbnail__badge' : ''} segment__thumbnail__badge--live`}>
                                    Currently watching
                                </span>
                            }
                            {
                                !isCurrentlyWatching &&
                                <>
                                    {((runTimeMinutes ?? 0) - (segmentProgressMinutes ?? 0) > 0) && 
                                        <span className={`segment__thumbnail__badge${isSegmentDetails ? ' segment__details__thumbnail__badge' : ''} segment__thumbnail__badge--regular sentence-label text--medium-grey`}>
                                            {segmentProgressMinutes <= 0 && `${runTimeMinutes} min`}
                                            {segmentProgressMinutes >= 1 && runTimeMinutes && `${runTimeMinutes - segmentProgressMinutes} min left`}
                                        </span>
                                    }
                                    <span className={`segment__thumbnail__play${isSegmentDetails ? ' segment__details__thumbnail__play' : ''}`} aria-label='play segment'>
                                        <i className='watch watch-play' />
                                    </span>
                                    {segmentProgressSeconds >= 1 && runTimeSeconds && 
                                        <span className={'segment__thumbnail__progress-bar'}>
                                            <span 
                                                className={'segment__thumbnail__progress-bar__fg'} 
                                                style={{ 
                                                    width: `${Math.round(segmentProgressSeconds / runTimeSeconds * 100)}%`,
                                                    borderBottomRightRadius: `${segmentProgressSeconds / runTimeSeconds > 0.98 ? 4 : 0}px`, 
                                                }}
                                            />
                                            <span className={'segment__thumbnail__progress-bar__bg'} />
                                        </span>
                                    }
                                </>
                            }
                        </button>
                    }
                    <div className={`segment__info${isSegmentDetails ? ' segment__details__info' : ''}`}>
                        {
                            !isSingleSegment && segmentNumber && segmentsTotal &&
                            <div className={`${isSegmentDetails ? 'paragraph-2' : 'sentence-label '} text--medium-grey`}>{`Segment ${segmentNumber} of ${segmentsTotal}`}</div>
                        }
                        <div className={`segment__info__title text--white${isSegmentDetails ? ' segment__details__info__title heading-6--medium' : ' paragraph-1'} ${isSingleSegment ? 'mt-0' : ''}`}>{title}</div>
                        {
                            !isSegmentDetails && description &&
                            <button className='segment__info__description link-2 text--medium-grey' onClick={seeDetails}>See details</button>
                        }
                    </div>
                </div>
            }
            {
                format == SegmentFormat.LiveWebcast &&
                <>
                    {
                        isLive &&
                        <div><span className='segment__live__badge sentence-label'>Live now</span></div>
                    }
                    <div className='sentence-label text--medium-grey'>{startTime}</div>
                    <div className={`segment__info__title paragraph-1--medium${!isLive ? ' text--medium-grey' : ''}`}>{title}</div>
                    {
                        !isSegmentDetails && description &&
                        <button className='link-2 text--medium-grey' onClick={seeDetails}>See details</button>
                    }
                </>
            }
        </div>
    );
};

export default Segment;