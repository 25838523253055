import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect } from 'react';

import { useGetCleSessionQuery } from '../../datasource/queries/cle';
import { MenuOptions } from '../../enums/MenuOptions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FetchType, useAnalytics } from '../../hooks/useAnalytics';
import { LicenseModal } from '../LicenseModal/LicenseModal';
import Loader, { LoaderSizes } from '../Loader/Loader';
import { ErrorContainer, MainError } from '../MainError/MainError';
import { MainMenu } from '../MainMenu/MainMenu';
import { setEncryptedRegistrationId } from '../Store/requestCreditSlice';

export type IRequestCreditsPageProps = {
    /* to be used with storybook */
    rid?: string;
}

export function RequestCreditsPage({ rid }: IRequestCreditsPageProps) {
    const dispatch = useAppDispatch();

    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.encryptedRegistrationId);

    const { pushToDataLayer } = useAnalytics();

    useEffect(() => {
        const params = new URL(location.href).searchParams;

        const encryptedRegistrationId = rid ?? params.get('rid');

        if (encryptedRegistrationId)
            dispatch(setEncryptedRegistrationId(encryptedRegistrationId));

        pushToDataLayer({ event: 'request_credit_start', fetchType: FetchType.Program });
    }, [dispatch, pushToDataLayer, rid]);

    const { isFetching, isError, isSuccess } = useGetCleSessionQuery(rid ?? encryptedRegistrationId ?? skipToken);
   
    return (
        <div
            className='container-fluid d-flex flex-nowrap p-0 overflow-hidden position-relative'
            style={{ height: '100%' }}
        >
            {isFetching
                ? <Loader size={LoaderSizes.Medium} />
                : <>
                    {isError && (
                        <MainError errorContainer={ErrorContainer.Standalone}>
                            <p className="heading-4">There was an error loading your credit information</p>
                            <small>Please double check you are signed into the correct account.</small>
                        </MainError>
                    )}

                    {isSuccess && (
                        <>
                            <MainMenu standalone={{ type: 'large', menu: MenuOptions.RequestCredit }} />

                            <LicenseModal ignoreReview />
                        </>
                    )}                
                </>}

        </div>
    );
}