import '../../styles/_end-of-video-panel.scss';
import '../../styles/_button.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { LaunchSegment } from '../../datasource/generated';
import { MenuOptions } from '../../enums/MenuOptions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FetchType, useAnalytics } from '../../hooks/useAnalytics';
import useChangeSegment from '../../hooks/useChangeSegment';
import useCpeExams from '../../hooks/useCpeExams';
import { useSegmentImages } from '../../hooks/useSegmentImages';
import CreditProgressSaved from '../CreditProgressSaved/CreditProgressSaved';
import { ErrorContainer, MainError } from '../MainError/MainError';
import SegmentCard from '../SegmentCard/SegmentCard';
import { setIsExamStatusVisible, setLargePanelOpenCommand, setSmallPanelOpenCommand } from '../Store/menuSlice';
import { setLastVideoStartedFrom } from '../Store/playerSlice';

export interface EndOfVideButtonText {
    requestCredit: string,
    takeCpeExam: string,
    dontTakeCpeExam: string,
}

export interface IEndOfVideoProps {
    title: string,
    nonTitleCta?: string,
    programFormat?: 'Live' | 'OD Multi-Segment' | 'OD Single-Segment' | 'CPE Segment',
    currentSegment?: LaunchSegment,
    nextSegment?: LaunchSegment,
    autoplay?: boolean,
    onPlaySegment?: () => void,
    buttonText: EndOfVideButtonText,
    areExamsPassed?: boolean,
    isGroupcast: boolean,
}

export const EndOfVideoPanel = ({
    title,
    nonTitleCta,
    programFormat,
    currentSegment,
    nextSegment,
    autoplay,
    onPlaySegment,
    buttonText,
    areExamsPassed,
    isGroupcast,
}: IEndOfVideoProps) => {
    const dispatch = useAppDispatch();

    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const { changeSegment } = useChangeSegment();
    const { openExam, isGetExamsError } = useCpeExams();

    useEffect(() => {
        if (programFormat === 'CPE Segment')
            dispatch(setIsExamStatusVisible({ CpeEndOfVideo : true }));

        return () => {
            dispatch(setIsExamStatusVisible({ CpeEndOfVideo : false }));
        };
    }, [dispatch, programFormat]);

    const topicIndex = launchDetails.segments?.findIndex(x => x.itemSk === nextSegment?.itemSk);
    const topicCount = launchDetails.segments?.length;

    const didImagesFinishCaching = useAppSelector((state) => state.player.didSegmentThumbnailsFinishedCaching);
    const { getSegmentCachedImageUrl, getSegmentImageUrl } = useSegmentImages();

    const nextSegmentImageSrc = () => {
        if (nextSegment) {
            if (didImagesFinishCaching)
                return getSegmentCachedImageUrl(nextSegment.itemSk);
            else
                return getSegmentImageUrl(nextSegment.itemSk);
        }            
    };

    const shouldAutoplay = autoplay && nextSegment?.itemSk;
    const [autoplayCountdown, setAutoplayCountdown] = useState<number>(60);

    const playNextSegment = useCallback(() => {
        changeSegment({ itemSk: nextSegment?.itemSk ?? 0 });
        onPlaySegment && onPlaySegment(); // To close the large panel
    }, [changeSegment, nextSegment?.itemSk, onPlaySegment]);
    
    const { pushToDataLayer } = useAnalytics();

    const autoPlayNext = useCallback(() => {
        if (shouldAutoplay && autoplayCountdown == 0) {
            const startedFrom = 'auto_played';
            
            dispatch(setLastVideoStartedFrom(startedFrom));   
            
            playNextSegment();
            pushToDataLayer({  event: 'video_start', additionalParams: { started_from: startedFrom } });
        }
    }, [autoplayCountdown, dispatch, playNextSegment, pushToDataLayer, shouldAutoplay]);

    useEffect(() => {
        if ((autoplayCountdown < 0) || !shouldAutoplay ) return;

        const timerInterval = setInterval(async () => {
            setAutoplayCountdown(prevCountdown => prevCountdown - 1);
            autoPlayNext();
        },  1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [autoPlayNext, autoplayCountdown, shouldAutoplay]);

    const completedSegment = (
        <section className='end-of-video__completed-segment'>
            {<span className='paragraph-1 mb-0'>{currentSegment?.title}</span>}
            <CreditProgressSaved />
        </section>
    );

    const onClickRequestCredit = () => {
        if (isGroupcast)
            dispatch(setSmallPanelOpenCommand(MenuOptions.Credit));
        else {
            dispatch(setLargePanelOpenCommand(MenuOptions.RequestCredit));
            pushToDataLayer({ event: 'request_credit_start', fetchType: FetchType.Program });
        }
           
    };

    const onSegmentCardPress = () => {
        const startedFrom = 'end_of_video_promotion';
        
        dispatch(setLastVideoStartedFrom(startedFrom));

        playNextSegment();
        pushToDataLayer({  event: 'video_start', additionalParams: { started_from: startedFrom } });
    };

    const requestCreditButton = (
        <button 
            className='fluid-button fluid-button--large fluid-button--green'
            onClick={onClickRequestCredit}
        >{buttonText.requestCredit}</button>
    );

    const cpeExamButtons = (
        <div className='end-of-video__cpe__btn-group'>
            <button
                className='fluid-button fluid-button--large fluid-button--white'
                onClick={openExam}
            >{buttonText.takeCpeExam}</button>
            <button
                className='fluid-button fluid-button--large fluid-button--transparent'
                onClick={onClickRequestCredit}
            >{buttonText.dontTakeCpeExam}</button>
        </div>
    );

    if (isGetExamsError) {
        return <MainError errorContainer={ErrorContainer.LargePanel}/>;
    }

    return (
        <div className='end-of-video'>

            <div className='large-panel__heading'>
                <h3 className={'heading-4 mb-0'}>{title}</h3>
            </div>
            

            {(programFormat === 'Live') &&
                    <section className='end-of-video__live'>
                        <CreditProgressSaved/>
                        {requestCreditButton}
                    </section>
            }

            {(programFormat === 'OD Single-Segment') &&
                    <section className='end-of-video__single-segment'>
                        {completedSegment}
                        {requestCreditButton}
                    </section>
            }

            {programFormat === 'OD Multi-Segment' &&
                <>
                    {completedSegment}
                    <div className='end-of-video__divider'/>
                    {
                        nextSegment ?
                            (<section className='end-of-video__next-segment'>
                                {autoplay &&
                                 <span className='text--medium-grey mb-0'>
                                    Next segment starts in <span className='text--white mb-0'>{autoplayCountdown}s</span>
                                 </span>
                                }

                                <SegmentCard
                                    segmentTitle={nextSegment.title ?? ''}
                                    subTitle={`Segment ${(topicIndex ?? 0) + 1} of ${topicCount}`}
                                    imageSrc={nextSegmentImageSrc() ?? ''}
                                    onSegmentCardPress={onSegmentCardPress}
                                />

                            </section>) :
                            (<section className='end-of-video__request-credit-cta'>
                                <h3 className='heading-4 mb-0'>{nonTitleCta}</h3>
                                {requestCreditButton}
                            </section>)
                    }
                </>
            }

            {programFormat === 'CPE Segment' &&
                    <section className='end-of-video__cpe'>
                        {completedSegment}
                        {areExamsPassed ? requestCreditButton : cpeExamButtons}
                    </section>
            }
        </div>
    );
};

export default EndOfVideoPanel;
