import { useEffect, useMemo, useState } from 'react';

export function useMediaQuery(mediaQueryString: string) {
    const mediaQuery = useMemo(() => window.matchMedia(mediaQueryString), [mediaQueryString]);
    
    const [matches, setMatches] = useState(() => mediaQuery.matches);
    
    useEffect(() => {
        function onMatchChange(e: MediaQueryListEvent) {
            setMatches(e.matches);
        }
        
        mediaQuery.addEventListener('change', onMatchChange);
        return () => mediaQuery.removeEventListener('change', onMatchChange);
    }, [mediaQuery]);
    
    return matches;
}