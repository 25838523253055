import '../../../styles/_license-card.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useLicenseToast } from '../../../contexts/LicenseToastContext';
import {
    CreditRegion,
    IndividualCreditAndLicenseInfo,
    InteractiveType,
    STINDVSTATELICENSE_LICENSETYPE,
} from '../../../datasource/generated';
import Button from '../../Button/Button';
import { ILicenseModalFocusTarget } from '../../LicenseModal/LicenseModal';
import { ReactComponent as CreditStatusDanger } from './assets/Danger.svg';
import { ReactComponent as CreditStatusSuccess } from './assets/Success.svg';
import { ReactComponent as CreditStatusWarning } from './assets/Warning.svg';

export interface ILicenseCardProps {
  license: IndividualCreditAndLicenseInfo;
  creditDetails?: CreditRegion;
  onEditLicensePress: (
    license: IndividualCreditAndLicenseInfo,
    jurisdictionName?: string
  ) => void;
  focusTarget?: ILicenseModalFocusTarget;
}

export const LicenseCard = ({
    license,
    creditDetails,
    onEditLicensePress,
    focusTarget,
}: ILicenseCardProps) => {
    const onEditClick = () => {
        onEditLicensePress(license, creditDetails?.description ?? undefined);
    };

    const CREDIT_STATES = {
        approved: ['Approved'],
        warning: ['Pending', 'CreditUponRequest', 'ApplicationSubmitted'],
        failed: ['NotApproved', 'Expired', 'NonMcleState', 'Undefined'],
    };

    const statusIcon = useMemo(() => {
        const height = 16;
        const width = 16;
        if (
            CREDIT_STATES.warning.includes(creditDetails?.statusEnum ?? 'Undefined')
        )
            return (
                <CreditStatusWarning
                    height={height}
                    width={width}
                    aria-label="Credit Status Warning"
                />
            );
        if (
            CREDIT_STATES.approved.includes(creditDetails?.statusEnum ?? 'Undefined')
        )
            return (
                <CreditStatusSuccess
                    height={height}
                    width={width}
                    aria-label="Credit Status Success"
                />
            );
        return (
            <CreditStatusDanger
                height={height}
                width={width}
                aria-label="Credit Status Danger"
            />
        );
    }, [
        CREDIT_STATES.approved,
        CREDIT_STATES.warning,
        creditDetails?.statusEnum,
    ]);

    const cardRef = useRef<HTMLDivElement>(null);
    const editRef = useRef<HTMLButtonElement>(null);
    const notesAndMessagesRef = useRef<HTMLDivElement>(null);
    const [seeMore, setSeeMore] = useState<boolean>(false);
    const { toasts, addToast } = useLicenseToast();

    const cpeCreditsNotOffered =
    license.licenseType === STINDVSTATELICENSE_LICENSETYPE.CPA &&
    creditDetails?.totalOffered === 0;

    const onCreditsNotOfferedClick = () => {
        addToast(
            `This credit was available for the live webcast only.
         If you watched the live webcast, you may still launch the program and submit a request for credit,
         but your certificate will only reflect the time you participated in the live webcast.
         No credit will be offered for any additional time spent watching this program.`
        );
    };

    const notesAndMessageClass = (() => {
        if (cpeCreditsNotOffered)
            return `license-card__notes-and-message 
        license-card__notes-and-message__inline text--medium-grey paragraph-2`;

        return `license-card__notes-and-message 
            license-card__notes-and-message__${
        seeMore ? 'expanded' : 'collapsed'
        } 
            text--medium-grey paragraph-2`;
    })();

    const handleToggleSeeMore = () => {
        setSeeMore(!seeMore);
    };

    useEffect(() => {
        if (seeMore) {
            notesAndMessagesRef.current?.focus();
        }
    }, [seeMore]);

    useEffect(() => {
        if (!focusTarget) return;

        if (license.indvStateLicense_SK === focusTarget.licenseSk) {
            if (focusTarget.mode === 'card') cardRef.current?.focus();

            if (focusTarget.mode === 'edit-button') editRef.current?.focus();
        }
    }, [focusTarget, license.indvStateLicense_SK]);

    const expandButtonText = seeMore ? 'See Less' : 'See More';

    function getCombinedNotesAndMessage() {
        const notes =
      creditDetails?.notes &&
      creditDetails.interactiveType !== InteractiveType.YES
          ? creditDetails.notes.toString()
          : '';

        const regionMessage = creditDetails?.regionMessage
            ? creditDetails.regionMessage.toString()
            : '';

        const combinedText =
      notes && regionMessage
          ? `${notes}${regionMessage}`
          : notes || regionMessage;

        return combinedText || '';
    }

    const combinedNotesAndMessage = getCombinedNotesAndMessage();

    return (
        <div
            className="license-card"
            id={`${license.indvStateLicense_SK}`}
            tabIndex={-1}
            ref={cardRef}
        >
            <div className="license-card__header">
                <h2 className="paragraph-1 mb-0">{creditDetails?.description}</h2>
                <Button
                    buttonClass="license-card__edit"
                    label="Edit"
                    action={onEditClick}
                    aria-label={`Edit ${creditDetails?.description}`}
                    ref={editRef}
                />
            </div>

            <div className="license-card__divider" />

            <div className="license-card__body">
                <div className="license-card__body__summary">
                    <div className="license-card__body__summary__credit-status">
                        {statusIcon}
                        <h3 className="paragraph-2 mb-0">
                            {creditDetails?.statusDescription}
                        </h3>
                    </div>

                    <div>
                        <h4 className="paragraph-2 mb-0">{`${creditDetails?.totalOffered} Total Credits`}</h4>
                        <ul className="paragraph-2 text--medium-grey" aria-label="Credits">
                            {creditDetails?.credits?.map((credit) => {
                                const creditFormatted = `${credit.creditOffered} ${credit.typeTitle}`;
                                return <li key={credit.typeSK}>{creditFormatted}</li>;
                            })}
                        </ul>
                    </div>
                    {creditDetails?.expirationDateFormatted && (
                        <p className="paragraph-2 text--medium-grey">
                            {`Earn credits through ${creditDetails?.expirationDateFormatted}`}
                        </p>
                    )}
                </div>
            </div>

            {(creditDetails?.regionMessage || creditDetails?.notes) && (
                <div
                    className={notesAndMessageClass}
                    ref={notesAndMessagesRef}
                    tabIndex={-1}
                >
                    {combinedNotesAndMessage && (
                        <div
                            className=""
                            dangerouslySetInnerHTML={{ __html: combinedNotesAndMessage }}
                        />
                    )}

                    {cpeCreditsNotOffered ? (
                        <i
                            role="button"
                            tabIndex={0}
                            aria-label="CPE credits information"
                            onClick={onCreditsNotOfferedClick}
                            onKeyDown={(e) => e.key === 'Enter' && onCreditsNotOfferedClick()}
                            className="pl-2 license-info-button watch watch-support"
                        />
                    ) : (
                        <button
                            onClick={handleToggleSeeMore}
                            className="license-card__toggle-see-more"
                        >
                            {expandButtonText}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};
