import '../../styles/_support-panel.scss';

import React from 'react';

export type IBetaFeedbackProps = {
    panelTitle: string;
    panelContent: string;
};

export const BetaFeedback = (props: IBetaFeedbackProps) => {
    const { panelTitle, panelContent } = props;

    return (
        <div className="beta-feedback">
            <h3 className="support-panel__sub-panel__title">{panelTitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: panelContent }}></div>
        </div>
    );
};

export default BetaFeedback;
