import { menuItemsLive, menuItemsOnDemand, menuItemsOnDemandGroupcast } from '../stories/MainMenu/MainMenu.utils';
import { useAppSelector } from '.';

export const useMenuItems = () => {
    const user = useAppSelector((state) => state.user);
    const launchDetails = useAppSelector((state) => state.player.launchDetails);

    const menuItems  = launchDetails.isWebcast 
        ? menuItemsLive 
        : (user.isGroupcast ? menuItemsOnDemandGroupcast : menuItemsOnDemand);

    return menuItems;
};