function isIOS(ua: string) {
    return isIPod(ua) || isIPhone(ua) || isIPad(ua);
}

function isIPod(ua: string) {
    return [
        'iPod Simulator',
        'iPod'
    ].includes(navigator.platform)
    || /ipod/i.test(ua);
}

function isIPhone(ua: string) {
    return [
        'iPhone Simulator',
        'iPhone',
    ].includes(navigator.platform)
    || /iphone/i.test(ua);
}

function isIPad(ua: string) {
    return ['iPad', 'iPad Simulator'].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (/mac(osx?)?/i.test(ua) && !isIPhone(ua) && !isIPod(ua) && 'ontouchend' in document);
}

function isAndroid(ua: string) {
    return /android/i.test(ua);
}

function isWindows(ua: string) {
    return /windows/i.test(ua);
}

function isLinux(ua: string) {
    return /linux/i.test(ua);
}

function isMac(ua: string) {
    return /mac/i.test(ua);
}

const platform = {
    ua: navigator.userAgent,
    deviceType() {
        switch(true) {
            case isIPhone(this.ua):
                return 'iPhone';
            case isIPod(this.ua):
                return 'iPod';
            case isIPad(this.ua):
                return 'iPad';
            case isAndroid(this.ua):
                return 'Android';
            default:
                return 'Desktop';
        }
    },
    os() {
        switch (true) {
            case (isIOS(this.ua)):
                return 'iOS';
            case (isAndroid(this.ua)):
                return 'Android';
            case (isWindows(this.ua)):
                return 'Windows';
            case (isMac(this.ua)):
                return 'Mac';
            case (isLinux(this.ua)):
                return 'Linux';
            default:
                return 'unknown';
        }
    },
};

export default platform;
