import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClosedCaption } from '../datasource/generated';
import { pushPosterImageCachedImages } from '../stories/Store/playerSlice';
import { cacheImagesAsync, LIVE_STATIC_IMAGE_SRC, MP3_STATIC_IMAGE_SRC, OD_STATIC_IMAGE_SRC } from '../utility/imageUtils';
import { useAppDispatch, useAppSelector, useAppStore } from '.';

interface CaptionTrack {
    file: string,
    label: string,
    kind: string,
}

interface PlaylistItem {
    file: string,
    image?: string,
    type?: string,
    description?: string | null | undefined,
    starttime?: number,
    tracks?: Array<CaptionTrack>,
}

export const usePlaylist = () => {
    const store = useAppStore();

    const launchDetails = useAppSelector((state) => state.player.launchDetails);
    const currentSegmentItemSk = useAppSelector((state) => state.player.currentSegmentItemSk);
    const cpeMarkers = useAppSelector ((state) => state.player.launchDetails.video?.cpeMarkers);
    const requestStartTime = useAppSelector((state) => state.player.requestStartTime);
    const didImagesFinishCaching = useAppSelector((state) => state.player.didPosterImageFinishCaching);
    const cachedImages = useAppSelector((state) => state.player.cachedImages);
    const hasLoadedLaunchDetails = useAppSelector((state) => state.player.hasLoadedLaunchDetails);
    const dispatch = useAppDispatch();
    const isMp3 = launchDetails.isPortable && launchDetails.isAudioOnly;

    const [hasCachedImages, setHasCachedImages] = useState(didImagesFinishCaching);

    const segment = launchDetails.segments?.find(seg => seg.itemSk === currentSegmentItemSk);
    const video = launchDetails.video ? launchDetails.video : segment?.video;
    const [searchParams] = useSearchParams();
    const positionParameter = searchParams.get('pos');

    const coverImage = useMemo(() => {
        const coverImage = isMp3
            ? MP3_STATIC_IMAGE_SRC
            : ((video?.thumbnails && video?.thumbnails[video?.thumbnails.length - 1]?.url) || OD_STATIC_IMAGE_SRC);

        return cachedImages[coverImage] ?? coverImage;
    }, [cachedImages, isMp3, video?.thumbnails]);

    const playlist = useMemo<PlaylistItem[]>(() => {
        if (!hasLoadedLaunchDetails) {
            return [];
        }

        if (launchDetails?.externalUrl) {
            return [{
                file: '',
                image: '',
                starttime: 0,
            }];
        }
    
        if (launchDetails?.isWebcast) {
            return [{
                file: video?.url || '',
                image: LIVE_STATIC_IMAGE_SRC,
                type: 'ivs',
                starttime: 0,
            }];
        }
    
        const captions = video?.closedCaptions?.map((caption: ClosedCaption) => {
            return {
                file: caption.url,
                label: caption.label,
                kind: 'captions'
            } as CaptionTrack;
        });

        const currentPlaybackProgress = store.getState().player.playbackProgress.find(x => x.itemSk === currentSegmentItemSk);
        const currentVideoPosition = currentPlaybackProgress?.position ?? 0;
        const currentVideoDuration = currentPlaybackProgress?.totalTime ?? 0;

        // Offset the position if the last watched position is the same time as cpeMarker to avoid glitch in cle timer state
        const playlistPosition = cpeMarkers?.some(x => Math.round(x.position ?? 0) === currentVideoPosition)
            ? Math.max(currentVideoPosition - 1, 0)
            : currentVideoPosition;

        return [{
            file: video?.url || '',
            image: coverImage,
            starttime: requestStartTime ?? (
                positionParameter
                    ? Number.parseInt(positionParameter)
                    : playlistPosition < currentVideoDuration
                        ? playlistPosition
                        : 0
            ),
            tracks: captions,
        }];
    }, [coverImage, cpeMarkers, currentSegmentItemSk, hasLoadedLaunchDetails, launchDetails?.externalUrl, launchDetails?.isWebcast, positionParameter, requestStartTime, store, video?.closedCaptions, video?.url]);

    useEffect(() => {
        if (didImagesFinishCaching) {
            setHasCachedImages(true);
            return;
        }

        const coverImage = playlist[0]?.image;

        if (!coverImage) return;

        cacheImagesAsync([coverImage], launchDetails?.isWebcast ? LIVE_STATIC_IMAGE_SRC : OD_STATIC_IMAGE_SRC)
            .then(cachedImages => {
                dispatch(pushPosterImageCachedImages(cachedImages));
                setHasCachedImages(true);
            });
    },[didImagesFinishCaching, dispatch, launchDetails?.isWebcast, playlist]);

    return {
        playlist: hasCachedImages ? playlist : [],
        video,
        segment,
    };
};
