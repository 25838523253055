import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { IFacultyProps } from '../Faculty/Faculty';

export interface FacultyState {
    selectedFaculty?: IFacultyProps;
}

const initialState: FacultyState = {
    selectedFaculty: undefined
};

const facultySlice = createSlice({
    name: 'faculty',
    initialState,
    reducers: {
        setSelectedFaculty(state, action: PayloadAction<IFacultyProps>) {
            state.selectedFaculty = action.payload;
        }
    },
});

export const { setSelectedFaculty } =
    facultySlice.actions;

export const { reducer: facultyReducer } = facultySlice;
