import '../../styles/_text-area.scss';

import React, { useState } from 'react';

export interface TextInputProps {
    currentValue?: string;
    textAreaLabel?: string;
    updateValue: (inputValue: any) => void;
    maxLength?: number;
    required?: boolean;
    showTextAreaError: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    ariaLabel?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextInputProps>(
    (
        {
            currentValue,
            textAreaLabel,
            updateValue,
            required,
            showTextAreaError,
            maxLength,
            ariaLabel,
        },
        textAreaRef
    ) => {
        const [textAreaFocused, setTextAreaFocused] = useState(false);

        const handleBlur = (e: React.FocusEvent) => {
            setTextAreaFocused(false);
        };

        const uniqueId = `textArea-${Math.random().toString(16).slice(2)}`;

        return (
            <>
                <div
                    className={`text-area ${
                        textAreaFocused
                            ? 'text-area--focused'
                            : currentValue?.length && currentValue?.length > 0
                                ? 'text-area--populated'
                                : ''
                    } ${showTextAreaError ? 'text-area--has-error' : ''}`}
                    data-replicated-value={currentValue}
                >
                    <label
                        id={`label-${uniqueId}`}
                        className="text-area__label sentence-label"
                        htmlFor={uniqueId}
                    >
                        {textAreaLabel}
                        {required && (
                            <span
                                aria-hidden="true"
                                title="required"
                                className="ml-1"
                            >
                                *
                            </span>
                        )}
                    </label>
                    <textarea
                        className="text-area__text-area paragraph-1"
                        id={uniqueId}
                        name={textAreaLabel}
                        onBlur={handleBlur}
                        onFocus={() => setTextAreaFocused(true)}
                        onChange={(e) => updateValue(e.currentTarget.value)}
                        value={currentValue ?? ''}
                        placeholder={
                            textAreaFocused
                                ? ''
                                : `${textAreaLabel} ${required ? '*' : ''}`
                        }
                        ref={textAreaRef}
                        maxLength={500}
                        required={required}
                        aria-describedby={
                            showTextAreaError ? `error-${uniqueId}` : `label-${uniqueId}`
                        }
                        aria-invalid={currentValue === ''}
                        aria-label={ariaLabel}
                    />
                    <div
                        className={`text-area__character-count sentence-label ${
                            maxLength && currentValue?.length === maxLength
                                ? 'text-area__character-count--max'
                                : ''
                        }`}
                    >
                        {`${currentValue?.length}/500`}
                    </div>
                </div>
                <div
                    className={`sentence-label text-input__error ${
                        showTextAreaError ? 'text-input__error--visible' : ''
                    }`}
                    aria-live="polite"
                >
                    <i className="watch watch-alert mr-1"></i>
                    {showTextAreaError && (
                        <span id={`error-${uniqueId}`}>
                            This field is required
                        </span>
                    )}
                </div>
            </>
        );
    }
);

TextArea.displayName = 'TextArea';

export default TextArea;
