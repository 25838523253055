const formatReloadingPageString = (message: string) => {
    return message
        .replace('reload the page', '<a href="javascript:window.location.reload(true)">reload the page<a>')
        .replace('reloading the page', '<a href="javascript:window.location.reload(true)">reloading the page<a>');
};

const concatErrorCode = (message: string, code: string) => {
    return message
        .concat(`<p>${code}</p>`);
};

export const formatErrorMessage = (message: string, code?: string) => {
    const formattedMessage = formatReloadingPageString(message);
    return code ? 
        concatErrorCode(formattedMessage, code)
        : formattedMessage;
};