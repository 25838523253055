import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

import { type AppStore } from '../stories/Store/configureStore';
import * as Actions from '../stories/Store/signalRSlice';
import logger from './logger';

const hubConnection = new HubConnectionBuilder()
    .withUrl('/signalr', {
        skipNegotiation: false,
        transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .configureLogging(process.env.REACT_APP_CONSOLE_LOG_ENABLED?.toLowerCase() === 'true'
        ? LogLevel.Information
        : LogLevel.None)
    .build();

hubConnection.onclose(function () {
    // logger.log('SignalR disconnected');
});

hubConnection.onreconnecting((err: unknown) => {
    logger.log('SignalR onreconnecting error', {
        error: err,
    });
}); 
// logger.log('SignalR starting middleware');

let serviceStarted = false;

export function signalRStart(store: AppStore) {    
    if (!serviceStarted) {
        hubConnection.on('ReceiveMediaMessage', (message) => {
        // logger.log('SignalR received message', {
            //     message: message,
            // });
            store.dispatch(Actions.receiveRMessage(message));
        });
        serviceStarted = true;
    }

    // logger.log('SignalR starting connector');

    if (hubConnection.state === HubConnectionState.Disconnected) {
        hubConnection.start().then(() => {
            // logger.log('SignalR connector started');
        });
    }
}

export default hubConnection;
