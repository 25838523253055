import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface Toast {
    id: number,
    type: 'alert' | 'announcement',
    iconName?: 'alert', 
    contentType: 'html' | 'text',
    message: string,
    cta?: {
        text: string,
        link: string,
    },
    htmlContent: string,
    expirationTimeoutId: number,
    availableTimeoutId: number,
    needsReceiptUpdate: boolean;
}

interface HTMLContentToast {
    id?: number,
    iconName?: 'alert',
    contentType: 'html',
    htmlContent: string,
    expirationTimeoutId?: number,
    availableTimeoutId?: number,
    needsReceiptUpdate?: boolean;
}

interface StringContentToast {
    id?: number,
    contentType: 'text',
    message: string,
    cta?: {
        text: string,
        link: string,
    },
    expirationTimeoutId?: number,
    availableTimeoutId?: number,
    needsReceiptUpdate?: boolean;
}

type Alert = HTMLContentToast | StringContentToast
export type Announcement = HTMLContentToast | StringContentToast

export interface ToastState {
    toasts: Toast[]
}

const initialState: ToastState = {
    toasts: []
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addAlert(state, action: PayloadAction<Alert>) {
            const content = action.payload.contentType === 'html'
                ? action.payload.htmlContent
                : action.payload.message;

            const existingToast = state.toasts.find(t => t.htmlContent === content || t.message === content);

            if (existingToast) {
                return;
            }
            
            const alert: Toast = { 
                id: Date.now(), 
                type: 'alert',
                message: '',
                htmlContent: '',
                availableTimeoutId: 0,
                expirationTimeoutId: 0,
                needsReceiptUpdate: false,
                ...action.payload
            };
            state.toasts.push(alert);
        },
        addAnnouncement(state, { payload }: PayloadAction<Announcement>) {
            const announcement: Toast = { 
                id: Date.now(), 
                type: 'announcement',
                message: '',
                htmlContent: '',
                availableTimeoutId: 0,
                expirationTimeoutId: 0,
                needsReceiptUpdate: true,
                ...payload
            };
            
            const existingAnnouncementIndex = state.toasts.findIndex(t => t.id == payload.id);
            
            if (existingAnnouncementIndex === -1) {
                state.toasts.push(announcement);
                return;
            }

            state.toasts[existingAnnouncementIndex] = announcement;
        },
        removeToast(state, action: PayloadAction<number>) {
            const toast = state.toasts.find(t => t.id === action.payload);
            
            clearTimeout(toast?.availableTimeoutId);
            clearTimeout(toast?.expirationTimeoutId);
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
        },
        removePlaybackErrorAlert(state) {
            const playbackErrorAlert = state.toasts.find(t => t.htmlContent.includes('290000'));
            
            if (playbackErrorAlert) {
                toastSlice.caseReducers.removeToast(state, { payload: playbackErrorAlert.id, type: 'toast/removeToast' });
            }
        },
    },
});

export const {
    addAlert,
    addAnnouncement,
    removeToast,
    removePlaybackErrorAlert,
} = toastSlice.actions;

export const { reducer: toastReducer } = toastSlice;
