import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect } from 'react';

import { useGetCleSessionQuery } from '../../datasource/queries/cle';
import { useAppDispatch, useAppSelector } from '../../hooks';
import ProgramEvaluationSurvey from '../ProgramEvaluationSurvey/ProgramEvaluationSurvey';
import { ProgramEvaluationSurveyFromWms } from '../ProgramEvaluationSurveyFromWms/ProgramEvaluationSurveyFromWms';
import { setIsSideBarDisabled } from '../Store/menuSlice';

type IProgramEvaluationPanelProps = {
    onSubmit?: () => void;
}

export const ProgramEvaluationPanel = ({ onSubmit }: IProgramEvaluationPanelProps) => {
    const encryptedRegistrationId = useAppSelector((state) => state.requestCredit.lastSubmittedEncryptedRegistrationId ?? state.requestCredit.encryptedRegistrationId);
    const dispatch = useAppDispatch();

    const { data: cleSession } = useGetCleSessionQuery(encryptedRegistrationId ?? skipToken);

    const surveyFromWms = cleSession?.watchWmsSfSurveyEnabled;

    useEffect(() => {
        dispatch(setIsSideBarDisabled(true));
    }, [dispatch]);
    
    return (
        (surveyFromWms) ? 
            <ProgramEvaluationSurveyFromWms encryptedRegistrationId={encryptedRegistrationId} onSubmit={onSubmit} />
            :
            <ProgramEvaluationSurvey encryptedRegistrationId={encryptedRegistrationId} />          
    );
};

export default ProgramEvaluationPanel;