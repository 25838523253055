import '../../styles/_main-error.scss';

import React, { PropsWithChildren } from 'react';

import { useGetClientConfigQuery } from '../../datasource/queries/clientConfig';
import { ModalPanel } from '../ModalPanel/ModalPanel';

export enum ErrorContainer {
    Standalone,
    PlayerModal,
    SmallPanel,
    LargePanel
}

export type IMainErrorProps = PropsWithChildren<{
    noHelpInfo?: boolean;
    errorContainer: ErrorContainer;
    marginRequired?: boolean;
}>

function reloadPage() {
    window.location.reload();
}
export function MainError({ noHelpInfo, errorContainer, marginRequired=false, children }: IMainErrorProps) {
    const { data: clientConfig } = useGetClientConfigQuery();
    const helpMessage = <>For more help, contact customer service at <a href="tel:8002604754">800.260.4754</a> or visit our <a href={clientConfig?.pliHelpCenterUrl ?? ''}>help center</a>.</>;
    const marginTop = marginRequired? 'mt-4' : 'mt-0';
    return (
        <>{errorContainer === ErrorContainer.Standalone &&
            <ModalPanel isOpen noNav className="watch-modal--standalone d-flex justify-content-center">
                <div className="watch-modal__content watch-modal__content--slim text-center">
                    <div className="main-error main-error--standalone text--white">
                        {children}
                        {!noHelpInfo && clientConfig && (
                            <>
                                <br />
                                <small>{helpMessage}</small>
                            </>
                        )}
                    </div>
                </div>
            </ModalPanel> }

        {(errorContainer === ErrorContainer.SmallPanel ||
          errorContainer === ErrorContainer.PlayerModal || 
          errorContainer === ErrorContainer.LargePanel) &&
            <div className={`main-error main-error--component ${marginTop}`}>
                <h1 className={`${errorContainer === ErrorContainer.SmallPanel ? 'heading-5' : 'heading-4'} mb-0`} >
                    Sorry, that didn’t work
                </h1>
                <p className="paragraph-1 mb-0">Please try <button className='main-error main-error--link_button' 
                    onClick={reloadPage}>reloading the page</button>.</p>
                <p className="paragraph-1 mb-0">{helpMessage}</p>
            </div>
        }
        </>
    );
}