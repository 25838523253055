import '../../styles/_percent-circle.scss';

import React from 'react';

import { ScreenSizeQueries } from '../../enums/ScreenSizeQueries';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export type IPercentCircleProps = {
    percentCompleted?: number;
    isPlayable?: boolean;
    isHidden?: boolean;
};

export const PercentCircle = ({ percentCompleted = 0, isPlayable, isHidden } : IPercentCircleProps) => {
    const isLargeScreen = useMediaQuery(ScreenSizeQueries.LARGE);

    const isComplete = percentCompleted === 100;
    const notStarted = percentCompleted === 0;
    
    const altText = !isPlayable 
        ? 'percent' 
        : 'percent, play segment' ;

    const strokeWidth = isLargeScreen ? 4 : 3;

    const circle = {
        size: isLargeScreen ? 32 : 24,
        stroke: strokeWidth,
        get radius() {
            return this.size / 2 - this.stroke / 2;
        },
        get offset() {
            return 2 * Math.PI * this.radius;
        },
    };

    const playButtonPath = isLargeScreen
        ? 'M13 11V21L21 16L13 11Z'
        : 'M9.75 8.25V15.75L15.75 12L9.75 8.25Z';

    const checkMarkPath = isLargeScreen
        ? 'M10 15.6364L14.5882 20L23 12'
        : 'M7.5 11.7273L10.9412 15L17.25 9';

    return (
        <div className={`percent-circle ${isHidden ? 'invisible' : ''}`} aria-hidden={isHidden}>
            <div style={{ width: circle.size, height: circle.size, fill: 'none' }}>
                <svg role='img' viewBox={`0 0 ${circle.size} ${circle.size}`} aria-label={`${(percentCompleted.toFixed(0)).toString()} ${altText}`}>
                    <circle
                        cx={circle.size / 2}
                        cy={circle.size / 2}
                        r={circle.radius}
                        stroke='#333333'
                        strokeWidth={strokeWidth}
                    />
                    <circle
                        cx={circle.size / 2}
                        cy={circle.size / 2}
                        r={circle.radius}
                        stroke='#01C365'
                        strokeWidth={strokeWidth}
                        strokeDasharray={[
                            (percentCompleted * circle.offset) / 100,
                            circle.offset,
                        ].join(',')}
                        strokeLinecap="butt"
                        transform={`rotate(-90 ${circle.size / 2} ${circle.size / 2})`}
                    />
                    {isComplete ? (
                        <path
                            d={checkMarkPath}
                            stroke='#01C365'
                            strokeWidth={strokeWidth}
                        />
                    ) : (
                        isPlayable && 
                            <path
                                d={playButtonPath} 
                                fill={notStarted ? '#888888' : '#01C365'}
                            />
                    )}
                </svg>
            </div>
        </div>
    );
};