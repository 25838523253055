
import { createSlice,PayloadAction } from '@reduxjs/toolkit';

import { IndividualCreditAndLicenseInfo } from '../../datasource/generated';
import launchSlice from '../../datasource/mutations/launch';
import cleSlice from '../../datasource/queries/cle';
import licensesSlice from '../../datasource/queries/licenses';
import { ILicenseModalFocusTarget } from '../LicenseModal/LicenseModal';

interface LicenseEditArgs {
    license: IndividualCreditAndLicenseInfo,
    jurisdictionName: string,
}
export interface RequestCreditState {
    encryptedRegistrationId?: string;
    lastSubmittedEncryptedRegistrationId?: string;
    programPk?: string;
    programTitle?: string;
    isPortable: boolean;
    authCodes?: number[];
    licenseModalState: 'add' | 'edit' | false;
    licenseBeingEdited?: LicenseEditArgs;
    errorMessageElementIds: string[];
    disabledIndvLicenseSks: number[];
    licenses: IndividualCreditAndLicenseInfo[];
    authorizationCode?: number;
    itemSk?: number;
    licenseFocusTarget?: ILicenseModalFocusTarget;
}

const initialState: RequestCreditState = {
    encryptedRegistrationId: undefined,
    lastSubmittedEncryptedRegistrationId: undefined,
    programPk: undefined,
    programTitle: undefined,
    isPortable: false,
    authCodes: undefined,
    licenseModalState: false,
    licenseBeingEdited: undefined,
    errorMessageElementIds: [],
    disabledIndvLicenseSks: [],
    licenses: [],
    authorizationCode: undefined,
    itemSk: undefined,
    licenseFocusTarget: undefined,
};

const requestCreditSlice = createSlice({
    name: 'requestCredit',
    initialState,
    reducers: {
        setEncryptedRegistrationId(state, action: PayloadAction<string>) {
            state.encryptedRegistrationId = action.payload;
        },
        setLastSubmittedEncryptedRegistrationId(state, action: PayloadAction<string>) {
            state.lastSubmittedEncryptedRegistrationId = action.payload;
        },
        setIsAddingLicense(state) {
            state.licenseModalState = 'add';
        },
        setIsEditingLicense(state, action: PayloadAction<LicenseEditArgs>) {
            state.licenseModalState = 'edit';
            state.licenseBeingEdited = action.payload;
        },
        closeLicenseModal(state, action: PayloadAction<ILicenseModalFocusTarget>) {
            state.licenseFocusTarget = action.payload;
            state.licenseModalState = false;
            state.licenseBeingEdited = undefined;
        },
        addErrorMessageElementId(state, action: PayloadAction<string>) {
            if (state.errorMessageElementIds.findIndex(el => el === action.payload) === -1)
                state.errorMessageElementIds = [...state.errorMessageElementIds, action.payload];
        },
        removeErrorMessageElementId(state, action: PayloadAction<string>) {
            state.errorMessageElementIds = [...state.errorMessageElementIds.filter(el => el !== action.payload)];
        },
        disableLicenseForCreditRequest(state, action: PayloadAction<number>) {
            if (state.disabledIndvLicenseSks && state.disabledIndvLicenseSks.findIndex(l => l === action.payload) === -1)
                state.disabledIndvLicenseSks = [...state.disabledIndvLicenseSks, action.payload];
        },
        enableLicenseForCreditRequest(state, action: PayloadAction<number>) {
            if (state.disabledIndvLicenseSks.findIndex(l => l === action.payload) !== -1) {
                state.disabledIndvLicenseSks = [...state.disabledIndvLicenseSks.filter(l => l !== action.payload)];
            }
        },
        setAuthorizationCode(state, action: PayloadAction<number | undefined>) {
            state.authorizationCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            licensesSlice.endpoints.getLicenses.matchFulfilled,
            (state, { payload }) => {
                state.licenses = payload;
                // Filter disabled Sks that are no longer in users licenses
                state.disabledIndvLicenseSks = [...state.disabledIndvLicenseSks.filter(d => payload.some(l => l.indvStateLicense_SK === d))];
            }
        );
        /// consolidate launchDetails & cleSession data into required data for request credit
        builder.addMatcher(
            launchSlice.endpoints.launch.matchFulfilled,
            (state, { payload }) => {
                state.encryptedRegistrationId = payload.encryptedRegistrationId ?? undefined;
                state.programPk = payload.itemPk ?? undefined;
                state.programTitle = payload.title ?? undefined;
                state.isPortable = payload.isPortable ?? false;
                state.authCodes = payload.video?.authCodes?.map(x => x.authorizationCode ?? 0) ?? undefined;
                state.itemSk = payload.itemSk;
            }
        );
        builder.addMatcher(
            cleSlice.endpoints.getCleSession.matchFulfilled,
            (state, { payload, meta }) => {
                // When survey gets CleSession we shouldn't update the state to the 
                // already submitted credit request
                if(meta.arg.originalArgs === state.lastSubmittedEncryptedRegistrationId)
                    return;
                
                state.encryptedRegistrationId = meta.arg.originalArgs ?? undefined;
                state.programPk = payload.item_PK ?? undefined;
                state.programTitle = payload.programFullName ?? undefined;
                state.isPortable = payload.isPortable ?? false;
                state.authCodes = payload.authCodes ?? undefined;
                state.itemSk = payload.item_SK;
            }
        );
        
        builder.addMatcher(
            cleSlice.endpoints.getCleSession.matchRejected,
            (state, { meta }) => {
                state.encryptedRegistrationId = meta.arg.originalArgs ?? undefined;
            },
        );
    }
});

export const {
    setEncryptedRegistrationId,
    setLastSubmittedEncryptedRegistrationId,
    setIsAddingLicense,
    setIsEditingLicense,
    closeLicenseModal, 
    addErrorMessageElementId,
    removeErrorMessageElementId,
    enableLicenseForCreditRequest,
    disableLicenseForCreditRequest,
    setAuthorizationCode,
} = requestCreditSlice.actions;

export const { reducer: requestCreditReducer } = requestCreditSlice;