import '../../styles/_survey.scss';

import React, { SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SFSurveyQuestion } from '../../datasource/generated';
import { RadioInput } from '../RadioInput/RadioInput';
import TextInput from '../TextInput/TextInput';
import Toggle from '../Toggle/Toggle';
import { isQuestionRequired,surveyContext,SurveyQuestionType } from './utils';

type ISurveyQuestionProps = {
    question: SFSurveyQuestion,
}

export const SurveyQuestion = ({ question }:ISurveyQuestionProps) => {
    const inputName = `question.sk_${question.sk}`;

    const { formErrors } = useContext(surveyContext);

    const questionError = useMemo(() => {
        return formErrors.find(err => err.path.join('.') === inputName);
    },[formErrors, inputName]);

    const container = useRef<HTMLDivElement>(null);
    const [applicableState, setApplicableState] = useState(false);
    const applicableLabel = applicableState ? 'Not applicable' : 'Applicable';

    useEffect(() => {
        if(!applicableState)
            return;
        container.current?.querySelectorAll<HTMLInputElement>('input[type=radio]').forEach((input) => {
            input.checked = false;
        });
    },[applicableState]);

    const customStyle = question.surveyQuestionTitle === 'Overall Comments' ? true : false;
    const ratingGroup = question.surveyQuestionType === SurveyQuestionType.RADIO_GROUP_RATING ? true : false;

    const handleToggleChange = (e: SyntheticEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget;
        setApplicableState(checked);
    };

    const handleRadioInputChange = () => {
        setApplicableState(false);
    };
    
    return (
        <div ref={container} className="survey-question">
            <p style={{ color:  ratingGroup || customStyle ? 'white': 'silver', 
                paddingTop: ratingGroup || customStyle ? '56px' : '',
                fontSize: customStyle ? '24px' : '', 
                fontWeight: customStyle ? '700' : '', 
                lineHeight: customStyle ? '32px' : '',
                letterSpacing: customStyle ? '-0.02em' : '',
                marginBottom: customStyle ? '36px' : ''

            }}>
                {question.surveyQuestionText}
                {isQuestionRequired(question) && '*'}
            </p>
            <div>
                {question.surveyQuestionType === SurveyQuestionType.RADIO_GROUP && (
                    <ul>
                        <div className='radio-group'>
                            {question.surveyQuestionChoices?.map((choice) => (
                                <div key={choice.sk}>
                                    <RadioInput                                                                                  
                                        name={inputName}
                                        value={choice.surveyQuestionChoiceValue || ''}
                                        onChange={handleRadioInputChange}
                                        required={isQuestionRequired(question)}
                                        variant={'default'}>
                                        {choice.surveyQuestionChoiceValue}
                                    </RadioInput>
                                </div>
                            ))}
                        </div>
                    </ul>
                )}
                {ratingGroup && (
                    <div className='radio-input__group--inline'>
                        {question.surveyQuestionChoices?.map((choice) => (
                            <React.Fragment key={choice.sk}>
                                {choice.surveyQuestionChoiceTitle === 'N/A' ? (
                                    <div style={{ display: 'flex', flex: '1 0 100%' }}>
                                        <label 
                                            style={{ marginTop: '56px' }}
                                            htmlFor={inputName}>
                                            Not Applicable
                                        </label>
                                        <span style={{ marginLeft: 'auto', lineHeight: 'normal', marginTop: '56px' }}>
                                            <Toggle
                                                id={inputName}
                                                name={inputName}
                                                color='amethyst'
                                                ariaLabel={applicableLabel}
                                                checked={applicableState}
                                                onChange={(e) => handleToggleChange(e)}
                                                showCheckmark={false}
                                                size='large'
                                                required={isQuestionRequired(question)}
                                                value={choice.surveyQuestionChoiceValue ?? ''}
                                            />
                                        </span>
                                    </div>
                                ):
                                    (
                                                                                
                                        <RadioInput                                                                                  
                                            name={inputName}
                                            value={choice.surveyQuestionChoiceValue || ''}
                                            onChange={handleRadioInputChange}
                                            required={isQuestionRequired(question)}
                                            title={choice.surveyQuestionChoiceTitle || ''} 
                                            variant={'rating'}>
                                            {choice.surveyQuestionChoiceValue}
                                        </RadioInput>
            
                                    )}
                            </React.Fragment>
                        ))}
                    </div>    
                )}
                {question.surveyQuestionType === SurveyQuestionType.TEXT && (
                    <div className='survey__question-text'>

                        <TextInput
                            name={inputName}
                            initialValue={''}
                            showInputError={false}
                            disabled={false}
                            placeholder='Add comment'
                            variant={'survey'} />
                    
                    </div>
                )}
            </div>
            {
                questionError &&                       
                        <div className='survey__error'>
                            {questionError?.message}
                        </div>
            }
        </div>
    );
};